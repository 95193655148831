import {Paged} from "./Paged";
import {MyDate} from "./MyDate";
import {Struttura} from "./Struttura";

export class Blocco {

    id?: number;
    blocco?: string;
    piano?: string;
    dataInizioVal?: MyDate;
    dataFineVal?: MyDate;
    struttura?: Struttura;

    constructor(data: any) {
        Object.assign(this, data);
        this.struttura = data.struttura ? new Struttura(data.struttura) : undefined;
        this.dataInizioVal = data.dataInizioVal ? new MyDate(data.dataInizioVal) : undefined;
        this.dataFineVal = data.dataFineVal ? new MyDate(data.dataFineVal) : undefined;
        // this.tipoStruttura = data.tipoStruttura;
    }

    /* servono per essere visualizzati nei dropdown */
    get viewValue(): string {
        //return this.blocco ? this.blocco : '';
		return this.blocco ? this.blocco + ' ' + 'PIANO:' +this.piano : ' - ';
    }
    get value(): Blocco {
        return this;
    }

    get desStruttura() {
        return this.struttura ? this.struttura.viewValue : '';
    }


    get dataInizioValGGMMAA() {
        return this.dataInizioVal ? this.dataInizioVal.giornoMeseAnno : null;
    }


    get dataFineValGGMMAA() {
        return this.dataFineVal ? this.dataFineVal.giornoMeseAnno : null;
    }
    getOggCreate() {
        return {

            id: this.id,
            blocco: this.blocco,
            piano: this.piano,
            struttura: this.struttura ? { id: this.struttura!.id!} : undefined,
            dataInizioVal: this.dataInizioVal ? this.dataInizioVal.dateSave : null,
            dataFineVal: this.dataFineVal
        };
    }

    getOggUpdate() {
        return this.getOggCreate();
    }



 }

export class BloccoPaged extends Paged {
    content: Blocco[];

    constructor(data: any) {
        super(data);

        this.content = data.content ? data.content.map((ts: any) => new Blocco(ts)) : [];
    }
}

