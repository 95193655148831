import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-image-firma',
  templateUrl: './image-firma.component.html',
  styleUrls: ['./image-firma.component.scss']
})
export class ImageFirmaComponent implements OnInit {

  @Input() valore: string | undefined;

  imgUrl?: SafeUrl;
  loading = false;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.getImage();
  }

  private getImage() {

  }

  private convertToBase64( buffer: any ): string {
    let binary = '';
    const bytes = new Uint8Array( buffer );
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  sanitize( url: string ) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
