import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormInputField} from "../../../entities/form/FormInputField";
import {StatusForm} from "../../../entities/statusForm";
import {TestiService} from "../../../service/config/testi.service";
import {DropdownItem} from "../../../entities/utils/DropdownItem";


@Component({
  selector: 'app-form-base',
  templateUrl: './form-base.component.html',
  styleUrls: ['./form-base.component.scss']
})
export class FormBaseComponent implements OnChanges, OnInit, OnDestroy {

  @Input() arrayFormInfo!: FormInputField[];
  @Input() objValue?: any;
  @Input() selectsGroup?:  Map<string, DropdownItem[]> = new Map<string, DropdownItem[]>();
  @Input() enableFooter = true;
  @Input() showSave?: boolean;
  @Input() groupValidator?: string;
  @Input() showCancel?: boolean = true;
  @Input() showDelete?: boolean = true;
  @Input() blockSave?: boolean;
  @Input() blockCancel?: boolean;
  @Input() blockDelete?: boolean;
  @Input() textSave?: string;
  @Input() textCancel?: string;
  @Input() textDelete?: string;
  @Input() testi?: any;
  @Input() color?: string;

  @Output() saveForm = new EventEmitter();
  @Output() deleteForm = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() updateForm = new EventEmitter();
  @Output() updateFormDetail = new EventEmitter();
  @Output() formState = new EventEmitter<StatusForm>();

  @Output() callback = new EventEmitter();

  msgs: any[] = [];

  formWidth: number;
  formGroup!: FormGroup;

  constructor( private formBuilder: FormBuilder,
               private testiService: TestiService) {

  }

  ngOnInit() {
    this.initFormGroup(this.arrayFormInfo);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['objValue'] && !!this.formGroup) {
      this.aggiornaForm();
    }
  }

  private initFormGroup(arrayFormInfo: FormInputField[]) {
    this.msgs = [];
    let property;
    this.formGroup = this.formBuilder.group(this.buildFormControl(arrayFormInfo));
    this.setGroupValidators(this.groupValidator);
    /** update ofter any value change */
    this.formGroup.valueChanges.subscribe(
      data => {
        const objReturn = this.objValue;
        for ( const propertyName in this.formGroup!.value) {
          objReturn[propertyName] = this.formGroup!.value[propertyName];
        }

        this.updateForm.emit(objReturn);
        this.updateFormDetail.emit(data);
      }
    );

    /** update ofter status change */
    this.formGroup.statusChanges
      .subscribe( res => this.formState.emit(res) );
    setTimeout( () => this.formState.emit(this.formGroup.status as 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED'), 100);
  }

  private aggiornaForm() {
    this.arrayFormInfo.forEach( form => {
      this.formGroup!.get(form.prop)!.setValue(this.objValue[form.prop]);
    });
  }

  private setGroupValidators(groupValidator: any) {
  }

  actionSave() {
    const objReturn = this.objValue;
    for ( const propertyName in this.formGroup!.value) {
      objReturn[propertyName] = this.formGroup!.value[propertyName];
    }

    this.saveForm.emit(objReturn);
  }

  actionDelete() {
    const objReturn = this.objValue;
    for ( const propertyName in this.formGroup!.value) {
      objReturn[propertyName] = this.formGroup!.value[propertyName];
    }

    this.deleteForm.emit(objReturn);
  }

  actionCancel() {
    this.cancel.emit();
  }

  private buildFormControl(arr: FormInputField[]) {
    const group: any = {};

    arr.forEach(item => {
      ////console.log(item);
      group[item.prop] = this.objValue[item.prop] == null ? this.formControlNew(item) : this.formControlUpdate(item);
      group[item.prop].setValidators(this.makeValidators(item));
    });

    if (this.objValue.id && !group['id'])  {
      group['id'] = new FormControl({value: this.objValue.id, disabled: true});
    }

    return group;
  }

  private formControlUpdate(item: FormInputField): FormControl {
    return new FormControl({value: this.objValue[item.prop], disabled: item.block},{ nonNullable: true });
  }

  private formControlNew(item: FormInputField): FormControl {

    return new FormControl({value: '', disabled: item.block ? item.block : false });
  }

  private makeValidators(item: FormInputField): Validators[] {
    let _array: Validators[] = [];
    item.validator && (_array = item.validator.map(itemVal => { this.msgs.push({prop: item['prop'], msg: itemVal['errorMsg']});
      return itemVal['value'];
    } ));
    item.required && _array.push(Validators.required);

    return _array;
  }

  ngOnDestroy(): void {
    if (this.formGroup) {
      // this.formGroup = undefined;
    }
  }

}
