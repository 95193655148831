import {Paged} from "./Paged";
import {TipoStruttura} from "./TipoStruttura";
import {CentroCottura} from "./CentroCottura";
import {MyDate} from "./MyDate";
import moment from "moment";
import {DropdownItem} from "./utils/DropdownItem";

export class Struttura implements DropdownItem {

    id?: number;
    descrizione?: string;
	descrBreve?: string | null;
    tipoStruttura?: TipoStruttura;
    centroCottura?: CentroCottura;
    oraConsegnaVittoDa?:  MyDate | undefined;
    oraConsegnaVittoA?:  MyDate | undefined;
    constructor(data: any) {
        Object.assign(this, data);
        this.tipoStruttura = data.tipoStruttura ? new TipoStruttura(data.tipoStruttura) : undefined;
        this.centroCottura = data.centroCottura ? new CentroCottura(data.centroCottura) : undefined;
        this.oraConsegnaVittoDa = data.oraConsegnaVittoDa ? new MyDate(moment(data.oraConsegnaVittoDa, "HH.mm")) : undefined;
        this.oraConsegnaVittoA = data.oraConsegnaVittoA ? new MyDate(moment(data.oraConsegnaVittoA, "HH.mm")) : undefined;
        this.descrBreve = data.descrBreve ? data.descrBreve : data.desc_breve ? data.desc_breve : data.descBreve ? data.descBreve : null;
        // this.tipoStruttura = data.tipoStruttura;
    }

    /* servono per essere visualizzati nei dropdown */
    get viewValue(): string {
        return this.descrizione ? this.descrizione : '';
    }
    get value(): Struttura {
        return this;
    }

    get destipoStruttura() {
        return this.tipoStruttura ? this.tipoStruttura.viewValue : '';
    }

    get descentroCottura() {
        return this.centroCottura ? this.centroCottura.viewValue : '';
    }


    get oraConsegnaVittoDaHHMM() {
        return this.oraConsegnaVittoDa ? this.oraConsegnaVittoDa.timeGet: null;
    }

    get oraConsegnaVittoAHHMM() {
        return this.oraConsegnaVittoA ? this.oraConsegnaVittoA.timeGet: null;
    }
getOggCreate() {
        return {
            id: this.id,
            descrizione: this.descrizione,
            tipoStruttura: this.tipoStruttura ? { id: this.tipoStruttura!.id!} : undefined,
            centroCottura: this.centroCottura ? { id: this.centroCottura!.id!} : undefined,
            oraConsegnaVittoDa: this.oraConsegnaVittoDa ? this.oraConsegnaVittoDa.timeSave : null,
            oraConsegnaVittoA: this.oraConsegnaVittoA ? this.oraConsegnaVittoA.timeSave : null,



        };
    }

    getOggUpdate() {
        return this.getOggCreate();
    }



 }

export class StrutturaPaged extends Paged {
    content: Struttura[];

    constructor(data: any) {
        super(data);

        this.content = data.content ? data.content.map((ts: any) => new Struttura(ts)) : [];
    }
}

