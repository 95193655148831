import {MyDate} from "./MyDate";

export class statoTicket {
    idStatoTicket: number;
    desStatoTicket: string;

    constructor(data: any) {
        Object.assign(this, data);
    }
    get viewValue(): string{
        return this.desStatoTicket ? this.desStatoTicket : '';
    }

    get value(): statoTicket{
        return this;
    }
}

export class tipoInterventi {
    idTipoInterventi: number;
    desTipoInterventi: string;

    constructor(data: any) {
        Object.assign(this, data);
    }

    get viewValue(): string{
        return this.desTipoInterventi ? this.desTipoInterventi : '';
    }

    get value(): tipoInterventi{
        return this;
    }
}


export class unitaServizio {
    id: number;
    idUnitaServizio: number;
    struttDesc: string;
    descUnitaServizio:  string;

    constructor(data: any) {
        if (data && data.id) {
            this.id = data.id;
            this.idUnitaServizio = data.id;
            this.struttDesc = data.struttDesc;
            this.descUnitaServizio = data.usDesc;
        } else {
            Object.assign(this, data);
        }
    }

    get viewValue(): string{
        return this.descUnitaServizio ? this.struttDesc + " - " + this.descUnitaServizio : '';
    }

    get value(): unitaServizio{
        return this;
    }
}

export class unitaServizioExtended {
    id: number;
    idUnitaServizio: number;
    struttDesc: string;
    descUnitaServizio:  string;
    valida: boolean;

    constructor(data: any) {
        if (data && data.id) {
            this.id = data.id;
            this.idUnitaServizio = data.id;
            this.struttDesc = data.struttDesc;
            this.descUnitaServizio = data.usDesc;
            this.valida = data.valid ? data.valida : true;
        } else {
            Object.assign(this, data);
        }
    }

    get viewValue(): string{
        return this.descUnitaServizio ? this.struttDesc + " - " + this.descUnitaServizio : '';
    }

    get value(): unitaServizio{
        return this;
    }
}

export class tipoUnitaServizio {
    id: number;
    usDesc:  string;
    struttDesc: string;

    constructor(data: any) {
        Object.assign(this, data);
    }

    get viewValue(): string{
        return this.usDesc ? this.struttDesc + " - " + this.usDesc : '';
    }

    get value(): tipoUnitaServizio{
        return this;
    }
}



export class utenteGestore {
    id: number;
    name:  string;
    user: user;

    constructor(data: any) {
        Object.assign(this, data);
    }

    get viewValue(): string{
        return this.name ? this.name : '';
    }
    get value(): utenteGestore{
        return this;
    }
}

export class user {
    id: number;
    username:  string;
    enabledFlag: string;
    dataInizioVal: MyDate;
    dataFineVal:MyDate;
    userIdMod: number;
    dataModifica: MyDate;
    uuid: string;

    constructor(data: any) {
        Object.assign(this, data);
    }

    get viewValue(): string{
        return this.username ? this.username : '';
    }
}

export class tipoStanza {
    saDesc: string;
    saCod: string;

    constructor(data: any) {
        this.saDesc=data.saDesc;
        this.saCod=data.saCod;
    }

    get viewValue(): string{
        return this.saDesc ? this.saDesc : '';
    }
    //TODO Debug Stanze
    get value(): string{
        return this.saDesc ? this.saDesc : '';
    }
}

export class impianto{
    idImpianto: number;
    desImpianto: string;

    constructor(data: any) {
        Object.assign(this, data);
    }

    get viewValue(): string{
        return this.desImpianto ? this.desImpianto : '';
    }

    get value(): impianto{
        return this;
    }
}

export class urgenza{
    idUrgenza: number;
    desUrgenza: string;

    constructor(data: any) {
        Object.assign(this, data);
    }

    get viewValue(): string{
        return this.desUrgenza ? this.desUrgenza : '';
    }

    get value(): urgenza{
        return this;
    }
}

export class tipoRifiuto{
    idTipoRifiuto: number;
    desTipoRifiuto: string;

    constructor(data: any) {
        Object.assign(this, data);
    }

    get viewValue(): string{
        return this.desTipoRifiuto ? this.desTipoRifiuto : '';
    }

    get value(): tipoRifiuto{
        return this;
    }
}

export class tipoRichiesta{
    idTipoTicket: number;
    desTipoTicket: string;

    constructor(data: any) {
        Object.assign(this, data);
    }

    get viewValue(): string{
        return this.desTipoTicket ? this.desTipoTicket : '';
    }

    get value(): tipoRichiesta{
        return this;
    }

    getOggUpdate() {
        return this.getOggCreate();
    }

    getOggCreate() {
        return {
            idTipoTicket: this.idTipoTicket,
            desTipoTicket: this.desTipoTicket
        };
    }


}

