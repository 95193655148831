import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatRadioModule} from '@angular/material/radio';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatInputModule} from '@angular/material/input';
import {MatSortModule} from '@angular/material/sort';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FormsModule} from '@angular/forms';
import {MatRippleModule} from '@angular/material/core';
import {RowDataComponent} from "./row-data/row-data.component";
import {TableFooterComponent} from "./table-footer/table-footer.component";
import {RowHeaderComponent} from "./row-header/row-header.component";
import {TableViewComponent} from "./table-view/table-view.component";
import {TableHeaderComponent} from "./table-header/table-header.component";
import {TableBaseComponent} from "./table-base/table-base.component";
import {TableHeaderFilterComponent} from "./table-header/table-header-filter/table-header-filter.component";
import {TableHeaderTipoStrutturaComponent} from "./table-header/table-header-tipostruttura/table-header-tipostruttura.component";
import {MatSelectModule} from "@angular/material/select";
import {BleInputModule} from "../ble-input/ble-input.module";
import {MatTooltipModule} from "@angular/material/tooltip";


@NgModule({
  declarations: [
    TableViewComponent,
    RowDataComponent,
    RowHeaderComponent,
    TableFooterComponent,
    TableHeaderComponent,
    TableBaseComponent,
    TableHeaderFilterComponent,
    TableHeaderTipoStrutturaComponent
  ],
  imports: [
    CommonModule,
    BleInputModule,
    MatButtonModule,
    MatTableModule,
    MatRadioModule,
    MatSelectModule,
    MatPaginatorModule,
    MatInputModule,
    MatSortModule,
    MatProgressBarModule,
    MatCheckboxModule,
    FormsModule,
    MatRippleModule,
    MatTooltipModule
  ],
  exports: [
    TableViewComponent
  ]
})
export class TableViewModule { }
