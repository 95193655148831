import {Component, EventEmitter, Input, NgIterable, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss']
})
export class ChipListComponent implements OnInit {

  @Input() valore?: string | NgIterable<string>;
  @Input() testo?: string;
  @Input() block?: boolean;
  @Input() invalid ? = false;

  @Output() cambioValore = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  isArray(obj: any ) {
    return Array.isArray(obj);
  }

}
