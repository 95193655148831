
<app-header-popup [testo]="testi.storicoRichiesta + ' : ' + data[2]"
                  [loading]="loading"
                  (close)="cancel()">
</app-header-popup >

<app-table-view *ngIf="data"
                [tableData]="data[0]"
                [tableDataHeader]="data[1]"
                [buttonAggiorna]="false"
                [onlyView]="true"
                [cleanName]="true"
                [buttonAdd]="false"
                [overflow]="'hidden'"
                [showFooter]="false"
                [showHeader] = "false"
                [noScrollV] = "true"
                [hideTitle]="true"
                [admin]="true"
                [type]="'richieste'"
                [testi]="testi"
                [loading]="loading"
                [rows]="20">
</app-table-view>


