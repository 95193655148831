<mat-chip-list aria-label="Operatore" [selectable]="false" *ngIf="!isArray(valore)">
  <mat-chip color="accent" [disableRipple]="true">
    {{valore}}
  </mat-chip>
</mat-chip-list>

<mat-chip-list aria-label="Operatori" [selectable]="false" *ngIf="isArray(valore)">
  <mat-chip *ngFor="let operatore of valore"
            [disableRipple]="true"
            color="accent">
    {{operatore}}
  </mat-chip>
</mat-chip-list>
