import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-ble-input-number',
  templateUrl: './ble-input-number.component.html',
  styleUrls: ['./ble-input-number.component.scss']
})
export class BleInputNumberComponent implements OnInit, OnChanges {

  @Input() valore?: number;
  @Input() testo?: string;
  @Input() block?: boolean;
  @Input() invalid? = false;
  @Input() unit?: string;
  @Input() max?: number;
  @Input() autolock?: boolean = false;

  @Output() cambioValore = new EventEmitter();

  _valore?: number;

  constructor() { }

  ngOnInit(): void {
    setTimeout( () =>{
      if (this.valore) this.cambioValore.emit(this.valore);
    },0);
    this.max = this.max ? this.max : 999999999999999999999999;
    if (this.valore && this.autolock){
      this.block = true;
      this.cambioValore.emit(this.valore);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    changes['valore'] && (this._valore = this.valore);
  }

  inputChange() {
    this.cambioValore.emit(Number(this._valore));
  }


}
