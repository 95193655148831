import {Component, NgZone, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {KeycloakService} from "keycloak-angular";
import {SdgLibService} from "sdg-lib";
import {keycloakHelper} from "../service/portal/keycloakHelper";
import {MenuService} from "../service/portal/menu.service";
import {RouteConf} from "../entities/RouteConf";
import {buildRoutes} from "../service/config/routes";


@Component({
    selector: 'app-menu',
    templateUrl: 'menu.component.html',
    styleUrls: ['menu.component.scss'],
})
export class MenuComponent implements OnInit {
    loading: boolean = false;

    authRoute: RouteConf[] = [];

    init: boolean = true;

    clicked: boolean[] = [];
    clickedChild: boolean[] = [];
    clickRST: boolean[] = [];

    private roles: string[] | undefined;

    constructor(private readonly keycloak: KeycloakService,
                private router: Router,
                private shared: SdgLibService,
                private kcHelper: keycloakHelper,
                public menuService: MenuService,
                public zone: NgZone

    ){}


    async ngOnInit(): Promise<void> {
        const response = await fetch('./assets/json/modules.json');
        await response.json().then((c) => {
            const preRoutes = buildRoutes(c, this.router, this.shared.utente.roles!);
            this.router.resetConfig(preRoutes);

            this.roles = this.kcHelper.getRoles(this.keycloak.getUserRoles());

            for (const routes in c) {
                let route = c[routes]['authRoute'];
                // @ts-ignore
                const result = route.filter(d => this.roles.includes(d));
                if (result.length != 0) {
                    this.authRoute.push(c[routes]);
                    if (c[routes].displayName === "RICHIESTE"){
                        this.clicked.push(true);
                    } else {
                        this.clicked.push(false);
                    }
                }
            }
            this.globalLoading();
            //this.openLink({},'richieste', 0);
            this.menuService.toggle();
        });
    }

    private globalLoading(){
        this.shared.getGlobalLoading().subscribe(
            (res: boolean) => {
                setTimeout(() => this.loading = res, 0);
            }
        )
    }


    public goTo(path: string): void{
        window.open(path, '_self');
    }

    resetClick(){
        const end = this.clicked.length;
        this.clicked.splice(0, end);
        for (let i=0; i <= end; i++){
            this.clicked.push(false);
        }
    }

    resetClickChildren(){
        this.clickedChild = [];
        this.clickedChild.push(true);
    }

    selectFirst(children: any){
        let index = 0;
        this.authRoute.forEach((value,i) => {
            if (value.routePath == children[0]){
                index = i;
            }
        });
        this.clickedChild[index] = true;
    }

    openLink(object: any, link: string, index: number, child?: boolean) {
        let curUrl = this.router.url.substring(1).length > 0 ? this.router.url.substring(1) : "richieste";
        curUrl = curUrl == 'anagrafiche/menu' ? 'anagrafiche' : curUrl;
        if (curUrl != link && !object.externalModule ) {
            this.shared.setGlobalLoading(false);
        } else {
            console.log(curUrl,link)
        }
        if (child){
            this.resetClickChildren();
            this.clickedChild[index] = true;
            if (!object.externalModule) {
                const dest: string[] = [];
                dest.push(link);
                this.zone.run(() => {
                    this.router.navigate(dest);
                });
            } else {
                window.open(object.remoteEntry, '_blank', 'toolbar=no, location=no, status=no, menubar=no, scrollbars=no, resizable=no, width=907, height=556');
            }
        } else {
            this.resetClick();
            this.init = true;
            this.resetClickChildren();
            this.clicked[index] = true;
            if (!object.externalModule) {
                const dest: string[] = [];
                dest.push(link);
                this.zone.run(() => {
                    this.router.navigate(dest);
                });
            } else {
                window.open(object.remoteEntry, '_blank', 'toolbar=no, location=no, status=no, menubar=no, scrollbars=no, resizable=no, width=907, height=556' );
            }
        }
    }
}

