import {Paged} from "./Paged";
import {DropdownItem} from "./utils/DropdownItem";


export class Dieta implements DropdownItem {

    id?: number;
    descrizione?: string;


    constructor(data: any) {
        Object.assign(this, data);

    }

    /* servono per essere visualizzati nei dropdown */
    get viewValue(): string {
        return this.descrizione ? this.descrizione : '';
    }
    get value(): Dieta {
        return this;
    }



    getOggCreate() {
        return {

            id: this.id,
            descrizione: this.descrizione,

        };
    }

    getOggUpdate() {
        return this.getOggCreate();
    }



 }

export class DietaPaged extends Paged {
    content: Dieta[];

    constructor(data: any) {
        super(data);

        this.content = data.content ? data.content.map((ts: any) => new Dieta(ts)) : [];
    }
}

