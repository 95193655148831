import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {SdgLibService} from "sdg-lib";
import {MatDialog} from "@angular/material/dialog";
import {CallbackTableState} from "../../../../entities/enums/CallbackTableState";
import {TableName} from "../../../../entities/table/TableName";
import {TableDocsCols} from "./table-docs-cols";
import {TestiService} from "../../../../service/config/testi.service";
import {DmsService} from "../../../../service/dms.service";
import {DocsDMS} from "../../../../entities/DMS";
import {CallbackDetailsState} from "../../../../entities/enums/CallbackDetailsState";
import {MsgService} from "../../../../service/utils/msg.service";
import {TableCallback} from "../../../../entities/table/TableCallback";
import {Download} from "../../../../entities/Download";


@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss']
})
export class DocsComponent implements OnInit {

  @Input() codSr!: string;
  @Input() statoId!: number;
  @Input() hidden?: boolean;
  @Input() dmsUpload?: boolean;
  @Input() block?: boolean = true;
  @Input() invalid?: boolean = true;
  @Input() listaDocs: Download[];
  @Input() folder: string;
  @Input() testi?: any;

  @ViewChild('fileInput', { static: false }) fileInput?: ElementRef;
  @ViewChild('filePreview', { static: false }) filePreview?: ElementRef;

  docs: DocsDMS[] = [];

  files?: File[];

  button: boolean;

  tableVoci!: TableName[];

  loading = false;

  constructor(private msgService: MsgService,
              private dmsService: DmsService,
              private dialogService: MatDialog,
              private testiService: TestiService,
              private readonly shared: SdgLibService) {

  }

  ngOnInit(): void {
    this.getAllDocs();
    this.tableVoci = this.block ? TableDocsCols.getDocsList(this.testi) : TableDocsCols.getDocsListBlock(this.testi);
  }

  ngOnChange(): void {
    ////console.log(this.block, this.hidden, this.invalid);
  }

  private getAllDocs(){
    this.dmsService.getFolderContent(this.folder).subscribe(res => {this.docs = this.userPermissions(res)},
        err => {
          this.msgService.msgAlert("Documentale momentaneamente non disponibile", this.testi);
          this.dmsUpload = false;
        });
  }

  private userPermissions(data: DocsDMS[]){
    for (let doc of data){
      ////console.log(doc.user);
      doc.cancella = doc.user == this.shared.utente.username ? true : false;
      ////console.log(doc.cancella);
    }
    return data;
  }

  callBackTable(event: TableCallback) {
    switch (event.type) {
      case CallbackTableState.DOUBLE:
      case CallbackTableState.SINGLE:
        this.showFile(event.data.id, event.data.name);
        break;
      case CallbackTableState.DOWNLOAD:
        this.downloadFile(event.data.id, event.data.name);
        break;
      case CallbackTableState.FILE:
        const file:File = event.data;
        this.dmsService.createUploadDMS(this.folder, file).subscribe(
            data => {
              this.dmsService.uploadDMS(data.entry.id, file).subscribe( res =>
                this.getAllDocs());
            },
            err => this.msgService.msgStringError(this.testi, err.error.error.errorKey));
        break;
      case CallbackTableState.DELETE:
        this.openConferma(event.data);
        break;
    }

  }

  downloadFile(id: string, name: string) {
    const downloadURL = this.dmsService.linkDMS() +  id;
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);

    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.shared.token);

    fetch(downloadURL, { headers })
        .then(response => response.blob())
        .then(blobby => {
          let objectUrl = window.URL.createObjectURL(blobby);
          anchor.href = objectUrl;
          anchor.download = name;
          anchor.click();
          window.URL.revokeObjectURL(objectUrl);
        });
  }

  showFile(id: string, name: string) {
    const downloadURL = this.dmsService.linkDMS() +  id;
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);

    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.shared.token);

    fetch(downloadURL, { headers })
        .then(response => response.blob())
        .then(blobby => {
          let objectUrl = window.URL.createObjectURL(blobby);
          window.open(objectUrl, "_blank");
        });
  }

onChangeFileInput(): void {
    /*
    // const files: { [key: string]: File } = this.fileInput.nativeElement.files;
    // this.file = files[0];

    const files: FileList = this.fileInput && this.fileInput.nativeElement.files;

    const arrayFiles: File[] = [];

    for (let i = 0; i < files.length; i++) {
      arrayFiles.push(files.item(i)!);
    }

    this.files = arrayFiles;
    */
  }

  onClickFileInputButton(): void {
  /*
    if(this.fileInput){
      this.fileInput.nativeElement.click();
    }
    */
  }

  upload() {
    /*
    if (this.files && this.files.length) {
      this.loading = true;


      const obs$: Observable<any> = this.files.length > 1 ?
          this.pulizieOrdinarieService.uploadDocumenti(this.codSr, this.files) : this.pulizieOrdinarieService.uploadDocumento(this.codSr, this.files[0]);

      obs$
          .subscribe(
              image => {
                this.files = undefined;
                this.getAllDocumenti(this.codSr);
              },
              () => this.loading = false,
              () => {
                this.loading = false;
              });
    }
    */
  }

  cancel() {
    this.files = undefined;
  }

  private openConferma(file: DocsDMS) {
    const msg = this.testi.confermaEliminaDocumento +" " + file.name + '?';
    this.msgService.popupConfermaPopup({type: CallbackDetailsState.DELETE, msg: msg, testi: this.testi}, this.testi)!
        .subscribe( res => {
          res.type == CallbackDetailsState.DELETE && this.deleteDocumento(file.id);
        } );
  }

  private deleteDocumento(fileId: string) {
    this.dmsService.cancelDMS(fileId).subscribe(res => this.getAllDocs());
  }

  ngOnDestroy(): void {

  }

}
