import {Paged} from "./Paged";

export class CentroCottura {

    id?: number;
    descrizione?: string;

    constructor(data: any) {
        Object.assign(this, data);
    }

    getOggCreate() {
        return {
            id: this.id,
            descrizione: this.descrizione,
        };
    }

    getOggUpdate() {
        return this.getOggCreate();
    }



    get viewValue(): string {
        return this.descrizione ? this.descrizione : '';
    }
    get value(): CentroCottura {
        return this;
    }






}

export class CentroCotturaPaged extends Paged {
    content: CentroCottura[];

    constructor(data: any) {
        super(data);

        this.content = data.content ? data.content.map((ts: any) => new CentroCottura(ts)) : [];
    }
}

