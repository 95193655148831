import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import {ErrorStateMatcher} from '@angular/material/core';
import {TestiService} from "../../../service/config/testi.service";
import {MsgService} from "../../../service/utils/msg.service";
import {PopUpInput} from "../../../entities/PopUpInput";
import {CallbackDetailsState} from "../../../entities/enums/CallbackDetailsState";
import {PopUpConferma} from "../../../entities/PopUpConferma";


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-popup-input',
  templateUrl: './popup-input.component.html',
  styleUrls: ['./popup-input.component.scss']
})
export class PopupInputComponent implements OnInit {

  constructor( private testiService: TestiService,
               public dialogRef: MatDialogRef<PopupInputComponent>,
               private msgService: MsgService,
               @Inject(MAT_DIALOG_DATA) public ogg: PopUpInput) {
    this.testi = this.testiService.componentDms;
  }

  testi;
  loading = false;

  type?: string;
  title?: string;
  placeholder? : string = this.title;
  valore: string = "";
  msgConferma?: string;

  footerConferma = true;

  folderFormControl = new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9 ]*')]);

  matcher = new MyErrorStateMatcher();

  ngOnInit(): void {
    if (this.ogg.type == CallbackDetailsState.NEW) {
      this.setPopupDelete(this.ogg);
    }
  }

  private setPopupDelete(data: PopUpConferma) {
    this.type = data.type;
    this.footerConferma = true;

    this.title = data.title ? data.title : this.testi.msgConfermaDeleteHeader;
    this.msgConferma = ` ${data.msg}:`;
  }

  conferma(data: string) {
    if (this.ogg.invalidFolder && !this.ogg.invalidFolder.includes(data)) {
      this.dialogRef.close({type: CallbackDetailsState.NEW, data: data});
    } else {
      this.msgService.msgStringError("Cartella già presente", this.testi);
    }
  }

  fallback(e: any){
    e.preventDefault();
  }

  closePanel() {
    this.dialogRef.close({type: CallbackDetailsState.CANCEL});
  }
}
