import {Component, HostListener, Inject, OnInit} from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormResponse} from "../../entities/FormResponse";
import {
    impianto,
    tipoInterventi,
    tipoRichiesta,
    tipoRifiuto,
    tipoStanza,
    unitaServizio, unitaServizioExtended,
    urgenza,
    utenteGestore
} from "../../entities/richiesteDetails";
import {TestiService} from "../../service/config/testi.service";
import {DmsService} from "../../service/dms.service";
import {TableRichiesteCols} from "../richieste/table-richieste-cols";
import {RichiesteService} from "../../service/richieste.service";
import {TableGroups} from "../../entities/table/TableGroups";
import {PopUpDetails} from "../../entities/PopUpDetails";
import {DetailsCallback} from "../../entities/table/DetailsCallback";
import {SdgLibService} from "sdg-lib";
import {DocsDMS} from "../../entities/DMS";
import {Richiesta} from "../../entities/Richiesta";
import {CallbackDetailsState} from "../../entities/enums/CallbackDetailsState";
import {MsgService} from "../../service/utils/msg.service";
import {ApiRestServicePortal} from "../../service/portal/api-rest.service-portal";



@Component({
  selector: 'app-richieste-details',
  templateUrl: './richieste-details.component.html',
  styleUrls: ['./richieste-details.component.scss']
})
export class RichiesteDetailsComponent implements OnInit {
    @HostListener('document:keydown', ['$event'])
        handleKeydown(event: KeyboardEvent) {
            if (event.key === 'Escape') {
                this.cancel("hostListener");
            }
    }

  title?: string;
  loading = false;

  docs: DocsDMS[] = [];

  prevRifiuto: any = undefined;
  prevIntervento: any = undefined;
  prevGestore: any = undefined;
  prevScadenza: any = undefined;
  prevMod = false;
  prevNote = '';
  intervento = true;

  operatoreLock: boolean = true;

  dmsUpload: boolean = false;

  btn: number;

  form: any;

  role: string;

  arrayFormInfo: TableGroups[];
  accessibilita: string = "";
  formButton: any;

  dryrun: boolean = true;

  richiesta: Richiesta;

  dropdownList: Map<string,any[]> = new Map<string,any[]>();

  update: boolean;

  error: string = "";

  msgForbidden: string;

  testi;
  instance: string;

  richiesteUsers= ["caposala", "operatore", "responsabile", "gestore", "ammnistratore"];

  constructor(public dialogRef: MatDialogRef<RichiesteDetailsComponent>,
              private testiService: TestiService,
              private richiesteService: RichiesteService,
              private portalService: ApiRestServicePortal,
              private dmsService: DmsService,
              private msgService: MsgService,
              private TRCols: TableRichiesteCols,
              private readonly shared: SdgLibService,
              @Inject(MAT_DIALOG_DATA) public data: PopUpDetails) {
    this.testi = this.testiService.componentRichieste;
    this.instance = this.portalService.portalName;

    this.msgForbidden = this.testi.msgForbidden;

    this.richiesta = this.data.oggetto;

    //console.log(this.richiesta);

    this.update = this.richiesta.numProto ? true : false;

    this.role = this.role ? this.role :  this.role = shared.utente!.roles!.filter(value => this.richiesteUsers.includes(value))[0];

    //console.log(this.role+this.richiesta.statoTicket!.desStatoTicket);

    this.arrayFormInfo = this.richiesta.idTicket ? this.TRCols.groups(this.instance, this.testi, this.role+this.richiesta.statoTicket!.desStatoTicket) : this.TRCols.groupsNew(this.instance,this.testi, this.role+"Nuova");
    this.formButton = this.richiesta.idTicket ? this.TRCols.formButton(this.testi, this.role+this.richiesta.statoTicket!.desStatoTicket) : this.TRCols.formButton(this.testi, this.role+"Nuova");
    this.title = this.richiesta.idTicket ? this.TRCols.formTitle(this.testi, this.role+this.richiesta.statoTicket!.desStatoTicket) + " - " + this.richiesta.idTicket : this.TRCols.formTitle(this.testi, this.role+"Nuova");

    this.dmsUpload = this.richiesta.idTicket ? false : true;

    if (this.data.dropdownList) {
      this.dropdownList = this.data.dropdownList;
    }
  }

  ngOnInit(): void {
    this.setDropDown(this.richiesta.idTicket == undefined || this.role+this.richiesta.statoTicket!.desStatoTicket == "operatorePRESA IN CARICO OPERATORE");
    if (this.richiesta.idTicket && this.role+this.richiesta.statoTicket!.desStatoTicket == "operatorePRESA IN CARICO OPERATORE") {
        this.accessibilita = "Selezionare il Tipo Rifiuto per abilitare il tasto Rifiuta, Selezionare almeno il campo gestore per abilitare il tasto Approva"
    } else {
        this.accessibilita = "";
    }
    if (this.richiesta.idTicket && this.role+this.richiesta.statoTicket!.desStatoTicket == "operatorePRESA IN CARICO OPERATORE" && this.richiesta.utenteGestore){
        setTimeout(()=> {
            this.prevGestore = '';
            this.updateForm(this.richiesta);
        });
    }
  }

  updateForm(event: any) {
      const pattern = this.role+this.richiesta.statoTicket!.desStatoTicket;

      const checkGestore = "gestorePRESA IN CARICO GESTORE";
      const checkOperatore = "operatorePRESA IN CARICO OPERATORE";
      const checkCaposala = "caposalaCHIUSA";
      const checkCaposalaNew = "caposalaundefined";
      const checkOperatoreNew = "operatoreundefined";
      const checkResponsabileNew = "responsabileundefined";
      const checkResponsabile = "responsabileRESPINTA IN ATTESA DI CONFERMA";

      switch (pattern) {
          case checkCaposalaNew:
              if (event.tipoInterventi.idTipoInterventi) {
                  this.dmsUpload = true;
                  if ((event.tipoInterventi.idTipoInterventi == 1 || event.tipoInterventi.idTipoInterventi == 10) && this.prevIntervento !=  event.tipoInterventi.idTipoInterventi){
                      this.arrayFormInfo = this.TRCols.groupsNew(this.instance,this.testi, this.role + "Impianto");
                      this.prevIntervento = event.tipoInterventi.idTipoInterventi;
                  } else if (event.tipoInterventi.idTipoInterventi != 1 && event.tipoInterventi.idTipoInterventi !=10 && this.prevIntervento !=  event.tipoInterventi.idTipoInterventi){
                      this.arrayFormInfo = this.TRCols.groupsNew(this.instance,this.testi, this.role + "Nuova");
                      this.prevIntervento = event.tipoInterventi.idTipoInterventi;
                  } else {
                      this.dryrun = false;
                  }
              }
              if (event.unitaServizio.idUnitaServizio) {
                  this.prevGestore = '';
                  this.unitaServizioChange(event);
              }
              break;
          case checkOperatoreNew:
              if (event.tipoInterventi.idTipoInterventi) {
                  this.dmsUpload = true;
                  if ((event.tipoInterventi.idTipoInterventi == 1 || event.tipoInterventi.idTipoInterventi == 10) && this.prevIntervento !=  event.tipoInterventi.idTipoInterventi){
                      this.arrayFormInfo = this.TRCols.groupsNew(this.instance,this.testi, this.role + "NewImpianto");
                      this.prevIntervento = event.tipoInterventi.idTipoInterventi;
                  } else if (event.tipoInterventi.idTipoInterventi != 1 && event.tipoInterventi.idTipoInterventi !=10 && this.prevIntervento !=  event.tipoInterventi.idTipoInterventi){
                      this.arrayFormInfo = this.TRCols.groupsNew(this.instance,this.testi, this.role + "Nuova");
                      this.prevIntervento = event.tipoInterventi.idTipoInterventi;
                  } else {
                      this.dryrun = false;
                  }
              };
              if (event.unitaServizio.idUnitaServizio) {
                  this.unitaServizioChange(event);
              }
              break;
          case checkResponsabileNew:
              if (event.tipoInterventi.idTipoInterventi) {
                  this.dmsUpload = true;
                  if ((event.tipoInterventi.idTipoInterventi == 1 || event.tipoInterventi.idTipoInterventi == 10) && this.prevIntervento !=  event.tipoInterventi.idTipoInterventi){
                      this.arrayFormInfo = this.TRCols.groupsNew(this.instance,this.testi, this.role + "NewImpianto");
                      this.prevIntervento = event.tipoInterventi.idTipoInterventi;
                  } else if (event.tipoInterventi.idTipoInterventi != 1 && event.tipoInterventi.idTipoInterventi !=10 && this.prevIntervento !=  event.tipoInterventi.idTipoInterventi){
                      this.arrayFormInfo = this.TRCols.groupsNew(this.instance,this.testi, this.role + "Nuova");
                      this.prevIntervento = event.tipoInterventi.idTipoInterventi;
                  } else {
                      this.dryrun = false;
                  }
              };
              if (event.unitaServizio.idUnitaServizio) {
                  this.unitaServizioChange(event);
              }
              break;
          case checkOperatore:
              if (event.tipoInterventi.idTipoInterventi) {
                  if ((event.tipoInterventi.idTipoInterventi == 1 || event.tipoInterventi.idTipoInterventi == 10) && this.prevIntervento !=  event.tipoInterventi.idTipoInterventi){
                      this.prevIntervento = event.tipoInterventi.idTipoInterventi;
                      this.arrayFormInfo = this.TRCols.groups(this.instance,this.testi, this.role + "Impianto");
                  } else if (event.tipoInterventi.idTipoInterventi != 1 && event.tipoInterventi.idTipoInterventi !=10 && this.prevIntervento !=  event.tipoInterventi.idTipoInterventi){
                      this.prevIntervento = event.tipoInterventi.idTipoInterventi;
                      this.arrayFormInfo = this.TRCols.groups(this.instance,this.testi, this.role + "NonImpianto");
                  } else {
                      this.dryrun = false;
                  }
              }
              if (event.tipoRifiuto && event.tipoRifiuto.idTipoRifiuto && event.tipoRifiuto.idTipoRifiuto != undefined && event.tipoRifiuto.idTipoRifiuto != this.prevRifiuto){
                  this.dmsUpload = true;
                  this.prevMod = true;
                  this.prevRifiuto = event.tipoRifiuto.idTipoRifiuto;
                  this.arrayFormInfo = this.TRCols.groups(this.instance,this.testi, this.role + this.richiesta.statoTicket!.desStatoTicket + "Rifiuta");
                  this.formButton = this.TRCols.formButton(this.testi, this.role + this.richiesta.statoTicket!.desStatoTicket + "Rifiuta");
              } else if (event.tipoRifiuto && event.tipoRifiuto.idTipoRifiuto == undefined && event.tipoRifiuto.idTipoRifiuto != this.prevRifiuto){
                  this.dmsUpload = false;
                  this.prevMod = true;
                  this.prevRifiuto = event.tipoRifiuto.idTipoRifiuto;
                  this.ReForm();
              }
              if (event.dataScadenza && event.dataScadenza != '' && event.dataScadenza != this.prevScadenza){
                  this.dmsUpload = true;
                  this.prevMod = true;
                  this.prevScadenza = event.dataScadenza;
                  this.AccettaPage();
              } else if (event.dataScadenza == undefined && event.dataScadenza != this.prevScadenza) {
                  this.prevScadenza = event.dataScadenza;
                  if (!this.prevGestore){
                      this.ReForm();
                  }
              } else {
                  if (event.dataScadenza == undefined) console.log(1);
                  if (event.dataScadenza != this.prevScadenza) console.log(2);
                  if (!this.prevGestore) {
                      //this.ReForm();
                  }
              }
              if (event.utenteGestore.id && event.utenteGestore.id != undefined && event.utenteGestore.id!= this.prevGestore){
                  this.dmsUpload = true;
                  this.prevMod = true;
                  this.prevGestore = event.utenteGestore.id;
                  this.Accetta();
              } else if (event.utenteGestore.id == undefined && event.utenteGestore.id != this.prevGestore){
                  this.prevGestore = event.utenteGestore.id;
                  this.ReForm();
              } else {
                  if (event.utenteGestore.id == undefined) console.log(11);
                  if (event.utenteGestore.id != this.prevGestore) console.log(12);
                  if (!this.prevScadenza) console.log(13, this.prevScadenza);
              }
              break;
          case checkGestore:
              if (event.dataInizioIntervento && this.prevIntervento != event.dataInizioIntervento && this.prevNote == event.note){
                  this.dmsUpload = true;
                  this.prevMod = true;
                  this.prevIntervento = event.dataInizioIntervento;
                  this.formButton = this.TRCols.formButton(this.testi, this.role + this.richiesta.statoTicket!.desStatoTicket + "Chiudi");
              } else if (!event.dataInizioIntervento && this.prevIntervento != event.dataInizioIntervento && this.prevNote == event.note) {
                  this.dmsUpload = true;
                  this.prevMod = true;
                  this.prevIntervento = event.dataInizioIntervento;
                  this.ReForm()
              }
              break;
          case checkResponsabile:
              this.dmsUpload = true;
              this.formButton = this.TRCols.formButton(this.testi, this.role+this.richiesta.statoTicket!.desStatoTicket);
              break;
      }


     if (event.note){
         this.dmsUpload = true;
         this.prevNote = event.note;
     }

  }

  private AccettaPage() {
      this.arrayFormInfo = this.TRCols.groups(this.instance,this.testi, this.role+this.richiesta.statoTicket!.desStatoTicket+"Accetta");
  }

  private Accetta(){
      this.arrayFormInfo = this.TRCols.groups(this.instance,this.testi, this.role+this.richiesta.statoTicket!.desStatoTicket+"Accetta");
      this.formButton = this.TRCols.formButton(this.testi, this.role+this.richiesta.statoTicket!.desStatoTicket+"Accetta");
  }

  private ReForm(){
      this.dmsUpload = false;
      this.arrayFormInfo = this.richiesta.idTicket ? this.TRCols.groups(this.instance,this.testi, this.role+this.richiesta.statoTicket!.desStatoTicket) : this.TRCols.groupsNew(this.instance,this.testi, this.role+"Nuova");
      this.formButton = this.TRCols.formButton(this.testi, this.richiesta.idTicket ? this.role+this.richiesta.statoTicket!.desStatoTicket : this.role+"Nuova");
  }

  private unitaServizioChange(event: any){
      //console.log(event.unitaServizio.idUnitaServizio)
      this.richiesteService.getStanzeType(event.unitaServizio.idUnitaServizio)
          .subscribe( (res: tipoStanza[]) => {
              this.dropdownList.set('checkStanze', [{viewValue: 'Nessuna Stanza', value: 'emptyItem'}]);
              if (res.length != 0) {
                  this.dropdownList.set('stanze', res);
              } else {
                  this.dropdownList.set('stanze', [{viewValue: 'Nessuna Stanza', value: 'emptyItem'}]);
              }
          });
  }

  saveOgg(obj: FormResponse) {
    const data = obj.richiesta;
    switch(obj.type){
      case "save":
        this.dialogRef.close({type: CallbackDetailsState.SAVE, data } as DetailsCallback);
        break;
      case "contesta":
        this.dialogRef.close({type: CallbackDetailsState.CONTESTA, data } as DetailsCallback);
        break;
      case "approva":
        this.dialogRef.close({type: CallbackDetailsState.APPROVA, data } as DetailsCallback);
        break;
      case "rifiuta":
        this.dialogRef.close({type: CallbackDetailsState.RIFIUTA, data } as DetailsCallback);
        break;
      case "rivendica":
        this.dialogRef.close({type: CallbackDetailsState.RIVENDICA, data } as DetailsCallback);
        break;
      case "riprendi":
        this.dialogRef.close({type: CallbackDetailsState.RIPRENDI, data } as DetailsCallback);
        break;
      case "confermaRifiuto":
        this.dialogRef.close({type: CallbackDetailsState.CRIFIUTO, data } as DetailsCallback);
        break;
      case "nonConfermaRifiuto":
        this.dialogRef.close({type: CallbackDetailsState.NCRIFIUTO, data } as DetailsCallback);
        break;
      case "accetta":
        this.dialogRef.close({type: CallbackDetailsState.ACCETTA, data } as DetailsCallback);
        break;
      case "rrichiesta":
        this.dialogRef.close({type: CallbackDetailsState.RRICHIESTA, data } as DetailsCallback);
        break;
      case "crichiesta":
          // @ts-ignore
          if (data.dataInizioIntervento && data.dataInizioIntervento != "") {
             this.dialogRef.close({type: CallbackDetailsState.CRICHIESTA, data } as DetailsCallback);
         } else {
              this.error= "Per chiudere la Richiesta è Necessario specificare la Data Inizio Lavori";
         }
        break;

    }
  }

  cancel(event:any) {
      if (event && event.target && event.target.toString() == "[object HTMLInputElement]") {
          console.log("Prevent Close");
      } else {
          this.richiesta.note = '';
          this.dialogRef.close({type: CallbackDetailsState.CANCEL} as DetailsCallback );
      }
  }

  cancelApprova() {
      this.dialogRef.close({type: CallbackDetailsState.CANCEL} as DetailsCallback );
  }

  private setDropDown(pattern: boolean){
    this.richiesteService.getRichiesteType()
        .subscribe((res: tipoRichiesta[]) => {
            this.dropdownList.set('tipoRichiestaList', res);
        });
    if (pattern && !this.shared.utente!.roles!.includes('manutenzione_interna')){
        this.richiesteService.getInterventiType(false)
            .subscribe( (res: tipoInterventi[]) => {
                this.dropdownList.set('tipoIntervento', res);
            });
    } else {
        this.richiesteService.getInterventiType(true)
            .subscribe( (res: tipoInterventi[]) => {
                this.dropdownList.set('tipoIntervento', res);
            });
    }
    this.richiesteService.getUnitaServizioType()
        .subscribe( (res: unitaServizio[]) => {
          this.dropdownList.set('unitaServizioList', res);
        });
    this.richiesteService.getUnitaServizioFullType()
        .subscribe( (res: unitaServizioExtended[]) => {
            this.dropdownList.set('unitaServizioFullList', res);
        });
    this.richiesteService.getRifiutoType()
        .subscribe( (res: tipoRifiuto[]) => {
          this.dropdownList.set('rifiutoList', res);
        });
    this.richiesteService.getUrgenzaType()
        .subscribe( (res: urgenza[]) => {
          this.dropdownList.set('tipoUrgenza', res);
        });
    this.richiesteService.getImpiantoType()
        .subscribe( (res: impianto[]) => {
          this.dropdownList.set('tipoImpianto', res);
        });
    this.richiesteService.getUtentiGestore()
        .subscribe( (res: utenteGestore[]) => {
          this.dropdownList.set('utentiGestore', res);
        });
    if (this.richiesta.idTicket) {
      this.richiesteService.getStanzeType(this.richiesta.unitaServizio!.idUnitaServizio)
          .subscribe( (res: tipoStanza[]) => {
            this.dropdownList.set('stanze', res);
          });
    } else {
      this.dropdownList.set('stanze', []);
    }
  }
}
