import {Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf} from '@angular/core';
import {FormInputField} from '../../../entities/form/FormInputField';
import {AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Utente} from "../../../entities/Utente";
import {TableName} from "../../../entities/table/TableName";

@Component({
  selector: 'app-form-inputs',
  templateUrl: './form-inputs.component.html',
  styleUrls: ['./form-inputs.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputsComponent),
      multi: true
    }
  ]
})
export class FormInputsComponent implements ControlValueAccessor, OnInit {

  @Input() testi?: any;
  @Input() formType!: FormInputField;
  @Input() tableHeader?: TableName[];
  @Input() nullDropDown?: boolean = false;
  @Input() tableData?: any[];
  @Input() tableName?: string;
  @Input() dropdownList?: any[];
  @Input() dmsUpload?: boolean;
  @Input() dropdownList2?: any[];
  @Input() utenti?: Utente[];
  @Input() username?: boolean;
  @Input() email?: boolean;
  @Input() DragDropMaxHeight?: number;
  @Input() msgForbidden?: string | '';
  @Input() maxWidth?: false;

  @Input() objValue?: Object;

  private _value: any;
  control!: AbstractControl;
  constructor(@Optional() @Host() @SkipSelf()
               private controlContainer: ControlContainer) {
  }

  ngOnInit () {
    if (this.controlContainer) {
      if (this.formType) {
        this.control = this.controlContainer.control!.get(this.formType.prop)!;
      } else {
        console.warn('Missing FormControlName directive from host element of the component');
      }
    } else {
      console.warn('Can\'t find parent FormGroup directive');
    }
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  set value(val: any) {
     this._value = val;
     this.onChange(val);
     this.onTouch(val);
  }

  get value() {
    return this._value;
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  inputFirma(firma: any) {
    this.writeValue(firma);
  }

  inputChip(operatore: any) {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  ngOnDestroy(): void {

  }

}
