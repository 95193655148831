import {Observable} from 'rxjs';
import {Injectable} from "@angular/core";
import {DocsDMS} from "../entities/DMS";
import {map} from "rxjs/operators";
import {ApiRestServiceRichieste} from "./api-rest.service-richieste";
import {ApiRestServicePortal} from "./portal/api-rest.service-portal";


@Injectable({
    providedIn: 'root'
})
export class DmsService {

    constructor(private apiRestService: ApiRestServiceRichieste,
                private portalService: ApiRestServicePortal){
    }

    createTicketFolder(dms: string): Observable<any> {
        const url = 'nodes/'+ this.portalService.dmsTicketFolder +'/children';
        return this.apiRestService.postWithBodyDMS(url, {"name" : dms, "nodeType":"cm:folder"})
    }

    cancelDMS(dms: string): Observable<any> {
        const url = 'nodes/'+dms;
        return this.apiRestService.deleteDMS(url);
    }

    createUploadDMS(folder: string, data:any): Observable<any>{
        const url = 'nodes/' + folder + '/children';
        const obj = {"name": data.name, "nodeType": "cm:content"};
        return this.apiRestService.postWithBodyDMS(url, obj);
    }

    renameDMS(idDms: string, idTicket: string): Observable<any>{
        const url = 'nodes/' + idDms;
        const obj = {"name": idTicket};
        return this.apiRestService.putWithBodyDMS(url, obj);
    }

    uploadDMS(id: string, data:any): Observable<any>{
        const url = 'nodes/' + id + '/content';
        return this.apiRestService.putFile(url, data);
    }

    linkDMS(): string {
        return this.apiRestService.getLinkDMS();
    }

    getFolderContent(dms:string): Observable<any> {
        const url = 'nodes/'+dms+'/children';
        return this.apiRestService.getDMS(url).pipe(
            map((res: any) =>
                res.list.entries.map((doc: any) => new DocsDMS(doc))
            )
        );
    }


}
