<ng-container [ngSwitch]="formType.input">

    <!-- Input Text -->
    <div class="inputForm" *ngSwitchDefault>
                <app-ble-input [valore]="value"
                               [testo]="formType.text"
                               [block]="formType.block"
                               [hidden] = "formType.hidden"
                               [minText]="formType.minText"
                               [maxText]="formType.maxText"
                               [invalid]="control.invalid"
                               (cambioValore)="writeValue($event)">

                </app-ble-input>
    </div>

    <!-- Input Time -->
    <div class="inputForm" *ngSwitchCase="'time'">
        <app-ble-input-time [valore]="value"
                            [testo]="formType.text"
                            [block]="formType.block"
                            [minutes]="formType.minutes"
                            [hidden] = "formType.hidden"
                            [invalid]="control.invalid"
                            (cambioValore)="writeValue($event)">
        </app-ble-input-time>
    </div>
    <!-- Input Number -->
    <div class="inputForm" *ngSwitchCase="'number'">
        <app-ble-input-number [valore]="value || formType.value"
                              [testo]="formType.text"
                              [block]="formType.block"
                              [unit]="formType.unit"
                              [max]="formType.max"
                              [hidden] = "formType.hidden"
                              [invalid]="control.invalid"
                              (cambioValore)="writeValue($event)">
        </app-ble-input-number>
    </div>



    <!-- textarea -->
    <div class="inputForm" *ngSwitchCase="'textarea'">
        <app-ble-input-textarea [valore]="value"
                                [testo]="formType.text"
                                [block]="formType.block"
                                [rows]="formType.rows || 2"
                                [cols]="formType.cols || 30"
                                [msgForbidden]="msgForbidden"
                                [hidden] = "formType.hidden"
                                [invalid]="control.invalid"
                                (cambioValore)="writeValue($event)">
        </app-ble-input-textarea>
    </div>

    <!-- multiSelect -->
    <div class="inputForm" *ngSwitchCase="'multselect'">
        <app-ble-dropdown [valore]="value"
                          [lista]="dropdownList"
                          [testo]="formType.text"
                          [autoSize]="formType.autoSize"
                          [disabled]="formType.block || !dropdownList?.length"
                          [invalid]="control.invalid"
                          [hidden] = "formType.hidden"
                          [multiple]="true"
                          (cambioValore)="writeValue($event)">
        </app-ble-dropdown>
    </div>

    <!-- dropdown -->
    <div class="inputForm" *ngSwitchCase="'dropdown'">
        <app-ble-dropdown [valore]="value"
                          [nullDropDown]="nullDropDown"
                          [lista]="dropdownList!"
                          [lista2]="dropdownList2!"
                          [autoSize]="formType.autoSize"
                          [reloadValue]="formType.reloadValue"
                          [refSize]="formType.refSize"
                          [testo]="formType.text"
                          [tooltip]="formType.tooltip"
                          [reloader]="formType.reloader"
                          [children]="formType.children"
                          [disabled]="formType.block || !dropdownList?.length"
                          [clean]="!formType.required"
                          [hidden] = "formType.hidden"
                          [invalid]="control.invalid"
                          (cambioValore)="writeValue($event)">
        </app-ble-dropdown>
    </div>

    <!-- dragdrop -->

    <div class="inputForm" *ngSwitchCase="'dragdrop'">
        <app-dettaglio-dragdrop [listaAssegnati]="dropdownList2!"
                                [testi]="testi"
                                [listaDaAssegnare]="dropdownList!"
                                [disable]="formType.block!"
                                [hidden] = "formType.hidden"
                                [DragDropMaxHeight]="DragDropMaxHeight"
                                (cambioValore)="writeValue($event)">
        </app-dettaglio-dragdrop>

    </div>

    <!-- check -->
    <div class="inputForm" *ngSwitchCase="'check'">
        <app-ble-checkbox [valore]="value"
                          [testo]="formType.text"
                          [block]="formType.block"
                          [hidden] = "formType.hidden"
                          [invalid]="control.invalid"
                          (cambioValore)="writeValue($event)">
        </app-ble-checkbox>
    </div>

    <!-- slide -->
    <div class="inputForm" *ngSwitchCase="'slide'">
        <app-ble-slide [valore]="value"
                       [testo]="formType.text"
                       [block]="formType.block"
                       [hidden] = "formType.hidden"
                       [invalid]="control.invalid"
                       (cambioValore)="writeValue($event)">
        </app-ble-slide>
    </div>

    <!-- date -->
    <app-ble-datepicker *ngSwitchCase="'date'"
                        [valore]="value"
						[minDate]="formType.minDate"
                        [minLatest]="formType.minLatest"
                        [clear]="formType.clear"
                        [invalid]="control.invalid"                        
                        [testo]="formType.text"
                        [block]="formType.block"
                        [invalidSet]="formType.required"
                        [hidden] = "formType.hidden"
						[clear] = "formType.clear"
                        [autolock]="formType.autolock"                        (cambioValore)="writeValue($event)">
    </app-ble-datepicker>

    <!-- datetime -->
    <app-ble-datetimepicker *ngSwitchCase="'datetime'"
                        [valore]="value"
                        [invalid]="control.invalid"
                        [testo]="formType.text"
                        [block]="formType.block"
                        [hidden] = "formType.hidden"
                        [clear] = "formType.clear"
                        (cambioValore)="writeValue($event)">
    </app-ble-datetimepicker>

    <!-- Chip List -->
    <app-chip-list *ngSwitchCase="'chipList'"
                   [valore]="value"
                   [testo]="formType.text"
                   [invalid]="control.invalid"
                   [hidden] = "formType.hidden"
                   [block]="formType.block"
                   (cambioValore)="writeValue($event)">
    </app-chip-list>


    <!-- Assegna Prodotto -->
    <app-image-firma *ngSwitchCase="'image'"
                     [valore]="value">
    </app-image-firma>

    <!-- Input password -->
    <div class="inputForm" *ngSwitchCase="'password'">
        <app-ble-password [valore]="value"
                          [block]="formType.block"
                          [hidden] = "formType.hidden"
                          [invalid]="control.invalid"
                          (cambioValore)="writeValue($event)">
        </app-ble-password>
    </div>

    <!-- Documenti -->
    <div class="inputForm" *ngSwitchCase="'documents'">
        <app-docs [codSr]="'Test'"
                  [testi]="testi"
                  [block]="formType.block"
                  [dmsUpload]="dmsUpload"
                  [invalid] = "control.invalid"
                  [folder]="value"
                  [hidden] = "formType.hidden"
                  [listaDocs]="value"
                 [statoId]="1">
        </app-docs>
    <!--
    <app-docs [codSr]="richiesta!.codsr!"
              [statoId]="richiesta!.stato!.id!">
    </app-docs>
    -->
    </div>
    <!-- TableData <app-table-view *ngIf="formType.tableData"-->
    <div class="inputForm" *ngSwitchCase="'table'">
        <app-table-view *ngIf="formType.tableData"
                        [tableData]="formType.tableData"
                        [tableDataHeader]="formType.tableHeader!"
                        [filterHeader]=""
                        [testi]="testi"
                        [height]="formType.height!"
                        [noRowPattern]="true"
                        [scroll]="false"
                        [input]="true"
                        [showFooter]="false"
                        [rowResize]="false"
                        [buttonAggiorna]="false"
                        [emptyMessage]="formType.emptyText"
                        [buttonAdd]="false"
                        [cleanName]="true"
                        [tableType]="formType.tableName"
                        [admin]="true"
                        (callBack)="writeValue($event)">
        </app-table-view>
    </div>

</ng-container>
