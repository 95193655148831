import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {TreeNodeMaterial} from "../../../entities/TreeNodeMaterial";

@Component({
  selector: 'app-input-tree',
  templateUrl: './input-tree.component.html',
  styleUrls: ['./input-tree.component.scss']
})
export class InputTreeComponent implements OnInit, OnChanges {

  @Input() valore: any = [];
  @Input() block = false;
  @Input() invalid = false;
  @Input() check = true;
  @Input() multiple = false;
  @Input() removeLevel?: string | string[];
  @Input() minNode?: string | string[];
  @Input() saveLevel?: string;
  @Input() list: TreeNodeMaterial[];

  _list: TreeNodeMaterial[];
  _startList: TreeNodeMaterial[];
  _prevSelected: TreeNodeMaterial;

  @Output() cambioValore = new EventEmitter();
  @Output() callback = new EventEmitter();

  treeControl = new NestedTreeControl<any>(node => node.children);
  dataSource = new MatTreeNestedDataSource<any>();

  selectedNodes: TreeNodeMaterial[] = [];

  nodes: TreeNodeMaterial[] = [];

  tree: number[] = [];

  constructor() {
  }

  hasChild = (_: number, node:any) => !!node.children && node.children.length > 0;

  ngOnInit(): void {
    if (this.valore){
      this.changeCheck(this.valore);
    }
    this.makeListCheckbox(this.list);
  }

  checkNode(type: string){
    if (typeof this.minNode == 'string') {
      if (this.minNode == type) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.minNode!.includes(type)) {
        return true;
      } else {
        return false;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    changes['list'] && this.makeListCheckbox(this.list);
  }

  clickNode(node:any) {
    node.check = !node.check;
    if (!this.multiple) {
      if (node.type == this.minNode) {
        this.changeCheck(node);
      }
    }else{
      if (this.minNode!.includes(node.type)){
        this._list = this.propagateCheck(this.list, node);
        this.valore = this.getSelected(this._list, true);
        this.cambioValore.emit(this.valore);
      }
    }
  }

  private cleanList(lists: TreeNodeMaterial[], node: TreeNodeMaterial) {
    if (this.multiple) {
      for (let list of lists) {
        if (list.children) {
          if (list.id == node.id) {
            list.check = true;
          } else {
            list.check = false;
          }
          this.cleanList(list.children, node);
        }
      }
    } else {
      if (this._prevSelected != node && node.check == true){
         if (this._prevSelected) {this._prevSelected.check = false}
         this._prevSelected = node;
      }
      lists = this._startList;
    }
    this.dataSource.data = lists;
  }


  private makeListCheckbox(list:any) {
    const _list = this.checkBoxSelected(list);
    this._list = this.propagateCheck(_list);
    this._startList = this._list;
    this.dataSource.data = this.removeLevel ? this.reduceList(this._list) : this._list;
  }

  private checkBoxSelected(list: TreeNodeMaterial[]): TreeNodeMaterial[] {
    list = list ? list : [];
    return list.map( node => {
      node['check'] = this.checkSelected(node);
      if (this.checkSelected(node)) {
        for (let ancestor of this.getAncestors(this.list,node)){
          this.treeControl.expand(ancestor);
        }
      }
      node['indeterminate'] = false;
      if (node.children) {
        node.children = this.checkBoxSelected(node.children);
      }
      return node;
    });
  }

  private reduceList(lists: TreeNodeMaterial[]) {
    for (let list of lists){
      if (list.children && list.children.length > 0){
        if (list.children[0].type == this.removeLevel){
          list.children = [];
        };
        this.reduceList(list.children);
      }
    }

    return lists;
  }

  private getAncestors(array: any,node: any) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].id === node.id && array[i].type === node.type) {
          return [array[i]];
        }
        const a: any = this.getAncestors(array[i].children, node);
        if (a !== null) {
          a.unshift(array[i]);
          return a;
        }
      }
    return null;
  }



  private childCheck(node: TreeNodeMaterial): TreeNodeMaterial{
    const howMany = node.children!.length;
    const checked = node.children!.filter(nd => nd.check).length;
    const unchecked = node.children!.filter(nd => nd.check == false).length;
    const indeterminate = node.children!.filter(nd => nd.indeterminate).length;
    if (checked == 0 && indeterminate == 0){
      node.indeterminate = false;
      node.check = false;
    } else if (checked < howMany && indeterminate == 0 && unchecked != 0) {
      node.indeterminate = true;
      node.check = false;
    } else if (indeterminate == 0){
      node.indeterminate = false;
      node.check = true;
    } else if (indeterminate != 0){
      node.indeterminate = true;
      node.check = false;
    } else {
      node.indeterminate = false;
      node.check = false;
    }
    return node;
  }

  private checkTree(list: TreeNodeMaterial[], selected?: TreeNodeMaterial): TreeNodeMaterial[] {
    list.map(node => {
      if (node.children && node.children.length != 0) {
        if (selected && selected.id === node.id && selected.type === node.type) {
           node.children = this.parentCheck(node.children,node.check,node.id)
        } else {
          const childTree = node.children!.filter(nd => nd.children).length;
          for (let i = 0; i <= childTree + 1; i++) {
            this.checkTree(node.children!, selected);
          }
          node = this.childCheck(node);
        }
      }
      return node;
    });
    return list;
  }

  private parentCheck(list: TreeNodeMaterial[], checked?: boolean, id?: string ): TreeNodeMaterial[] {
    list.map(node => {
        if (node.children && node.children.length != 0) {
           if (id != node.id) {
             node.check = checked != undefined ? checked : node.check;
             node.indeterminate = false;
           }
           node.children=this.parentCheck(node.children, node.check, id);
        } else {
          node.check = checked != undefined ? checked : node.check;
        }
      return node;
    });

    return list;
  }

  private propagateCheck(list: TreeNodeMaterial[], node?: TreeNodeMaterial): TreeNodeMaterial[] {
    list = this.checkTree(list, node);
    return list;
  }

 //
  changeCheck(node:any) {
    const value = node.check ? node.check : false;
    if (this.valore){
      this.cambioValore.emit(this.valore);
    }
    if (!this.multiple && !this.check) {
      this.cleanList(this.list, node);
      this.valore = node;
    } else {
      this.nodes.push(node.id, value, node.type);
      this.getSelected(this.propagateCheck(this.list, node), true);
      this.valore = this.selectedNodes;
    }
    this._list = this.propagateCheck(this.list, node);
    this.cambioValore.emit(this.valore);
  }

  getSelected(nodes: TreeNodeMaterial[], start?: boolean) {
    if (start) {this.selectedNodes = []}
    for (let node of nodes){
      if (node.type != this.saveLevel && node.children) {
        this.getSelected(node.children);
      } else {
        if (node.check === true){
          this.selectedNodes.push(node);
        } else {
          if (this.selectedNodes.length == 1 && this.selectedNodes[0] === node){
            this.selectedNodes = [];
          }
        }
      }
    }
  }


  private checkSelected(node:any) {
    if (this.valore) {
      const selected = new TreeNodeMaterial(this.valore);
      return selected.id === node.id && selected.type === node.type;
    } else {
      return false;
    }
  }

}
