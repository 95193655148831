import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {AuthModule} from "./auth/auth.module";
import {MenuModule} from "./menu/menu.module";
import {MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {DialogService} from "primeng/dynamicdialog";
import {MenuRoutingModule} from "./menu/menu-routing.module";
import {CommonModule} from "@angular/common";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {MatSidenavModule} from "@angular/material/sidenav";
import {SdgLibModule} from "sdg-lib";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {Oauth2TokenInterceptor} from "./config/oauth2-auth-token-interceptor.service";
import {KeycloakService} from "keycloak-angular";
import {InitAppService} from "./config/initApp.service";
import {MatButtonModule} from "@angular/material/button";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {RichiesteRouting} from "./richieste/richieste.routing";
import {RichiesteModule} from "./richieste/richieste.module";
import {AnagraficheModule} from "./anagrafiche/anagrafiche.module";
import {PopupConfermaComponent} from "./shared-modules/popup-conferma/popup-conferma/popup-conferma.component";
import {SnackBarComponent} from "./shared-modules/snack-bar/snack-bar.component";
import {PopUpDettagliComponent} from "./shared-modules/pop-up-dettagli/pop-up-dettagli.component";
import {AnagraficheRouting} from "./anagrafiche/anagrafiche.routes";
import {HeaderPopupModule} from "./shared-modules/header-popup/header-popup.module";
import {FormFieldModule} from "./shared-modules/form-field/form-field.module";
import {MatFormFieldModule} from "@angular/material/form-field";
import {PopupInputComponent} from "./shared-modules/popup-input/popup-input/popup-input.component";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DmsModule} from "./dms/dms.module";
import {DmsRouting} from "./dms/dms.routes";
import {LavanoloModule} from "./lavanolo/lavanolo.module";
import {PulizieModule} from "./pulizie/pulizie.module";
import {CdkDrag, DragDropModule} from "@angular/cdk/drag-drop";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
  declarations: [AppComponent,
                PopupConfermaComponent,
                PopupInputComponent,
                SnackBarComponent,
                PopUpDettagliComponent
  ],
    imports: [
        CommonModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatSnackBarModule,
        MatSidenavModule,
        MatToolbarModule,
        MatIconModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatInputModule,
        MenuRoutingModule,
        MenuModule,
        AuthModule,
        MatDialogModule,
        SdgLibModule,
        RichiesteRouting,
        RichiesteModule,
        HeaderPopupModule,
        FormFieldModule,
        DragDropModule

    ],
    exports: [SnackBarComponent],
  providers: [
      {
          provide: KeycloakService,
          useFactory: keycloakServiceProvider,
          deps: ['keycloak-service']
      },
      {
          provide: APP_INITIALIZER,
          useFactory: initApp,
          multi: true,
          deps: [InitAppService, KeycloakService]
      },
      {
          provide: HTTP_INTERCEPTORS,
          useClass: Oauth2TokenInterceptor,
          multi: true
      },
      //AuthGuard,
      {
        provide: MatDialogRef,
        useValue: {}
      },
      DialogService
    ],
  bootstrap: [AppComponent]
})

export class AppModule {
}

export function initApp(config: InitAppService, keycloakService: KeycloakService) {
    return () => config.getPromiseConfig();
}

export function keycloakServiceProvider(keycloakService: KeycloakService): KeycloakService {
    return keycloakService;
}
