import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {TableCallback} from "../../../entities/table/TableCallback";
import {FilterType} from "../../../entities/table/FilterType";
import {CallbackTableState} from "../../../entities/enums/CallbackTableState";
import {TestiService} from "../../../service/config/testi.service";
import {RichiesteService} from "../../../service/richieste.service";
import {TipoPastoService} from "../../../service/tipopasto.service";
import {CentriCostoService} from "../../../service/centricosto.service";
import {DieteService} from "../../../service/diete.service";
import {PulizieService} from "../../../service/pulizie.service";
import {SdgLibService} from "sdg-lib";
import {PazientiService} from "../../../service/pazienti.service";
import {StruttureService} from "../../../service/strutture.service";
import {of} from "rxjs";

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})

export class TableHeaderComponent implements OnInit {

  @Input() tableType?: string;
  @Input() filterHeader?: string;
  @Input() buttonAdd?: boolean;
  @Input() disableAdd?: boolean = false;
  @Input() buttonAddName?: string;
  @Input() testi?: any;
  @Input() type?: string;
  @Input() buttonBrowse?: boolean;
  @Input() hideTitle? = false;
  @Input() cleanName?: boolean;
  @Input() buttonFunc? = false;
  @Input() buttonFuncName?: string;
  @Input() buttonAggiorna = false;
  @Input() filterHeaderList?: FilterType[] = [];
  @Input() filterDataList?: Map<string, any[]> | undefined;
  @Input() centerTitle?: number = 0;
  @Input() back?: boolean;
  @Input() dmsAddFolder?: boolean;
  @Input() searchField?: boolean;
  @Input() titleStyle?: string = '';
  @Input() reload?: boolean = false;

  @Output() callBack = new EventEmitter<TableCallback>();
  @Output() callBackUpdate = new EventEmitter<TableCallback>();
  @Output() setFilter = new EventEmitter();

  callbackTableState = CallbackTableState;

  data:any = '';

  filterTypeList: FilterType[] = [];
  allList: Map<string, any[]> = new Map<string, any[]>();

  constructor(
      private testiService: TestiService,
              private richiesteService: RichiesteService,
              private pazientiService: PazientiService,
			  private tipoPastoService: TipoPastoService,
              private struttureService: StruttureService,
              private dieteService: DieteService,
              private pulizieService: PulizieService,
              private centriCostoService: CentriCostoService,
              private readonly shared: SdgLibService
              ) {

  }

  ngOnInit(): void {
    this.setFilterType(this.filterHeader);
    this.setAllList(this.filterHeader);
  }

  eventHeaders(event: CallbackTableState) {
    this.callBack.emit({type: event});
  }

  fileHandler(event: any) {
      this.data = event.target.files[0] ?? null;
      this.callBack.emit({type: CallbackTableState.FILE, data: this.data});
  }



  private setFilterType(type: string | undefined): void {
    switch (type) {
      case 'utenti':
        this.setFilterUtenti();
        break;
      case 'tipoMenuFilter':
        this.setFilterTipoMenu();
        break;
      case 'puntiPrelievoFilter':
        this.setFilterPuntiPrelievo();
        break;
      case 'pazienti':
        this.setFilterPazienti();
        break;
      case 'standard':
        this.setFilterStandard();
        break;
      case 'us':
        this.setFilterUS();
        break;
      case 'richieste':
        this.setFilterRichieste();
        break;
      case 'report':
        this.setFilterReport();
        break;
      case 'pulizie':
        this.setFilterpulizie();
        break;
      case 'stanze':
        this.setFilterStanze();
        break;
      case 'US':
        this.setFilterUS();
        break;
      default:
        break;
    }


  }
  private setFilterStandard(): void {
    this.filterTypeList = [
      {label: this.testi.descrizione + ':',  type: 'text', max: '', param: 'descrizione' },
    ];
  }

  private setFilterUtenti(): void {
    this.filterTypeList = [
      {label: this.testi.nome + ':',  type: 'text', max: '', param: 'firstName' },
      {label: this.testi.cognome + ':',  type: 'text', max: '', param: 'lastName' },
      {label: this.testi.username + ':',  type: 'text', max: '', param: 'username' },
      {label: this.testi.email + ':',  type: 'text', max: '', param: 'email' },
    ];
  }

  indietro() {
    this.callBack.emit({type: CallbackTableState.BACK})
  }

  searchText(event: any){
    this.callBack.emit({type: CallbackTableState.SEARCH, data: event});
  }

  keypress(event: any, term: any){
    if (event.key == "Enter"){
      this.searchText(term);
    }
  }

  newFolder(event: any) {
    this.callBack.emit({type: CallbackTableState.FOLDER, data: event});
  }
  
  private setFilterLavanolo(): void {
    this.filterTypeList = [
      {label: this.testi.dataDa + ':',  type: 'dataDa', max: '', param: 'datada'},
      {label: this.testi.stato + ':',  type: 'dropdown', max: '', param: 'idStato',  prop: 'idStatoTicket', lista: 'statoTicket' },
      {label: this.testi.tipoServizio + ':',  type: 'dropdown', max: '', param: 'idServizio', prop: 'idTipoInterventi', lista: 'tipoIntervento'  },
      {label: this.testi.unitaServizio + ':',  type: 'dropdown', max: '', param: 'idUnitaServizio', prop: 'id', lista: 'unitaServizio' },
      {label: this.testi.dataA + ':',  type: 'dataA', max: '', param: 'dataa' },
      {label: this.testi.descrizione + ':',  type: 'text', max: '', param: 'descrizione' },
    ];
  }

  private setFilterUS(): void {
    this.filterTypeList = [
      {label: 'Username' + ':',  type: 'text', max: '', param: 'descrizione' },
      {label: 'Unità di Servizio' + ':',  type: 'text', max: '', param: 'unitaservizio' },
    ];
  }
  
    private setFilterpulizie(): void {
    this.filterTypeList = [
      {label: this.testi.dataDa + ':',  type: 'dataDa', max: '', param: 'datada'},
      {label: this.testi.stato + ':',  type: 'dropdown', max: '', param: 'idStato',  prop: 'idStatoTicket', lista: 'statoTicket' },
      {label: this.testi.tipoServizio + ':',  type: 'dropdown', max: '', param: 'idServizio', prop: 'idTipoInterventi', lista: 'tipoIntervento'  },
      {label: this.testi.unitaServizio + ':',  type: 'dropdown', max: '', param: 'idUnitaServizio', prop: 'id', lista: 'unitaServizio' },
      {label: this.testi.dataA + ':',  type: 'dataA', max: '', param: 'dataa' },
      {label: this.testi.descrizione + ':',  type: 'text', max: '', param: 'descrizione' },
    ];
  }

  private setFilterStanze(): void {
    this.filterTypeList = [
      {label: this.testi.dataDa + ':',  type: 'dataDa', max: '', param: 'datada'},
      {label: this.testi.stanza + ':',  type: 'dropdown', max: '', param: 'idStanza', prop: 'id', lista: 'stanze'  },
      {label: this.testi.unitaServizio + ':',  type: 'dropdown', max: '', param: 'idUnitaServizio', prop: 'id', lista: 'unitaServizio'},
      {label: this.testi.dataA + ':',  type: 'dataA', max: '', param: 'dataa' },
      {label: this.testi.descrizione + ':',  type: 'text', max: '', param: 'descrizione' },
    ];
  }

  private setFilterTipoMenu(): void {
    this.filterTypeList = [
      {label: this.testi.descrizione + ':',  type: 'text', max: '', param: 'descrizione' },
      {label: this.testi.dieta + ':',  type: 'dropdown', param: 'idDieta', prop: 'id', lista: 'dieteList'  },
       ];
  }

  private setFilterPuntiPrelievo(): void {
    this.filterTypeList = [
      {label: this.testi.descrizione + ':',  type: 'text', max: '', param: 'descrizione' },
      {label: this.testi.ccDesc + ':',  type: 'dropdown', param: 'idCentroCosto', prop: 'id', lista: 'centriCostoList'  },
    ];
  }

  private setFilterPazienti(): void {
    this.filterTypeList = [
      {label: this.testi.nome + ':',  type: 'text', max: '', param: 'nome' },
      {label: this.testi.cognome + ':',  type: 'text', max: '', param: 'cognome' },
      {label: this.testi.tipoStruttura + ':',  type: 'dropdown', param: 'tipostruttura', prop: 'id', lista: 'tipoStrutturaList'},
      {label: this.testi.struttura + ':',  type: 'dropdown', param: 'struttura', prop: 'id', lista: 'strutturaList'},
      {label: this.testi.unitaServizio + ':',  type: 'dropdown', param: 'us', prop: 'id', lista: 'usList'},
      {label: this.testi.dieta + ':',  type: 'dropdown', param: 'dieta', prop: 'id', lista: 'dietaList'},
      {label: this.testi.dataDa + ':',  type: 'dataDa', max: '', param: 'datainserimentoda' },
      {label: this.testi.dataA + ':',  type: 'dataA', max: '', param: 'datainserimentoa' },
      {label: "Solo Presenti" + ':',  type: 'check', max: '', param: 'presente' }
    ];
  }


  private setFilterRichieste(): void {
    this.filterTypeList = [
      {label: this.testi.dataDa + ':',  type: 'dataDa', max: '', param: 'datada'},
      {label: this.testi.stato + ':',  type: 'dropdown', max: '', param: 'idStato',  prop: 'idStatoTicket', lista: 'statoTicket', tooltip: true },
      {label: this.testi.tipoServizio + ':',  type: 'dropdown', max: '', param: 'idServizio', prop: 'idTipoInterventi', lista: 'tipoIntervento' , tooltip: true },
      {label: this.testi.descrizione + ':',  type: 'text', max: '', param: 'descrizione' },
      {label: this.testi.dataA + ':',  type: 'dataA', max: '', param: 'dataa' },
      {label: this.testi.unitaServizio + ':',  type: 'dropdown', max: '', param: 'idUnitaServizio', prop: 'id', lista: 'unitaServizio', tooltip: true },
      {label: this.testi.note + ':',  type: 'text', max: '', param: 'note' },
      {label: this.testi.prot + ':',  type: 'text', max: '', param: 'idticket' }
    ];
  }

  private anno(): Observable<any[]> {
    const currentYear:number = new Date().getFullYear();
    let anni = [];
    for (let anno = 2010; anno <= currentYear; anno++) {
      anni.push({viewValue: anno, value: {'lsAnno':anno }})
    }

    return of(anni);
  }


  private mese(): Observable<any[]> {
    let mesi= [];
      mesi.push({viewValue: 'Gennaio', value: {'lsMese':'01'}});
      mesi.push({viewValue: 'Febbraio', value: {'lsMese':'02'}});
      mesi.push({viewValue: 'Marzo', value: {'lsMese':'03'}});
      mesi.push({viewValue: 'Aprile', value: {'lsMese':'04'}});
      mesi.push({viewValue: 'Maggio', value: {'lsMese':'05'}});
      mesi.push({viewValue: 'Giugno', value: {'lsMese':'06'}});
      mesi.push({viewValue: 'Luglio', value: {'lsMese':'07'}});
      mesi.push({viewValue: 'Agosto', value: {'lsMese':'08'}});
      mesi.push({viewValue: 'Settembre', value: {'lsMese':'09'}});
      mesi.push({viewValue: 'Ottobre', value: {'lsMese':'10'}});
      mesi.push({viewValue: 'Novembre', value: {'lsMese':'11'}});
      mesi.push({viewValue: 'Dicembre', value: {'lsMese':'12'}});
    return of(mesi);
  }


  private setFilterReport(): void {
    this.filterTypeList = [
      {label: 'Anno' + ':',  type: 'dropdown', max: '', param: 'lsAnno',  prop: 'lsAnno', lista: 'anni', tooltip: false },
      {label: 'Mese' + ':',  type: 'dropdown', max: '', param: 'lsMese',  prop: 'lsMese', lista: 'mesi', tooltip: false },
      {label: this.testi.stato + ':',  type: 'dropdown', max: '', param: 'lsStato',  prop: 'idStatoTicket', lista: 'statoTicket', tooltip: false },
      {label: 'Impianto' + ':',  type: 'dropdown', max: '', param: 'lsImpianto', prop: 'idImpianto', lista: 'tipoImpianto' , tooltip: false },
      {label: 'Unità di Servizio' + ':',  type: 'dropdown', max: '', param: 'lsUs', prop: 'id', lista: 'unitaServizio', tooltip: false },
      {label: 'Urgenza' + ':',  type: 'dropdown', max: '', param: 'lsUrgenza', prop: 'idUrgenza', lista: 'urgenza' , tooltip: false },
      {label: 'Descrizione' + ':',  type: 'text', max: '', param: 'descrizione' }
      ];
  }



  private setAllList(type: string |undefined) {
    const arrayName: Map<string, Observable<any[]>>  = new Map<string, Observable<any[]>>();
     if (type == 'richieste') {
       arrayName.set('tipoRichiesta', this.richiesteService.getRichiesteType());
       arrayName.set('tipoIntervento', this.shared.utente!.roles!.includes('manutenzione_interna') ?  this.richiesteService.getInterventiType(true): this.richiesteService.getInterventiType(false));
       arrayName.set('unitaServizio', this.richiesteService.getUSType());
       arrayName.set('statoTicket', this.richiesteService.getStatoTicketType());
     }
     if (type == 'report') {
       arrayName.set('tipoRichiesta', this.richiesteService.getRichiesteType());
       arrayName.set('tipoImpianto', this.richiesteService.getImpiantoType());
       arrayName.set('unitaServizio', this.richiesteService.getUSType());
       arrayName.set('urgenza', this.richiesteService.getUrgenzaType());
       arrayName.set('statoTicket', this.richiesteService.getStatoTicketType());
       arrayName.set('anni', this.anno());
       arrayName.set('mesi', this.mese());
     }
     if (type == 'pazienti'){
       arrayName.set('tipoStrutturaList', this.pazientiService.getAllTipoStrutture());
       arrayName.set('strutturaList',this.struttureService.getAllStrutture());
       arrayName.set('usList',this.pazientiService.getAllUS());
       arrayName.set('stanzeList',this.pazientiService.getAllStanze());
       arrayName.set('dietaList',this.pazientiService.getAllDiete());
     }
     if (type == 'stanze') {
      arrayName.set('unitaServizio', this.pulizieService.getUnitaServizio());
      arrayName.set('stanze', this.pulizieService.getStanze());
    }
     if (type == 'tipoMenuFilter') {
      arrayName.set('dieteList', this.dieteService.getAllDiete());
       }
     if (type == 'puntiPrelievoFilter') {
        arrayName.set('centriCostoList', this.centriCostoService.getAllCentriCosto());
  	 }
       /*if (this.filterDataList) {
         this.setallList(this.filterDataList);
     }
     } else {*/
     this.getallList(arrayName);

  }


  private getallList(arrayName: Map<string, Observable<any[]>>) {
    arrayName.forEach( (value: Observable<any[]>, key: string) => this.allList.set(key, []));

    arrayName.forEach( (value: Observable<any[]>, key: string) => {
      value.subscribe( res => {
        this.allList.set(key, res)
      });
    });
  }

  print() {

  }

}
