import {MyDate} from './MyDate';
import {
  impianto,
  statoTicket,
  tipoInterventi,
  tipoRichiesta,
  tipoRifiuto,
  unitaServizio, unitaServizioExtended,
  urgenza,
  utenteGestore
} from "./richiesteDetails";
import {Paged} from "./Paged";

export class richiestaPaged extends Paged {
  content: Richiesta[];

  constructor(data: any) {
    super(data);
    this.content = data.content ? data.content.map( (rc: Richiesta) => new Richiesta(rc) ) : [];
  }
}

export class storico {
  idStoricoMov: number;
  idTicket: number;
  idTicketRiferimento: number;
  dataStoricoMov?: MyDate;
  userStoricoMov: string;
  statoStoricoMov: string;
  descTicket: string;
  note: string;
  unitaServizio: string;
  stanza: string;
  tipoRichiesta: string;
  impianto: string;
  urgenza: string;
  gestore: string;
  tipoRifiuto: string;
  referente: string;
  dataInizioIntervento?: MyDate;

  constructor(data: any) {
    Object.assign(this, data);
    this.idStoricoMov = data.idStoricoMov;
    this.idTicket = data.idTicket;
    this.idTicketRiferimento = data.idTicketRiferimento ? data.idTicketRiferimento : data.idTicketRif;
    this.dataStoricoMov = data.dataStoricoMov ? new MyDate(data.dataStoricoMov): undefined;
    this.userStoricoMov = data.userStoricoMov.username ? data.userStoricoMov.username : data.userStoricoMov;
    this.statoStoricoMov = data.statoStoricoMov.desStatoTicket ? data.statoStoricoMov.desStatoTicket: data.statoStoricoMov;
    this.descTicket = data.descTicket;
    this.note = data.note;
    this.unitaServizio = data.descUS ? data.descUS : data.unitaServizio;
    this.stanza = data.stanza;
    this.tipoRichiesta = data.descTipoRichiesta ? data.descTipoRichiesta : data.tipoRichiesta;
    this.impianto = data.descImpianto ? data.descImpianto : data.impianto;
    this.urgenza = data.descUrgenza ? data.descUrgenza : data.urgenza;
    this.gestore = data.gestore;
    this.tipoRifiuto = data.tipoRifiuto;
    this.referente = data.descReferente ? data.descReferente : data.referente;
    this.dataInizioIntervento = data.dataInizioIntervento ? new MyDate(data.dataInizioIntervento) : undefined;

  }
  get dataStMov(): string {
    return this.dataStoricoMov ? this.dataStoricoMov.giornoMeseAnnoOreSec : '';
  }

  get dataInInt(): string {
    return this.dataInizioIntervento ? this.dataInizioIntervento.giornoMeseAnno : '';
  }
}


export class Richiesta {
  dms?: string;
  idTicket: number;
  idTicketRif: number;
  desTicket: string;
  note?: string;
  dataApertura?: MyDate;
  dataScadenza?: MyDate;
  dataMod?: MyDate;
  dataInizioIntervento?: MyDate;
  dataPC?: MyDate;
  numProto?: string;
  unitaServizio?: unitaServizioExtended;
  stanza?: string;
  flgEmail?: boolean;
  idUtenteModifica?: number;
  utenteModifica?: string;
  idUtenteInserimento?: number;
  utenteInserimento?: string;
  utenteGestore?: utenteGestore;
  idReferente?: number;
  descReferente?: string;
  numTentativi?: number;
  statoTicket?: statoTicket;
  tipoInterventi?: tipoInterventi;
  impianto?: impianto;
  urgenza?: urgenza;
  tipoRifiuto?: tipoRifiuto;
  tipoRichiesta?: tipoRichiesta;
  tipoTicket?: tipoRichiesta;
  storico?: storico[] | undefined;
  utenteAss?: string;

  constructor(data: any) {
    this.idTicket = data.idTicket;
    this.idTicketRif = data.idTicketRif;
    this.desTicket = data.desTicket;
    this.note = data.note;
    this.dataApertura = data.dataApertura ? new MyDate(data.dataApertura) : undefined;
    this.dataScadenza = data.dataScadenza ? new MyDate(data.dataScadenza) : undefined;
    this.dataMod = data.dataMod ? new MyDate(data.dataMod) : undefined;
    this.dataPC =  data.dataMod ? new MyDate(data.dataMod) : undefined;
    this.dataInizioIntervento= data.dataInizioIntervento ? new MyDate(data.dataInizioIntervento) : undefined;
    this.numProto = data.numProto;
    this.unitaServizio = new unitaServizioExtended(data.unitaServizio);
    this.flgEmail = data.flgEmail;
    this.idUtenteModifica = data.idUtenteModifica;
    this.utenteModifica = data.idUtenteModifica;
    this.utenteGestore = new utenteGestore(data.utenteGestore);
    this.idReferente = data.idReferente;
    this.descReferente = data.descReferente;
    this.numTentativi = data.numTentativi;
    this.statoTicket = data.statoTicket;
    this.tipoInterventi = new tipoInterventi(data.tipoInterventi);
    this.impianto = new impianto(data.impianto);
    this.urgenza = new urgenza(data.urgenza);
    this.tipoRifiuto = new tipoRifiuto(data.tipoRifiuto);
    this.tipoRichiesta = new tipoRichiesta(data.tipoRichiesta);
    this.tipoTicket = new tipoRichiesta(data.tipoRichiesta);
    this.storico = data.storico ? data.storico.map( (rc: storico) => new storico(rc)) : undefined;
    this.stanza = data.stanza;
    this.dms = data.dms;
    this.utenteAss = this.utenteGestore ? this.utenteGestore.user ? this.utenteGestore.user.username : "" : "";
    //this.utenteAss = data.utenteGestoreName ? data.utenteGestoreName : "";
  }

  get stato(): string {
      return this.statoTicket ? this.statoTicket.desStatoTicket : "";
  }

  get gestore(): string {
    return this.utenteGestore ? this.utenteGestore.name : "";
  }

  get tipoUnitaServizio(): string {
    return this.unitaServizio ? this.unitaServizio.descUnitaServizio : "";
  }

  get tipoServizio(): string {
    return this.tipoInterventi ? this.tipoInterventi.desTipoInterventi : "";
  }

  get nomeImpianto(): string {
    return this.impianto ? this.impianto.desImpianto : "";
  }

  get nomeUrgenza(): string {
    return this.urgenza ? this.urgenza.desUrgenza : "";
  }


  get nomeRifiuto(): string {
    return this.tipoRifiuto ? this.tipoRifiuto.desTipoRifiuto: "";
  }

  get nomeRichiesta(): string {
    return this.tipoRichiesta ? this.tipoRichiesta.desTipoTicket : "";
  }

  get dataAp(): string {
    return this.dataApertura ? this.dataApertura.giornoMeseAnno : '';
  }

  get dataApExcel(): string {
    return this.dataApertura ? this.dataApertura.giornoMeseAnnoOreSec : '';
  }

  get dataMo(): string {
    return this.dataMod ? this.dataMod.giornoMeseAnno : '';
  }

  get dataScad(): string {
    return this.dataScadenza ? this.dataScadenza.giornoMeseAnno : '';
  }

  get utenteInserimentoString(): string {
      const firstData: storico[]  = this.storico!.filter(line => line.statoStoricoMov == "INSERITA");
      return firstData ? firstData[0].userStoricoMov : '';
  }

  get dataII(): string {
    return this.dataInizioIntervento ?  this.dataInizioIntervento.giornoMeseAnno : '';
  }

  getOggUpdate() {
    return {
      idTicket: this.idTicket,
      dataScadenza: this.dataScadenza ? this.dataScadenza.dateSave : this.dataScadenza,
      desTicket: this.desTicket,
      numProto: this.numProto,
      dataApertura: this.dataApertura ? this.dataApertura.dateSave : this.dataApertura,
      dataMod: this.dataMod ? this.dataMod.dateSave : this.dataMod,
      note: this.note,
      unitaServizio: this.unitaServizio,
      stanza: this.stanza,
      flgEmail: this.flgEmail,
      utenteModifica: this.utenteModifica,
      utenteInserimento: this.utenteInserimento,
      utenteGestore: this.utenteGestore,
      idReferente: this.idReferente,
      descReferente: this.descReferente,
      idTicketRif: this.idTicketRif,
      dataInizioIntervento: this.dataInizioIntervento ? this.dataInizioIntervento.dateSave : this.dataInizioIntervento,
      numTentativi: this.numTentativi,
      tipoRifiuto: this.tipoRifiuto ? this.tipoRifiuto.idTipoRifiuto ? this.tipoRifiuto : null : null,
      tipoIntervento: this.tipoInterventi,
      statoTicket: this.statoTicket,
      impianto: this.impianto,
      urgenza: this.urgenza,
      tipoTicket: this.tipoRichiesta ? {id: this.tipoRichiesta!.idTipoTicket!} : undefined,
      dms: this.dms
    }
  }
  getOggContesta() {
    if (this.impianto!.idImpianto) {
      return {
        desTicket: this.desTicket || "",
        unitaServizio: this.unitaServizio,
        stanza: this.stanza,
        flgEmail: this.flgEmail || false,
        tipoInterventi: this.tipoInterventi,
        tipoRichiesta: this.tipoRichiesta,
        impianto: this.impianto,
        urgenza: this.urgenza || undefined,
        tipoTicket: this.tipoRichiesta,
        descReferente: this.descReferente,
        idTicketRif: this.idTicket
      }
    } else {
      return {
        desTicket: this.desTicket || "",
        unitaServizio: this.unitaServizio,
        stanza: this.stanza,
        flgEmail: this.flgEmail || false,
        tipoInterventi: this.tipoInterventi,
        tipoRichiesta: this.tipoRichiesta,
        impianto: null,
        urgenza: this.urgenza || undefined,
        tipoTicket: this.tipoRichiesta,
        descReferente: this.descReferente,
        idTicketRif: this.idTicket
      }
    }
  }

  getOggCreate() {
    if (this.impianto!.idImpianto){
      return {
        desTicket: this.desTicket || "",
        unitaServizio: this.unitaServizio,
        stanza: this.stanza || "",
        flgEmail: this.flgEmail || false,
        idTicketRif: this.idTicketRif,
        tipoInterventi: this.tipoInterventi,
        tipoRichiesta: this.tipoRichiesta,
        impianto: this.impianto || undefined,
        urgenza: this.urgenza || undefined,
        tipoTicket: this.tipoRichiesta,
        descReferente: this.descReferente,
        dms: this.dms
      };
    } else {
      return {
        desTicket: this.desTicket || "",
        unitaServizio: this.unitaServizio,
        stanza: this.stanza || "",
        flgEmail: this.flgEmail || false,
        idTicketRif: this.idTicketRif,
        tipoInterventi: this.tipoInterventi,
        tipoRichiesta: this.tipoRichiesta,
        urgenza: this.urgenza || undefined,
        tipoTicket: this.tipoRichiesta,
        descReferente: this.descReferente,
        dms: this.dms
      };
    }
  }

  getOggStep() {
    return {
      idTicket: this.idTicket,
      utenteGestore: this.utenteGestore ? this.utenteGestore : null,
      tipoRifiuto: this.tipoRifiuto ? this.tipoRifiuto.idTipoRifiuto ? this.tipoRifiuto : null : null,
      tipoInterventi: this.tipoInterventi ? this.tipoInterventi : null,
      dataScadenza: this.dataScadenza ? this.dataScadenza.dateSave : this.dataScadenza,
      dataInizioIntervento: this.dataInizioIntervento ? this.dataInizioIntervento.dateSave : this.dataInizioIntervento,
      note: this.note,
      dms: this.dms
    };
  }

  getOggStep2() {
    return {
      idTicket: this.idTicket,
      utenteGestore: this.utenteGestore ? this.utenteGestore : null,
      tipoRifiuto: this.tipoRifiuto ? this.tipoRifiuto.idTipoRifiuto ? this.tipoRifiuto : null : null,
      tipoInterventi: this.tipoInterventi ? this.tipoInterventi : null,
      dataScadenza: this.dataScadenza ? this.dataScadenza.dateSave : this.dataScadenza,
      dataInizioIntervento: this.dataInizioIntervento ? this.dataInizioIntervento.dateSave : this.dataInizioIntervento,
      impianto: this.impianto && Object.keys(this.impianto).length != 0 ? this.impianto : null,
      stanza: this.stanza ? this.stanza : '',
      note: this.note,
      dms: this.dms
    };
  }
}




