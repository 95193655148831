import {MyDate} from './MyDate';
import {
    impianto,
    statoTicket,
    tipoInterventi,
    tipoRichiesta,
    tipoRifiuto,
    unitaServizio,
    urgenza,
    utenteGestore
} from "./richiesteDetails";
import {Paged} from "./Paged";

export class ReportPaged extends Paged {
  content: Report[];

  constructor(data: any) {
    super(data);
    this.content = data.content ? data.content.map( (rc: Report) => new Report(rc) ) : [];
  }
}

export class Report {
  protocollo: number;
  descrizione: string;
  note: string;
  impianto: string;
  dataApertura: MyDate | undefined;
  unitaServizio: string;
  statoRichiesta: string;
  utenteApertura:string;
  urgenza: string;

  constructor(data: any) {
    Object.assign(this, data);
    this.dataApertura = data.dataApertura ? new MyDate(data.dataApertura) : undefined;

  }

  get dataApExcel(): string {
    return this.dataApertura ? this.dataApertura.giornoMeseAnnoOreSec : '';
  }
}




