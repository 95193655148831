import {MailWelcome} from "./MailWelcome";

export class Mail {
  mailTo: string[];
  templateType: string;
  mailSubject: string;
  mailFrom: string;
  model: MailWelcome;

  constructor(data: any) {
    this.mailTo = data.mailTo;
    this.templateType = data.templateType;
    this.mailSubject = data.mailSubject;
    this.mailFrom = data.mailFrom;
    this.model = data.model;
  }


  getOggCreate(): MailCreate {
    return {
      mailTo: this.mailTo!,
      templateType: this.templateType!,
      mailSubject: this.mailSubject!,
      mailFrom: this.mailFrom!,
      model: this.model!
    };
  }

}

export interface MailCreate {
  mailTo: string[];
  templateType: string;
  mailSubject: string;
  mailFrom: string;
  model: MailWelcome;

}
