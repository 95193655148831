<app-header-popup cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container"
                  [loading]="loading"
                  (close)="cancel()">
</app-header-popup>

<div *ngIf="arrayFormInfo && objValue">
  <app-form-base [arrayFormInfo]="arrayFormInfo"
                 [objValue]="objValue"
                 [selectsGroup]="dropdownList"
                 [enableFooter]="enableFooter"
                 (formState)="statoForm($event)"
                 (saveForm)="saveOgg($event)"
                 (cancel)="cancel()">
  </app-form-base>
</div>
