
<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="struttura-tree" [ngClass]="{'error-form': invalid}">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node;" matTreeNodeToggle>
    <li class="mat-tree-node">
      <mat-icon class="" matTreeNodeToggle></mat-icon>
      <mat-checkbox *ngIf="checkNode(node.type)"
                    class="checklist-leaf-node"
                    [disabled]="block"
                    [checked]="node.check"
                    [(ngModel)]="node.check"
                    [(indeterminate)]="node.indeterminate"
                    (change)="changeCheck(node)">
      </mat-checkbox>
      <div *ngIf="node.check && checkNode(node.type)" class="node-item-selected" matTreeNodeToggle (click)="clickNode(node)">{{node.name}}</div>
      <div *ngIf="node.check && !checkNode(node.type)" class="node-item" matTreeNodeToggle (click)="clickNode(node)">{{node.name}}</div>
      <div *ngIf="!node.check" class="node-item" matTreeNodeToggle (click)="clickNode(node)">{{node.name}}</div>
    </li>
  </mat-tree-node>
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <li>
      <div class="mat-tree-node">
        <mat-icon class="mat-icon-rtl-mirror" matTreeNodeToggle>
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
        <mat-checkbox class="checklist-leaf-node"
                      *ngIf="checkNode(node.type)"
                      [disabled]="block"
                      [checked]="node.check"
                      [(ngModel)]="node.check"
                      [(indeterminate)]="node.indeterminate"
                      (change)="changeCheck(node)">
        </mat-checkbox>
        <!--<div class="node-item" matTreeNodeToggle>{{node.name}}</div>-->
        <div *ngIf="node.check && checkNode(node.type)" class="node-item" matTreeNodeToggle (click)="clickNode(node)">{{node.name}}</div>
        <div *ngIf="node.check && !checkNode(node.type)" class="node-item" matTreeNodeToggle (click)="clickNode(node)">{{node.name}}</div>
        <div *ngIf="!node.check" class="node-item" matTreeNodeToggle (click)="clickNode(node)">{{node.name}}</div>
      </div>
      <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree>
