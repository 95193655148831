<!-- Input textarea -->
<!--
<textarea class="inputStandard"
          matInput
          type="textarea"
          [ngClass]="{ 'input-error': invalid }"
          [(ngModel)]="valore"
          [hidden]="hidden ? hidden: false"
          [rows]="rows"
          [cols]="cols"
          [disabled]="block ? block : false"
          (keyup)="inputChange()"
          [placeholder]="testo ? testo : ''">
</textarea>
-->
<textarea class="inputStandard"
          matInput
          type="textarea"
          (mousedown)="$event.stopPropagation()"
          [ngClass]="{ 'input-error': invalid }"
          [(ngModel)]="valore"
          [hidden]="hidden ? hidden: false"
          [rows]="rows"
          [cols]="cols"
          [maxlength]="max"
          [disabled]="block ? block : false"
          (ngModelChange)="this.valoreUpdate.next($event)"
          [placeholder]="testo ? testo : ''">
</textarea>
