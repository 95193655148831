// imports
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {MenuComponent} from "./menu.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

import {MenuRoutingModule} from "./menu-routing.module";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatProgressBarModule} from "@angular/material/progress-bar";


@NgModule({
    declarations: [MenuComponent],
    imports: [CommonModule, BrowserModule, BrowserAnimationsModule, MenuRoutingModule, MatProgressSpinnerModule, MatProgressBarModule],
    exports: [
        MenuComponent
    ]
})
export class MenuModule {}
