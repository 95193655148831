<div class="table-header-container">
  <div *ngIf="name != ''" class="table-header-name" [ngClass]="{'cursor-click': order }" (click)="changeOrder(order)">
    {{name}} <i *ngIf="order"
                class="fas"
                [ngClass]="{
    'fa-sort-up': orderIcon === 'up',
    'fa-sort-down': orderIcon === 'down',
    'fa-sort' : orderIcon === 'none'}"></i>
    <i *ngIf="!order"
       class="fas"
       [ngClass]="{'fa-sort' : orderIcon === 'none'}">
    </i>
  </div>


  <div class="table-header-filter" [ngSwitch]="filter">
    <ng-container *ngIf="filter">

      <div *ngSwitchCase="'input'">
        <input matInput
               class="header-input"
               type="text"
               [name]="rowProp"
               [placeholder]="'Cerca per' + ' ' + name"
               (keyup)="inputFilter($event)">
      </div>

      <div *ngSwitchCase="'number'">
        <input matInput
               class="header-input"
               [name]="rowProp"
               [placeholder]="'Cerca per' + ' ' + name"
               (keyup)="inputNumber($event)">
      </div>

      <div *ngSwitchCase="'data'">
        <app-ble-datepicker [testo]="'Cerca per' + ' ' + name"
                            [clear]="true"
                            (cambioValore)="filterData(rowProp, $event)">
        </app-ble-datepicker>
      </div>

      <div *ngSwitchCase="'dropdown'">
        <app-ble-dropdown [lista]="filterList"
                          [testo]="'Cerca per' + ' ' + name"
                          [clean]="true"
                          (cambioValore)="dropdown($event)">
        </app-ble-dropdown>
      </div>

      <div *ngSwitchCase="'multiple'">
        <app-ble-dropdown [lista]="filterList"
                          [testo]="'Cerca per' + ' ' + name"
                          [clean]="true"
                          (cambioValore)="dropdown($event)">
        </app-ble-dropdown>
      </div>

      <div *ngSwitchDefault>
      </div>


    </ng-container>

  </div>


</div>
