import {Component, HostListener, OnInit, ViewChild} from "@angular/core";
import {MatDrawer} from "@angular/material/sidenav";
import {KeycloakProfile} from "keycloak-js";
import {KeycloakService} from "keycloak-angular";
import {SdgLibService} from "sdg-lib";
import {MenuService} from "./service/portal/menu.service";
import {from} from "rxjs";
import {ApiRestService} from "./service/utils/api-rest.service";
import {ApiRestServicePortal} from "./service/portal/api-rest.service-portal";


@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {

    @ViewChild("drawer", {static: true}) public drawer!: MatDrawer;
    @HostListener('touchstart')
    @HostListener('mousemove') lastActivity() {
        //console.log("Actvity");
        this.apiRestService.setLastActivity(new Date());
    }


    public isLoggedIn = false;
    public userProfile: KeycloakProfile | null = null;

    loaded: boolean = false;
    menuOpen: boolean = true;
    routerHeight: string = "0";
    loggedIn: Promise<boolean>;

    constructor(private menuService: MenuService,
                private keycloak: KeycloakService,
                private apiRestService: ApiRestServicePortal,
                private shared: SdgLibService) {
        this.apiRestService.setLastActivity(new Date());
    }

    public ngOnInit() {
        this.menuService.setDrawer(this.drawer);
        this.globalLoading();
    }

    private globalLoading() {
        this.shared.getGlobalLoading().subscribe(
            (res: boolean) => {
                setTimeout(() => {
                    this.routerHeight = this.loaded ? "auto" : "0vh";
                    this.menuOpen = this.menuService.getMenu();
                    this.loaded = res;
                }, 0);
            }
        );
    }

    onActivate(event: any) {
        this.menuService.loading = false;
    }

    onDeactivate(event: any) {
        this.loaded = false;
        this.routerHeight = this.loaded ? "auto" : "0vh";
    }


}
