<div class="view-container mat-elevation-z4">

  <div class="loading" *ngIf="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

    <app-table-header *ngIf="!hideTitle"
                      [tableType]="tableType"
                      [filterHeader]="filterHeader"
                      [filterHeaderList]="filterHeaderList"
                      [filterDataList]="filterDataList"
					  [centerTitle]="centerTitle"
                      [buttonAdd]="buttonAdd"
                      [disableAdd]="disableAdd"
                      [buttonAddName]="buttonAddName"
                      [testi]="testi"
                      [type]="type"
                      [buttonBrowse]="buttonBrowse"
                      [buttonFunc]="buttonFunc"
                      [titleStyle]="titleStyle"
					  [buttonFuncName]="buttonFuncName"
                      [cleanName]="cleanName"
                      [buttonAggiorna]="buttonAggiorna"
                      [back]="back"
                      [dmsAddFolder]="dmsAddFolder"
                      [searchField]="searchField"
                      (setFilter)="setFilter($event)"
                      (callBack)="callBack.emit($event)"
                      (callBackUpdate)="callBackUpdate.emit($event)">
    </app-table-header>


     <div [ngClass]="{ 'loading': loading }" *ngIf="_tableData">
       <app-table-base [tableData]="_tableData"
                       [rows]="rows"
                       [height]="height"
                       [onlyView]="onlyView"
                       [noScrollV]="noScrollV"
                       [testi]="testi"
                       [pageIndex]="pageIndex"
					   [noRowPattern]="noRowPattern"
                       [input]="input"
                       [extendRows] = "extendRows"
                       [colorSelected]="colorSelected"
                       [overflow]="overflow || 'auto'"
                       [rowResize]="rowResize"
                       [scroll]="scroll"
                       [reload]="reload"
                       [dms]="dms"
                       [pulizie]="pulizie"
                       [emptyMessage]="emptyMessage"
                       [noclick]="noclick"
                       [totalPageslazy]="totalPageslazy"
                       [tableDataHeader]="tableDataHeader"
                       [showFooter]="showFooter"
                       (mousedown)="$event.stopPropagation()"
                       (callBack)="callBack.emit($event)"
                       (callBackPaginator)="callBackPaginator.emit($event)">
       </app-table-base>
     </div>


</div>
