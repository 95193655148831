import {Component, HostListener, Injectable, OnInit} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {Router} from "@angular/router";
import {SdgLibService} from "sdg-lib";
import {MenuService} from "../service/portal/menu.service";
import {Observable} from "rxjs";
import {ApiRestServicePortal} from "../service/portal/api-rest.service-portal";


@Injectable({ providedIn: 'root' })

@Component({
  selector: 'app-keycloak',
  templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})


export class AuthComponent implements OnInit {
  public fullName: string;
  explicitLogout: boolean = false;


  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event:any) {
    if (!this.explicitLogout) {
      document.cookie = `logout=true;`;
    }
    //window.location.href = "http://www.google.com";//{domain-name}/auth/realms/{realm-name}/protocol/openid-connect/logout?redirect_uri=encodedRedirectUri";
  }


  constructor(private readonly keycloak: KeycloakService,
              private menuService: MenuService,
              private router: Router,
              private shared: SdgLibService,
              private lib: ApiRestServicePortal
  ) {}

  public async ngOnInit() {
    this.fullName = this.shared.utente.nome + " " + this.shared.utente.cognome;
  }

  public logout() {
    this.explicitLogout = true;
    document.cookie = `logout=false;`;
    this.keycloak.logout(window.location.origin);
  }

  toggleMenu(){
      this.menuService.toggle();
  }

  goToHome(){
      this.router.navigate([this.router.url]);
  }

}
