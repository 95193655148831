import {Paged} from "./Paged";

export class DriverRistorazione {

    id?: number;
    descrizione?: string;
	costo?: number;

    constructor(data: any) {
        Object.assign(this, data);
    }





    getOggCreate() {
        return {
            id: this.id,
            descrizione: this.descrizione,
			costo: this.costo,
        };
    }

    getOggUpdate() {
        return this.getOggCreate();
    }

    get viewValue(): string {
        return this.descrizione ? this.descrizione : '';
    }
    get value(): DriverRistorazione {
        return this;
    }


}

export class DriverRistorazionePaged extends Paged {
    content: DriverRistorazione[];

    constructor(data: any) {
        super(data);
        this.content = data.content ? data.content.map((ts: any) => new DriverRistorazione(ts)) : [];
    }
}

