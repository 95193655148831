import {Injectable} from '@angular/core';

import {ApiRestServiceAnagrafiche} from './api-rest.service-anagrafiche';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpHeaders} from "@angular/common/http";
import {CentroCosto, CentroCostoPaged} from "../entities/CentroCosto";


@Injectable({
  providedIn: 'root'
})
export class CentriCostoService {

  constructor( private apiRestService: ApiRestServiceAnagrafiche) {}


  getAllCentriCosto(): Observable<CentroCosto[]> {
    const url =  `centricosto/all`;

    return this.apiRestService.getWithHeader(url)
      .pipe(
        map( res => res ? res.map((ts: any) => new CentroCosto(ts)) : [] )
      );
  }

  getAllCentriCostoFiltrataPaged(page: number, size: number, params: any[][]): Observable<CentroCostoPaged> {
    const url =  `centricosto/filtratapaged?page=${page}&size=${size}`;
   //const url =  `centriCosto/allPaged?page=${page}&size=${size}`;


    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Paged': 'true'
    });

    return this.apiRestService.getWithParams(url, params, header)
        .pipe(
            map( res => new CentroCostoPaged(res) )
           //map( res => res ? res.map((ts: any) => new CentriCostoPaged(ts)) : [] )
        );
  }





  getCentriCosto(id: number): Observable<CentroCosto> {
    const url =  `centricosto/id=${id}`;

    return this.apiRestService.getWithHeader(url)
      .pipe(
        map( res => new CentroCosto(res) )
      );
  }



  deleteCentriCosto(id: number, headers: HttpHeaders): Observable<CentroCosto> {
    const url =  `centricosto/id=${id}`;

    return this.apiRestService.delete(url, headers);
  }


  updateCentriCosto(centriCosto: CentroCosto): Observable<CentroCosto> {
    const url = `centricosto/update/id=${centriCosto.ccId}`;

    return this.apiRestService.patchWithBody(url, centriCosto.getOggUpdate());
  }

  createCentriCosto(centriCosto: CentroCosto): Observable<CentroCosto> {
    const url = `centricosto/save`;

    return this.apiRestService.postWithBody(url, centriCosto.getOggCreate());
  }

















}
