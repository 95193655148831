import {Injectable, NgZone} from '@angular/core';
import {Snackbar} from '../../entities/Snackbar';
import {MatSnackBar} from "@angular/material/snack-bar";
import {SnackBarComponent} from "../../shared-modules/snack-bar/snack-bar.component";


@Injectable({
  providedIn: 'any'
})
export class SnackbarService {

  private snackBarOpen = false;
  private snackList: Snackbar[] = [];

  constructor(private snackBar: MatSnackBar,
              private zone: NgZone) {  }

  setListMsg(snackbar: Snackbar) {
    if (!this.snackBarOpen) {
      this.openSnackBar(snackbar);
    } else {
      this.checkMsgPriority(snackbar);
    }
  }

  checkMsgPriority(snackbar: Snackbar) {
    if (snackbar.severity == 'error') {
      this.snackList.unshift(snackbar);
      this.snackBar.dismiss();
    } else {
      this.snackList.push(snackbar);
    }
  }

  openSnackBar(snackbar: Snackbar) {
    this.snackBarOpen = true;

    let cssClass;

    switch (snackbar.severity) {
      case 'success':
        cssClass = 'snackbar-ok';
        break;
      case 'error':
        cssClass = 'snackbar-error';
        break;
      case 'alarmAsset':
      case 'warn':
        cssClass = 'snackbar-alert';
        break;
      case 'info':
        cssClass = 'snackbar-info';
        break;
    }

    this.snackBar.openFromComponent(SnackBarComponent, {
      data: { severity: snackbar.severity, msg: snackbar.msg },
      duration: snackbar.severity == 'error' ? 5000 : 2000,
      panelClass: cssClass,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    })
        .afterDismissed()
        .subscribe(
            () => {
              if (this.snackList.length) {
                const snackbar = this.snackList.shift() as Snackbar;
                this.zone.run(() => {
                  this.openSnackBar(snackbar);
                });
              } else {
                this.snackBarOpen = false;
              }
            }
        );
  }
}
