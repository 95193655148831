
export class MailWelcome {
  password: string;
  userdata: string;
  username: string;
  portale: string;
  url: string;
  mail: string;
  date: string;

  constructor(data: any) {
    this.password = data.password;
    this.userdata = data.userdata;
    this.username = data.username;
    this.mail = data.mail;
    this.date = data.date;
    this.portale = data.portale;
    this.url = data.url;
  }


  getOggCreate(): MailWelcomeCreate {
    return {
      password: this.password!,
      userdata: this.userdata!,
      username: this.username!,
      mail: this.mail!,
      date: this.date!,
      portale: this.portale!,
      url: this.url!
    };
  }

}

export interface MailWelcomeCreate {
  password: string;
  userdata: string;
  username: string;
  mail: string;
  date: string;
  portale: string;
  url: string;
}
