import {Injectable} from "@angular/core";
import {KeycloakEventType, KeycloakService} from "keycloak-angular";
import {HttpBackend, HttpClient} from "@angular/common/http";
import {map, switchMap, tap} from "rxjs/operators";
import {forkJoin, from, Observable} from "rxjs";
import {Router} from "@angular/router";
import {SdgLibService} from "sdg-lib";
import {TestiService} from "../service/config/testi.service";
import {ApiRestServicePortal} from "../service/portal/api-rest.service-portal";
import {keycloakHelper} from "../service/portal/keycloakHelper";
import {TreeNodeMaterial} from "../entities/TreeNodeMaterial";
import {MenuAnagraficheService} from "../service/menu-anagrafiche.service";


@Injectable({
  providedIn: 'root'
})
export class InitAppService {

  private https = '';

  httpSenzaInterceptor: HttpClient;

  conf: any;
  refresh: any;

  public isLoggedIn = false;

  constructor(private http: HttpClient,
              private handler: HttpBackend,
              private shared: SdgLibService,
              private router: Router,
              private testiService: TestiService,
              private apiRestService: ApiRestServicePortal,
              private menuService: MenuAnagraficheService,
              private keycloakService: KeycloakService,
              private kcHelper: keycloakHelper) {
    this.httpSenzaInterceptor = new HttpClient(handler);
  }

  getPromiseConfig(): Promise<void> {
    this.https = window.location.href.toString().split(':')[0];
    return new Promise<void>((resolve, reject) => {
      forkJoin({
        menu: this.menuService.setMenu(),
        configuration: this.setConfiguration(),
      }).subscribe((msg) => {
        this.getTree().subscribe(res => {this.shared.fullTree = res;});
        this.getTree().subscribe(res => {this.shared.partTree = res;});
        resolve();
      })
    })
  }

  private httpSwitch(config: any): any {
    let k: keyof typeof config;
    for (k in config) {
      if (typeof config[k] == 'string' && config[k].toString().includes('http')) {
        const test = config[k].toString().split(':')[0];
        if (test != this.https) {
          config[k] = config[k].toString().replace(test, this.https);
        }
      }
    }
    return config;
  }

  getTree(): Observable<TreeNodeMaterial[]> {
    const url = this.conf['apiRestLinkAnagrafiche'] + 'blocchi/tree';
    return this.apiRestService.getWithHeader(url)
        .pipe(
            map(res => res.map((tree: any) => new TreeNodeMaterial(tree)))
        );
  }

  private setConfiguration(): Observable<any> {
    this.setObservableToken();
    const year = (new Date()).getFullYear();
    return this.getConfiguration()
        .pipe(
           tap((conf) => {
              this.conf = new Object(this.httpSwitch(conf));
              this.shared.conf = this.httpSwitch(conf);
              this.apiRestService.externalUrl = this.conf.externalUrl;
              this.apiRestService.apiRestLinkRichieste = this.conf.apiRestLinkRichieste;
              this.apiRestService.apiRestKeycloak = this.conf.apiRestKeycloakLink;
              this.apiRestService.apiRestLinkAnagrafiche = this.conf.apiRestLinkAnagrafiche;
              this.apiRestService.apiRestLinkPulizie = this.conf.apiRestLinkPulizie;
              this.apiRestService.apiRestLinkLavanolo = this.conf.apiRestLinkLavanolo;
              this.apiRestService.apiRestLinkRistorazione = this.conf.apiRestLinkRistorazione;
              this.apiRestService.apiRestLinkDMS = this.conf.apiRestLinkDMS;
              this.apiRestService.apiRestDMS = this.conf.apiRestDMS;
              this.apiRestService.dmsFolderId=this.conf.dmsFolderId;
              this.apiRestService.dmsTicketFolder = conf[year];
              this.apiRestService.portalName = this.conf.portal;
              this.apiRestService.portalUrl = this.conf.url;
            })
        ).pipe(switchMap((conf) => this.getTesti(this.httpSwitch(conf).testi)))
  }

  private getTesti(lang: string): Observable<any>{
    return this.testiService.setTesti(lang);
  }

  private setObservableToken(): void {
    this.keycloakService.isLoggedIn().then((res: any)=>{
      if (res == true){
        from (this.keycloakService.getToken()).subscribe( token => {
          this.shared.token = token;
          document.cookie = "logout=false;";
          this.shared.utente = this.shared.tokenDecoded;
          this.setUserData(this.keycloakService.getKeycloakInstance().token!);
          this.checkValidity();
        });
      }
    });


    this.keycloakService.keycloakEvents$.subscribe(event => {
      if (event.type == KeycloakEventType.OnAuthSuccess) {
        from(this.keycloakService.getToken())
            .subscribe(token => {
              this.shared.token = token;
              this.setUserData(token);
              this.checkValidity();
            });
      }
      if (event.type == KeycloakEventType.OnAuthSuccess) {
        this.apiRestService.refreshToken = this.keycloakService.getKeycloakInstance().refreshToken!;
      }
    });
    this.tokenRenew();
  }

  private updateToken(){
    const elapsed = (new Date().getTime() - this.apiRestService.getLastActivity().getTime()) / 60000;
    if ( elapsed <= this.conf.sessionTimeout) {
      //console.log(elapsed, this.conf.sessionTimeout)
      this.keycloakService.updateToken(1).then((refreshed) => {
        if (refreshed) {
          console.log("Token Rinnovato", new Date());
          this.setUserData(this.keycloakService.getKeycloakInstance().token!);
          this.apiRestService.refreshToken = this.keycloakService.getKeycloakInstance().refreshToken!;
        } else {
          //console.log('Token Ancora Valido ',new Date());
        }
      }).catch(() => {
        console.error('Impossibile Rinnovare il Token ', new Date());
      });
    } else {
      this.keycloakService.logout()
    }
  }

  private getConfiguration(): Observable<any> {
    const url = `./assets/json/configuration.json`;
    return this.httpSenzaInterceptor.get(url);
  }

  private setUserData(token: string) {
    this.apiRestService.setlogged(true);
    this.shared.token = token;
    this.shared.utente = this.shared.tokenDecoded;
  }

  private tokenRenew() {
    clearInterval(this.refresh);
    this.refresh = setInterval(() => this.updateToken(), 1000*60);
  }

  private checkValidity(){
    const tokenEnd = this.shared.utente.validityend ?? '';
    let [day,month,year] = tokenEnd.split('/');
    const valEnd = new Date(+year, + month - 1, + day);
    const tokenStart = this.shared.utente.validitystart ?? '';
    [day,month,year] = tokenStart.split('/');
    const valStart = new Date(+year, + month - 1, + day);
    const date: Date = new Date();
    if (valStart > date){
      window.alert("Utente non ancora attivo, inizio validità: " + tokenStart);
      this.keycloakService.logout();
    }
    if (tokenEnd === '') {
      if (valEnd <= date) {
        window.alert("Utenza scaduta, fine validità: " + tokenEnd);
        this.keycloakService.logout();
      }
    }
  }
}
/* ad rhoncus suilla */
/*     ¯\_(ツ)_/¯    */
