import {NgModule} from '@angular/core';
import {KeycloakAngularModule} from 'keycloak-angular';
import {AuthComponent} from './auth.component';
import {CommonModule} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {SdgLibModule} from "sdg-lib";


@NgModule({
  declarations: [AuthComponent],
  imports: [
      CommonModule,
      KeycloakAngularModule,
      MatIconModule],
  providers: [
      SdgLibModule
  ],
  exports: [
      AuthComponent
  ],
  bootstrap: [AuthComponent]
})
export class AuthModule {}
