<div class="filter-container">
  <table *ngIf="type == 'pazienti'" style="width:100%;">
      <tr>
        <td style="width:5%;text-align:right;">
            {{filterTypeList[6].label}}&nbsp;&nbsp;
        </td>
        <td style="width:10%;">
            <ng-container *ngTemplateOutlet="data; context: { filtro: filterTypeList[6], index: 6}"></ng-container>
        </td>
        <td style="width:10%;text-align:right;">
          {{filterTypeList[0].label}}&nbsp;&nbsp;
        </td>
        <td style="width:10%;">
          <ng-container *ngTemplateOutlet="text; context: { filtro: filterTypeList[0], index: 0}"></ng-container>
        </td>
        <td style="width:10%;text-align: right">
          {{filterTypeList[1].label}}&nbsp;&nbsp;
        </td>
        <td style="width:10%;">
          <ng-container *ngTemplateOutlet="text; context: { filtro: filterTypeList[1], index: 1}"></ng-container>
        </td>
        <td style="width:10%;text-align: right">
          {{filterTypeList[5].label}}&nbsp;&nbsp;
        </td>
        <td #td1 style="width:10%;">
          <ng-container *ngTemplateOutlet="dropdown; context: { filtro: filterTypeList[5], multiple: false, index: 5, td:td1}"></ng-container>
        </td>
        <td style="width:10%;text-align:right;">
          <button mat-flat-button
                  type="button"
                  color="primary"
                  [disabled]="!filtroCambiato"
                  (click)="applicaFilter()">
            {{testi.applica}}
          </button>
        </td>
      </tr>
      <tr style="height:1vh;"></tr>
      <tr>
        <td style="width:5%;text-align:right;">
          {{filterTypeList[7].label}}&nbsp;&nbsp;
        </td>
        <td style="width:10%;">
          <ng-container *ngTemplateOutlet="data; context: { filtro: filterTypeList[7], index: 7}"></ng-container>
        </td>
        <td style="width:10%;text-align:right;">
          {{filterTypeList[2].label}}&nbsp;&nbsp;
        </td>
        <td #td2 style="width:10%;">
          <ng-container *ngTemplateOutlet="dropdown; context: { filtro: filterTypeList[2], multiple: false, index: 2, td:td2}"></ng-container>
        </td>
        <td style="width:10%;text-align:right">
          {{filterTypeList[3].label}}&nbsp;&nbsp;
        </td>
        <td #td3 style="width:10%;">
          <ng-container *ngTemplateOutlet="dropdown; context: { filtro: filterTypeList[3], multiple: false, index: 3, td: td3}"></ng-container>
        </td>
        <td style="width:10%;text-align:right">
          {{filterTypeList[4].label}}&nbsp;&nbsp;
        </td>
        <td #td4 style="width:10%;">
          <ng-container *ngTemplateOutlet="dropdown; context: { filtro: filterTypeList[4], multiple: false, index: 4, td: td4}"></ng-container>
        </td>
        <td style="width:10%;text-align:right;">
          <ng-container *ngTemplateOutlet="check; context: { filtro: filterTypeList[8], multiple: false, index: 8}"></ng-container>
        </td>
      </tr>
  </table>

  <table *ngIf="type == 'richieste'" style="width:100%;">
    <tr>
      <td style="width:5%;text-align:right;">
        {{filterTypeList[0].label}}&nbsp;&nbsp;
      </td>
      <td style="width:10%;">
        <ng-container *ngTemplateOutlet="data; context: { filtro: filterTypeList[0], index: 0}"></ng-container>
      </td>
      <td style="width:10%;text-align:right;">
        {{filterTypeList[1].label}}&nbsp;&nbsp;
      </td>
      <td #td1 style="width:15%;">
        <ng-container *ngTemplateOutlet="dropdown; context: { filtro: filterTypeList[1], multiple: false, index: 1, td:td1}"></ng-container>
      </td>
      <td style="width:10%;text-align: right">
        {{filterTypeList[2].label}}&nbsp;&nbsp;
      </td>
      <td #td2 style="width:15%;">
        <ng-container *ngTemplateOutlet="dropdown; context: { filtro: filterTypeList[2], multiple: false, index: 2, td: td2}"></ng-container>
      </td>
      <td style="width:5%;text-align: right">
        {{filterTypeList[7].label}}&nbsp;&nbsp;
      </td>
      <td style="width:15%;">
        <ng-container *ngTemplateOutlet="textN; context: { filtro: filterTypeList[7], index: 7}"></ng-container>
      </td>
      <td style="width:10%;text-align:right;">
        <button mat-flat-button
                type="button"
                color="primary"
                [disabled]="!filtroCambiato"
                (click)="applicaFilter()">
          {{testi.applica}}
        </button>
      </td>
    </tr>
    <tr style="height:1vh;"></tr>
    <tr>
      <td style="width:5%;text-align:right;">
        {{filterTypeList[4].label}}&nbsp;&nbsp;
      </td>
      <td style="width:10%;">
        <ng-container *ngTemplateOutlet="data; context: { filtro: filterTypeList[4], index: 4}"></ng-container>
      </td>
      <td style="width:10%;text-align:right;">
        {{filterTypeList[5].label}}&nbsp;&nbsp;
      </td>
      <td #td4 style="width:15%;">
        <ng-container *ngTemplateOutlet="dropdown; context: { filtro: filterTypeList[5], multiple: false, index: 5, td:td4}"></ng-container>
      </td>
      <td style="width:10%;text-align:right">
        {{filterTypeList[3].label}}&nbsp;&nbsp;
      </td>
      <td style="width:15%;">
        <ng-container *ngTemplateOutlet="text; context: { filtro: filterTypeList[3], index: 3}"></ng-container>
      </td>
      <td style="width:5%;text-align:right">
        {{filterTypeList[6].label}}&nbsp;&nbsp;
      </td>
      <td style="width:15%;">
        <ng-container *ngTemplateOutlet="text; context: { filtro: filterTypeList[6], index: 6}"></ng-container>
      </td>
      <td style="width:10%;text-align:right;">
      </td>
    </tr>
  </table>
  <!--REPORT-->
  <table *ngIf="type == 'report'" style="width:100%;">
    <tr>
      <td style="width:5%;text-align:right;">
        {{filterTypeList[0].label}}&nbsp;&nbsp;
      </td>
      <td #td1 style="width:10%;">
        <ng-container *ngTemplateOutlet="dropdown; context: { filtro: filterTypeList[0], multiple: true, selectAll: true, index: 0, tooltip: false, td:td1, presetYear: true }"></ng-container>
      </td>
      <td style="width:10%;text-align:right;">
        {{filterTypeList[2].label}}&nbsp;&nbsp;
      </td>
      <td #td2 style="width:15%;">
        <ng-container *ngTemplateOutlet="dropdown; context: { filtro: filterTypeList[2], multiple: true, selectAll: true, index: 2, tooltip: false, td:td2}"></ng-container>
      </td>
      <td style="width:10%;text-align: right">
        {{filterTypeList[3].label}}&nbsp;&nbsp;
      </td>
      <td #td3 style="width:15%;">
        <ng-container *ngTemplateOutlet="dropdown; context: { filtro: filterTypeList[3], multiple: true, selectAll: true, index: 3, tooltip: false, td: td3}"></ng-container>
      </td>
      <td style="width:5%;text-align: right">
        {{filterTypeList[4].label}}&nbsp;&nbsp;
      </td>
      <td #td4 style="width:15%;">
        <ng-container *ngTemplateOutlet="dropdown; context: { filtro: filterTypeList[4], multiple: true, selectAll: true, index: 4, tooltip: false, td: td4}"></ng-container>
      </td>
      <td style="width:10%;text-align:right;">
        <button mat-flat-button
                type="button"
                color="primary"
                [disabled]="!filtroCambiato"
                (click)="applicaFilter()">
          {{testi.applica}}
        </button>
      </td>
    </tr>
    <tr style="height:1vh;"></tr>
    <tr>
      <td style="width:5%;text-align:right;">
        {{filterTypeList[1].label}}&nbsp;&nbsp;
      </td>
      <td #td5 style="width:10%;">
        <ng-container *ngTemplateOutlet="dropdown; context: { filtro: filterTypeList[1], multiple: true, selectAll: true, index: 1, tooltip: false, td:td5}"></ng-container>
      </td>
      <td style="width:10%;text-align:right;">
        {{filterTypeList[5].label}}&nbsp;&nbsp;
      </td>
      <td #td6 style="width:15%;">
        <ng-container *ngTemplateOutlet="dropdown; context: { filtro: filterTypeList[5], multiple: true, selectAll: true, index: 5, td:td6}"></ng-container>
      </td>
      <td style="width:5%;text-align:right">
        {{filterTypeList[6].label}}&nbsp;&nbsp;
      </td>
      <td style="width:15%;">
        <ng-container *ngTemplateOutlet="text; context: { filtro: filterTypeList[6], index: 6}"></ng-container>
      </td>
      <td style="width:10%;text-align:right;">
      </td>
    </tr>
  </table>
  <!-- US -->
  <table *ngIf="type == 'us'" style="width:100%;">
    <tr>
      <td style="width:10%;text-align:left;">
        {{filterTypeList[0].label}}&nbsp;&nbsp;
      </td>
      <td style="width:20%;text-align:left;">
        <ng-container *ngTemplateOutlet="text; context: { filtro: filterTypeList[0], index: 0}"></ng-container>
      </td>
      <td style="width:5%"></td>
      <td style="width:10%;text-align:left;">
        {{filterTypeList[1].label}}&nbsp;&nbsp;
      </td>
      <td  style="width:20%;text-align:left;">
        <ng-container *ngTemplateOutlet="text; context: { filtro: filterTypeList[1], index: 1}"></ng-container>
      </td>
      <td style="width:35%;"></td>
    </tr>
  </table>
  <!-- STANDARD -->
  <div class="filter-lista" *ngIf="type != 'richieste' && type != 'pazienti' && type != 'report' && type !='us'">
    <div class="filtro" *ngFor="let filtro of filterTypeList; let i = index">

      <div class="filtro-label">
        {{filtro.label}}
      </div>

      <ng-container [ngSwitch]="filtro.type">

        <div class="input-data" *ngSwitchCase="'text'">
          <ng-container *ngTemplateOutlet="text; context: { filtro: filtro, index: i }"></ng-container>
        </div>

        <div class="input-data" *ngSwitchCase="'dropdown'" style="max-width:70%;">
          <ng-container *ngTemplateOutlet="dropdown; context: { filtro: filtro, multiple: false, index: i }"></ng-container>
        </div>

        <div class="input-data" *ngSwitchCase="'multSelect'">
          <ng-container *ngTemplateOutlet="dropdown; context: { filtro: filtro, multiple: true, index: i }"></ng-container>
        </div>

        <div class="input-data" *ngSwitchCase="'dataDa'">
          <ng-container *ngTemplateOutlet="data; context: { filtro: filtro, index: i }"></ng-container>
        </div>

        <div class="input-data" *ngSwitchCase="'dataA'">
          <ng-container *ngTemplateOutlet="data; context: { filtro: filtro, index: i }"></ng-container>
        </div>

        <div class="input-data" *ngSwitchCase="'data'">
          <ng-container *ngTemplateOutlet="data; context: { filtro: filtro, index: i }"></ng-container>
        </div>

      </ng-container>

    </div>
  </div>

  <div *ngIf="type != 'richieste' && type != 'pazienti' && type != 'report'" class="filtro-actions">
    <div class="input-data" *ngIf="filterTypeList.length">
      <ng-container *ngTemplateOutlet="applica"></ng-container>
    </div>

    <div class="input-data" *ngIf="showExport">
      <ng-container *ngTemplateOutlet="excel"></ng-container>
    </div>
  </div>

</div>

<!--TEXT-->
<ng-template #text let-filtro="filtro" let-index="index">

  <app-ble-input [valore]="''"
                 [testo]="filtro.label"
                 (cambioValore)="filterText($event, filtro.param, index)">
  </app-ble-input>

</ng-template>

<!--TEXT-->
<ng-template #textN let-filtro="filtro" let-index="index">

  <app-ble-input-number [valore]=""
                 [testo]="filtro.label"
                 (cambioValore)="filterText($event, filtro.param, index)">
  </app-ble-input-number>

</ng-template>

<!--Check-->
<ng-template #check let-filtro="filtro" let-index="index">
  {{filtro.label}}
  <app-ble-checkbox [valore]="true"
                    (cambioValore)="filterCheck($event, filtro.param, index)">
  </app-ble-checkbox>
</ng-template>

<!--DROPDOWN-->
<ng-template #dropdown let-filtro="filtro" let-multiple="multiple" let-index="index" let-td="td" let-selectAll="selectAll" let-presetYear="presetYear">
  <app-ble-dropdown style="width: 100%"
  					[lista]="allList.get(filtro.lista)!"
  					[testo]="filtro.label"
                    [disabled]="!allList.get(filtro.lista)?.length"
                    [clean]="true"
                    [tdElement]="td"
                    [multiple]="multiple"
                    [presetYear]="presetYear"
                    [selectAll]="selectAll"
					[autoSize]="autoSize"
                    (cambioValore)="filterDropdown($event, filtro.prop, filtro.param, multiple, index)">
    </app-ble-dropdown>
</ng-template>

<!--DROPDOWN MULTIPLE -->
<ng-template #dropdownmultiple let-filtro="filtro" let-multiple="multiple" let-index="index" let-td="td" let-presetYear="presetYear">
  <app-ble-dropdown style="width: 100%"
                    [lista]="allList.get(filtro.lista)!"
                    [testo]="filtro.label"
                    [disabled]="!allList.get(filtro.lista)?.length"
                    [clean]="true"
                    [tdElement]="td"
                    [multiple]="true"
                    [presetYear]="presetYear"
                    [autoSize]="autoSize"
                    (cambioValore)="filterDropdown($event, filtro.prop, filtro.param, multiple, index)">
  </app-ble-dropdown>
</ng-template>

<!--DROPDOWN-->
<ng-template #dropdownnotooltip let-filtro="filtro" let-multiple="multiple" let-index="index" let-td="td" let-presetYear="presetYear">
  <app-ble-dropdown style="width: 100%"
                    [lista]="allList.get(filtro.lista)!"
                    [testo]="filtro.label"
                    [disabled]="!allList.get(filtro.lista)?.length"
                    [tooltip]="false"
                    [tdElement]="td"
                    [clean]="true"
                    [presetYear]="presetYear"
                    [multiple]="multiple"
                    [autoSize]="autoSize"
                    (cambioValore)="filterDropdown($event, filtro.prop, filtro.param, multiple, index)">
  </app-ble-dropdown>
</ng-template>

<!--Data-->
<ng-template #data let-filtro="filtro" let-index="index">

    <app-ble-datepicker [clear]="true"
                        [testo]="filtro.label"
                        (cambioValore)="filterDate($event, filtro.param, index)">
    </app-ble-datepicker>

</ng-template>

<!--EXCEL-->
<ng-template #excel>
  <div class="input-applica">
    <button mat-flat-button
            type="button"
            color="primary"
            (click)="exportExcel()">
      {{testi.download}} <i class="fas fa-file-csv"></i>
    </button>
  </div>

</ng-template>

<!--APPLICA-->
<ng-template #applica>

  <div class="input-applica">
      <button mat-flat-button
              type="button"
              color="primary"
              [disabled]="!filtroCambiato"
              (click)="applicaFilter()">
        {{testi.applica}}
      </button>
  </div>

</ng-template>


