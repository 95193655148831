<div class="snackbar-container">

  <div class="snackbar-main">
    <div class="snackbar-icon">
      <i [ngClass]="icon"></i>
    </div>
    <div class="snackbar-text">
      <div class="snackbar-title">
        {{title}}
      </div>
      <div class="snackbar-msg">
        {{msg}}
      </div>
    </div>
  </div>

  <div class="snackbar-close">
    <button mat-icon-button aria-label="Close" (click)="close()">
      <i class="fas fa-times"></i>
    </button>
  </div>
</div>
