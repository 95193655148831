import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MyDate} from "../../../entities/MyDate";

@Component({
  selector: 'app-ble-input-time',
  templateUrl: './ble-input-time.component.html',
  styleUrls: ['./ble-input-time.component.scss']
})
export class BleInputTimeComponent implements OnInit {

  @Input() valore?: string | any;
  @Input() testo?: string;
  @Input() block?: boolean;
  @Input() invalid ? = false;
  @Input() hidden ? = false;
  @Input() minutes?: number;
  @Input() max?: number;
  @Input() min?: number;

  @Output() cambioValore = new EventEmitter();

  step = [];

  constructor() { }

  ngOnInit(): void {
    if (typeof this.valore != 'string'){
      for (let item of Object.entries(this.valore)){
        if (typeof item[1] === 'string') {
          this.valore = item[1];
        }
      }
    }
  }

  inputChange(event: any) {
    //console.log(event);
    this.cambioValore.emit(new MyDate(event));
  }
}
