
export class Credentials {
  type: string;
  value: string;
  temporary: boolean;

  constructor(data: any) {
    this.type = "password";
    this.value = data.value;
    this.temporary = true;
  }


  getOggCreate(): CredentialsCreate {
    return {
      type: "password",
      value: this.value!,
      temporary: true,
    };
  }

}

export interface CredentialsCreate {
  type: string;
  value: string;
  temporary: boolean;

}
