<app-header-popup [testo]="title"
                  [loading]="loading"
                  (close)="closePanel()">
</app-header-popup>

<div mat-dialog-content class="conferma-content">
  <i class="fas fa-question-circle"></i>
  <form class="input-form">
    <mat-form-field class="example-full-width">
      <mat-label>{{msgConferma}}</mat-label>
      <input type="text" matInput [formControl]="folderFormControl"
             [errorStateMatcher]="matcher"
             placeholder="Nome Cartella"
             (keydown.enter)="fallback($event)">
      <mat-error *ngIf="folderFormControl.hasError('text') && !folderFormControl.hasError('required')">
        Nome cartella non valido
      </mat-error>
      <mat-error *ngIf="folderFormControl.hasError('required')">
        Campo Obbligatorio
      </mat-error>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions class="conferma-footer">
  <ng-container *ngIf="footerConferma">
    <button mat-flat-button type="button" color="warn" (click)="closePanel()">
      <i class="fas fa-times"></i>
      {{testi.annulla}}
    </button>
    <button mat-flat-button type="button" color="primary" (click)="conferma(folderFormControl.value || '')" [disabled]="folderFormControl.invalid">
      <i class="fas fa-check"></i>
      {{testi.crea}}
    </button>
  </ng-container>

</div>
