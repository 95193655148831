import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {HeaderPopupComponent} from "./header-popup/header-popup.component";


@NgModule({
  declarations: [
    HeaderPopupComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressBarModule,
    MatDialogModule
  ],
  exports: [
    HeaderPopupComponent
  ]
})
export class HeaderPopupModule { }
