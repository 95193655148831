import {Paged} from "./Paged";
import {MyDate} from "./MyDate";

export class SchedaControllo {

    id?: number;
    descrizione?: string;
    dataInizioVal?: MyDate;
    dataFineVal?: MyDate;


    constructor(data: any) {
        Object.assign(this, data);
        this.dataInizioVal = data.dataInizioVal ? new MyDate(data.dataInizioVal) : undefined;
        this.dataFineVal = data.dataFineVal ? new MyDate(data.dataFineVal) : undefined;

    }

    /* servono per essere visualizzati nei dropdown */
    get viewValue(): string {
        return this.descrizione ? this.descrizione : '';
    }
    get value(): SchedaControllo {
        return this;
    }


    get dataInizioValGGMMAA() {
        return this.dataInizioVal ? this.dataInizioVal.giornoMeseAnno : null;
    }

    get dataFineValGGMMAA() {
        return this.dataFineVal ? this.dataFineVal.giornoMeseAnno : null;
    }


    getOggCreate() {
        return {

            id: this.id,
            descrizione: this.descrizione,
            dataInizioVal: this.dataInizioVal? this.dataInizioVal.dateSave : null,
            dataFineVal: this.dataFineVal ? this.dataFineVal.dateSave : null,

        };
    }

    getOggUpdate() {
        return this.getOggCreate();
    }



 }

export class SchedaControlloPaged extends Paged {
    content: SchedaControllo[];

    constructor(data: any) {
        super(data);

        this.content = data.content ? data.content.map((ts: any) => new SchedaControllo(ts)) : [];
    }
}

