<!-- Input Number -->
<div class="input-container">
  <input type="number"
         class="inputStandard"
         matInput
         step="1"
         max="9999"
         (mousedown)="$event.stopPropagation()"
         [(ngModel)]="_valore"
         [ngClass]="{'input-error': invalid }"
         [disabled]="block ? block : false"
         (keyup)="inputChange()"
         [placeholder]="testo ? testo : ''">

  <div class="input-unit" *ngIf="unit">
    ({{unit}})
  </div>
</div>
