import {DropdownItem} from "./utils/DropdownItem";

export class Impianto implements DropdownItem {

    idImpianto?: number;
    desImpianto?: string;
    constructor(data: any) {
        Object.assign(this, data);
    }

    /* servono per essere visualizzati nei dropdown */
    get viewValue(): string {
        return this.desImpianto ? this.desImpianto : '';
    }
    get value(): Impianto {
        return this;
    }

 }


