<div>
    <app-table-view *ngIf="tableVoci && richiestePaged"
                    [tableData]="richiestePaged.content"
                    [tableDataHeader]="tableVoci"
                    [filterHeader]="'richieste'"
                    [filterDataList]="dropdownList"
                    [buttonAggiorna]="true"
                    [buttonAdd]="isCaposala() || isOperatore() || isResponsabile()"
                    [tableType]="testi.richieste"
                    [admin]="true"
                    [testi]="testi"
                    [type]="'richieste'"
                    [loading]="loading"
                    [rows]="rows"
                    [totalPageslazy]="richiestePaged.totalElements"
                    [pageIndex]="richiestePaged.number"
                    (callBack)="callBackTable($event)"
                    (callBackPaginator)="cambioPage($event)">
    </app-table-view>
</div>


