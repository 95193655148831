<ng-container [ngSwitch]="rowProp">
    <div *ngIf="pattern == 'boolean'">
            <span *ngIf="colTooltip" class="tooltip">
                <span class="cell-text" [ngStyle]="{'max-width': size+'rem'}">{{stringSize(rowData[rowProp],size)}}</span>
                <span class="tooltiptext"><i *ngIf="rowData[rowProp] == true" class="fas fa-check"></i></span>
            </span>
        <span *ngIf="!colTooltip">
                <i *ngIf="rowData[rowProp] == true" class="fas fa-check"></i>
            </span>
    </div>
    <div *ngIf="pattern == 'presente'">
            <span *ngIf="colTooltip" class="tooltip">
                <span class="cell-text" [ngStyle]="{'max-width': size+'rem'}">{{stringSize(rowData[rowProp],size)}}</span>
                <span class="tooltiptext"><i *ngIf="rowData[rowProp] == 1" class="fas fa-check"></i></span>
            </span>
        <span *ngIf="!colTooltip">
                <i *ngIf="rowData[rowProp] == 1" class="fas fa-check"></i>
            </span>
    </div>
    <!-- -->
    <div *ngIf="onlyView && !text0 && !text1 && !noclick && !pattern">
        <div #cell *ngSwitchDefault class="cell-data cursor-arrow">
            <span *ngIf="colTooltip" class="tooltip">
                <span class="cell-text" [ngStyle]="{'max-width': size+'rem'}">{{stringSize(rowData[rowProp],size)}}</span>
                <span class="tooltiptext">{{rowData[rowProp]}}</span>
            </span>
            <span *ngIf="!colTooltip">
                {{rowData[rowProp]}}
            </span>
        </div>
    </div>
    <div *ngIf="!onlyView && !text0 && !text1 && !noclick && !pattern">
        <!-- Usato in table-view-base -->
        <div #cell *ngSwitchDefault (click)="singleDoubleClick(rowData, state.MODIFICA)"  class="cell-data cursor-click">
            <span *ngIf="colTooltip" class="tooltip">
                <span class="cell-text" [ngStyle]="{'max-width': size+'rem'}">{{stringSize(rowData[rowProp],size)}}</span>
                <span class="tooltiptext">{{rowData[rowProp]}}</span>
            </span>
            <span *ngIf="!colTooltip">
                {{rowData[rowProp]}}
            </span>
        </div>
    </div>
    <div *ngIf="onlyView && text0 && text1 && !noclick && !pattern">
        <div #cell *ngSwitchDefault class="cell-data cursor-arrow">
            <span *ngIf="colTooltip" class="tooltip">
                <span class="cell-text" [ngStyle]="{'max-width': size+'rem'}">{{tesaurus(rowData[rowProp],text0,text1)}}</span>
                <span class="tooltiptext">{{tesaurus(rowData[rowProp],text0,text1)}}</span>
            </span>
            <span *ngIf="!colTooltip">
                {{tesaurus(rowData[rowProp],text0,text1)}}
            </span>
        </div>
    </div>
    <div *ngIf="!onlyView && text0 && text1 && !noclick  && !pattern">
        <div #cell *ngSwitchDefault (click)="singleDoubleClick(rowData, state.MODIFICA)"  class="cell-data cursor-click">
            <span *ngIf="colTooltip" class="tooltip">
                <span class="cell-text" [ngStyle]="{'max-width': size+'rem'}">{{tesaurus(rowData[rowProp],text0,text1)}}</span>
                <span class="tooltiptext">{{tesaurus(rowData[rowProp],text0,text1)}}</span>
            </span>
            <span *ngIf="!colTooltip">
                {{tesaurus(rowData[rowProp],text0,text1)}}
            </span>
        </div>
    </div>
    <div *ngIf="onlyView && !text0 && !text1 && noclick  && !pattern">
        <div #cell *ngSwitchDefault class="cell-data cursor-arrow">
            <span *ngIf="colTooltip" class="tooltip">
                <span class="cell-text" [ngStyle]="{'max-width': size+'rem'}">{{stringSize(rowData[rowProp],size)}}</span>
                <span class="tooltiptext">{{rowData[rowProp]}}</span>
            </span>
            <span *ngIf="!colTooltip">
                {{rowData[rowProp]}}
            </span>
        </div>
    </div>
    <div *ngIf="!onlyView && !text0 && !text1 && noclick  && !pattern">
        <div #cell *ngSwitchDefault (click)="singleDoubleClick(rowData, state.MODIFICA)"  class="cell-data no-click">
            <span *ngIf="colTooltip" class="tooltip">
                <span class="cell-text" [ngStyle]="{'max-width': size+'rem'}">{{stringSize(rowData[rowProp],size)}}</span>
                <span class="tooltiptext">{{rowData[rowProp]}}</span>
            </span>
            <span *ngIf="!colTooltip">
                {{rowData[rowProp]}}
            </span>
        </div>
    </div>
    <div *ngIf="onlyView && text0 && text1 && noclick  && !pattern">
        <div #cell *ngSwitchDefault class="cell-data cursor-arrow">
            <span *ngIf="colTooltip" class="tooltip">
                <span class="cell-text" [ngStyle]="{'max-width': size+'rem'}">{{tesaurus(rowData[rowProp],text0,text1)}}</span>
                <span class="tooltiptext">{{tesaurus(rowData[rowProp],text0,text1)}}</span>
            </span>
            <span *ngIf="!colTooltip">
                {{tesaurus(rowData[rowProp],text0,text1)}}
            </span>
        </div>
    </div>
    <div *ngIf="!onlyView && text0 && text1 && noclick  && !pattern">
        <div #cell *ngSwitchDefault (click)="singleDoubleClick(rowData, state.MODIFICA)"  class="cell-data no-click">
            <span *ngIf="colTooltip" class="tooltip">
                <span class="cell-text" [ngStyle]="{'max-width': size+'rem'}">{{tesaurus(rowData[rowProp],text0,text1)}}</span>
                <span class="tooltiptext">{{tesaurus(rowData[rowProp],text0,text1)}}</span>
            </span>
            <span *ngIf="!colTooltip">
                {{tesaurus(rowData[rowProp],text0,text1)}}
            </span>
        </div>
    </div>
    <div *ngSwitchCase="'valida'" class="table-cancel">
        <ng-container *ngTemplateOutlet="button; context: {
                                        data: rowData,
                                        type: state.VALIDA,
                                        icon: 'fas fa-clipboard-check',
                                        color:'primary',
                                        disabled: !rowData?.enableValida
                                        }">
        </ng-container>
    </div>

    <div *ngSwitchCase="'stampa'" class="table-cancel">
        <ng-container *ngTemplateOutlet="button; context: {
                                                    data: rowData,
                                                    type: state.STAMPA,
                                                    icon: 'fas fa-print',
                                                    color:'primary',
                                                    disabled: !rowData?.enablePdf
                                                    }">
        </ng-container>
    </div>

    <div *ngSwitchCase="'images'" class="table-cancel">
        <ng-container *ngTemplateOutlet="button; context: {
                                                    data: rowData,
                                                    type: state.FOTO,
                                                    icon: 'far fa-file-image',
                                                    color:'primary',
                                                    disabled: false
                                                    }">
        </ng-container>
    </div>

    <div *ngSwitchCase="'cancellaScheda'" class="table-cancel">
        <ng-container *ngTemplateOutlet="button; context: {
            data: rowData,
            type: state.DELETE,
            icon: 'fas fa-trash-alt',
            color:'warn' ,
            disabled: rowData?.stato?.descStato != statoIntervento.DA_ASSEGNARE
        }"></ng-container>
    </div>
    <div *ngSwitchCase="'download'" class="table-cancel">
        <ng-container *ngTemplateOutlet="button; context: {
                data: rowData,
                type: state.DOWNLOAD,
                icon: 'fas fa-download',
                color:'primary'
        }"></ng-container>
    </div>
    <div *ngSwitchCase="'cancella'" class="table-cancel">
        <div *ngIf="rowData[rowProp] != false">
            <ng-container  *ngTemplateOutlet="button; context: {
                data: rowData,
                type: state.DELETE,
                icon: 'fas fa-trash-alt',
                color:'warn'
            }"></ng-container>
        </div>
    </div>
    <div *ngSwitchCase="'authCancel'" class="table-cancel">
        <div *ngIf="rowData.delete" class="table-cancel">
        <ng-container *ngTemplateOutlet="button; context: {
                data: rowData,
                type: state.DELETE,
                icon: 'fas fa-trash-alt',
                color:'warn'
                }"></ng-container>
        </div>
        <div *ngIf="!rowData.delete" class="table-cancel">
            <span>
                 {{rowData[rowProp]}}
            </span>
        </div>
    </div>
        <div *ngSwitchCase="'modifica'" class="table-modifica">
        <ng-container
                *ngTemplateOutlet="button; context: { data: rowData, type: state.MODIFICA, icon: 'fas fa-edit', color:'primary' ,disabled: !admin}"></ng-container>
    </div>
        <div *ngSwitchCase="'controlli'" class="table-controlli">
        <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.MODIFICA, icon: 'fas fa-edit', color:'primary' ,disabled: !admin}"></ng-container>
        <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DETTAGLIO, icon: 'fas fa-magnifying-glass', color:'primary' ,disabled: !admin}"></ng-container>
    </div>
    
    <div *ngSwitchCase="'addUpDownDel'" class="table-controlli">
        <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.CREA, icon: 'fas fa-plus', color:'primary' ,disabled: !admin, tooltip: 'Crea Richiesta'}"></ng-container>
        <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.VALIDA, icon: 'fas fa-thumbs-up', color:'primary' ,disabled: !admin, tooltip: 'Valida Ordine'}"></ng-container>
        <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.CONTESTA, icon: 'fas fa-thumbs-down', color:'primary' ,disabled: !admin, tooltip: 'Contesta Ordine'}"></ng-container>
        <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DELETE, icon: 'fas fa-trash-alt', color:'primary' ,disabled: !admin, tooltip: 'Elimina Ordine'}"></ng-container>
        <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DETTAGLIO, icon: 'fas fa-magnifying-glass', color:'primary' ,disabled: !admin, tooltip: 'Storico Richiesta'}"></ng-container>
    </div>
    
    <div *ngSwitchCase="'cancellaSe'" class="table-cancel">
        <div *ngIf="rowData['flagAdt'] != 'Y' || rowData['codice'] == 'CUMULATIVO'">
            <ng-container *ngTemplateOutlet="button; context: {
                data: rowData,
                type: state.DELETE,
                icon: 'fas fa-trash-alt',
                color:'warn'
            }"></ng-container>
        </div>
    </div>
    <div *ngSwitchCase="'detMarkDel2'" class="table-controlli">
        <div *ngIf="rowData.stato == 'In Lavorazione'">
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.CHIUDI, icon: 'fas fa-lock', color:'primary' ,disabled: !admin}"></ng-container>
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DELETE, icon: 'fas fa-trash-alt', color:'primary' ,disabled: !admin}"></ng-container>
        </div>
        <div *ngIf="rowData.stato != 'In Lavorazione'" style="min-height: 3vh;">
        </div>
    </div>
    <div *ngSwitchCase="'detMarkDel'" class="table-controlli">
        <div *ngIf="rowData.stato == 'In Lavorazione'">
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DETTAGLIO, icon: 'fas fa-magnifying-glass', color:'primary' ,disabled: !admin}"></ng-container>
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.CHIUDI, icon: 'fas fa-check', color:'primary' ,disabled: !admin}"></ng-container>
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DELETE, icon: 'fas fa-trash-alt', color:'primary' ,disabled: !admin}"></ng-container>
        </div>
        <div *ngIf="rowData.stato != 'In Lavorazione'">
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DETTAGLIO, icon: 'fas fa-magnifying-glass', color:'primary' ,disabled: !admin}"></ng-container>
        </div>
    </div>
    <div *ngSwitchCase="'detCloseDel'" class="table-controlli">
        <div *ngIf="rowData.stato == 'In Lavorazione'">
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DETTAGLIO, icon: 'fas fa-magnifying-glass', color:'primary' ,disabled: !admin}"></ng-container>
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.CHIUDI, icon: 'fas fa-lock', color:'primary' ,disabled: !admin}"></ng-container>
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DELETE, icon: 'fas fa-trash-alt', color:'primary' ,disabled: !admin}"></ng-container>
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.EXCEL, icon: 'fas fa-file-excel', color:'primary' ,disabled: !admin}"></ng-container>
        </div>
        <div *ngIf="rowData.stato != 'In Lavorazione'">
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DETTAGLIO, icon: 'fas fa-magnifying-glass', color:'primary' ,disabled: !admin}"></ng-container>
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.EXCEL, icon: 'fas fa-file-excel', color:'primary' ,disabled: !admin}"></ng-container>
        </div>
    </div>
    <div *ngSwitchCase="'delDet'" class="table-controlli">
        <div *ngIf="rowData.stato == 'In Lavorazione'">
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DETTAGLIO, icon: 'fas fa-magnifying-glass', color:'primary' ,disabled: !admin}"></ng-container>
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DELETE, icon: 'fas fa-trash-alt', color:'primary' ,disabled: !admin}"></ng-container>
        </div>
        <div *ngIf="rowData.stato != 'In Lavorazione'">
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DETTAGLIO, icon: 'fas fa-magnifying-glass', color:'primary' ,disabled: !admin}"></ng-container>
        </div>
    </div>
    <div *ngSwitchCase="'delDet2'" class="table-controlli">
        <div *ngIf="rowData.stato == 'In Lavorazione'">
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DETTAGLIO, icon: 'fas fa-magnifying-glass', color:'primary' ,disabled: !admin}"></ng-container>
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DELETE, icon: 'fas fa-trash-alt', color:'primary' ,disabled: !admin}"></ng-container>
        </div>
        <div *ngIf="rowData.stato != 'In Lavorazione'">
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.CREA, icon: 'fas fa-plus', color:'primary' ,disabled: !admin}"></ng-container>
            <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DETTAGLIO, icon: 'fas fa-magnifying-glass', color:'primary' ,disabled: !admin}"></ng-container>
        </div>
    </div>
    <div *ngSwitchCase="'del'" class="table-controlli">
        <ng-container *ngTemplateOutlet="smallbutton; context: { data: rowData, type: state.DELETE, icon: 'fas fa-trash-alt', color:'primary' ,disabled: !admin}"></ng-container>
    </div>
    <div *ngSwitchCase="'drop01'" class="table-controlli">
        <ng-container *ngTemplateOutlet="dropdown; context: { data: rowData, type: state.VALUE, disabled: !admin}"></ng-container>
    </div>
    <div *ngSwitchCase="'input'" class="table-controlli">
        <ng-container *ngTemplateOutlet="input; context: { data: rowData, type: state.VALUE, disabled: !admin}"></ng-container>
    </div>
</ng-container>


<!-- Button -->
<ng-template #button let-data="data" let-type="type" let-icon="icon" let-color="color" let-disabled="disabled">
    <button mat-icon-button
            type="button"
            [color]="color"
            [disabled]="disabled"
            (click)="callbackEmit(data, type)">
        <i [ngClass]="icon"></i>
    </button>

</ng-template>

<!-- Small Button -->
<ng-template #smallbutton let-data="data" let-type="type" let-icon="icon" let-color="color" let-disabled="disabled" let-tooltip="tooltip">
    <button mat-icon-button class="mat-icon-small-button"
            type="button"
            matTooltip="{{tooltip}}"
            [color]="color"
            [disabled]="disabled"
            (click)="callbackEmit(data, type)">
        <i [ngClass]="icon"></i>
    </button>

</ng-template>

<!-- Dropdown -->
<ng-template #dropdown let-data="data" let-type="type" let-disabled="disabled">
        <mat-select [(ngModel)]=selected
                    (selectionChange)="callbackDropEmit(data, type, selected)">
            <mat-option [value]="0">{{text0}}</mat-option>
            <mat-option [value]="1">{{text1}}</mat-option>
        </mat-select>
</ng-template>


<ng-template #input let-data="data" let-type="type" let-disabled="disabled">
    <input type="number"
           class="inputStandard"
           matInput
           step="1"
           min="0"
           max="{{maxValue}}"
           [(ngModel)]=selected
           (keyup)="callbackInputChange(data,type,selected)">
</ng-template>
