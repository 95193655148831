import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TestiService} from "../../../service/config/testi.service";
import {PopUpConferma} from "../../../entities/PopUpConferma";
import {CallbackDetailsState} from "../../../entities/enums/CallbackDetailsState";


@Component({
  selector: 'app-popup-conferma',
  templateUrl: './popup-conferma.component.html',
  styleUrls: ['./popup-conferma.component.scss']
})
export class PopupConfermaComponent implements OnInit {

  testi: any;
  constructor( private testiService: TestiService,
               public dialogRef: MatDialogRef<PopupConfermaComponent>,
               @Inject(MAT_DIALOG_DATA) public ogg: PopUpConferma ) {
    this.testi = this.ogg.testi;
  }

  loading = false;

  type?: string;
  title?: string;
  msgConferma?: string;

  footerConferma = false;
  footerOK = false;

  ngOnInit(): void {
    if (this.ogg.type == CallbackDetailsState.DELETE) {
      this.setPopupDelete(this.ogg);
    }
    if (this.ogg.type == CallbackDetailsState.CHIUDI) {
      this.setPopupClose(this.ogg);
    }
    if (this.ogg.type == CallbackDetailsState.SAVE) {
      this.setPopupSave(this.ogg);
    }
    if (this.ogg.type == CallbackDetailsState.CONFERMA) {
      this.setPopupConferma(this.ogg);
    }
  }

  private setPopupConferma(data: PopUpConferma) {
    this.type = data.type;
    this.footerOK = true;
    this.title = this.testi.msgAlertSummary;
    this.msgConferma = data.msg;
  }

  private setPopupSave(data: PopUpConferma) {
    this.type = data.type;
    this.footerConferma = true;
    this.title = data.title ? data.title : this.testi.msgConferma;
    this.msgConferma = `${data.msg}?`;
  }
  private setPopupClose(data: PopUpConferma) {
    this.type = data.type;
    this.footerConferma = true;

    this.title = data.title ? data.title : this.testi.msgConfermaDeleteHeader;
    this.msgConferma = `${this.testi.msgConfermaChisuraMessage} ${data.msg}?`;
  }
  private setPopupDelete(data: PopUpConferma) {
    this.type = data.type;
    this.footerConferma = true;
    //console.log(this.testi.msgConfermaDeleteHeader);

    this.title = data.title ? data.title : this.testi.msgConfermaDeleteHeader;
    this.msgConferma = `${this.testi.msgConfermaDeleteMessage} ${data.msg}?`;
  }

  conferma() {
    this.dialogRef.close( { type: CallbackDetailsState.DELETE, data: this.ogg.data } );
  }

  closePanel() {
    this.dialogRef.close({type: CallbackDetailsState.CANCEL});
  }
}
