import {MyDate} from "./MyDate";

export class DMS {
  entry: EntryDMS;

  constructor(data: any) {
    Object.assign(this, data);
  }
}

export class EntryDMS {
  id: string;

  constructor(data: any) {
    Object.assign(this, data);
  }
}

export class DocsDMS{
  name: string;
  date?: MyDate;
  type: string;
  user: string;
  id: string;
  size: number;
  isFile: boolean;
  isFolder: boolean;
  download: boolean;
  cancella: boolean;
  nodetype?: string;
  createdAt?: MyDate;
  createdBy?: string;
  modifiedAt?: MyDate;
  modifiedBy?: string;
  child?: DocsDMS[];

  constructor(data: any) {
    this.name = data.entry.name;
    this.date = data.entry.modifiedAt ? new MyDate(data.entry.modifiedAt) : undefined;
    this.modifiedAt = data.entry.modifiedAt ? new MyDate(data.entry.modifiedAt) : undefined;
    this.createdAt = data.entry.createdAt ? new MyDate(data.entry.createdAt) : undefined;
    this.type = data.entry.content ? data.entry.content.mimeTypeName : 'Cartella';
    this.user = data.entry.modifiedByUser.id;
    this.modifiedBy = data.entry.modifiedByUser.id;
    this.createdBy = data.entry.createdByUser ? data.entry.createdByUser.id : data.entry.modifiedByUser.id;
    this.id = data.entry.id;
    this.size = data.entry.content ? data.entry.content.sizeInBytes : null;
    this.isFile = data.entry.isFile;
    this.isFolder = data.entry.isFolder;
    this.nodetype = data.entry.nodetype ? data.entry.nodetype : "";
    this.download = true;
    this.cancella = false;
  }

  get getDate(){
    return this.date ? this.date.giornoMeseAnnoOre : '';
  }
}

export class DocsVersion {
  name: string;
  id: string;
  size: number;
  isFile: boolean;
  isFolder: boolean;
  download: boolean;
  nodetype?: string;
  modifiedAt?: MyDate;
  modifiedBy?: string;
  url?: string;

  constructor(data: any) {
    this.name = data.entry.name;
    this.modifiedAt = data.entry.modifiedAt ? new MyDate(data.entry.modifiedAt) : undefined;
    this.modifiedBy = data.entry.modifiedByUser ? data.entry.modifiedByUser.id : '';
    this.id = data.entry.id;
    this.size = data.entry.content ? data.entry.content.sizeInBytes : null;
    this.isFile = data.entry.isFile;
    this.isFolder = data.entry.isFolder;
    this.nodetype = data.entry.nodetype ? data.entry.nodetype : "";
    this.download = true;
    this.url="/versions/"+data.entry.id+"/content"
  }

  get getDate(){
    return this.modifiedAt ? this.modifiedAt.giornoMeseAnnoOre : '';
  }
}
