import {Injectable} from "@angular/core";

@Injectable({ providedIn: 'root' })

export class keycloakHelper {

    constructor() {}

    public getRoles(roles: string[] | undefined): string[] | undefined{
        const keycloakRoles = new Set(['manage-account', 'manage-account-links', 'view-profile', 'offline_access', 'uma_authorization', 'user', 'default-roles-sdgkeycloak']);
        const activeRoles = roles?.filter((name) => {
            return !keycloakRoles.has(name);
        });
        return activeRoles;
    }

}


