import {NgModule} from '@angular/core';
import {RichiesteRouting} from './richieste.routing';
import {RichiesteComponent} from "./richieste/richieste.component";
import {CommonModule} from "@angular/common";
import {TableViewModule} from "../shared-modules/table-view/table-view.module";
import {FormFieldModule} from "../shared-modules/form-field/form-field.module";
import {RichiesteDetailsComponent} from "./richieste-details/richieste-details.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {BleInputModule} from "../shared-modules/ble-input/ble-input.module";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {HeaderPopupModule} from "../shared-modules/header-popup/header-popup.module";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {RichiesteDetailsComponentPop} from "./richieste/richieste-details-pop/richieste-details-pop.component";
import {AuthModule} from "../auth/auth.module";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {DragDropModule} from "@angular/cdk/drag-drop";


@NgModule({
  declarations: [
    RichiesteComponent,
    RichiesteDetailsComponent,
    RichiesteDetailsComponentPop
  ],
  imports: [
    AuthModule,
    BleInputModule,
    CommonModule,
    FormFieldModule,
    HeaderPopupModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatToolbarModule,
    RichiesteRouting,
    TableViewModule,
    MatDialogModule,
      DragDropModule
  ],
  exports: [
  ]
})
export class RichiesteModule { }
