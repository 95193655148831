import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Role} from '../entities/Role';
import {ApiRestServiceRichieste} from "./api-rest.service-richieste";


@Injectable({
  providedIn: 'root'
})
export class RuoloService {

  constructor( private apiRestService: ApiRestServiceRichieste) {}


  getAllRuoli(): Observable<Role[]> {
    const url =  `role`;

    return this.apiRestService.getWithHeader(url)
      .pipe(
        map( res => res.map((rl: any) => new Role(rl)) )
      );
  }

}
