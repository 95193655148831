<!--<div *ngIf="!menuService.loading">-->
    <div *ngFor="let remote of authRoute; let i = index">
        <div *ngIf="!clicked[i] && !remote.childrenOf && !remote.fatherOf" class="main-menu-item" (click)="openLink(remote, remote.routePath, i)">{{remote.displayName}}</div>
        <div *ngIf="!clicked[i] && !remote.childrenOf && remote.fatherOf" class="main-menu-item" (click)="openLink(remote, remote.routePath, i);selectFirst(remote.fatherOf)">{{remote.displayName}}</div>
        <div *ngIf="clicked[i] && !remote.childrenOf && !remote.fatherOf" class="main-menu-item-selected" (click)="openLink(remote,remote.routePath, i)">{{remote.displayName}}</div>
        <div *ngIf="clicked[i] && !remote.childrenOf && remote.fatherOf" class="main-menu-item">{{remote.displayName}}
            <div *ngIf="remote.fatherOf" class="main-menu-item-selected-father">
                <div *ngFor="let children of authRoute; let l = index">
                    <div *ngIf="init && !children.firstModule && !clickedChild[l] && children.childrenOf == remote.routePath" class="main-menu-sub-item"  (click)="openLink(children, children.routePath, l, true); init = false;">&nbsp;&gt;&nbsp;{{children.displayName}}</div>
                    <div *ngIf="init && children.firstModule && children.childrenOf == remote.routePath" class="main-menu-sub-item-selected" (click)="openLink(children, children.routePath, l,true); init = false;">&nbsp;&gt;&nbsp;{{children.displayName}}</div>
                    <div *ngIf="!init && !clickedChild[l] && children.childrenOf == remote.routePath" class="main-menu-sub-item"  (click)="openLink(children, children.routePath, l, true)">&nbsp;&gt;&nbsp;{{children.displayName}}</div>
                    <div *ngIf="!init && clickedChild[l] && children.childrenOf == remote.routePath" class="main-menu-sub-item-selected" (click)="openLink(children, children.routePath, l,true)">&nbsp;&gt;&nbsp;{{children.displayName}}</div>
                </div>
            </div>
        </div>
    </div>
<!--</div>-->
<div *ngIf="!loading"><mat-progress-bar mode="indeterminate"></mat-progress-bar></div>

