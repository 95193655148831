//Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonModule} from '@angular/material/button';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {DragDropModule} from "@angular/cdk/drag-drop";
import {BleInputModule} from "../ble-input/ble-input.module";
import {TableViewModule} from "../table-view/table-view.module";
import {ImageFirmaComponent} from "./form-inputs/image-firma/image-firma.component";
import {FormValidaComponent} from "./form-valida/form-valida.component";
import {FormFieldFooterComponent} from "./form-field-footer/form-field-footer.component";
import {FormColumnComponent} from "./form-column/form-column.component";
import {ChipListComponent} from "./form-inputs/chip-list/chip-list.component";
import {DocsComponent} from "./form-inputs/docs/docs.component";
import {FormInputsComponent} from "./form-inputs/form-inputs.component";
import {FormBaseComponent} from "./form-base/form-base.component";
import {DettaglioDragdropComponent} from "./form-inputs/dettaglio-dragdrop/dettaglio-dragdrop.component";
import {FormColumnKeycloakComponent} from "./form-column-keycloak/form-column-keycloak.component";
import {FormColumnRichiesteComponent} from "./form-column-richieste/form-column-richieste.component";


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    BleInputModule,
    MatInputModule,
    MatRadioModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
	MatTooltipModule,
    DragDropModule,
    TableViewModule
  ],

  declarations: [
    FormFieldFooterComponent,
    FormColumnKeycloakComponent,
    FormColumnRichiesteComponent,
    FormBaseComponent,
    FormColumnComponent,
    FormInputsComponent,
    ChipListComponent,
    FormValidaComponent,
    ImageFirmaComponent,
    DettaglioDragdropComponent,
    DocsComponent,
  ],

  exports: [
    FormBaseComponent,
    FormColumnComponent,
    FormFieldFooterComponent,
    FormValidaComponent,
    FormColumnKeycloakComponent,
    FormColumnRichiesteComponent
  ]

})
export class FormFieldModule { }
