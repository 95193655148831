<div class="main-container">
    <div class="header">
        <app-keycloak></app-keycloak>
    </div>
    <div style="height:10px;"></div>
    <div class="container-background"></div>

    <div *ngIf="!loaded">
        <div class="loader-app-component">
            <img src="../assets/img/sdg_spinner.gif" alt="Servizi di Governo" style="color: blue;">
        </div>
    </div>

    <mat-drawer-container class="container" style="height: 90vh">
        <mat-drawer #drawer class="sinistra" mode="side" opened="false">
            <app-menu></app-menu>
        </mat-drawer>
        <div class="destra">
            <div style="height:{{routerHeight}}">
                <router-outlet (activate)='onActivate($event)' (deactivate)='onDeactivate($event)'></router-outlet>
            </div>

        </div>
    </mat-drawer-container>


</div>



