import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {TestiService} from "../../service/config/testi.service";


@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent implements OnInit {

  icon = '';
  msg?: string;
  title?: string;

  testi;

  constructor(private snackBarRef: MatSnackBar,
              private router: Router,
              private testiService: TestiService,
              @Inject(MAT_SNACK_BAR_DATA) public data: any) {
    //TODO
    //this.testi = this.testiService.componentAnagrafiche;          
    this.testi = this.testiService.componentRichieste;
  }

  ngOnInit(): void {
    this.setTitleEdIcon(this.data.type);
    this.msg = this.data.msg;
  }

  private setTitleEdIcon(type: 'success' | 'error' | 'warn' | 'info') {
    switch (type) {
      case 'success':
        this.icon = 'fas fa-check';
        this.title = this.testi.msgSuccessSummary;
        break;
      case 'error':
        this.icon = 'fas fa-exclamation-triangle';
        this.title = this.testi.msgErrorSummary;
        break;
      case 'warn':
        this.icon = 'fas fa-info-circle';
        this.title = this.testi.msgAlertSummary;
        break;
      case 'info':
        this.icon = 'fas fa-info-circle';
        this.title = '';
        break;
    }
  }

  close() {
    this.snackBarRef.dismiss();
  }

}
