<div class="table-footer-container" *ngIf="buttonAdd">
  <div class="button-add">
    <button mat-raised-button
            type="button"
            color="primary"
            (click)="addDialog()">
      <i class="fas fa-plus"></i> {{'Aggiungi' + ' ' + tableType }}
    </button>
  </div>

</div>

<div class="table-footer-container2" *ngIf="!buttonAdd">

</div>
