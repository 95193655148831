<div class="image-container" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<div class="image-container" *ngIf="imgUrl && !loading">
  <img [src]="imgUrl">
</div>

<div class="image-container" *ngIf="!imgUrl && !loading">
  <img src="./assets/images/empty-img.png">
</div>
