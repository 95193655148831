import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {TableName} from "../../../entities/table/TableName";
import {SharedFunction} from "../../../service/utils/SharedFunction";
import {TableCallback} from "../../../entities/table/TableCallback";


@Component({
  selector: 'app-table-base',
  templateUrl: './table-base.component.html',
  styleUrls: ['./table-base.component.scss']
})
export class TableBaseComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() tableDataHeader!: TableName[];
  @Input() tableData: any[] = [];
  @Input() rows!: number;
  @Input() height?: number;
  @Input() overflow: string;
  @Input() pulizie: boolean = false;
  @Input() emptyMessage?: string = "Nessun Elemento trovato";
  @Input() totalPageslazy?: number;
  @Input() pageIndex?: number;
  @Input() noRowPattern? = false;
  @Input() rowResize? = true;
  @Input() scroll? = true;
  @Input() colorSelected?: string = '';
  @Input() showFooter = true;
  @Input() onlyView = false;
  @Input() noScrollV?: boolean = true;
  @Input() testi?: any;
  @Input() masterOrder?: string;
  @Input() filterList?: {};
  @Input() noclick?: boolean;
  @Input() input?: boolean = false;
  @Input() dms?: boolean = false;
  @Input() extendRows?: boolean = false;
  @Input() reload?: boolean = false;

  @Output() callBackPaginator: EventEmitter<PageEvent> = new EventEmitter();
  @Output() callBack = new EventEmitter();


  @ViewChild('paginatorDefault', {static: false}) paginatorDefault!: MatPaginator;
  @ViewChild('paginatorLazy', {static: true}) paginatorLazy!: MatPaginator;
  @ViewChild('selectTable', {static: false}) selectTable: any;

  dataSource = new MatTableDataSource<any>([]);
  columnsName: string[] = [];
  columnsInfo: TableName[] = [];

  orderColList: string[] = [];

  selected: number;

  effectiveRow: number;

  nessunElemento = this.emptyMessage ? this.emptyMessage :  "Nessun Elemento trovato";

  constructor() {
  }

  ngOnInit(): void {
    if (!this.dms) {
      this.orderColList = new Array(this.tableDataHeader.length);
      this.effectiveRow = this.extendRows == true ? this.rows : this.tableData.length;
      if (this.extendRows == true && this.tableData.length > 0){
        while (this.tableData.length < this.rows){
          this.tableData.push('empty');
        }
      }
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    changes['tableDataHeader'] && this.makeTableColums();
    changes['tableData'] && this.setSource();
  }

  ngAfterViewInit(): void {
    this.setPaginator();
  }


  changeOrder(prop: string, index: number) {
    //TODO
    //SABINA:  FABRICIO NON SETTA ORDINAMENTO IN CASO DI PAGINAZIONE .... IO PROVATO A RIATTIVARE SEMPRE DA TESTARE!!!!
    if (this.totalPageslazy) {
    } else {
      //this.setOrderDefault(prop, index);
    }
   // this.setOrderDefault(prop, index);
  }

  scrollRows(rows: number) {
    const rowHeight = 25;
    let height = 50;
    height = height + (rowHeight * rows);

    return height.toString() + "pt";
  }

  private setOrderDefault(prop: string, index: number) {
    if (this.orderColList[index] == 'down') {
      this.orderColList[index] = 'up';
    } else {
      this.orderColList = new Array(this.tableDataHeader.length);
      this.orderColList[index] = 'down';
    }

    this.changeDataSourceOrder(this.orderColList[index], prop);
  }

  private changeDataSourceOrder(direction: string, prop: string) {
    let newData;
    if (direction == 'down') {
      newData = SharedFunction.orderByAsc(this.dataSource.data, prop);
    } else {
      newData = SharedFunction.orderByDesc(this.dataSource.data, prop);
    }

    this.dataSource.data = newData;
  }


  private makeTableColums() {
    if (this.tableDataHeader) {
      this.orderColList = new Array(this.tableDataHeader.length);
      this.columnsName = this.tableDataHeader.map(tb => tb.prop);
      this.columnsInfo = this.tableDataHeader;
    }
  }

  private setSource() {
    this.dataSource = new MatTableDataSource<any>(this.tableData);
    this.paginatorDefault && (this.paginatorDefault.length = this.tableData.length);
    if (!this.totalPageslazy) {
      this.dataSource.paginator = this.paginatorDefault;
    }
  }

  private setPaginator() {
    if (!this.totalPageslazy) {
      this.dataSource.paginator = this.paginatorDefault;
    } else {
    }
  }

  cambioPage(event: PageEvent) {
    this.callBackPaginator.emit(event);
  }

  callBackRowData(event: TableCallback) {
    this.callBack.emit(event);
  }

  callBackRowDataColor(event: TableCallback) {
    //console.log(event);
    this.selected = event.data.id;
    this.callBack.emit(event);
  }

  selectColor(row: any){
    if (this.selected && this.selected == row.id && !this.noclick) {
      return 'background-color:' + this.colorSelected + ';' ;
    }
    return;
  }


  /*
      if (this.noRowPattern){
      //console.log("Color Change")
      this.textDiv.nativeElement.style.backgroundColor = 'yellow';
    }
   */
  /*

  callBackRowHeader(event) {
    if (this.lazy) {
      this.debounceEmiter$.next({type: 'filter', data: [event.name, event.data] });
    } else {
      this.setRowHeaderFilter(event);
    }
  }*/

}
