import {EventEmitter, Injectable} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {BehaviorSubject} from 'rxjs';
import {Configuration} from "../../entities/Configuration";
import {TokenDecoded} from "../../entities/TokenDecoded";

@Injectable({
  providedIn: 'root',
})

export class ConfigService {
  private _configuration!: Configuration;
  private _utente?: string;
  private _token?: string;
  private _tokenDecoded?: TokenDecoded;
  private _refreshToken?: string;
  private _cambioContentSize = new EventEmitter();
  isMobile$: BehaviorSubject<boolean>;

  constructor(private media: MediaMatcher) {
    const mediaQuery = media.matchMedia('(max-width: 600px)');
    //Subscribe nel Index.ts / index.html
    this.isMobile$ = new BehaviorSubject<boolean>(mediaQuery.matches);
  }

  get isMobile(): boolean {
    return this.isMobile$.getValue();
  }

  get cambioContentSize() {
    return this._cambioContentSize;
  }

  set cambioContentSize(value) {
    this._cambioContentSize = value;
  }

  get configuration(): Configuration {
    return this._configuration;
  }

  set configuration(value) {
    this._configuration = value;
  }

  get isLogged(): boolean {
    return !!this.utente && !!this.token && !!this.refreshToken;
  }

  get utente(): string | undefined {
    return this._utente;
  }

  set utente(value: string | undefined) {
    this._utente = value;
  }

  get token(): string | undefined {
    return this._token;
  }

  set token(value: string | undefined) {
    this._token = value;
  }

  get tokenDecoded(): TokenDecoded | undefined {
    return this._tokenDecoded;
  }

  set tokenDecoded(value: TokenDecoded | undefined) {
    this._tokenDecoded = value;
  }

  get refreshToken(): string | undefined {
    return this._refreshToken;
  }

  set refreshToken(value: string | undefined) {
    this._refreshToken = value;
  }
}
