import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TestiService} from "../../../service/config/testi.service";

@Component({
  selector: 'app-form-field-footer',
  templateUrl: './form-field-footer.component.html',
  styleUrls: ['./form-field-footer.component.scss']
})
export class FormFieldFooterComponent implements OnInit {

  @Input() disabled?: boolean;
  @Input() showDelete?: boolean;
  @Input() showCancel? = true;
  @Input() showSave? = true;
  @Input() hideSave?: boolean;
  @Input() showRiattiva?: boolean;
  @Input() newmode?: boolean =  true;

  @Input() blockDelete?: boolean;
  @Input() blockCancel?: boolean;
  @Input() blockSave?: boolean;

  @Input() tooltipSave?: string = "";
  @Input() tooltipDelete?: string = "";

  @Input() textCancel?: string;
  @Input() textDelete?: string;
  @Input() textSave?: string;
  @Input() color?: string;
  @Input() testi?: any;

  @Output() saveForm = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() delete = new EventEmitter();


  constructor(private testiService: TestiService) {
  }

  ngOnInit() {

  }

}
