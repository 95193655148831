import {Injectable} from '@angular/core';

import {ApiRestServiceAnagrafiche} from './api-rest.service-anagrafiche';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {HttpHeaders} from "@angular/common/http";
import {Paziente, PazientePaged} from "../entities/Paziente";
import {Struttura} from "../entities/Struttura";
import {UnitaServizio} from "../entities/UnitaServizio";
import {Dieta} from "../entities/Dieta";
import {Stanza} from "../entities/Stanza";
import {TipoStruttura} from "../entities/TipoStruttura";
import {DropdownItem} from "../entities/utils/DropdownItem";
import {StanzaLight} from "../entities/StanzaLight";


@Injectable({
  providedIn: 'root'
})
export class PazientiService {

  constructor( private apiRestService: ApiRestServiceAnagrafiche) {}


  getAllPazienti(): Observable<Paziente[]> {
    const url =  `pazienti/all`;

    return this.apiRestService.getWithHeader(url)
      .pipe(
        map( res => res ? res.map((ts: any) => new Paziente(ts)) : [] )
      );
  }

  getAllPazientiFiltrataPaged(page: number, size: number, params: any[][]): Observable<PazientePaged> {
    const url =  `pazienti/filtratapaged?page=${page}&size=${size}`;
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Paged': 'true'
    });

    return this.apiRestService.getWithParams(url, params, header)
        .pipe(
            map( res => new PazientePaged(res))
        );
  }

  /**
   * @deprecated - use getAllStrutture from strutture.service
   */
  getAllStrutture(): Observable<Struttura[]> {
    const url =  `strutture/all`;
    return this.apiRestService.getWithHeader(url)
        .pipe(
            map( res => res ? res.map((ts: any) => new Struttura(ts)) : [] )
        );
  }

  getAllTipoStrutture(): Observable<TipoStruttura[]> {
    const url =  `vistrutturaflat/risto/tipostruttura`;
    return this.apiRestService.getWithHeader(url)
        .pipe(
            map( res => res ? res.map((ts: any) => new TipoStruttura(ts)) : [] )
        );
  }

  getStrutturaById(id: number): Observable<Struttura[]> {
    const url =  `vistrutturaflat/risto/struttura?tipostrutturaid=` + id.toString();
    return this.apiRestService.getWithHeader(url)
        .pipe(
            map( res => res ? res.map((ts: any) => new Struttura(ts)) : [] )
        );
  }

  getAllUS(): Observable<UnitaServizio[]> {
    const url =  `unitaservizio/all`;
    return this.apiRestService.getWithHeader(url)
        .pipe(
            map( res => res ? res.map((ts: any) => new UnitaServizio(ts)) : [] )
        );
  }

  getUSbyID(id: number): Observable<UnitaServizio[]> {
    const url =  `utentiunitaservizio/dto/idstruttura=` + id.toString();
    return this.apiRestService.getWithHeader(url)
        .pipe(
            map( res => res ? res.map((ts: any) => new UnitaServizio(ts)) : [] )
        );
  }

  getStanzebyID(id: number): Observable<Stanza[]> {
    const url =  `stanze/allbyunitaservizio/id=` + id.toString();
    return this.apiRestService.getWithHeader(url)
        .pipe(
            map( res => res ? res.map((ts: any) => new Stanza(ts)) : [] )
        );
  }

  getStanzeLightbyID(id: number): Observable<StanzaLight[]> {
    const url =  `stanze/allbyunitaservizio/id=` + id.toString();
    return this.apiRestService.getWithHeader(url)
        .pipe(
            map( res => res ? res.map((ts: any) => new StanzaLight(ts)) : [] )
        );
  }


  getAllStanze(): Observable<Stanza[]> {
    const url =  `stanze/all`;
    return this.apiRestService.getWithHeader(url)
        .pipe(
            map( res => res ? res.map((ts: any) => new Stanza(ts)) : [] )
        );
  }

  getAllDiete(): Observable<Dieta[]> {
    const url =  `diete/all`;
    return this.apiRestService.getWithHeader(url)
        .pipe(
            map( res => res ? res.map((ts: any) => new Dieta(ts)) : [] ),
            tap(s => { ////console.log(s);
            })
        )
  }

  getAllSessi(): DropdownItem[] {
    let sesso: DropdownItem[] = [];
    sesso.push({"value": "M", "viewValue":"M"});
    sesso.push({"value": "F", "viewValue":"F"});
    return sesso;
  }

  getAllPresenti(): DropdownItem[] {
    let sesso: DropdownItem[] = [];
    sesso.push({"value": "0", "viewValue":"Presente"});
    sesso.push({"value": "1", "viewValue":"Assente"});
    return sesso;
  }

  getPaziente(id: number): Observable<Paziente> {
    const url =  `pazienti/id=${id}`;
    return this.apiRestService.getWithHeader(url)
      .pipe(
        map( res => new Paziente(res) )
      );
  }


  deletePaziente(id: number, headers: HttpHeaders): Observable<Paziente> {
    const url =  `pazienti/id=${id}`;

    return this.apiRestService.delete(url, headers);
  }


  updatePaziente(paziente: Paziente): Observable<Paziente> {
    const url = `pazienti/update/id=${paziente.id}`;
    return this.apiRestService.patchWithBody(url, paziente.getOggUpdate());
  }

  createPaziente(paziente: Paziente): Observable<Paziente> {
    const url = `pazienti/save`;
    return this.apiRestService.postWithBody(url, paziente.getOggCreate());
  }



}
