import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {forkJoin, Observable, of} from "rxjs";
import {SdgLibService} from "sdg-lib";


@Injectable({
    providedIn: 'root'
})

export class TestiService {

    allTesti: Map<string, any> = new Map<string, any>();

    get componentRichieste() { return this.checkTestoEsite('componentRichieste'); }
    get componentAnagrafiche() { return this.checkTestoEsite('componentAnagrafiche'); }
    get componentPulizie() { return this.checkTestoEsite('componentPulizie'); }
    get componentLavanolo() { return this.checkTestoEsite('componentLavanolo'); }
    get componentRistorazione() { return this.checkTestoEsite('componentRistorazione'); }
    get componentDms() { return this.checkTestoEsite('componentDms'); }
    get componentAreeControllo() { return this.checkTestoEsite('componentAreeControllo'); }
    get componentCentriCottura() { return this.checkTestoEsite('componentCentriCottura'); }
    get componentTipoServizio() { return this.checkTestoEsite('componentTipoServizio'); }
    get componentTipoStruttura() { return this.checkTestoEsite('componentTipoStruttura'); }

    constructor( private http: HttpClient,
                 private handler: HttpBackend,
                 private shared: SdgLibService) {
        this.http = new HttpClient(handler);
    }


    private checkTestoEsite(type: string): any {
		const testi = this.shared.testi;
        for (let t of testi){
            if (t[0] == type){
                return t[1]
            }
        }


        return this.allTesti.has(type) ? this.allTesti.get(type) : {};
    }


    private getArrayTestiObservable(lang: string): Observable<any>[] {
        const langName = lang.charAt(0).toUpperCase() + lang.substring(1).toLowerCase();
        return [
            this.jsonRest('componentRichieste'+langName, 'componentRichieste', lang),
            this.jsonRest('componentPulizie'+langName, 'componentPulizie', lang),
            this.jsonRest('componentLavanolo'+langName, 'componentLavanolo', lang),
            this.jsonRest('menu'+langName, 'menu', lang),
            this.jsonRest('comune'+langName, 'comune', lang),
            this.jsonRest('componentUtente'+langName, 'componentUtente', lang),
            this.jsonRest('componentTipoStruttura'+langName, 'componentTipoStruttura', lang),
            this.jsonRest('componentTipoServizio'+langName, 'componentTipoServizio', lang),
            this.jsonRest('componentRistorazione'+langName, 'componentRistorazione', lang),
            this.jsonRest('componentLogin'+langName, 'componentLogin', lang),
            this.jsonRest('componentDms'+langName, 'componentDms', lang),
            this.jsonRest('componentCentriCottura'+langName, 'componentCentriCottura', lang),
            this.jsonRest('componentAreeControllo'+langName, 'componentAreeControllo', lang),
            this.jsonRest('componentAnagrafiche'+langName, 'componentAnagrafiche', lang),
        ];
    }

    setTesti(lang: string): Observable<any> {
        let array$;
        array$ = this.getArrayTestiObservable(lang);
        this.shared.testi = array$;
        return forkJoin(array$);
    }

    private jsonRest(link: string, type: string, folder: string) {
        const headers = new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'});
        const url = `./assets/testi/${folder}/${link}.json`;
        const urlConVersion = url;
        return this.http.get(urlConVersion, {headers})
            .pipe(
                catchError(error => {
                    console.error(`Errore al caricare file testo: ${link}`);
                    return of({});
                } ),
            )
            .pipe(
                tap( res => {
                    this.allTesti.set(type, res);
                } )
            );
    }



}
