import {Paged} from "./Paged";
import {MyDate} from "./MyDate";
import {Blocco} from "./Blocco";
import {TipoPrenotazione} from "./TipoPrenotazione";
import {CentroCosto} from "./CentroCosto";
import {PuntoPrelievo} from "./PuntoPrelievo";
import {DriverRistorazione} from "./DriverRistorazione";
import moment from "moment";

export class UnitaServizio {

    id?: number;
    usDesc?: string;
    blocco?: Blocco;
    bloccoDesc?: string;
    piano?: string;
    descUnitaServizio?: string;
    dataInizioVal?: MyDate;
    dataFineVal?: MyDate;
    userdId?: number;
    flSterilizzazione: string;
	myflSterilizzazione: boolean;
    flRepartoOperatorio: string;
    myflRepartoOperatorio: boolean;
    giorniConsegna: string;
    oraConsegnaPulito: MyDate | undefined;
    oraRitiroSporco1: MyDate | undefined;
    oraRitiroSporco2: MyDate | undefined;
    postiLettoOrd: number;
    postiLettoDh: number;
    postiLettoPag: number;
    flRistorazione?: string;
    flTipoPrenotazione: 0;
    idFlTipoPrenotazione?: number | null;
    ccId: string;
    ppId: string;
    driverRistoId: 0;
    dataInserimento: MyDate;
    myflRistorazione?: boolean;
    tipoPrenotazione?: TipoPrenotazione;
    centroCosto?: CentroCosto;
    puntoPrelievo?: PuntoPrelievo;
    driverRistorazione?: DriverRistorazione;
    constructor(data: any) {
        Object.assign(this, data);
        this.blocco = data.blocco ? new Blocco(data.blocco) : undefined;
 		this.puntoPrelievo = data.puntoPrelievo ? new PuntoPrelievo(data.puntoPrelievo) : undefined;
        this.driverRistorazione = data.driverRistorazione ? new DriverRistorazione(data.driverRistorazione) : undefined;
        this.centroCosto = data.centroCosto ? new CentroCosto(data.centroCosto) : undefined;
        this.tipoPrenotazione = data.tipoPrenotazione ? new TipoPrenotazione(data.tipoPrenotazione) : undefined;
        this.oraConsegnaPulito = data.oraConsegnaPulito ? new MyDate(moment(data.oraConsegnaPulito, "HH.mm")) : undefined;
        this.oraRitiroSporco1 = data.oraRitiroSporco1 ? new MyDate(moment(data.oraRitiroSporco1, "HH.mm")) : undefined;
        this.oraRitiroSporco2 = data.oraRitiroSporco2 ? new MyDate(moment(data.oraRitiroSporco2, "HH.mm")) : undefined;
        this.myflRistorazione = data.flRistorazione == 'Y' ? true : false;
        this.myflSterilizzazione = data.flSterilizzazione == 'Y' ? true : false;
        this.myflRepartoOperatorio = data.flRepartoOperatorio == 'Y' ? true : false;
    }

    /* servono per essere visualizzati nei dropdown */
    get viewValue(): string {
        return this.usDesc ? this.usDesc : '';
    }
    get value(): UnitaServizio {
        return this;
    }

    get desBlocco() {
        return this.blocco ? this.blocco.viewValue : '';
    }

	get desCentroCosto() {
        return this.centroCosto ? this.centroCosto.viewValue : '';
    }

    get desPuntoPrelievo() {
        return this.puntoPrelievo ? this.puntoPrelievo.viewValue : '';
    }

    get desDriverRistorazione() {
        return this.driverRistorazione ? this.driverRistorazione.viewValue : '';
    }

    get desTipoPrenotazione() {
        return this.tipoPrenotazione ? this.tipoPrenotazione.viewValue : '';
    }

    // get myflSterilizzazione() {
    //     return this.flSterilizzazione == 'Y' ? true : false;
    // }
    get oraConsegnaPulitoHHMM() {
        return this.oraConsegnaPulito ? this.oraConsegnaPulito.timeGet: null;
    }

    get oraRitiroSporco1HHMM() {
        return this.oraRitiroSporco1 ? this.oraRitiroSporco1.timeGet: null;
    }

    get oraRitiroSporco2HHMM() {
        return this.oraRitiroSporco2 ? this.oraRitiroSporco2.timeGet: null;
    }
    get dataInizioValGGMMAA() {
        return this.dataInizioVal ? this.dataInizioVal.giornoMeseAnno : null;
    }

    get dataFineValGGMMAA() {
        return this.dataFineVal ? this.dataFineVal.giornoMeseAnno : null;
    }




	getOggCreate()  {
        return {
        usDesc: this.usDesc,
            //            stato: this.stato ? { id: this.stato!.id!, descStato: this.stato!.descStato!} : undefined,
        //blocco: this.blocco
        blocco: this.blocco ? { id: this.blocco!.id!} : undefined,
        bloccoDesc: this.bloccoDesc ? this.bloccoDesc : null,
        piano: this.piano,
        dataInizioVal: this.dataInizioVal ? this.dataInizioVal.dateSave : null,
        dataFineVal: this.dataFineVal ? this.dataFineVal.dateSave : null,
    //    userdId: this.userdId,
        flSterilizzazione: this.myflSterilizzazione  ? 'Y' : 'N',
        flRepartoOperatorio: this.myflRepartoOperatorio ? 'Y' : 'N',
        giorniConsegna: this.giorniConsegna,
        oraConsegnaPulito: this.oraConsegnaPulito  ? this.oraConsegnaPulito.timeSave : null,
        oraRitiroSporco1: this.oraRitiroSporco1 ? this.oraRitiroSporco1.timeSave : null,
        oraRitiroSporco2: this.oraRitiroSporco2 ? this.oraRitiroSporco2.timeSave : null,
        postiLettoOrd: this.postiLettoOrd,
        postiLettoDh: this.postiLettoDh,
        postiLettoPag: this.postiLettoPag,
        flRistorazione: this.myflRistorazione ? 'Y' : 'N',
            tipoPrenotazione: this.tipoPrenotazione ? { id: this.tipoPrenotazione!.id!} : undefined,
            centroCosto: this.centroCosto ? { ccId: this.centroCosto!.ccId!} : undefined,
            puntoPrelievo: this.puntoPrelievo ? { ppId: this.puntoPrelievo!.ppId!} : undefined,
            driverRistorazione: this.driverRistorazione ? { id: this.driverRistorazione!.id!} : undefined,

        };
    }

    getOggUpdate() {
        return this.getOggCreate();
    }



 }

export class UnitaServizioPaged extends Paged {
    content: UnitaServizio[];

    constructor(data: any) {
        super(data);

        this.content = data.content ? data.content.map((ts: any) => new UnitaServizio(ts)) : [];
    }
}

