import {Paged} from "./Paged";
import {TipoPrenotazione} from "./TipoPrenotazione";
import {DropdownItem} from "./utils/DropdownItem";
import {TipoPastoEnum} from "./enums/enums-delacqua";

export class TipoPasto implements DropdownItem {

    id?: number;
    descrizione?: TipoPastoEnum;
    tipoPrenotazione?: TipoPrenotazione;

    constructor(data: any) {
        Object.assign(this, data);
        this.tipoPrenotazione = data.tipoPrenotazione ? new TipoPrenotazione(data.tipoPrenotazione) : undefined;
    }

    get viewValue(): string {
        return this.descrizione ? this.descrizione : "";
    }

    get value(): TipoPasto {
        return this;
    }

    get desTipoPrenotazione() {
        return this.tipoPrenotazione ? this.tipoPrenotazione.viewValue : "";
    }


    getOggCreate() {
        return {
            id: this.id,
            descrizione: this.descrizione,
            tipoPrenotazione: this.tipoPrenotazione ? {id: this.tipoPrenotazione!.id!} : undefined,
        };
    }

    getOggUpdate() {
        return this.getOggCreate();
    }


}

export class TipoPastoPaged extends Paged {
    content: TipoPasto[];

    constructor(data: any) {
        super(data);

        this.content = data.content ? data.content.map((ts: any) => new TipoPasto(ts)) : [];
    }
}

