<form class="form-base" *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="actionSave()">
  <div class="formContainer" *ngFor="let group of arrayFormInfo">
    <div class="formFieldGroup" *ngFor="let item of group.groupItems">
        <label [for]="item.prop" class="form-label-center" *ngIf="group.groupItems.length == 1">
          <div *ngIf="item.input != 'blank'">
            {{item.text}}:
          </div>
        </label>
        <label [for]="item.prop" class="form-label" *ngIf="group.groupItems.length != 1">
          <div *ngIf="item.input != 'blank'">
            {{item.text}}:
          </div>
        </label>
        <div class="form-input" *ngIf="item.input != 'blank'">
          <div *ngIf="group.groupItems.length == 1" id="div-center">
            <app-form-inputs [formType]="item"
                             [formControlName]="item.prop"
                             [objValue]="objValue"
                             [dmsUpload]="dmsUpload"
                             [testi]="testi"
                             [msgForbidden]="msgForbidden"
                             [DragDropMaxHeight]="DragDropMaxHeight"
                             [dropdownList]="selectsGroup!.get(item!.list!)"
                             [dropdownList2]="selectsGroup!.get(item!.list2!)">
            </app-form-inputs>
          </div>
          <div *ngIf="group.groupItems.length != 1">
            <app-form-inputs [formType]="item"
                             [formControlName]="item.prop"
                             [objValue]="objValue"
                             [dmsUpload]="dmsUpload"
                             [testi]="testi"
                             [DragDropMaxHeight]="DragDropMaxHeight"
                             [msgForbidden]="msgForbidden"
                             [dropdownList]="selectsGroup!.get(item!.list!)"
                             [dropdownList2]="selectsGroup!.get(item!.list2!)">
            </app-form-inputs>
          </div>
        </div>
      <div class="form-error"> <span *ngIf="formGroup!.controls[item.prop].invalid"> * </span> </div>
    </div>

  </div>

  <div class="formContainer">
    <div class="formFieldGroup">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="form-legenda">
    <div *ngIf="formGroup!.invalid" class="legenda-form-error"> * {{testi.obbligatorio}} </div>

    <div *ngFor="let msg of msgs" class="legenda-form-error">
      <span *ngIf="formGroup!.controls[msg.prop].invalid && formGroup!.controls[msg.prop].touched" class="error-validator">
        <div *ngIf="validateRegexEmail(formGroup!.controls[msg.prop].value) && msg.prop == 'email'">
             <div *ngIf="!msg.order">
               {{msg.msg}}
             </div>
        </div>
        <div *ngIf="!validateRegexEmail(formGroup!.controls[msg.prop].value) && msg.prop == 'email'">
             <div *ngIf="msg.order">
               {{msg.msg}}
             </div>
        </div>
          <div *ngIf="formGroup!.controls[msg.prop].value.length <= 2 && msg.prop == 'username'">
             <div *ngIf="!msg.order">
               {{msg.msg}}
             </div>
        </div>
        <div *ngIf="formGroup!.controls[msg.prop].value.length > 2  && msg.prop == 'username'">
             <div *ngIf="msg.order">
               {{msg.msg}}
             </div>
        </div>
        <div *ngIf="msg.prop != 'email' && msg.prop != 'username'">
               {{msg.msg}}
        </div>
      </span>
    </div>
  </div>


    <div class="footerForm" *ngIf="update">
        <app-form-field-footer [disabled]="formGroup!.invalid"
                               [hideSave]="true"
                               [testi]="testi"
                               (cancel)="actionCancel()">
        </app-form-field-footer></div>
  <div class="footerForm" *ngIf="enableFooter && !showRiattiva && !update">
    <app-form-field-footer [disabled]="formGroup!.invalid"
                           [testi]="testi"
                           (saveForm)="actionSave()"
                           (cancel)="actionCancel()">
    </app-form-field-footer>
  </div>
    <div class="footerForm" *ngIf="enableFooter && showRiattiva && !update">
        <app-form-field-footer [disabled]="formGroup!.invalid"
                               [showRiattiva]="showRiattiva"
                               [testi]="testi"
                               (delete)="actionDelete()"
                               (saveForm)="actionSave()"
                               (cancel)="actionCancel()">
        </app-form-field-footer>
    </div>

</form>
