import {MyDate} from './MyDate';
import * as moment from 'moment';

export class SimpleUtente {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  validityEnd?: MyDate | undefined;
  validityStart: MyDate;
  editDate: MyDate;
  editUser?: string;
  enabled: boolean;
  roles: string;
  note?: string;

  constructor(data: any) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.username = data.username;
    this.email = data.email;
    this.enabled = data.enabled;
    this.editUser = data.attributes ?  data.attributes.EditUser : "";

    let text = "";
    for (let nota of data.note){
      text +=nota;
    }
    this.note= text ?  text : "";

    let dI = data.validityStart ? moment(data.validityStart, "DD/MM/YYYY") : undefined;
    this.validityStart = new MyDate(dI);

    let dF = data.validityEnd ? moment(data.validityEnd, "DD/MM/YYYY") : undefined;
    if (dF) {
      this.validityEnd = dF.isValid() ? new MyDate(dF) : undefined;
    } else {
      this.validityEnd = undefined;
    }

    let eD = data.attributes ? moment(data.attributes.EditDate[0], "DD/MM/YYYY - HH:MM") : undefined;
    this.editDate =new MyDate(eD);

    let roles = '';
    for (let role of data.roles){
      roles += role.name + ','
    }
    this.roles = roles;
  }


  get dataFineValidita(): string | undefined {
    return this.validityEnd ? new MyDate(this.validityEnd).giornoMeseAnno : '';
  }

  get dataInizioValidita(): string {
    return new MyDate(this.validityStart).giornoMeseAnno;
  }

  get dataModificaUtente(): string {
    return new MyDate(this.editDate).giornoMeseAnnoOre;
  }



  getOggUpdate() {
    return this.getOggCreate();
  }

  dateISO(date: MyDate): string{
    return new MyDate(date).toISOString();
  }

  getOggCreate(): SimpleUtenteCreate {
    let roles = [];
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      username: this.username,
      email: this.email,
      validityEnd: this.validityEnd ? this.dateISO(this.validityEnd) : undefined,
      validityStart: this.dateISO(this.validityStart),
      editDate: this.dateISO(this.editDate),
      editUser: this.editUser ? this.editUser : '',
      enabled: this.enabled,
      roles: this.roles,
      note: this.note,
    };
  }


   getAttributeUser() : Map<string, string> {
     const m =  new Map<string, string>();
     m.set("ValidityStart", this.dataInizioValidita);
     m.set("ValidityEnd", this.dataFineValidita || '');
     m.set("Note", this.note ? this.note : "");
     m.set("EditDate", this.dataModificaUtente);
     m.set("EditUser", this.editUser ? this.editUser : "" );
     return m;

   }




}


export interface SimpleUtenteCreate {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  validityEnd?: string | '';
  validityStart?: string;
  editDate: string;
  editUser?: string;
  enabled: boolean;
  roles: string;
  note?: string;
}






