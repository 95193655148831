import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {RichiesteComponent} from "./richieste/richieste.component";
import {RistorazioneRoutesEnum, RoutesEnum} from "../entities/enums/enums-delacqua";


const routes: Routes = [
    /** todo  test - rimovere */
/*  {path:'',
    loadChildren: () => import('../anagrafiche/ristorazione/menu/menu-pasto.module').then(m => m.MenuPastoModule),
  },*/
  { path: '', component: RichiesteComponent },

  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RichiesteRouting { }
