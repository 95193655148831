import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-table-footer',
  templateUrl: './table-footer.component.html',
  styleUrls: ['./table-footer.component.scss']
})
export class TableFooterComponent implements OnInit {

  @Input() tableType?: string;
  @Input() buttonAdd = true;

  @Output() callBack = new EventEmitter();
  @Output() print = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  addDialog() {
    this.callBack.emit({type: 'add'});
  }

}
