import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class ApiRestServicePortal{

    setLastActivity(date: Date){
        this.lastActivity = new Date(date);
    }

    getLastActivity(): Date {
        return this.lastActivity;
    }

    setlogged(logged: boolean){
        this._logged = logged;
    }

    getlogged(): boolean {
        return this._logged;
    }

    set externalUrl(value: string){
        this._externalUrl = value;
    }

    get externalUrl(): string {
        return this._externalUrl;
    }

    set portalName(value: string){
        this._portal = value;
    }

    get portalName(): string {
        return this._portal;
    }

    set portalUrl(value: string){
        this._portalUrl = value;
    }

    get portalUrl(): string {
        return this._portalUrl;
    }

    set refreshToken(value: string){
        this._refreshToken = value;
    }

    get refreshToken(): string {
        return this._refreshToken;
    }

    set apiRestKeycloak(value: string) {
        this._linkRestKeycloak = value;
    }

    set apiRestLinkRichieste(value: string) {
        this._linkRestRichieste = value;
    }

    set apiRestLinkDMS(value: string) {
        this._linkRestDMS = value;
    }

    set apiRestDMS(value: string) {
        this._restDMS = value;
    }

    set apiRestLinkPulizie(value: string) {
        this._linkRestPulizie = value;
    }

    get apiRestLinkPulizie(): string {
        return this._linkRestPulizie;
    }

    set apiRestLinkLavanolo(value: string) {
        this._linkRestLavanolo = value;
    }

    get apiRestLinkLavanolo(): string {
        return this._linkRestLavanolo;
    }

    set apiRestLinkRistorazione(value: string) {
        this._linkRestRistorazione = value;
    }

    get dmsFolderId(): string {
        return this._dmsFolderId;
    }

    set dmsFolderId(value: string) {
        this._dmsFolderId = value;
    }

    get apiRestLinkRistorazione(): string {
        return this._linkRestRistorazione;
    }


    set dmsTicketFolder(value: string) {
        this._ticketFolder = value;
    }

    get dmsTicketFolder(): string {
        return this._ticketFolder;
    }

    set apiRestLinkAnagrafiche(value: string) {
        this._linkRestAnagrafiche = value;
    }

    //Set on ConfigService
    get apiRestLinkRichieste(): string {
        return this._linkRestRichieste;
    }

    get apiRestKeycloak(): string{
        return this._linkRestKeycloak;
    }

    get apiRestLinkDMS(): string {
        return this._linkRestDMS;
    }

    get apiRestDMS(): string {
        return this._restDMS;
    }

    get apiRestLinkAnagrafiche(): string {
        return this._linkRestAnagrafiche;
    }

    lastActivity: Date;
    private _portal: string;
    private _portalUrl: string;
    private _refreshToken: string;
    private _logged: boolean = false;
    private _linkRestRichieste: string;
    private _linkRestKeycloak: string;
    private _externalUrl: string;
    private _linkRestAnagrafiche: string;
    private _linkRestPulizie: string;
    private _linkRestLavanolo: string;
    private _linkRestRistorazione: string;
    private _linkRestDMS: string;
    private _restDMS: string;
    private _ticketFolder: string;
    private _dmsFolderId: string;
    private headerBase = new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'http://localhost:3000'
    });
    private headerLogin = new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'http://localhost:3000'
    });


    private httpSenzaInterceptor: HttpClient;

    constructor(protected http: HttpClient,
                private handler: HttpBackend) {

        this.httpSenzaInterceptor = new HttpClient(handler);

    }



    getWithHeader(url: string, header?: HttpHeaders): Observable<any> {
        const _url = url;

        const _header = header ? header : this.headerBase;

        return this.http.get<any>(_url, {headers: _header})
            .pipe(
                catchError(error => this.setError(error))
            );
    }


    private setError(error: any) {
        return throwError(error);
    }
}
