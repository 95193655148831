import {Paged} from "./Paged";
import {MyDate} from "./MyDate";
import {UnitaServizio} from "./UnitaServizio";
import {Stanza} from "./Stanza";
import {Struttura} from "./Struttura";
import {Dieta} from "./Dieta";
import {TipoStruttura} from "./TipoStruttura";
import {StanzaLight} from "./StanzaLight";


export class Paziente {

    id?: number;
    codice?: string;
    nome?: string;
    cognome?: string;
    pid?: string;
    cf?: string;
    sesso?: string;
    presente?: number;
    datains?: MyDate;
    unitaServizio?: UnitaServizio;
    tipoStruttura?: TipoStruttura;
    stanze?: Stanza | StanzaLight;
    letto?: string;
    struttura?: Struttura;
    dieta?: Dieta;
    createdBy?: string;
    createdOn?: MyDate;
    modifiedBy?: string;
    modifiedOn?: MyDate;
    deletedBy?: string;
    deletedOn?: MyDate;
    flagFittizio?: string;
    flagAdt?: string;


    constructor(data: any) {
        Object.assign(this, data);
        this.datains = data.datains ? new MyDate(data.datains) : undefined;
        this.unitaServizio = data.unitaServizio ? new UnitaServizio(data.unitaServizio) : undefined;
        this.tipoStruttura = data.tipoStruttura ? new TipoStruttura(data.tipoStruttura) : undefined;
        this.stanze = data.stanze ? new Stanza(data.stanze) : undefined;
        this.struttura = data.struttura ? new Struttura(data.struttura) : undefined;
        this.dieta = data.dieta ? new Dieta(this.dieta) : undefined;
        this.createdOn = data.createdOn ? new MyDate(data.createdOn) : undefined;
        this.modifiedOn = data.modifiedOn ? new MyDate(data.modifiedOn) : undefined;
        this.deletedOn = data.deletedOn ? new MyDate(data.deletedOn) : undefined;
    }

    get flagFittizioBoolean(): boolean{
        return this.flagFittizio == "Y" ? true : false;
    }

    set flagFittizioBoolean(flag: boolean) {
        this.flagFittizio = flag == true ?  "Y" : "N";
    }

    set flagAdtBoolean(flag: boolean) {
        this.flagAdt = flag == true ?  "Y" : "N";
    }

    get flagAdtBoolean(): boolean{
        return this.flagAdt == "Y" ? true : false;
    }

    get dietaName(): string{
        return this.dieta ? this.dieta.descrizione! : '';
    }

    get tipoStrutturaName(): string {
        return this.tipoStruttura && this.tipoStruttura.descrizione ? this.tipoStruttura.descrizione : ''
    }

    get unitaServizioName(): string {
        return this.unitaServizio && this.unitaServizio.usDesc ? this.unitaServizio.usDesc: ''
    }

    get stanzeName(): string {
        return this.stanze && this.stanze.saDesc ? this.stanze.saDesc : ''
    }

    get usName(): string {
        return this.unitaServizio ? this.unitaServizio.viewValue! : '';
    }

    get strutturaName(): string{
        return this.struttura ? this.struttura.viewValue! : '';
    }


    getOggCreate() {
        return {
            codice: this.codice,
            nome: this.nome,
            cognome: this.cognome,
            cf: this.cf,
            sesso: this.sesso,
            presente: this.presente,
            datains: this.datains ? this.datains.dateSave : null,
            unitaServizio: this.unitaServizio ? { id: this.unitaServizio!.id!} : undefined,
            stanze: this.stanze ? {id: this.stanze!.id} : undefined,
            letto: this.letto,
            struttura: this.struttura ? {id: this.struttura!.id} : undefined,
            dieta: this.dieta ? {id: this.dieta!.id} : undefined,
            flagFittizio: this.flagFittizio,
            flagAdt: this.flagAdt
        };
    }

    getOggUpdate() {
        return this.getOggCreate();
    }

    get viewValue(): string {
        return this.cf? this.cf : '';
    }

    get value(): Paziente {
        return this;
    }

}

export class PazientePaged extends Paged {
    content: Paziente[];

    constructor(data: any) {
        super(data);

        this.content = data.content ? data.content.map((ts: any) => new Paziente(ts)) : [];
    }
}

