import {TableName} from "../../../../entities/table/TableName";


export class TableDocsCols {

  static getDocsList(testi: any): TableName[] {
    return [
      {name: "Nome", filter: '', order: '', prop: 'name', size: 30},
      {name: "Ultima Modifica", filter: '', order: '', prop: 'getDate', size: 20},
      {name: "Utente", filter: '', order: '', prop: 'user', size: 20},
      {name: "Tipo", filter: '', order: '', prop: 'type', size: 20 },
      {name: '', filter: '', order: '', prop: 'download', size: 5 },
      {name: '', filter: '', order: '', prop: 'cancella', size: 5 },
    ];
  }

  static getDocsListBlock(testi: any): TableName[] {
    return [
      {name: "Nome", filter: '', order: '', prop: 'name', size: 30},
      {name: "Ultima Modifica", filter: '', order: '', prop: 'getDate', size: 20},
      {name: "Utente", filter: '', order: '', prop: 'user', size: 20},
      {name: "Tipo", filter: '', order: '', prop: 'type', size: 20 },
      {name: '', filter: '', order: '', prop: 'download', size: 5 },
      {name: '', filter: '', order: '', prop: 'cancella', size: 5 },
    ];
  }

}

