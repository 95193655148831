import {Injectable} from '@angular/core';

import {ApiRestServiceAnagrafiche} from './api-rest.service-anagrafiche';
import {Observable} from 'rxjs';
import {TipoPasto, TipoPastoPaged} from '../entities/TipoPasto';
import {map} from 'rxjs/operators';
import {HttpHeaders} from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class TipoPastoService {

  constructor( private apiRestService: ApiRestServiceAnagrafiche) {}


  getAllTipoPasto(): Observable<TipoPasto[]> {
    const url =  `tipopasto/all`;

    return this.apiRestService.getWithHeader(url)
      .pipe(
          map( res => res.map((ts:any) => new TipoPasto(ts)) )
      );
  }

  getAllTipoPastoFiltrataPaged(page: number, size: number, params: any[][]): Observable<TipoPastoPaged> {
    const url =  `tipopasto/filtratapaged?page=${page}&size=${size}`;
   // const url =  `tipoPosto/allPaged?page=${page}&size=${size}`;


    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Paged': 'true'
    });

    return this.apiRestService.getWithParams(url, params, header)
        .pipe(
            map( res => new TipoPastoPaged(res) )
           //map( res => res ? res.map((ts: any) => new TipoPastoPaged(ts)) : [] )
        );
  }





  getTipoPasto(id: number): Observable<TipoPasto> {
    const url =  `tipopasto/id=${id}`;

    return this.apiRestService.getWithHeader(url)
      .pipe(
        map( res => new TipoPasto(res) )
      );
  }



  deleteTipoPasto(id: number, headers: HttpHeaders): Observable<TipoPasto> {
    const url =  `tipopasto/id=${id}`;

    return this.apiRestService.delete(url, headers);
  }


  updateTipoPasto(tipoPasto: TipoPasto): Observable<TipoPasto> {
    const url = `tipopasto/update/id=${tipoPasto.id}`;

    return this.apiRestService.patchWithBody(url, tipoPasto.getOggUpdate());
  }

  createTipoPasto(tipoPasto: TipoPasto): Observable<TipoPasto> {
    const url = `tipopasto/save`;

    return this.apiRestService.postWithBody(url, tipoPasto.getOggCreate());
  }

















}
