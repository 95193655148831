<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<div class="docs-container">

    <div class="doc-list">
        <ng-container *ngTemplateOutlet="fileList"></ng-container>
    </div>

    <div class="file-preview-container">
        <ng-container *ngIf="!files; else fileName"></ng-container>
    </div>

</div>


<ng-template #fileList>
    <app-table-view *ngIf="docs"
                    [tableData]="docs || []"
                    [tableDataHeader]="tableVoci"
                    [tableType]="'Documenti'"
                    [admin]="true"
                    [testi]="testi"
                    [buttonAdd]="false"
                    [buttonBrowse]="dmsUpload || false"
                    [loading]="loading"
                    [showHeader]="false"
                    [showFooter]="false"
                    [rows]="2"
                    (callBack)="callBackTable($event)">
    </app-table-view>
</ng-template>



<ng-template #fileName>

    <div class="file-selected">
        <div class="file-name" *ngFor="let fl of files">{{testi.file}}: {{ fl?.name }}</div>
    </div>

</ng-template>

<ng-template #buttonContainer>
        <input #fileInput
               type="file"
               multiple
               style="display: none"
               accept="image/*"
               (change)="onChangeFileInput()" />

        <button type="button"
                mat-raised-button
                color="primary"
                (click)="onClickFileInputButton()">
            aggiunge file
        </button>

        <button type="button"
                mat-raised-button
                color="primary"
                [disabled]="!!!files"
                (click)="upload()">
            upload
        </button>

        <button type="button"
                mat-raised-button
                color="accent"
                [disabled]="!!!files"
                (click)="cancel()">
            cancel
        </button>

</ng-template>
