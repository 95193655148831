export enum CallbackTableState {
  MODIFICA = 'MODIFICA',
  DELETE = 'DELETE',
  STAMPA = 'STAMPA',
  VALIDA = 'VALIDA',
  SINGLE = 'SINGLE',
  DOUBLE = 'DOUBLE',
  CLICKCELL = 'CLICKCELL',
  EXCEL = 'EXCEL',
  PDF = 'PDF',
  FILE = 'FILE',
  TEXT = 'TEXT',
  SEARCH = 'SEARCH',
  BACK = 'BACK',
  FOLDER = 'FOLDER',
  FOTO = 'FOTO',
  FILTER = 'FILTER',
  FILTERUPDATE = 'FILTERUPDATE',
  NUOVO = 'NUOVO',
  AGGIORNA = 'AGGIORNA',
  DOWNLOAD = 'DOWNLOAD',
  DETTAGLIO = 'DETTAGLIO',
  CONTESTA = 'CONTESTA',
  CREA = 'CREA',
  FUNCTION = 'FUNCTION',
  CHIUDI = 'CHIUDI',
  VALUE = 'VALUE',
  CHILD = 'CHILD'
}
