import {Injectable} from '@angular/core';

import {ApiRestServiceAnagrafiche} from './api-rest.service-anagrafiche';
import {Observable} from 'rxjs';
import {Stato} from '../entities/Stato';
import {map} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class StatoService {

  constructor( private apiRestService: ApiRestServiceAnagrafiche) {}


  getAllStato(): Observable<Stato[]> {
    const url =  `stato/all`;

    return this.apiRestService.getWithHeader(url)
      .pipe(
        map( res => res ? res.map((stato: any) => new Stato(stato)) : [] )
      );
  }

  getStato(id: number): Observable<Stato> {
    const url =  `stato/id=${id}`;

    return this.apiRestService.getWithHeader(url)
      .pipe(
        map( res => new Stato(res) )
      );
  }

  deleteStato(id: number): Observable<Stato> {
    const url =  `stato/id=${id}`;

    return this.apiRestService.delete(url);
  }
}
