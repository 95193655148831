import {Injectable} from '@angular/core';

import {ApiRestServiceAnagrafiche} from './api-rest.service-anagrafiche';
import {Observable} from 'rxjs';
import {Struttura, StrutturaPaged} from '../entities/Struttura';
import {map} from 'rxjs/operators';
import {HttpHeaders} from "@angular/common/http";
import {Impianto} from "../entities/Impianto";


@Injectable({
  providedIn: 'root'
})
export class StruttureService {

  constructor( private apiRestService: ApiRestServiceAnagrafiche) {}


  getAllStrutture(): Observable<Struttura[]> {
    const url =  `strutture/all`;

    return this.apiRestService.getWithHeader(url)
      .pipe(
        map( res => res ? res.map((ts: any) => new Struttura(ts)) : [] )
      );
  }

  getAlImpianti(): Observable<Impianto[]> {
    const url =  `impianto/all`;

    return this.apiRestService.getWithHeader(url)
        .pipe(
            map( res => res ? res.map((ts: any) => new Impianto(ts)) : [] )
        );
  }

  getAllStruttureFiltrataPaged(page: number, size: number, params: any[][]): Observable<StrutturaPaged> {
    const url =  `strutture/filtratapaged?page=${page}&size=${size}`;
   // const url =  `strutture/allPaged?page=${page}&size=${size}`;


    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Paged': 'true'
    });

    return this.apiRestService.getWithParams(url, params, header)
        .pipe(
            map( res => new StrutturaPaged(res) )
           //map( res => res ? res.map((ts: any) => new StrutturePaged(ts)) : [] )
        );
  }





  getStrutture(id: number): Observable<Struttura> {
    const url =  `strutture/id=${id}`;

    return this.apiRestService.getWithHeader(url)
      .pipe(
        map( res => new Struttura(res) )
      );
  }



  deleteStrutture(id: number, headers: HttpHeaders): Observable<Struttura> {
    const url =  `strutture/id=${id}`;

    return this.apiRestService.delete(url, headers);
  }


  updateStruttura(struttura: Struttura): Observable<Struttura> {
    const url = `strutture/update/id=${struttura.id}`;

    return this.apiRestService.patchWithBody(url, struttura.getOggUpdate());
  }

  createStruttura(struttura: Struttura): Observable<Struttura> {
    const url = `strutture/save`;

    return this.apiRestService.postWithBody(url, struttura.getOggCreate());
  }

















}
