import {
  Component, ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatSelect} from '@angular/material/select';
import {MatOption} from '@angular/material/core';
import {FormBuilder} from "@angular/forms";
import {SharedFunction} from "../../../service/utils/SharedFunction";


@Component({
  selector: 'app-ble-dropdown',
  templateUrl: './ble-dropdown.component.html',
  styleUrls: ['./ble-dropdown.component.scss']
})
export class BleDropdownComponent implements OnInit, OnChanges {

  @Input() valore?: any;
  @Input() lista?: any[] = [];
  @Input() label?: string;
  @Input() nullDropDown?: boolean = false;
  @Input() invalid? = false;
  @Input() testo?: string;
  @Input() disabled ? = false;
  @Input() ricerca = true;
  @Input() clean ? = false;
  @Input() presetYear? = false;
  @Input() multiple ? = false;
  @Input() selectAll ? = false;
  @Input() loading = false;
  @Input() children? = false;
  @Input() lista2?: any[] = [];
  @Input() tooltip? = true;
  @Input() autoSize?: boolean = false;
  @Input() reloadValue?: any = undefined;
  @Input() refSize?: number = 900;
  @Input() reloader?: boolean = true;
  @Input() tdElement? : HTMLElement;

  @Output() cambioValore = new EventEmitter();

  @ViewChild('dropdown', { static: false }) dropdown!: MatSelect;

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    if (this.autoSize) {
      //Popup-Size
      this.screenWidth = (window.innerWidth * 0.8) <= 900 ? (window.innerWidth * 0.8) - 76 : this.refSize! - 76;
      //Label Size
      this.labelSize = (this.screenWidth * 0.3) <= 250 ? (this.screenWidth * 0.3) : 250;
      //DrowDown Size
      this.dropdownSize = (this.screenWidth - this.labelSize) <= this.refSize!-300 ? (this.screenWidth - this.labelSize) : this.refSize!-300;
      this.initialWidth = this.dropdownSize.toString() + 'px';
    }
  }

  screenWidth: number;
  labelSize: number;
  dropdownSize: number;
  initialWidth: string;

  _lista: any[] = [];
  _preLista: any[] = [];
  _prevLista: any[] = [];
  dryrun: boolean = true;
  valoreInput?: string;

  setting?: boolean;
  empty=false;

  selected: any;

  _selectAll = false;

  selectedValue: any;

  firstLoad: boolean = true;

  constructor(private fb: FormBuilder, private el: ElementRef,) {
  }

  ngOnInit(): void {
    if (this.autoSize) {
      this.onResize();
    }
    if (this.tdElement) {
      this.initialWidth = this.tdElement.offsetWidth.toString() + 'px' ;
    } else {
      this.initialWidth = this.dropdownSize.toString() + 'px';
    }
  }

  ngAfterViewInit() {
    //console.log(this.presetYear);
    if (this.presetYear) {
      const currentYear:number = new Date().getFullYear();
      setTimeout(() => {
        this.valore = [{'lsAnno':currentYear}];
        this.valueChange(this.valore);
      })
    }
  }

  stringSize(text: string, size: number | undefined){
        if (text) {
            if (size && text.length > size && text.length > 0) {
                //text = text.substring(0, size);
                //text = text + "...";
            }
        }
        return text;
    }

  ngOnChanges(changes: SimpleChanges): void {
    this.reloader = this.reloader ? this.reloader : true;
    if (this.lista && changes['lista'] ) {
      this._lista = this.lista;
      if (this.lista != this._preLista && !this.reloader){
        this._preLista = this.lista;
        this.valore = undefined;
        setTimeout(() =>
          this.valueChange(this.valore), 100
        );
      }
      if (this.children && JSON.stringify(this.lista)===JSON.stringify(this.lista2)) {
        this.selectedValue = this.lista[0].value;
        if (this.invalid) {
          setTimeout(() => {
            this.valueChange(this.lista![0].value);
          });
        }
        this.empty = true;
      } else {
        this.empty=false;
        if (this.valore == "emptyItem" && !this.invalid){
          setTimeout(() => {
            this.valueChange(undefined);
          });
        }
      }
    }
    if (!this.valore && this.reloadValue){
      this.valore = this.reloadValue;
    }
  }


  compareOgg(ogg1: any, ogg2: any): boolean {
    //if (ogg2.length > 0) console.log(ogg1,ogg2);
    //console.log(ogg1,ogg2);
    return SharedFunction.objectCompare(ogg1, ogg2);
  }

  getToolTip(selected: any){
    return selected.viewValue;
  }

  valueChange(event: any | undefined) {
    if (event != null) {
      this.cambioValore.emit(event);
    } else {
      this.cambioValore.emit(this.nullDropDown ? null : []);
    }
  }

  truncateText(text: string){
    return text;
  }

  defaultSet(event: any | undefined) {

  }

  inputFilter() {
}

  preventSpaceAsEnter(event: KeyboardEvent) {
    if (event.key === ' ') {
      event.stopPropagation();
    }
  }

  setSelectAll(ev: MatOption) {
    let array;

    if (!this._selectAll) {
      this._selectAll = true;
      this.dropdown.options.forEach( item => item.select() );
      array = [...this.dropdown.value];
      array.shift();
    } else {
      this.dropdown.options.forEach( item => item.deselect() );
      array = [...this.dropdown.value];
      this._selectAll = false;
    }
    this.cambioValore.emit(array);
  }
}

@Pipe({
  name: 'filterInput',
  pure: false
})
export class FilterInput implements PipeTransform {
  transform(items: any[], filter: string | undefined): any {
    if (!items || !filter) {
      return items;
    }
    return items.filter(item => item['viewValue'].toLocaleLowerCase().includes(filter.toLocaleLowerCase() ));
  }
}
