import * as moment from 'moment';

export class MyDate extends Date {

  constructor(data: any) {
    super(data);
  }

  get timeSave() {
    return moment(this).format('HH.mm');
  }

  get timeToobar() {
    // 14:26
    return moment(this).format('HH:mm');
  }

  get giornoMeseAnno() {
    // 09/04/2020
    return moment(this).format('DD/MM/YYYY');
  }
  
  get timeGet() {
    // 09/04/2020
    return moment(this).format('HH.mm');
  }

  get giornoMeseAnnoOre() {
    // 09/04/2020 - 11:13
    return moment(this).format('DD/MM/YYYY[ - ]HH:mm');
  }

  get giornoMeseAnnoOreSec() {
    // 09/04/2020 - 11:13
    return moment(this).format('DD/MM/YYYY[ - ]HH:mm:ss');
  }





  get dateSave() {
    // 2000-12-12T12:12:12
    return moment(this).format('YYYY-MM-DD[T]HH:mm:ss');
  }

  get dateSaveZ() {
    // 2000-12-12T12:12:12
    return moment(this).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
  }

  get dateInizio() {
    // 2000-12-12T00:00:00
    return moment(this).format('YYYY-MM-DD[T]00:00:00');
  }

  get dateFine() {
    // 2000-12-23:59:59
    return moment(this).format('YYYY-MM-DD[T]23:59:59');
  }

  get subtract30() {
    return new MyDate(moment(this).subtract(30, 'days').toISOString());
  }

  subtractDays(numberDays: number) {
    return new MyDate(moment(this).subtract(numberDays, 'days').toISOString());
  }

  addDays(numberDays: number) {
    return new MyDate(moment(this).add(numberDays, 'days').toISOString());
  }

  lastDayOfMonth() {
    return new MyDate( moment(this).endOf('month') );
  }

  firstdayOfMounth() {
    return new MyDate( moment(this).startOf('month') );
  }

  meseName() {
    const mm = moment(this).locale('it');
    return mm.format('MMMM');
  }

  get dateExcel() {
    // 2019-08-12
    return moment(this).format('YYYY-MM-DD');
  }

}
