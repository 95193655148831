import {Paged} from "./Paged";
import {MyDate} from "./MyDate";
import {UnitaServizio} from "./UnitaServizio";
import {CategoriaLocali} from "./CategoriaLocali";
import {PuntoPrelievo} from "./PuntoPrelievo";
import {CentroCosto} from "./CentroCosto";
import {ListinoCosti} from "./ListinoCosti";


export class Stanza {

    id?: number;
    saCod: string;
    saDestinazione?: string;
    unitaServizio?: UnitaServizio;
    colore?: ListinoCosti;
    desStanza?: string;
    mq?: number;
    perc: number;
    dataInizioVal?: MyDate;
    dataFineVal?: MyDate;
    userdId?: number;
    saDesc?: string;
    puntoPrelievo?: PuntoPrelievo;
    usDesc: string;
    centroCosto?: CentroCosto;
    mqLordi: number;
    supVerticali: number;
    mcLordi: number;
    mqVetri: number;
    categoriaLocali?: CategoriaLocali;
    frequenza: string;
    intervento1: string;
    intervento2: string;
    intervento3: string;
    ripasso: string;
    dataInserimento: MyDate;

    constructor(data: any) {
        Object.assign(this, data);
        this.dataInizioVal = data.dataInizioVal ? new MyDate(data.dataInizioVal) : undefined;
        this.colore = data.colore ? new ListinoCosti(data.colore) : undefined;
        this.unitaServizio = data.unitaServizio ? new UnitaServizio(data.unitaServizio) : undefined;
        this.puntoPrelievo = data.puntoPrelievo ? new PuntoPrelievo(data.puntoPrelievo) : undefined;
        this.centroCosto = data.centroCosto ? new CentroCosto(data.centroCosto) : undefined;
        this.categoriaLocali = data.categoriaLocali ? new CategoriaLocali(data.categoriaLocali) : undefined;
      // this.tipoStanza = data.tipoStanza;
    }

    /* servono per essere visualizzati nei dropdown */
    get viewValue(): string {
        return this.saDesc ? this.saDesc : '';
    }
    get value(): Stanza {
        return this;
    }

    get desUnitaServizio() {
        return this.unitaServizio ? this.unitaServizio.viewValue : '';
    }

    get desPuntoPrelievo() {
        return this.puntoPrelievo ? this.puntoPrelievo.ppId + ' - ' + this.puntoPrelievo.viewValue : '';
    }

    get desCentroCosto() {
        return this.centroCosto ? this.centroCosto.ccId + ' - ' + this.centroCosto.viewValue : '';
    }

    get desColore() {
        return this.colore ? this.colore.viewValue : '';
    }


    get desCategoriaLocale() {
        return this.categoriaLocali ? this.categoriaLocali.viewValue : '';
    }


    get dataInizioValGGMMAA() {
        return this.dataInizioVal ? this.dataInizioVal.giornoMeseAnno : null;
    }

    get dataFineValGGMMAA() {
        return this.dataFineVal ? this.dataFineVal.giornoMeseAnno : null;
    }


    getOggCreate()  {
        return {
            id: 		          this.id,
            saCod: 		         this.saCod,
            saDestinazione: 	         this.saDestinazione,
            unitaServizio: this.unitaServizio ? { id: this.unitaServizio!.id!} : undefined,
            colore: 		       this.colore ? { colore: this.colore!.colore!} : undefined,
            mq: 		       this.mq,
            perc: 		       this.perc,
            dataInizioVal: this.dataInizioVal ? this.dataInizioVal.dateSave : null,
        //    dataFineVal: this.dataFineVal ? this.dataFineVal.dateSave : null,
            userdId: 		       this.userdId,
            saDesc: 		       this.saDesc,
            puntoPrelievo:         this.puntoPrelievo ? { ppId: this.puntoPrelievo!.ppId!} : undefined,
            usDesc: 		       this.usDesc,
            centroCosto:           this.centroCosto ? { ccId: this.centroCosto!.ccId!} : undefined,
            mqLordi: 		       this.mqLordi,
            supVerticali: 	       this.supVerticali,
            mcLordi: 		       this.mcLordi,
            mqVetri: 		       this.mqVetri,
            categoriaLocali: this.categoriaLocali ? { id: this.categoriaLocali!.id!} : undefined,
            frequenza: 		     this.frequenza,
            intervento1: 	     this.intervento1,
            intervento2: 	     this.intervento2,
            intervento3: 	     this.intervento3,
            ripasso: 		     this.ripasso,
            dataInserimento: 	     this.dataInserimento,

        };
    }

    getOggUpdate() {
        return this.getOggCreate();
    }



 }

export class StanzaPaged extends Paged {
    content: Stanza[];

    constructor(data: any) {
        super(data);

        this.content = data.content ? data.content.map((ts: any) => new Stanza(ts)) : [];
    }
}

