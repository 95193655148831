import {Paged} from "./Paged";

export class CentroCosto {
	id: string;
	new?: boolean;
    ccId?: string;
    descrizione?: string;

    constructor(data: any) {
        Object.assign(this, data);
    }

    getOggCreate() {
        return {
            ccId: this.ccId,
            descrizione: this.descrizione,
        };
    }

    getOggUpdate() {
        return this.getOggCreate();
    }



    get viewValue(): string {
        return this.descrizione ? this.descrizione : '';
    }
    get value(): CentroCosto {
        return this;
    }






}

export class CentroCostoPaged extends Paged {
    content: CentroCosto[];

    constructor(data: any) {
        super(data);

        this.content = data.content ? data.content.map((ts: any) => new CentroCosto(ts)) : [];
    }
}

