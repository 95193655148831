<!-- Input Time -->
<!-- The timepicker input -->
<input matTimepicker
       #t="matTimepicker"
       #time="ngModel"
       [ngClass]="{ 'input-error': invalid }"
       [strict]="false"
       id="timepicker-example"
       mode="24h"
       [ngModel]="valore"
       [disabled]="block ? block : false"
       [hidden]="hidden ? hidden: false"
       [placeholder]="testo ? testo : ''"
       name="time"
       (timeChange)="inputChange($event)">

    <i  class="far fa-clock" (click)="t.showDialog()"></i>


<!--<input matInput
       class="inputTime inputForm"
       type="time"
       step="900"
       [ngClass]="{ 'input-error': invalid }"
       [(ngModel)]="valore"
       [disabled]="block ? block : false"
       [hidden]="hidden ? hidden: false"
       [placeholder]="testo ? testo : ''"
       (keyup)="inputChange()">
-->
