import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-header-popup',
  templateUrl: './header-popup.component.html',
  styleUrls: ['./header-popup.component.scss']
})
export class HeaderPopupComponent implements OnInit {

  @Input() testo?: string;
  @Input() loading = false;

  @Output() close = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
