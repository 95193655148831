import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {Observable, Subject} from 'rxjs';
import {Snackbar} from "../../entities/Snackbar";
import {TestiService} from "../config/testi.service";
import {PopUpConferma} from "../../entities/PopUpConferma";
import {SharedFunction} from "./SharedFunction";
import {PopupConfermaComponent} from "../../shared-modules/popup-conferma/popup-conferma/popup-conferma.component";
import {PopUpInput} from "../../entities/PopUpInput";
import {PopupInputComponent} from "../../shared-modules/popup-input/popup-input/popup-input.component";


@Injectable({
  providedIn: 'root'
})
export class MsgService {
  testi: any;

  private _msgSnackbar$: Subject<Snackbar> = new Subject();
  get msgSnackbar$(): Subject<Snackbar> { return this._msgSnackbar$; }

  constructor(private testiService: TestiService,
              private dialog: MatDialog) {
  }

  private reloadTesti(testi: any){
  //TODO
    this.testi = testi;
  }

  genericMsgSuccess(testi: any, typeOgg: string, update?: boolean) {
    this.reloadTesti(testi);
    const summary = this.testi.msgSuccessSummary;
    const details = this.testi.correttamente;
    this._msgSnackbar$.next ({severity: 'success', summary: summary, msg: details} );
  }

  popupConferma(confermaMsg: PopUpConferma, testi: any): Observable<PopUpConferma> {
    this.reloadTesti(testi);
    confermaMsg.testi = testi;
    return this.openConferma(confermaMsg, testi);
  }

  popupConfermaPopup(confermaMsg: PopUpConferma, testi: any): Observable<PopUpConferma> {
    this.reloadTesti(testi);
    return this.openConfermaPopup(confermaMsg, testi);
  }

  private openConferma(confermaMsg: PopUpConferma, testi: any) {
    this.reloadTesti(testi);
    this.dialog.closeAll();
    confermaMsg.testi = testi;
    const dialog = SharedFunction.openPopUpConferma(this.dialog, confermaMsg, PopupConfermaComponent);
    return dialog.afterClosed();
  }

  private openConfermaPopup(confermaMsg: PopUpConferma, testi: any) {
    this.reloadTesti(testi);
        confermaMsg.testi = testi;
        const dialog = SharedFunction.openPopUpConferma(this.dialog, confermaMsg, PopupConfermaComponent);
        return dialog.afterClosed();
    }

  popupInputText(confermaMsg: PopUpInput): Observable<PopUpInput> {
    this.reloadTesti(this.testi);
    return this.openInputText(confermaMsg);
  }

  private openInputText(confermaMsg: PopUpInput) {
    this.reloadTesti(this.testi);
    this.dialog.closeAll();
    const dialog = SharedFunction.openPopUpInput(this.dialog, confermaMsg, PopupInputComponent);
    return dialog.afterClosed();
  }

  msgDeleteSucess(testi: any,typeOgg: string) {
    this.reloadTesti(testi);
    const summary = this.testi.msgDeleteSummary;
    let details = ''
    if (this.testi.msgDeleteDetails) {
      details = typeOgg + ' ' + this.testi.msgDeleteDetails;
    } else {
      details = typeOgg;
    }

    this._msgSnackbar$.next( {severity: 'success', summary: summary, detail: details, msg: details} );
  }

  msgError(error: any, testi: any, type?: string) {
    this.reloadTesti(testi);
    const summary = this.testi.errore;
    let details = this.checktypeError(error, testi);
    if (type && type === "keycloak") {
      details = this.checkmessageError(error, testi);
    }
    this._msgSnackbar$.next( {severity: 'error', summary: summary, detail: details, msg: details} );
  }

  msgStringError(error: string, testi: any, type?: string) {
    this.reloadTesti(testi);
    const summary = this.testi.errore;
    if (error.includes("Duplicate")){
      error = "Un file con lo stesso nome è già presente"
    }
    this._msgSnackbar$.next({severity: 'error', summary: summary, detail: error, msg: error});

  }

  msgStringErrorMaxChar(testi: any, type?: string) {
    this.reloadTesti(testi);
    const summary = this.testi.errore;
    const error = this.testi.maxchar;

    this._msgSnackbar$.next( {severity: 'error', summary: summary, detail: error, msg: error} );
  }

  msgAlert(msg: string, testi: any) {
    this.reloadTesti(testi);
    const summary = "Attenzione";
    const details = msg;

    this._msgSnackbar$.next( {severity: 'warn', summary, detail: details, msg: details} );
  }

  msgMailError(testi: any) {
    this.reloadTesti(testi);
    const summary = "Attenzione";
    const details = "Operazione Eseguita con Successo ma non è stato possibile inviare la mail"
    this._msgSnackbar$.next( {severity: 'warn', summary, detail: details, msg: details} );
  }

  msgSuccess(testi: any, typeOgg: string,  update?: boolean) {
    this.reloadTesti(testi);
    const summary = this.testi.msgSuccessSummary;
    let details = typeOgg + ' ';
    ////console.log(update);
    if (update) {
      details = details + this.testi.msgSuccessUpdate;
    } else {
      details = details + this.testi.msgSuccessCreate;
    }
    this._msgSnackbar$.next ({severity: 'success', summary: summary, msg: details} );
  }

  msg(msg: string, type = 'success', testi: any) {
    this.reloadTesti(testi);
    const summary = this.testi.msgSuccessSummary;
    this._msgSnackbar$.next ({severity: 'success', summary: summary, msg: msg} );
  }

  private checktypeError(error: any, testi: any) {
    this.reloadTesti(testi);
    let msg;
    if (error.error.message) {
      msg = error.error.message;
    } else {
      if (error.message){
        msg = error.message;
      } else {
        msg = this.testi.msgAllarmMessage3;
      }
    }

    return msg;
  }

  private checkmessageError(error: any, testi: any) {
    this.reloadTesti(testi);
    let msg;
    if (error.error) {
      msg = error.error.errorMessage;
    } else {
      msg = this.testi.msgAllarmMessage3;
    }

    if (msg === "User exists with same username") {
      msg = "Username già presente a sistema"
    }
    if (msg === "User exists with same email") {
      msg = "E-Mail già presente a sistema"
    }
    return msg;
  }

  private checkError(errorMsg: string, testi: any) {
    this.reloadTesti(testi);
    if (errorMsg.includes('violato vincolo di integrità') ) {
      const vincolo = errorMsg.match(/=\(.*\)/g);
      if (vincolo) {
        const name = vincolo[0].slice(2, -1);
        return `${name} ${this.testi.msgAllarmMessage1}`;
      }
    }

    return this.testi.msgAllarmMessage3;
  }

}
