import {FormControl, Validators} from '@angular/forms';
import {TableName} from "../../entities/table/TableName";
import {Form} from "../../entities/Form";
import {FormInputField} from "../../entities/form/FormInputField";
import {Injectable} from "@angular/core";


@Injectable({
  providedIn: 'root'
})

export class TableRichiesteCols {
  baseDate =  new Date();
  today = new Date();
  tomorrow =  new Date(this.baseDate.setDate(this.baseDate.getDate() + 1));

  //Definisce i titoli della Form Richieste: il name è l'unione di ruolo+statoRichiesta, il value è il testo scelto
  private formTitles(testi: any) {
    return[
      {name:"caposalaNuova", value: testi.caposalaNuova},
      {name:"operatoreNuova", value: testi.caposalaNuova},
      {name:"responsabileNuova", value: testi.caposalaNuova},
      {name:"caposalaINSERITA", value: testi.caposalaInserita},
      {name:"operatoreINSERITA", value: testi.operatoreRivendica},
      {name:"operatoreAPERTA", value: testi.operatoreRivendica},
      {name:"responsabileRESPINTA IN ATTESA DI CONFERMA", value: testi.responsabileRifiuto},
      {name:"gestoreASSEGNATA", value: testi.gestoreAccetta},
      {name:"operatorePRESA IN CARICO OPERATORE", value: testi.operatoreApprova},
      {name:"gestorePRESA IN CARICO GESTORE", value: testi.gestoreRichiesta},
      {name:"caposalaCHIUSA", value: testi.caposalaContesta},
      {name:"responsabileCHIUSA", value: testi.caposalaContesta},
      {name:"caposalaCHIUSADEF", value: "CHIUSA"},
      {name:"responsabileCHIUSADEF", value: "CHIUSA"},
      {name:"operatoreASSEGNATA AL GESTORE", value: testi.operatoreRiprendi}
    ];
  }

  //Definisce i bottoni presenti nella form: il name è l'unione di ruolo+statoRichiesta, il value è il pattern scelto
  private formButtons(testi: any) {
    return[
      {name:"caposalaNuova", showCancel: true, showSave: true, showDelete: false, blockSave: false, blockDelete: false, blockCancel: false, saveForm: 'save',cancel: 'Esci', deleteForm: '', tooltipSave: '', tooltipDelete: ''},
      {name:"responsabileNuova", showCancel: true, showSave: true, showDelete: false, blockSave: false, blockDelete: false, blockCancel: false, saveForm: 'save',cancel: 'Esci', deleteForm: '', tooltipSave: '', tooltipDelete: ''},
      {name:"operatoreNuova", showCancel: true, showSave: true, showDelete: false, blockSave: false, blockDelete: false, blockCancel: false, saveForm: 'save',cancel: 'Esci', deleteForm: '', tooltipSave: '', tooltipDelete: ''},
      {name:"operatoreINSERITA", showCancel: true, showSave: true, showDelete: false,  blockSave: false, blockDelete: false, blockCancel: false, textSave: testi.rivendica, saveForm: 'rivendica' , cancel: 'Esci', deleteForm: '', tooltipSave: '', tooltipDelete: ''},
      {name:"operatoreAPERTA", showCancel: true, showSave: true, showDelete: false, blockSave: false, blockDelete: false, blockCancel: false,textSave: testi.rivendica, saveForm: 'rivendica' , cancel: 'Esci', deleteForm: '', tooltipSave: '', tooltipDelete: ''},
      {name:"responsabileRESPINTA IN ATTESA DI CONFERMA", showCancel: true, showSave:true, showDelete: true, blockSave: false, blockDelete: false, blockCancel: false, textDelete: testi.NCRifiuto, textSave: testi.CRifiuto, saveForm: 'confermaRifiuto', deleteForm: 'nonConfermaRifiuto', cancel: 'Esci', tooltipSave: '', tooltipDelete: ''},
      {name:"gestoreASSEGNATA AL GESTORE", showCancel: true, showSave: true, showDelete: false, blockSave: false, blockDelete: false, blockCancel: false, saveForm: 'accetta', textSave: testi.accetta, cancel: 'Esci' , deleteForm: '', tooltipSave: '', tooltipDelete: ''},
      {name:"operatorePRESA IN CARICO OPERATORE", showCancel: true, showSave:false, showDelete: false,  blockSave: false, blockDelete: false, blockCancel: false,textDelete: testi.rifiuta, textSave: testi.approva, saveForm: 'approva', deleteForm: 'rifiuta', cancel: 'Esci', tooltipSave: '', tooltipDelete: ''},
      {name:"gestorePRESA IN CARICO GESTORE", showCancel: true, showSave:true, showDelete: true,  blockSave: false, blockDelete: false, blockCancel: false,textDelete: testi.RRichiesta, textSave: testi.CRichiesta, saveForm: 'crichiesta', deleteForm: 'rrichiesta', cancel: 'Esci', tooltipSave: '', tooltipDelete: ''},
      {name:"gestorePRESA IN CARICO GESTOREChiudi", showCancel: true, showSave:true, showDelete: true,  blockSave: false, blockDelete: true, blockCancel: false,textDelete: testi.RRichiesta, textSave: testi.CRichiesta, saveForm: 'crichiesta', deleteForm: 'rrichiesta', cancel: 'Esci', tooltipSave: '', tooltipDelete: ''},
      {name:"caposalaCHIUSA", showCancel: true, showSave: true, showDelete: false, blockSave: false, blockDelete: false, blockCancel: false,saveForm: 'contesta', textSave: testi.contesta, cancel: 'Esci' , deleteForm: '', tooltipSave: '', tooltipDelete: ''},
      {name:"caposalaCHIUSADEF", showCancel: true, showSave: false, showDelete: false, blockSave: false, blockDelete: false, blockCancel: false,saveForm: 'contesta', textSave: testi.contesta, cancel: 'Esci' , deleteForm: '', tooltipSave: '', tooltipDelete: ''},
      {name:"responsabileCHIUSADEF", showCancel: true, showSave: false, showDelete: false, blockSave: false, blockDelete: false, blockCancel: false,saveForm: 'contesta', textSave: testi.contesta, cancel: 'Esci' , deleteForm: '', tooltipSave: '', tooltipDelete: ''},
      {name:"responsabileCHIUSA", showCancel: true, showSave: true, showDelete: false, blockSave: false, blockDelete: false, blockCancel: false,saveForm: 'contesta', textSave: testi.contesta, cancel: 'Esci' , deleteForm: '', tooltipSave: '', tooltipDelete: ''},
      {name:"operatoreASSEGNATA AL GESTORE",showCancel: true, showSave: true, showDelete: false,  blockSave: false, blockDelete: false, blockCancel: false,saveForm: 'riprendi', textSave: testi.riprendiAss, cancel: 'Esci' , deleteForm: '', tooltipSave: '', tooltipDelete: ''},
      {name:"operatorePRESA IN CARICO OPERATORERifiuta", showCancel: true, showSave:true, showDelete: true,  blockSave: true, blockDelete: false, blockCancel: false,textDelete: testi.rifiuta, textSave: testi.approva, saveForm: 'approva', deleteForm: 'rifiuta', cancel: 'Esci', tooltipSave: '', tooltipDelete: ''},
      {name:"operatorePRESA IN CARICO OPERATOREAccetta", showCancel: true, showSave:true, showDelete: true,  blockSave: false, blockDelete: true, blockCancel: false,textDelete: testi.rifiuta, textSave: testi.approva, saveForm: 'approva', deleteForm: 'rifiuta', cancel: 'Esci', tooltipSave: '', tooltipDelete: ''},
      {name:"operatorePRESA IN CARICO OPERATORE", showCancel: true, showSave:true, showDelete: true, blockSave: true, blockDelete: true, blockCancel: false, textDelete: testi.rifiuta, textSave: testi.approva, saveForm: 'approva', deleteForm: 'rifiuta', cancel: 'Esci',
        tooltipSave: "Per Accettare inserire il Gestore e (facoltativa) la Data Scadenza", tooltipDelete: "Per rifiutare inserire Tipo Rifiuto "},
    ];
  }

  //Definisce i campi sbloccati (editabili) nella form: il name è l'unione di ruolo+statoRichiesta, il value è un array con la prop dei campi
  unblock: Form[]= [
    {name:"caposalaImpianto", value: ["tipoRichiesta", "tipoInterventi", "urgenza", "unitaServizio", "impianto", "stanza", "descReferente", "desTicket", "flgEmail", "docs"]},
    {name:"responsabileNewImpianto", value: ["tipoRichiesta", "tipoInterventi", "urgenza", "unitaServizio", "impianto", "stanza", "descReferente", "desTicket", "flgEmail", "docs"]},
    {name:"operatoreNewImpianto", value: ["tipoRichiesta", "tipoInterventi", "urgenza", "unitaServizio", "impianto", "stanza", "descReferente", "desTicket", "flgEmail", "docs"]},
    {name:"operatoreImpianto", value: ["tipoInterventi", "tipoRifiuto","impianto","utenteGestore","dataAperturaEff","dataScadenza","note", "docs"]},
    {name:"operatoreNonImpianto", value: ["tipoInterventi", "tipoRifiuto","utenteGestore","dataAperturaEff","dataScadenza","note", "docs"]},
    {name:"caposalaNuova", value: ["tipoRichiesta", "tipoInterventi", "urgenza", "unitaServizio", "stanza", "descReferente", "desTicket", "flgEmail", "docs"]},
    {name:"operatoreNuova", value: ["tipoRichiesta", "tipoInterventi", "urgenza", "unitaServizio", "stanza", "descReferente", "desTicket", "flgEmail", "docs"]},
    {name:"responsabileNuova", value: ["tipoRichiesta", "tipoInterventi", "urgenza", "unitaServizio", "stanza", "descReferente", "desTicket", "flgEmail", "docs"]},
    {name:"operatorePRESA IN CARICO OPERATORE", value: ["tipoInterventi","tipoRifiuto","utenteGestore","dataAperturaEff","dataScadenza","note", "docs"]},
    {name:"operatorePRESA IN CARICO OPERATORERifiuta", value: ["tipoRifiuto","dataAperturaEff","note", "docs"]},
    {name:"operatorePRESA IN CARICO OPERATOREAccetta", value: ["tipoInterventi","dataAperturaEff","note", "dataScadenza", "utenteGestore", "docs"]},
    {name:"caposalaCHIUSA", value: ["descReferente","desTicket","urgenza","flgEmail", "docs"]},
    {name:"responsabileCHIUSA", value: ["descReferente","desTicket","urgenza","flgEmail", "docs"]},
    {name:"caposalaCHIUSADEF", value: []},
    {name:"responsabileCHIUSADEF", value: []},
    {name:"gestoreASSEGNATA", value: ["note", "docs"]},
    {name:"gestorePRESA IN CARICO GESTORE", value: ["dataInizioIntervento", "note", "docs"]},
    {name:"responsabileRESPINTA IN ATTESA DI CONFERMA", value: ["note", "docs"]}
  ];

  //Definisce i campi nascosti nella form: il name è l'unione di ruolo+statoRichiesta, il value è un array con la prop dei campi
  hidden: Form[] = [
    {name:"caposalaImpianto", value:["utenteAss", "numProto","statoTicket","dataScadenza","dataAperturaSys","dataApertura","dataChiusuraSys","dataChiusuraEff","utenteGestore","dataInizio"]},
    {name:"responsabileNewImpianto", value:["utenteAss", "numProto","statoTicket","dataScadenza","dataAperturaSys","dataApertura","dataChiusuraSys","dataChiusuraEff","utenteGestore","dataInizio"]},
    {name:"operatoreNewImpianto", value:["utenteAss", "numProto","statoTicket","dataScadenza","dataAperturaSys","dataApertura","dataChiusuraSys","dataChiusuraEff","utenteGestore","dataInizio"]},
    {name:"caposalaNuova", value:["utenteAss", "numProto","statoTicket","dataScadenza","dataAperturaSys","dataApertura","dataChiusuraSys","dataChiusuraEff","utenteGestore","dataInizio"]},
    {name:"operatoreNuova", value:["utenteAss", "numProto","statoTicket","dataScadenza","dataAperturaSys","dataApertura","dataChiusuraSys","dataChiusuraEff","utenteGestore","dataInizio"]},
    {name:"responsabileNuova", value:["utenteAss", "numProto","statoTicket","dataScadenza","dataAperturaSys","dataApertura","dataChiusuraSys","dataChiusuraEff","utenteGestore","dataInizio"]},
    {name:"caposalaINSERITA", value:["dataScad","dataChiusuraSys","dataChiusuraEff","gestore","dataInizioIntervento"]},
    {name:"caposalaCHIUSA", value: ["dataScad","dataAperturaSys","dataAperturaEff","dataChiusuraSys","dataChiusuraEff","gestore","dataInizioIntervento"]},
    {name:"responsabileCHIUSA", value: ["dataScad","dataAperturaSys","dataAperturaEff","dataChiusuraSys","dataChiusuraEff","gestore","dataInizioIntervento"]},
    {name:"caposalaCHIUSADEF", value: ["dataScad","dataAperturaSys","dataAperturaEff","dataChiusuraSys","dataChiusuraEff","gestore","dataInizioIntervento"]},
    {name:"responsabileCHIUSADEF", value: ["dataScad","dataAperturaSys","dataAperturaEff","dataChiusuraSys","dataChiusuraEff","gestore","dataInizioIntervento"]},
    {name:"operatoreINSERITA", value: ["dataChiusuraSys","dataChiusuraEff","gestore","dataInizioIntervento"]},
    {name:"operatoreAPERTA", value: ["dataChiusuraSys","dataChiusuraEff","gestore","dataInizioIntervento"]},
    {name:"operatorePRESA IN CARICO OPERATORE", value: ["dataChiusuraSys","dataChiusuraEff","dataInizioIntervento"]},
    {name:"operatorePRESA IN CARICO OPERATORERifiuta", value: ["dataChiusuraSys","dataChiusuraEff","dataInizioIntervento"]},
    {name:"operatorePRESA IN CARICO OPERATOREAccetta", value: ["dataChiusuraSys","dataChiusuraEff","dataInizioIntervento"]},
    {name:"responsabileRESPINTA IN ATTESA DI CONFERMA", value: ["dataChiusuraSys","dataChiusuraEff","dataInizioIntervento"]}
  ];

  //Definisce i campi della tabella Richieste
  static getRichiesteList(testi: any): TableName[] {
    return [
      {name: testi.prot, filter: '', order: 'idTicket', prop: 'idTicket', size: 4 , tooltip:true },
      {name: testi.stato, filter: '', order: 'stato', prop: 'stato', size: 8 , tooltip:true },
      {name: testi.descrizione, filter: '', order: 'desTicket', prop: 'desTicket', size: 7, tooltip: true },
      {name: testi.dataApEff, filter: '', order: 'dataAp', prop: 'dataAp', size: 3 },
      {name: testi.tipoServizio, filter: '', order: 'tipoServizio', prop: 'tipoServizio', size: 4 , tooltip:true},
      {name: testi.note, filter: '', order: 'note', prop: 'note', size: 8, tooltip: true },
      {name: testi.tipoUnitaServizio, filter: '', order: 'tipoUnitaServizio', prop: 'tipoUnitaServizio', size: 10 , tooltip:true},
      {name: testi.impianto, filter: '', order: 'nomeImpianto', prop: 'nomeImpianto', size: 8 , tooltip:true},
      {name: testi.urgenza, filter: '', order: 'nomeUrgenza', prop: 'nomeUrgenza', size: 6 , tooltip:true},
      {name: testi.gestore, filter: '', order: 'gestore', prop: 'gestore', size: 6, tooltip: true },
      {name: testi.stanza, filter: '', order: 'stanza', prop: 'stanza', size: 5 , tooltip:true},
      {name: testi.tipoRifiuto, filter: '', order: 'nomeRifiuto', prop: 'nomeRifiuto', size: 6, tooltip:true },
      {name: testi.tipoRichiesta, filter: '', order: 'nomeRichiesta', prop: 'nomeRichiesta', size: 10, tooltip: true },
      {name: '', filter: '', order: 'controlli', prop: 'controlli', size:8 },
    ];
  }

  //Definisce i campi della tabella Storico Richiesta
  static getStoricoRichiesteList(testi: any): TableName[] {
    return [
      {name: testi.dataMovimento, filter: '', order: 'dataStMov', prop: 'dataStMov', size: 9 , tooltip:true},
      {name: testi.stato, filter: '', order: 'statoStoricoMov', prop: 'statoStoricoMov', size: 4, tooltip: true },
      {name: testi.descrizione, filter: '', order: 'descTicket', prop: 'descTicket', size: 7 , tooltip:true},
      {name: testi.note, filter: '', order: 'note', prop: 'note', size: 7 , tooltip:true},
      {name: testi.tipoUnitaServizio, filter: '', order: 'unitaServizio', prop: 'unitaServizio', size: 8, tooltip:true },
      {name: testi.impianto, filter: '', order: 'impianto', prop: 'impianto', size: 8, tooltip: true },
      {name: testi.urgenza, filter: '', order: 'urgenza', prop: 'urgenza', size: 6 , tooltip:true},
      {name: testi.stanza, filter: '', order: 'stanza', prop: 'stanza', size: 6 , tooltip:true},
      {name: testi.userMovimento, filter: '', order: 'userStoricoMov', prop: 'userStoricoMov', size: 4 , tooltip:true},
      {name: testi.referente, filter: '', order: 'referente', prop: 'referente', size: 6, tooltip: true },
      {name: testi.tipoRichiesta, filter: '', order: 'tipoRichiesta', prop: 'tipoRichiesta', size: 8, tooltip: true },
      {name: testi.richiestaRif, filter: '', order: 'idTicketRiferimento', prop: 'idTicketRiferimento', size: 4 , tooltip:true}
    ];
  }

  static getRichiesteListMobile(testi: any): TableName[] {
    return [
      {name: testi.prot, filter: '', order: 'idTicket', prop: 'idTicket', size: 4 , tooltip:true },
      {name: testi.stato, filter: '', order: 'stato', prop: 'stato', size: 4 , tooltip:true },
      {name: testi.descrizione, filter: '', order: 'desTicket', prop: 'desTicket', size: 28, tooltip: true },
      {name: "Data Ap.", filter: '', order: 'dataAp', prop: 'dataAp', size: 2 },
      {name: testi.tipoServizio, filter: '', order: 'tipoServizio', prop: 'tipoServizio', size: 4 , tooltip:true},
      {name: testi.note, filter: '', order: 'note', prop: 'note', size: 8, tooltip: true },
      {name: testi.tipoUnitaServizio, filter: '', order: 'tipoUnitaServizio', prop: 'tipoUnitaServizio', size: 10 , tooltip:true},
      {name: testi.impianto, filter: '', order: 'nomeImpianto', prop: 'nomeImpianto', size: 8 , tooltip:true},
      {name: "Urg.", filter: '', order: 'nomeUrgenza', prop: 'nomeUrgenza', size: 3 , tooltip:true},
      {name: testi.gestore, filter: '', order: 'gestore', prop: 'gestore', size: 6, tooltip: true },
      {name: testi.stanza, filter: '', order: 'stanza', prop: 'stanza', size: 5 , tooltip:true},
      {name: "T.Rifiuto", filter: '', order: 'nomeRifiuto', prop: 'nomeRifiuto', size: 3, tooltip:true },
      {name: "T.Rich.", filter: '', order: 'nomeRichiesta', prop: 'nomeRichiesta', size: 3, tooltip: true },
      {name: '', filter: '', order: 'controlli', prop: 'controlli', size:8 },
    ];
  }

  static getStoricoRichiesteListMobile(testi: any): TableName[] {
    return [
      {name: testi.firstname, filter: '', order: '', prop: 'firstName', size: 50 },
      {name: testi.lastname, filter: '', order: '', prop: 'lastName', size: 50 }
    ];
  }

  ///Gestisce i titoli delle Form
  formTitle(testi:any | undefined, pattern: string){
    let res = testi.dettaglioRichiesta;
    for (let title of this.formTitles(testi)){
      if (title.name === pattern){
        res = title.value;
      }
    }
    return res;
  }

  ///Gestisce la costruzione dei Button nella form
  formButton(testi:any | undefined, pattern: string){
    let res = {showCancel: true, showSave:false, showDelete: false , blockSave: false, blockDelete: false, textCancel: testi.chiudi, textDelete: '', textSave: '', saveForm: '', deleteForm: '', cancel: 'Esci', tooltipDelete: '',tooltipSave: ''};
    for (let btn of this.formButtons(testi)){
      if (btn.name === pattern){
        res = {
          showCancel: btn.showCancel,
          showSave: btn.showSave,
          showDelete: btn.showDelete,
          blockSave: btn.blockSave,
          blockDelete: btn.blockDelete,
          textDelete: btn.textDelete,
          textCancel: testi.chiudi,
          textSave: btn.textSave,
          deleteForm: btn.deleteForm,
          saveForm: btn.saveForm,
          cancel: btn.cancel,
          tooltipSave: btn.tooltipSave,
          tooltipDelete: btn.tooltipDelete
        }
      }
    }
    return res;
  }

  //Gestione Form Utente (PopUp): definisce le singole RIGHE di cui si compone la form, groupItems definisce la lista dei singoli input.
  //addProp: aggiunge ai singoli input le proprietà hidden/unblocked secondo le impostazioni precedenti
  groups(instance: string, testi:any | undefined, pattern: string) {
    return [
      { title: '', header: 'one', group: 'line1',  expand: true, groupItems: this.addProp(this.list1(testi), pattern)},
      { title: '', header: 'two', group: 'line2',  expand: true, groupItems: this.addProp(this.list2(testi), pattern)},
      { title: '', header: 'three', group: 'line3',  expand: true, groupItems: this.addProp(this.list3(testi),pattern)},
      { title: '', header: 'four', group: 'line4',  expand: true, groupItems: this.addProp(this.list4(testi), pattern)},
      { title: '', header: 'five', group: 'line5',  expand: true, groupItems: this.addProp(this.list5(testi), pattern)},
      { title: '', header: 'six', group: 'line6',  expand: true, groupItems: this.addProp(this.list6(testi,pattern), pattern)},
      { title: '', header: 'seven', group: 'line7',  expand: true, groupItems: this.addProp(this.list7(testi), pattern)},
      { title: '', header: 'eigth', group: 'line8',  expand: true, groupItems: this.addProp(this.list8(instance, testi,pattern), pattern)},
      { title: '', header: 'nine', group: 'line9',  expand: true, groupItems: this.addProp(this.list9(testi), pattern)},
      { title: '', header: 'ten', group: 'line10',  expand: true, groupItems: this.addProp(this.list10(testi), pattern)},
      { title: '', header: 'eleven', group: 'line11',  expand: true, groupItems: this.addProp(this.list11(testi), pattern)  },
      { title: '', header: 'twelve', group: 'line12',  expand: true, groupItems: this.addProp(this.list12(testi), pattern)  },
      { title: '', header: 'thirteen', group: 'line13',  expand: true, groupItems: this.addProp(this.list13(testi), pattern)  },
      { title: '', header: 'fourteen', group: 'line14',  expand: true, groupItems: this.addProp(this.list14(testi), pattern)  }
    ];
  }

  groupsNew(instance:string, testi:any | undefined, pattern: string) {
    return [
      { title: '', header: 'one', group: 'line1',  expand: true, groupItems: this.addProp(this.list1(testi), pattern)},
      { title: '', header: 'two', group: 'line2',  expand: true, groupItems: this.addProp(this.list2(testi), pattern)},
      { title: '', header: 'three', group: 'line3',  expand: true, groupItems: this.addProp(this.list3New(testi),pattern)},
      { title: '', header: 'four', group: 'line4',  expand: true, groupItems: this.addProp(this.list4(testi), pattern)},
      { title: '', header: 'five', group: 'line5',  expand: true, groupItems: this.addProp(this.list5New(testi), pattern)},
      { title: '', header: 'six', group: 'line6',  expand: true, groupItems: this.addProp(this.list6(testi,pattern), pattern)},
      { title: '', header: 'seven', group: 'line7',  expand: true, groupItems: this.addProp(this.list7(testi), pattern)},
      { title: '', header: 'eigth', group: 'line8',  expand: true, groupItems: this.addProp(this.list8New(instance,testi,pattern), pattern)},
      { title: '', header: 'nine', group: 'line9',  expand: true, groupItems: this.addProp(this.list9(testi), pattern)},
      { title: '', header: 'ten', group: 'line10',  expand: true, groupItems: this.addProp(this.list10(testi), pattern)},
      { title: '', header: 'eleven', group: 'line11',  expand: true, groupItems: this.addProp(this.list11(testi), pattern)  },
      { title: '', header: 'twelve', group: 'line12',  expand: true, groupItems: this.addProp(this.list12(testi), pattern)  },
      { title: '', header: 'thirteen', group: 'line13',  expand: true, groupItems: this.addProp(this.list13(testi), pattern)  },
      { title: '', header: 'fourteen', group: 'line14',  expand: true, groupItems: this.addProp(this.list14(testi), pattern)  }
    ];
  }


  //Definisce il contenuto della prima riga dichiarata sopra
  list1(testi: any): FormInputField[] {
    return [
      {prop: 'tipoRichiesta', text: testi.tipoRichiesta, reloader: true, input: 'dropdown', list: 'tipoRichiestaList',required: true, validator: this.validateDrop(testi)},
      {prop: 'idTicketRif', text: testi.richiestaRif, reloader: true, input: 'text', required: false}
    ];
  }

  //Definisce il contenuto della seconda riga dichiarata sopra
  list2(testi: any): FormInputField[] {
    return [
      {prop: 'tipoInterventi', text: testi.tipoIntervento, reloader: true, input: 'dropdown', list: 'tipoIntervento',required: true,validator: this.validateImpianto(testi) },
      {prop: 'statoTicket', text: testi.stato, reloader: true, input: 'text', required: false}
    ];
  }

  list3(testi: any): FormInputField[] {
    return [
      {prop: 'tipoRifiuto', text: testi.tipoRifiuto, reloader: true, input: 'dropdown', list: 'rifiutoList', required: false}, //, validator: this.validateRifiutoDrop(testi)},
      {prop: 'dataScadenza', text: testi.dataScadenza, reloader: true, input: 'date', clear: true, required: false, minDate: this.today } //, validator: this.validateDateDrop(testi)
    ];
  }

  list3New(testi: any): FormInputField[] {
    return [
      {prop: 'tipoRifiuto', text: testi.tipoRifiuto, reloader: true, input: 'dropdown', list: 'rifiutoList', required: false},
      {prop: 'dataScadenza', text: testi.dataScadenza, reloader: true, input: 'date', clear: true, required: false , minDate: this.today}
    ];
  }


  list4(testi: any): FormInputField[] {
    return [
      {prop: 'urgenza', text: testi.urgenza, reloader: true, input: 'dropdown', list: 'tipoUrgenza', required: true, validator: this.validateDrop(testi)},
      {prop: 'blank', text: '', reloader: true, input: 'blank', required: false },
    ];
  }


  list5(testi: any): FormInputField[] {
    return [
      {prop: 'unitaServizio', text: testi.unitaServizio, reloader: true, input: 'dropdown', list: 'unitaServizioFullList', required: true, validator: this.validateUSDrop(testi)},
      {prop: 'dataApertura', text: testi.dataApEff, reloader: true, input: 'date', required: false},
    ];
  }

  list5New(testi: any): FormInputField[] {
    return [
      {prop: 'unitaServizio', text: testi.unitaServizio, reloader: true, input: 'dropdown', list: 'unitaServizioList', required: true, validator: this.validateUSDrop(testi)},
      {prop: 'dataApertura', text: testi.dataApEff, reloader: true, input: 'date', required: false},
    ];
  }

  list6(testi: any, pattern:string): FormInputField[] {
    let testo = "";
    if (pattern.includes("CHIUSA")){
      testo = testi.dataChiusura;
    } else {
      testo = testi.dataModifica;
    }
    return [
      {prop: 'impianto', text: testi.impianto, reloader: true, input: 'dropdown', list: "tipoImpianto", required: true, validator: this.validateDrop(testi)},
      {prop: 'dataMod', text: testo, reloader: true, input: 'date', required: false},
    ];
  }


  list7(testi: any): FormInputField[] {
    return [
      {prop: 'stanza', text: testi.stanze, reloader: true, input: 'dropdown', list: 'stanze', required: false, validator: this.validateChildDrop(testi), children: true, list2: 'checkStanze'},
      {prop: 'blank', text: '', reloader: true, input: 'blank', required: false },
    ];
  }

  list8(instance: string, testi: any, pattern: string): FormInputField[] {
    if (instance.toLowerCase().includes("vimercate")){
      return [
        {prop: 'descReferente', text: "Richiedente", reloader: true, input: 'text', required: true},
        {prop: 'utenteGestore', text: testi.gestore, reloader: true, input: 'dropdown', required: false, list: "utentiGestore"} //, validator: this.validateGestore(testi, pattern)},
      ];
    } else {
      return [
        {prop: 'descReferente', text: testi.referente, reloader: true, input: 'text', required: false},
        {prop: 'utenteGestore', text: testi.gestore, reloader: true, input: 'dropdown', required: false, list: "utentiGestore"} //, validator: this.validateGestore(testi, pattern)},
      ];
    }
  }

  list8New(instance: string, testi: any, pattern: string): FormInputField[] {
    if (instance.toLowerCase().includes("vimercate")){
      return [
        {prop: 'descReferente', text: "Richiedente", reloader: true, input: 'text', required: true},
        {prop: 'utenteGestore', text: testi.gestore, reloader: true, input: 'dropdown', required: false, list: "utentiGestore"},
      ];
    } else {
      return [
        {prop: 'descReferente', text: testi.referente, reloader: true, input: 'text', required: false},
        {prop: 'utenteGestore', text: testi.gestore, reloader: true, input: 'dropdown', required: false, list: "utentiGestore"},
      ];
    }
  }


  list9(testi: any): FormInputField[] {
    return [
      {prop: 'utenteAss', text: testi.utenteAss, reloader: true, input: 'text', required: false},
      {prop: 'blank', text: '', reloader: true, input: 'blank', required: false },
    ];
  }

  list10(testi: any): FormInputField[] {
    return [
      {prop: 'blank', text: '', reloader: true, input: 'blank', required: false },
      {prop: 'dataInizioIntervento', text: testi.dataInizio, reloader: true, input: 'date', required: false, clear:true , maxDate: this.today},
    ];
  }

  list11(testi: any): FormInputField[] {
    return [
      {prop: 'desTicket', text: testi.descrizione, reloader: true, input: 'textarea', required: true},
    ];
  }

  list12(testi: any): FormInputField[] {
    return [
      {prop: 'note', text: testi.note, reloader: true, input: 'textarea', required: false},
    ];
  }

  list13(testi: any): FormInputField[] {
    return [
      {prop: 'flgEmail', text: testi.email, reloader: true, input: 'slide', required: false},
      {prop: 'blank', text: '', reloader: true, input: 'blank', required: false },
    ];
  }

  list14(testi: any): FormInputField[] {
    return [
      {prop: 'dms', text: testi.documenti, reloader: true, input: 'documents', required: false, description: testi.descrizioneDocumenti},
    ];
  }

  //Aggiunge i valori hidden e unblocked alle singole liste, applica prima il visible e poi il blocked perché se il controllo è bloccato/invisibile resta visibile
  private addProp(list: FormInputField[], pattern: string): FormInputField[]{
    for (let item of list){
      if (this.visible(pattern,item.prop)) {item.hidden = true} else {
        if (!this.editable(pattern, item.prop)) {
          item.block = true
        }
      }
    }
    return list;
  }

  //Gestice la lista dei campi bloccati
  private editable(comb: string, field: string): boolean {
    let res = false;
    for (let edit of this.unblock){
      if (edit.name === comb){
        res = edit.value.some(x => x === field);
      }
    }
    return res;
  }


  //Gestice la lista dei campi nascosti
  private visible(comb: string, field: string) {
    let res = false;
    for (let hide of this.hidden){
      if (hide.name === comb){
        res = hide.value.some(x => x === field);
      }
    }
    return res;
  }

  //Validator DropDown

  validateDrop(testi: any) {
    return  [
      {value: this.validateDropDown , errorMsg: testi.campoObbligatorio }
    ];
  }

  validateDropDown(formControl: FormControl) {
    let isValid = false;
    const value = Object.keys(formControl.value).length;
    if (value > 0) {
      isValid = true;
    }

    return isValid ? null : {
      userValid: {
        valid: false
      }
    };
  }
  validateUSDrop(testi: any) {
    return  [
      {value: this.validateUSDropDown , errorMsg: testi.campoObbligatorio }
    ];
  }

  validateUSDropDown(formControl: FormControl) {
    const parent = formControl.parent!;
    const stanza = parent.get("stanza")!;
    stanza.setValue('');
    stanza.updateValueAndValidity({onlySelf:true});
    let isValid = false;
    const value = Object.keys(formControl.value).length;
    if (value > 0) {
      isValid = true;
    }

    return isValid ? null : {
      userValid: {
        valid: false
      }
    };
  }

  validateRifiutoDrop(testi: any) {
    return  [
      {value: this.validateRifiutoDropDown , errorMsg: testi.campoObbligatorio }
    ];
  }


  //Validatore DropDown Figlie
  validateChildDrop(testi: any) {
    return  [
      {value: this.validateChildDropDown , errorMsg: testi.campoObbligatorio }
    ];
  }


  validateChildDropDown(formControl: FormControl) {
    let isValid = false;
    const value = Object.keys(formControl.value).length;
    if (value > 0) {
      isValid = true;
    }
    return isValid ? null : {
      userValid: {
        valid: false
      }
    };
  }



  //Validatore Impianto AD-HOC
  validateImpianto(testi: any) {
    return  [
      {value: this.validateImpiantoDropDown , errorMsg: testi.campoObbligatorio }
    ];
  }


  validateImpiantoDropDown(formControl: FormControl){
    const validator = function(formControl: FormControl){
      let isValid = false;
      const value = Object.keys(formControl.value).length;
      if (value > 0) {
        isValid = true;
      }
      return isValid ? null : {
        userValid: {
          valid: false
        }
      };
    }
    let isValid = false;
    const parent = formControl.parent!;
    const impianto = parent.get("impianto")!;
    const valid = Object.keys(formControl.value).length;
    const value = formControl.value.idTipoInterventi;
    if (value == 1 || value == 10) {
      impianto.enable({onlySelf:true});
      impianto.setValidators([Validators.required, validator]);
      impianto.updateValueAndValidity({onlySelf:true});
    } else {
      setTimeout( () => {
        impianto.disable({onlySelf:true});
        impianto.setValue([]);
        impianto.clearValidators();
        impianto.updateValueAndValidity({onlySelf: true});
      });
    }

    if (valid > 0) {
      isValid = true;
    }
    return isValid ? null : {
      userValid: {
        valid: false
      }
    };

  }

  //Validatore Gestore e cross-validation con Validatore Data, tenendo conto del caso caposala
  validateGestore(testi: any, pattern: string) {
      if (pattern.includes("caposala")){
          return [
              {value: this.validateCaposalaDropDown, errorMsg: testi.campoObbligatorio}
          ];
      } else {
          return [
              {value: this.validateGestoreDropDown, errorMsg: testi.campoObbligatorio}
              ];
      }
    }

  validateCaposalaDropDown(formControl: FormControl) {
    let isValid = true;
    return isValid ? null : {
      userValid: {
        valid: false
      }
    };
  }

  //Validatore Data e cross-validation con Validatore Gestore
  validateDateDrop(testi: any) {
    return  [
      {value: this.validateDateDropDown , errorMsg: testi.campoObbligatorio }
    ];
  }

  /** Gestione validazioni incrociate */

  validateGestoreDropDown(formControl: FormControl){
    let isValid = true;
    const value = Object.keys(formControl.value).length;
    if (value == 0){
      isValid=false;
    }
    return isValid ? null : {
      userValid: {
        valid: false
      }
    };
  }



  validateDateDropDown(formControl: FormControl){
    let isValid = true;
    const value = formControl.value;
    if (value == ''){
      isValid=false;
    }
    return isValid ? null : {
      userValid: {
        valid: false
      }
    };
  }

  /** Gestione Rifiuto*/

  validateRifiutoDropDown(formControl: FormControl) {
    const dateValidator = function(formControl: FormControl){
      let isValid = true;
      const value = formControl.value;
      const parent = formControl.parent!;
      const gestore = parent.get("utenteGestore")!;
      if (value != '') {
        isValid = true;
      } else if (Object.keys(gestore.value).length == 0){
        isValid = true;
      } else {
        isValid = false;
      }
      return isValid ? null : {
        userValid: {
          valid: false
        }
      };
    }
    const validator = (formControl: FormControl) =>{
      let isValid = false;
      const parent = formControl.parent!;
      const scadenza = parent.get("dataScadenza")!;
      if (Object.keys(formControl.value) != null) {
        const value = Object.keys(formControl.value).length;
        if (value > 0) {
          isValid = true;
        }
      } else if (scadenza.value == '') {
        isValid = true;
      } else {
        isValid = false;
      }
      return isValid ? null : {
        userValid: {
          valid: false
        }
      };
    }
    const parent = formControl.parent!;
    const gestore = parent.get("utenteGestore")!;
    const scadenza = parent.get("dataScadenza")!;
    const value = Object.keys(formControl.value).length;
    //Elimino i validator in caso di Rifiuto
    if ((scadenza.status == 'INVALID' || gestore.status == 'INVALID') && value > 0){
      setTimeout(() => {
        scadenza.clearValidators();
        gestore.clearValidators();
        scadenza.updateValueAndValidity();
        gestore.updateValueAndValidity();
      });
    }
    //Re-Inizializzo i Validator
    if ((scadenza.validator == null && gestore.validator == null) && value == 0){
      setTimeout(() => {
        scadenza.clearValidators();
        gestore.clearValidators();
        scadenza.setValidators([Validators.required, dateValidator]);
        gestore.setValidators([Validators.required, validator]);
        scadenza.removeValidators([Validators.required]);
        gestore.removeValidators([Validators.required]);
        scadenza.updateValueAndValidity();
        gestore.updateValueAndValidity();
      })
    }

    let isValid = true;
    return isValid ? null : {
      userValid: {
        valid: false
      }
    };
  }
}

