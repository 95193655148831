<app-header-popup [testo]="title"
                  [loading]="loading"
                  (close)="closePanel()">
</app-header-popup>

<div *ngIf="!footerOK" mat-dialog-content class="conferma-content">
  <i class="fas fa-question-circle"></i>&nbsp;&nbsp;{{msgConferma}}
</div>

<div *ngIf="footerOK" mat-dialog-content class="conferma-content">
  <i class="fas fa-circle-exclamation"></i>
  &nbsp;&nbsp;{{msgConferma}}
</div>

<div mat-dialog-actions class="conferma-footer">
  <ng-container *ngIf="footerConferma">
    <button mat-flat-button type="button" color="warn" (click)="conferma()" style="margin-right: 0.5em;">
      <i class="fas fa-check"></i>
      {{testi.si}}
    </button>
    <button mat-flat-button type="button" color="primary" (click)="closePanel()">
      <i class="fas fa-times"></i>
      {{testi.no}}
    </button>
  </ng-container>
  <ng-container *ngIf="footerOK">
    <button mat-flat-button type="button" color="primary" (click)="closePanel()">
      <i class="fas fa-times"></i>
      {{testi.chiudi}}
    </button>
  </ng-container>

</div>
