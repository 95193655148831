import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {KeycloakService} from 'keycloak-angular';
import {SdgLibService} from "sdg-lib";


@Injectable({
  providedIn: 'root'
})

export class Oauth2TokenInterceptor implements HttpInterceptor {

    constructor(private keycloakService: KeycloakService,
                private shared: SdgLibService
    ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.shared.token;
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
    return next.handle(request);
  }
}
