<div class="header-mfe-container">

  <div class="header-mfe-sinistra">

    <div class="header-mfe-menu-icon" (click)="toggleMenu()">
      <mat-icon class="menu-icon">menu</mat-icon>
    </div>

    <div class="header-mfe-logo-nome" (click)="goToHome()">
      <!--<img class="logo" src="/assets/img/finso.png"  onerror='<div align="left"><p class="portal-title">Portale Base</p></div>'/>-->
    </div>

  </div>

  <div class="header-mfe-destra">

    <div class="header-mfe-toolbar-msg">
      Benvenuto, <span class="header-mfe-toolbar-name" *ngIf="fullName"> {{fullName}}</span>
    </div>

    <!-- <div class="header-mfe-home">
      <i class="fas fa-sitemap" (click)="goToHome()"></i>
    </div> -->

    <div class="header-mfe-logout" (click)="logout()">
      <mat-icon class="logout-icon">exit_to_app</mat-icon>
    </div>

  </div>
</div>
