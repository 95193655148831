import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-ble-checkbox',
  templateUrl: './ble-checkbox.component.html',
  styleUrls: ['./ble-checkbox.component.scss']
})
export class BleCheckboxComponent implements OnInit {

  @Input() valore?: boolean;
  @Input() testo?: string;
  @Input() block?: boolean;
  @Input() invalid ? = false;
  @Input() color = 'primary';

  @Output() cambioValore = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  valueChange(evt: boolean) {
    this.valore = evt;
    this.cambioValore.emit(evt);
  }

}
