import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

import {FormResponse} from "../../../entities/FormResponse";
import {FormInputField} from "../../../entities/form/FormInputField";
import {TestiService} from "../../../service/config/testi.service";
import {StatusForm} from "../../../entities/statusForm";
import {TableGroups} from "../../../entities/table/TableGroups";
import {UtentiService} from "../../../service/utenti.service";
import {Utente} from "../../../entities/Utente";
import {DropdownItem} from "../../../entities/utils/DropdownItem";



@Component({
  selector: 'app-form-column-richieste',
  templateUrl: './form-column-richieste.component.html',
  styleUrls: ['./form-column-richieste.component.scss']
})
export class FormColumnRichiesteComponent implements OnChanges, OnInit, OnDestroy {

  @Input() arrayFormInfo!: TableGroups[];
  @Input() formButton: any;
  @Input() nullDropDown: boolean = false;
  @Input() objValue?: any;
  @Input() selectsGroup?:  Map<string, DropdownItem[]> = new Map<string, DropdownItem[]>();
  @Input() enableFooter = true;
  @Input() groupValidator?: string;
  @Input() utenti?: Utente[];
  @Input() DragDropMaxHeight?: number;
  @Input() showRiattiva? = false;
  @Input() update?: boolean;
  @Input() error?: string;
  @Input() msgForbidden?: string | '';
  @Input() dmsUpload?: boolean;
  @Input() testi?: any;
  @Input() type?: string = '';
  @Input() newmode?: boolean = true;

  @Output() saveForm = new EventEmitter();
  @Output() closeForm = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() updateForm = new EventEmitter();
  @Output() formState = new EventEmitter<StatusForm>();

  @Output() callback = new EventEmitter();

  msgs: any[] = [];

  formGroup!: FormGroup;

  preUpdate: Utente | undefined;
  constructor( private formBuilder: FormBuilder,
               private testiService: TestiService,
               private utentiService: UtentiService) {
    this.preUpdate = this.type && this.type != 'richieste' ? new Utente(this.objValue) : undefined;
  }

  ngOnInit() {
    this.initFormGroup(this.arrayFormInfo);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['objValue'] && !!this.formGroup) {
      this.aggiornaForm();
    }
  }

  private getDifference(a: string, b:string){
    var i = 0;
    var j = 0;
    var result = "";

    while (j < b.length)
    {
      if (a[i] != b[j] || i == a.length)
        result += b[j];
      else
        i++;
      j++;
    }
    return result;
  }

  private checkMod(obj: Utente){
    if (JSON.stringify(this.preUpdate) === (JSON.stringify(obj).replace(',"blank":"","":""','').replace(',"validityEnd":""', ''))) {
      this.update=true;
    } else {
      this.update=false;
    }
  }

  private initFormGroup(arrayFormInfo: TableGroups[]) {
    this.msgs = [];
    this.formGroup = this.formBuilder.group(this.buildFormControl(arrayFormInfo));
    this.setGroupValidators(this.groupValidator);

    /** update ofter any value change */
    this.formGroup.valueChanges.subscribe(
      data => {
        const objReturn = this.objValue;
        for ( const propertyName in this.formGroup!.value) {
          objReturn[propertyName] = this.formGroup!.value[propertyName];
        }
		this.checkMod(objReturn);
        this.updateForm.emit(objReturn);
      }
    );

    /** update ofter status change */

    this.formGroup.statusChanges
      .subscribe( res => this.formState.emit(res));
    setTimeout( () => this.formState.emit(this.formGroup.status as 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED'), 100);

  }


  odd(n: number){
    if ( n % 2 === 0) {
      return false;
    } else {
      return true;
    }
  }

  private aggiornaForm() {
    this.arrayFormInfo.forEach( group => {
      group.groupItems.forEach(item => {
        this.formGroup!.get(item.prop)!.setValue(this.objValue[item.prop]);
      });
    });
  }

  private setGroupValidators(groupValidator: any) {
  }


  actionSave(type?: string) {
    const objReturn = this.objValue;
    for ( const propertyName in this.formGroup!.value) {
      objReturn[propertyName] = this.formGroup!.value[propertyName];
    }
    //console.log(objReturn);
    this.saveForm.emit(new FormResponse(type || 'save', objReturn));
  }

  actionCancel() {
    this.cancel.emit();
  }

  actionDelete() {
    const objReturn = this.objValue;
    for ( const propertyName in this.formGroup!.value) {
      objReturn[propertyName] = this.formGroup!.value[propertyName];
    }
      this.delete.emit(objReturn);
  }

  private buildFormControl(arr: TableGroups[]) {
    const items: any = {};
    const group: any = {};

    arr.forEach(group => {
      group.groupItems.forEach(item => {
        items[item.prop] = this.objValue[item.prop] == null ? this.formControlNew(item) : this.formControlUpdate(item);
        items[item.prop].setValidators(this.makeValidators(item));
      });
    });

    if (this.objValue.id && !group['id'])  {
      items['id'] = new FormControl({value: this.objValue.id, disabled: true});
    }

    return items;
  }

  private formControlUpdate(item: FormInputField): FormControl {
    return new FormControl({value: this.objValue[item.prop], disabled: item.block});
  }

  private formControlNew(item: FormInputField): FormControl {
    return new FormControl({value: '', disabled: item.block ? item.block : false });
  }


  private makeValidators(item: FormInputField): Validators[] {
    let _array: Validators[] = [];
    item.validator && (_array = item.validator.map(itemVal => {this.msgs.push({prop: item['prop'], msg: itemVal['errorMsg']});
      return itemVal['value'];
    } ));
    item.required && _array.push(Validators.required);

	if (item.validateUtente){
      this.msgs.push({prop: item['prop'], msg: item.validateUtente, order: true});
      _array.push(this.utentiService.userExists)
    }
    if (item.validateMail){
      this.msgs.push({prop: item['prop'], msg: item.validateMail, order: true});
    }
    return _array;
  }

  validateRegexEmail(mail: string) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = regex.test(mail);

    return !isValid;
  }


  ngOnDestroy(): void {
    console.log(3);
    if (this.formGroup) {
      // this.formGroup = undefined;
    }
  }

}

