import {MatDrawer} from "@angular/material/sidenav";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class MenuService {

    private drawer: MatDrawer;

    public loading: boolean = true;

    setDrawer(drawer: MatDrawer) {
        this.drawer = drawer;
    }

    getMenu(): boolean {
        return this.drawer.opened;
    }

    setOn(): void {
        this.drawer.open();
    }

    toggle(): void {
        // @ts-ignore
        this.drawer.toggle();
    }

}
