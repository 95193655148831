<div class="filter-lista">
  <div class="filter-col">

    <ng-container *ngTemplateOutlet="riga; context: { filterLitst: filterPrimaRiga }"></ng-container>

    <ng-container *ngTemplateOutlet="riga; context: { filterLitst: filterSecondaRiga }"></ng-container>

<!--    <div class="filter-riga">

      <ng-container *ngTemplateOutlet="riga; context: { filterLitst: filterPrimaRiga }"></ng-container>

    </div>

    <div class="filter-riga">

      <ng-container *ngTemplateOutlet="riga; context: { filterLitst: filterSecondaRiga }"></ng-container>

    </div>-->

  </div>

  <div class="filter-applica">

    <div class="input-data">
      <ng-container *ngTemplateOutlet="applica"></ng-container>
    </div>

  </div>


</div>

<!--TEMPLATE RIGA-->
<ng-template #riga let-filterLitst="filterLitst">

  <div class="filtro" *ngFor="let filtro of filterLitst">

    <div class="filtro-label">
      <span *ngIf="filtro.type != 'checkbox'">{{filtro.label}}</span>
    </div>

    <ng-container [ngSwitch]="filtro.type">

      <div class="input-data" *ngSwitchCase="'text'">
        <ng-container *ngTemplateOutlet="text; context: { filtro: filtro }"></ng-container>
      </div>

      <div class="input-data" *ngSwitchCase="'dropdown'">
        <ng-container *ngTemplateOutlet="dropdown; context: { filtro: filtro, multiple: false}"></ng-container>
      </div>

      <div class="input-data" *ngSwitchCase="'multSelect'">
        <ng-container *ngTemplateOutlet="dropdown; context: { filtro: filtro, multiple: true }"></ng-container>
      </div>

      <div class="input-data" *ngSwitchCase="'data'">
        <ng-container *ngTemplateOutlet="data; context: { filtro: filtro }"></ng-container>
      </div>

      <div class="input-data" *ngSwitchCase="'checkbox'">
        <ng-container *ngTemplateOutlet="checkbox; context: { filtro: filtro }"></ng-container>
      </div>

    </ng-container>

  </div>

</ng-template>


<!--TEXT-->
<ng-template #text let-filtro="filtro" >

  <app-ble-input [valore]="''"
                 [testo]="filtro.label"
                 (cambioValore)="filterText($event, filtro.param, filtro.index)">
  </app-ble-input>

</ng-template>

<!--DROPDOWN-->
<ng-template #dropdown let-filtro="filtro" let-multiple="multiple" >

  <app-ble-dropdown [lista]="allList.get(filtro.lista)!"
                    [testo]="filtro.label"
                    [disabled]="!allList.get(filtro.lista)?.length"
                    [clean]="true"
                    [multiple]="multiple"
                    (cambioValore)="filterDropdown($event, filtro.prop, filtro.param, multiple, filtro.index)">
  </app-ble-dropdown>

</ng-template>

<!--Data-->
<ng-template #data let-filtro="filtro" >

  <app-ble-datepicker [clear]="true"
                      [testo]="filtro.label"
                      (cambioValore)="filterDate($event, filtro.param, filtro.index)">
  </app-ble-datepicker>

</ng-template>

<!-- CheckBox -->
<ng-template #checkbox let-filtro="filtro" >

  <app-ble-checkbox [valore]="false"
                    [testo]="filtro.label"
                    (cambioValore)="filterCheckBox($event, filtro.param, filtro.index)">
  </app-ble-checkbox>

</ng-template>

<!--EXCEL-->
<ng-template #excel>

  <div class="input-applica">
    <button mat-flat-button
            type="button"
            color="primary"
            (click)="exportExcel()">
      {{testi.download}} <i class="fas fa-file-csv"></i>
    </button>
  </div>

</ng-template>

<!--APPLICA-->
<ng-template #applica>

  <div class="input-applica">
    <button mat-flat-button
            type="button"
            color="primary"
            [disabled]="!filtroCambiato"
            (click)="applicaFilter()">
      {{testi.applica}}
    </button>
  </div>

</ng-template>

