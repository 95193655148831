<form class="form-base" *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="actionSave()">

  <div [ngClass]="formLine.input == 'chipList' ? 'formContainer60' : 'formContainer100'" *ngFor="let formLine of arrayFormInfo; let i = index">

    <div class="formFieldGroup">

      <label [for]="formLine.prop" class="form-label"> {{formLine.text}}: </label>

      <div class="form-input">
        <app-form-inputs [formType]="formLine"
                         [formControlName]="formLine.prop"
                         [objValue]="objValue"
                         [dropdownList]="selectsGroup!.get(formLine.list!)">
        </app-form-inputs>
      </div>

      <div class="form-error"> <span *ngIf="formGroup!.controls[formLine.prop]!.invalid!"> * </span> </div>

    </div>

  </div>

  <div class="form-legenda">
    <div *ngIf="formGroup!.invalid!" class="legenda-form-error"> * {{testi.obbligatorio}} </div>

    <div *ngFor="let msg of msgs">
      <span *ngIf="formGroup!.controls[msg.prop]!.invalid! && formGroup!.controls[msg.prop]!.touched!" class="error-validator">{{msg.msg}}</span>
    </div>
  </div>


  <div class="footerForm" *ngIf="enableFooter">
    <app-form-field-footer [disabled]="formGroup!.invalid!"
                           (saveForm)="actionSave()"
                           (cancel)="actionCancel()">
    </app-form-field-footer>
  </div>

</form>
