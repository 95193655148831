import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CallbackTableState} from "../../../../entities/enums/CallbackTableState";
import {FilterType} from "../../../../entities/table/FilterType";
import {TestiService} from "../../../../service/config/testi.service";
import {MyDate} from "../../../../entities/MyDate";
import {TableCallback} from "../../../../entities/table/TableCallback";
import {PulizieService} from "../../../../service/pulizie.service";
import {PazientiService} from "../../../../service/pazienti.service";


@Component({
  selector: 'app-table-header-filter',
  templateUrl: './table-header-filter.component.html',
  styleUrls: ['./table-header-filter.component.scss']
})
export class TableHeaderFilterComponent implements OnInit {

  @Input() filterTypeList: FilterType[] = [];
  @Input() testi?: any;
  @Input() type?: string;
  @Input() allList: Map<string, any[]> = new Map<string, any[]>();
  @Input() autoSize?: boolean = false;

  @Output() callBack = new EventEmitter<TableCallback>();
  @Output() callBackUpdate = new EventEmitter<TableCallback>();

  filterSelezionato: any[][] = [];
  filterSelezionatoApplica: any[][] = [];
  filtroCambiato = false;

  showExport = false;


  constructor(private testiService: TestiService,
              private pazientiService: PazientiService,
              private pulizieService: PulizieService) {
  }

  ngOnInit(): void {
    this.filterSelezionato = this.filterTypeList.map( () => [] );
    this.showExport = this.filterTypeList.some( filter => filter.type === 'excel');
  }

  filterText(event: any, param: string, index: number) {
    this.filterSelezionato[index] = event ? [param, event, event] : [];
    this.filtroCambiato = true;
  }

  filterCheck(event: any, param: string, index: number) {
    this.filterSelezionato[index] = event ? [param, event ? '1' : '0', event] : [];
    this.filtroCambiato = true;
  }

  filterDropdown(event: any, prop: string, param: string, multiple: boolean, index: number) {
  /* Per dropdown in cascata */
  if (param == 'idUnitaServizio' && event.length > 0){
   this.pulizieService.getStanzeByUS(event)
        .subscribe( res => {
          this.allList.set('stanze', res);
        });
  }
  /* Fine Per DropDown in cascata */
    /* Drop Down Pazienti */
    if (this.type == 'pazienti') {
      if (param == 'struttura' && event.id != null) {
        this.pazientiService.getUSbyID(event.id)
            .subscribe(res => this.allList.set('usList', res));
      } else if (param == 'struttura' && event.id == null) {
        this.pazientiService.getAllUS()
            .subscribe(res => this.allList.set('usList', res));
      }
      if (param == 'tipostruttura' && event.id != null) {
        this.pazientiService.getStrutturaById(event.id)
            .subscribe(res => {
              this.allList.set('strutturaList', res);
            });
      } else if (param == 'tipostruttura' && event.id == null) {
        this.pazientiService.getAllStrutture()
            .subscribe(res => this.allList.set('strutturaList', res));
      }
    }


    /*Fine DropDown Pazienti*/
    //console.log(event,prop,param,multiple,index);
    if (multiple) {
      const selectedList = event.map((item: any) => item[prop] ).join(',');
      this.filterSelezionato[index] = event.length ? [param, selectedList] : [];
    } else {
      //TODO
      this.filterSelezionato[index] = event ? [param, event[prop], event.viewValue] : [];
      if (event[prop] != undefined && event.viewValue != undefined) {
        this.filterSelezionato[index] = event ? [param, event[prop], event.viewValue] : [];
      } else {
        this.filterSelezionato[index] = event ? [param, event] :[];
      }

    }
    this.callBackUpdate.emit({type: CallbackTableState.FILTERUPDATE, data: [event,param]});
    this.filtroCambiato = true;
  }

  filterDate(event: Date, param: string, index: number) {
    let myDate = event ? new MyDate(event) : null;
    if (param == 'dataA' && myDate) {
      myDate = myDate.addDays(1);
    }
    this.filterSelezionato[index] = myDate ? [param, myDate.dateSave, myDate.giornoMeseAnno] : [];
    this.filtroCambiato = true;
  }

  applicaFilter() {
    const filter = this.filterSelezionato.filter( item => item.length );
    this.callBack.emit({type: CallbackTableState.FILTER, data: filter});
    this.filtroCambiato = false;
    this.filterSelezionatoApplica = filter;
  }

  exportExcel() {
    this.callBack.emit({type: CallbackTableState.EXCEL, data: this.filterSelezionatoApplica });
  }

}
