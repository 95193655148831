import {Route, Router, Routes} from '@angular/router';
import {Injectable} from "@angular/core";
import {RouteConf} from "../../entities/RouteConf";
import {LoggedGuard} from "./LoggedGuard";

@Injectable({ providedIn: 'root' })


export class routeBuilder {

    constructor() {
    }
}

export function buildRoutes(options: any, router: Router, roles: string[]): Routes {
    const lazyRoutes: Routes = Object.keys(options)
        .map((key) => {
            const entry: RouteConf = options[key];
            const auth = entry.authRoute as string[];
            let output: Route =   {
                path: entry.routePath,
                redirectTo: 'fallback',
            };
            if (auth.some(item => roles.includes(item))){
                switch (entry.ngModuleName) {
                    case "RichiesteModule":
                        output = {canActivate: [LoggedGuard], path: entry.routePath, loadChildren: () => import( '../../richieste/richieste.module').then((m) => m["RichiesteModule"])};
                        break;
                    case "AnagraficheModule":
                        output = {canActivate: [LoggedGuard],path: entry.routePath, loadChildren: () => import( '../../anagrafiche/anagrafiche.module').then((m) => m["AnagraficheModule"])};
                        break;
                    case "PulizieModule":
                        output = {canActivate: [LoggedGuard],path: entry.routePath, loadChildren: () => import( '../../pulizie/pulizie.module').then((m) => m["PulizieModule"])};
                        break;
                    case "PulizieOrdinarieModule":
                        output = {canActivate: [LoggedGuard],path: entry.routePath, loadChildren: () => import( '../../pulizie/pulizie-ordinarie/pulizie-ordinarie.module').then((m) => m["PulizieOrdinarieModule"])};
                        break;
                    case "PuliziePeriodicheModule":
                        output = {canActivate: [LoggedGuard],path: entry.routePath, loadChildren: () => import( '../../pulizie/pulizie-periodiche/pulizie-periodiche.module').then((m) => m["PuliziePeriodicheModule"])};
                        break;
                    case "PulizieStraordinarieModule":
                        output = {canActivate: [LoggedGuard],path: entry.routePath, loadChildren: () => import( '../../pulizie/pulizie-straordinarie/pulizie-straordinarie.module').then((m) => m["PulizieStraordinarieModule"])};
                        break;
                    case "PulizieSchedeControlloModule":
                        output = {canActivate: [LoggedGuard],path: entry.routePath, loadChildren: () => import( '../../pulizie/schede-controllo/schede-controllo.module').then((m) => m["PulizieSchedeControlloModule"])};
                        break;
                    case "PulizieChiusuraStanzeModule":
                        output = {canActivate: [LoggedGuard],path: entry.routePath, loadChildren: () => import( '../../pulizie/chiusura-stanze/chiusura-stanze.module').then((m) => m["PulizieChiusuraStanzeModule"])};
                        break;
                    case "LavanoloModule":
                        output = {canActivate: [LoggedGuard],path: entry.routePath, loadChildren: () => import( '../../lavanolo/lavanolo.module').then((m) => m["LavanoloModule"])};
                        break;
                    case "LavanoloGiornaliereModule":
                        output = {canActivate: [LoggedGuard],path: entry.routePath, loadChildren: () => import( '../../lavanolo/lavanolo-giornaliere/lavanolo-giornaliere.module').then((m) => m["LavanoloGiornaliereModule"])};
                        break;
                    case "LavanoloRichiestaModule":
                        output = {canActivate: [LoggedGuard],path: entry.routePath, loadChildren: () => import( '../../lavanolo/lavanolo-richiesta/lavanolo-richiesta.module').then((m) => m["LavanoloRichiestaModule"])};
                        break;
                    case "LavanoloVestizioneModule":
                        output = {canActivate: [LoggedGuard],path: entry.routePath, loadChildren: () => import( '../../lavanolo/lavanolo-vestizione/lavanolo-vestizione.module').then((m) => m["LavanoloVestizioneModule"])};
                        break;
                    case "SchedeControlloLavanoloModule":
                        output = {canActivate: [LoggedGuard],path: entry.routePath, loadChildren: () => import( '../../lavanolo/schede-controllo/schede-controllo.module').then((m) => m["SchedeControlloLavanoloModule"])};
                        break;
                    case "DmsModule":
                        output = {canActivate: [LoggedGuard],path: entry.routePath, loadChildren: () => import( '../../dms/dms.module').then((m) => m["DmsModule"])};
                        break;
                    case "RistorazioneControlliModule":
                        output = {canActivate: [LoggedGuard],path: entry.routePath, loadChildren: () => import( '../../controlli-ristorazione/ristorazioneControlli.module').then((m) => m["RistorazioneControlliModule"])};
                        break;
                    case "ristorazioneModule":
                        output = {canActivate: [LoggedGuard],path: entry.routePath, loadChildren: () => import( '../../mfe-fallback/mfe-fallback.module').then((m) => m["MfeFallbackModule"])};
                        break;
                    case "ReportModule":
                        output = {canActivate: [LoggedGuard],path: entry.routePath, loadChildren: () => import( '../../report/report.module').then((m) => m["ReportModule"])};
                        break;
                }
             }
            return output;
        });

    return [...lazyRoutes];
}

