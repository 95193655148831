<div *ngIf="noScrollV && colorSelected == ''" class="noScroll">
  <div id="t2" class="table-container" *ngIf="input == true">
    <div *ngIf="!showFooter" id=1 class="table-scroll"  style="max-height:{{height}}vh;overflow-y:{{overflow}};">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
          <th mat-header-cell *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%'}">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>
          </th>

          <td mat-cell *matCellDef="let element"
              [ngClass]="{
              'rigaVerde': element.coloreRiga == 'verde',
              'rigaGialla': element.coloreRiga == 'giallo',
              'rigaArancione': element.coloreRiga == 'arancione',
              'rigaRossa': element.coloreRiga == 'rosso'
              }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [noclick]="noclick"
                          (callBack)="callBackRowData($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;"></tr>

      </table>
    </div>
    <div *ngIf="showFooter" class="table-scroll">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

          <th mat-header-cell  *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%' } ">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>

          </th>

          <td mat-cell *matCellDef="let element"
              [ngClass]="{
              'rigaVerde': element.coloreRiga == 'verde',
              'rigaGialla': element.coloreRiga == 'giallo',
              'rigaArancione': element.coloreRiga == 'arancione',
              'rigaRossa': element.coloreRiga == 'rosso'
              }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [onlyView]="onlyView"
                          [noclick]="noclick"
                          [pattern]="column.pattern"
                          (callBack)="callBackRowData($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;"></tr>

      </table>
    </div>
    <ng-container *ngIf="!tableData?.length">
      <div class="msg-nessuno">
        {{nessunElemento}}
      </div>
    </ng-container>

    <div *ngIf="showFooter" class="table-footer">
      <mat-paginator #paginatorDefault
                     *ngIf="!totalPageslazy"
                     [pageSize]="rows"
                     [hidePageSize]="true"
                     showFirstLastButtons>
      </mat-paginator>

      <mat-paginator #paginatorLazy
                     *ngIf="totalPageslazy"
                     [pageSize]="rows"
                     [pageIndex]="pageIndex"
                     [length]="totalPageslazy"
                     [hidePageSize]="true"
                     (page)="cambioPage($event)"
                     showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>

  <div id="t3" class="table-container" *ngIf="!noRowPattern && rowResize && !input">
    <div *ngIf="!showFooter && !height" class="table-scroll"  style="max-height:{{scrollRows(rows)}};min-height:{{scrollRows(effectiveRow)}};overflow-y:{{overflow}};">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
          <th mat-header-cell *matHeaderCellDef
                              [ngStyle]="{'width': column.size + '%'}">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>
          </th>


          <td mat-cell *matCellDef="let element"
              [ngClass]="{
              'rigaVerde': element.coloreRiga == 'verde',
              'rigaGialla': element.coloreRiga == 'giallo',
              'rigaArancione': element.coloreRiga == 'arancione',
              'rigaRossa': element.coloreRiga == 'rosso'
              }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          (callBack)="callBackRowData($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;"></tr>

      </table>
    </div>
    <div *ngIf="!showFooter && height" class="table-scroll"  style="max-height:{{height}}vh;overflow-y:{{overflow}};">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
          <th mat-header-cell *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%'}">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>
          </th>


          <td mat-cell *matCellDef="let element"
              [ngClass]="{
              'rigaVerde': element.coloreRiga == 'verde',
              'rigaGialla': element.coloreRiga == 'giallo',
              'rigaArancione': element.coloreRiga == 'arancione',
              'rigaRossa': element.coloreRiga == 'rosso'
              }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
  					    [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [noclick]="noclick"
                          (callBack)="callBackRowData($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;"></tr>

      </table>
    </div>
    <div *ngIf="showFooter" class="table-scroll">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

          <th mat-header-cell  *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%' } ">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>

          </th>

          <td mat-cell *matCellDef="let element"
              [ngClass]="{
              'rigaVerde': element.coloreRiga == 'verde',
              'rigaGialla': element.coloreRiga == 'giallo',
              'rigaArancione': element.coloreRiga == 'arancione',
              'rigaRossa': element.coloreRiga == 'rosso'
              }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
  						[onlyView]="onlyView"
  						[noclick]="noclick"
                          [pattern]="column.pattern"
                          (callBack)="callBackRowData($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;"></tr>

      </table>
    </div>
    <ng-container *ngIf="!tableData?.length">
      <div class="msg-nessuno">
        {{nessunElemento}}
      </div>
    </ng-container>

    <div *ngIf="showFooter" class="table-footer">
      <mat-paginator #paginatorDefault
                     *ngIf="!totalPageslazy"
                     [pageSize]="rows"
                     [hidePageSize]="true"
                     showFirstLastButtons>
      </mat-paginator>

      <mat-paginator #paginatorLazy
                     *ngIf="totalPageslazy"
                     [pageSize]="rows"
                     [pageIndex]="pageIndex"
                     [length]="totalPageslazy"
                     [hidePageSize]="true"
                     (page)="cambioPage($event)"
                     showFirstLastButtons>
      </mat-paginator>
    </div>

  </div>
  <div id="t4" class="table-container" *ngIf="!noRowPattern && !rowResize && !input">
    <div *ngIf="!showFooter" class="table-scroll"  style="max-height:{{scrollRows(rows)}};">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
          <th mat-header-cell *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%'}">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>
          </th>


          <td mat-cell *matCellDef="let element"
              [ngClass]="{
              'rigaVerde': element.coloreRiga == 'verde',
              'rigaGialla': element.coloreRiga == 'giallo',
              'rigaArancione': element.coloreRiga == 'arancione',
              'rigaRossa': element.coloreRiga == 'rosso'
              }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [rowData]="element"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [onlyView]="onlyView"
                          [noclick]="noclick"
                          (callBack)="callBackRowData($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;"></tr>

      </table>
    </div>
    <div *ngIf="showFooter" class="table-scroll">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

          <th mat-header-cell  *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%' } ">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>

          </th>

          <td mat-cell *matCellDef="let element"
              [ngClass]="{
              'rigaVerde': element.coloreRiga == 'verde',
              'rigaGialla': element.coloreRiga == 'giallo',
              'rigaArancione': element.coloreRiga == 'arancione',
              'rigaRossa': element.coloreRiga == 'rosso'
              }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [noclick]="noclick"
                          (callBack)="callBackRowData($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;"></tr>

      </table>
    </div>
    <ng-container *ngIf="!tableData?.length">
      <div class="msg-nessuno">
        {{nessunElemento}}
      </div>
    </ng-container>

    <div *ngIf="showFooter" class="table-footer">
      <mat-paginator #paginatorDefault
                     *ngIf="!totalPageslazy"
                     [pageSize]="rows"
                     [hidePageSize]="true"
                     showFirstLastButtons>
      </mat-paginator>

      <mat-paginator #paginatorLazy
                     *ngIf="totalPageslazy"
                     [pageSize]="rows"
                     [pageIndex]="pageIndex"
                     [length]="totalPageslazy"
                     [hidePageSize]="true"
                     (page)="cambioPage($event)"
                     showFirstLastButtons>
      </mat-paginator>
    </div>

  </div>

  <div id=4 *ngIf="noRowPattern && scroll && !input">
    <div *ngIf="!showFooter" class="table-scroll"  style="max-height:{{scrollRows(rows)}};min-height:{{scrollRows(effectiveRow)}};">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
          <th mat-header-cell *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%'}">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>
          </th>


          <td mat-cell *matCellDef="let element; let i = index"
              [ngClass]="{
              'rigaVerde': element.coloreRiga == 'verde',
              'rigaGialla': element.coloreRiga == 'giallo',
              'rigaArancione': element.coloreRiga == 'arancione',
              'rigaRossa': element.coloreRiga == 'rosso'
              }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [noclick]="noclick"
                          [onlyView]="onlyView"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          (callBack)="callBackRowDataColor($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;"></tr>

      </table>
    </div>
    <div *ngIf="showFooter" class="table-scroll">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

          <th mat-header-cell  *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%' } ">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>

          </th>

          <td mat-cell *matCellDef="let element; let i = index"
              [ngClass]="{
              'rigaVerde': element.coloreRiga == 'verde',
              'rigaGialla': element.coloreRiga == 'giallo',
              'rigaArancione': element.coloreRiga == 'arancione',
              'rigaRossa': element.coloreRiga == 'rosso'
              }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [onlyView]="onlyView"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [noclick]="noclick"
                          (callBack)="callBackRowDataColor($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>
      </table>
    </div>
    <div *ngIf="noRowPattern && scroll">
      <div *ngIf="!showFooter && !pulizie" class="table-scroll"  style="max-height:{{scrollRows(rows)}};min-height:{{scrollRows(effectiveRow)}};">
        <table mat-table [dataSource]="dataSource">
          <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
            <th mat-header-cell *matHeaderCellDef
                [ngStyle]="{'width': column.size + '%'}">
              <app-row-header [rowProp]="column.prop"
                              [name]="column.name"
                              [order]="column.order"
                              [orderIcon]="orderColList[i]"
                              [filter]="true"
                              (callBackOrder)="changeOrder($event, i)">
              </app-row-header>
            </th>


            <td mat-cell *matCellDef="let element; let i = index"
                [ngClass]="{
              'rigaVerde': element.coloreRiga == 'verde',
              'rigaGialla': element.coloreRiga == 'giallo',
              'rigaArancione': element.coloreRiga == 'arancione',
              'rigaRossa': element.coloreRiga == 'rosso'
              }"
                [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

              <app-row-data [rowProp]="column.prop"
                            [reload]="reload"
                            [rowData]="element"
                            [text0]="column.text0"
                            [text1]="column.text1"
                            [refValue]="column.refValue"
                            [noclick]="noclick"
                            [onlyView]="onlyView"
                            [colTooltip]="column.tooltip || false"
                            [size]="column.size"
                            (callBack)="callBackRowDataColor($event)">
              </app-row-data>
            </td>

          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsName;"></tr>

        </table>
      </div>
      <div *ngIf="showFooter && !pulizie" class="table-scroll">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

          <th mat-header-cell  *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%' } ">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>

          </th>

          <td mat-cell *matCellDef="let element; let i = index"
              [ngClass]="{
              'rigaVerde': element.coloreRiga == 'verde',
              'rigaGialla': element.coloreRiga == 'giallo',
              'rigaArancione': element.coloreRiga == 'arancione',
              'rigaRossa': element.coloreRiga == 'rosso'
              }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [size]="column.size"
  						[onlyView]="onlyView"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          (callBack)="callBackRowDataColor($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>
      </table>
    </div>
    <ng-container *ngIf="!tableData?.length">
      <div class="msg-nessuno">
        {{nessunElemento}}
      </div>
    </ng-container>

    <div *ngIf="showFooter && !input" class="table-footer">
      <mat-paginator #paginatorDefault
                     *ngIf="!totalPageslazy"
                     [pageSize]="rows"
                     [hidePageSize]="true"
                     showFirstLastButtons>
      </mat-paginator>

      <mat-paginator #paginatorLazy
                     *ngIf="totalPageslazy"
                     [pageSize]="rows"
                     [pageIndex]="pageIndex"
                     [length]="totalPageslazy"
                     [hidePageSize]="true"
                     (page)="cambioPage($event)"
                     showFirstLastButtons>
      </mat-paginator>
    </div>

  </div>
</div>
</div>
<div *ngIf="!noScrollV && colorSelected == ''">
  <div id="t5" class="table-container" *ngIf="input == true">
    <div *ngIf="!showFooter" class="table-scroll"  style="max-height:{{height}}vh;overflow-y:{{overflow}};">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
          <th mat-header-cell *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%'}">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>
          </th>


          <td mat-cell *matCellDef="let element"
              [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso'
            }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [noclick]="noclick"
                          (callBack)="callBackRowData($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <div *ngIf="showFooter" class="table-scroll">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

          <th mat-header-cell  *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%' } ">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>

          </th>

          <td mat-cell *matCellDef="let element"
              [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso'
            }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [onlyView]="onlyView"
                          [noclick]="noclick"
                          [pattern]="column.pattern"
                          (callBack)="callBackRowData($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <ng-container *ngIf="!tableData?.length">
      <div class="msg-nessuno">
        {{nessunElemento}}
      </div>
    </ng-container>

    <div *ngIf="showFooter" class="table-footer">
      <mat-paginator #paginatorDefault
                     *ngIf="!totalPageslazy"
                     [pageSize]="rows"
                     [hidePageSize]="true"
                     showFirstLastButtons>
      </mat-paginator>

      <mat-paginator #paginatorLazy
                     *ngIf="totalPageslazy"
                     [pageSize]="rows"
                     [pageIndex]="pageIndex"
                     [length]="totalPageslazy"
                     [hidePageSize]="true"
                     (page)="cambioPage($event)"
                     showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>

  <div id="t6" class="table-container" *ngIf="!noRowPattern && rowResize && !input">
    <div *ngIf="!showFooter && !height" class="table-scroll"  style="max-height:{{scrollRows(rows)}};min-height:{{scrollRows(effectiveRow)}};overflow-y:{{overflow}};">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
          <th mat-header-cell *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%'}">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>
          </th>


          <td mat-cell *matCellDef="let element"
              [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso'
            }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          (callBack)="callBackRowData($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <div id="7" *ngIf="!showFooter && height" class="table-scroll"  style="max-height:{{height}}vh;overflow-y:{{overflow}};">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
          <th mat-header-cell *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%'}">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>
          </th>


          <td mat-cell *matCellDef="let element"
              [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso'
            }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [noclick]="noclick"
                          (callBack)="callBackRowData($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <div *ngIf="showFooter" class="table-scroll">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

          <th mat-header-cell  *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%' } ">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>

          </th>

          <td mat-cell *matCellDef="let element"
              [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso'
            }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [onlyView]="onlyView"
                          [noclick]="noclick"
                          [pattern]="column.pattern"
                          (callBack)="callBackRowData($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <ng-container *ngIf="!tableData?.length">
      <div class="msg-nessuno">
        {{nessunElemento}}
      </div>
    </ng-container>

    <div *ngIf="showFooter" class="table-footer">
      <mat-paginator #paginatorDefault
                     *ngIf="!totalPageslazy"
                     [pageSize]="rows"
                     [hidePageSize]="true"
                     showFirstLastButtons>
      </mat-paginator>

      <mat-paginator #paginatorLazy
                     *ngIf="totalPageslazy"
                     [pageSize]="rows"
                     [pageIndex]="pageIndex"
                     [length]="totalPageslazy"
                     [hidePageSize]="true"
                     (page)="cambioPage($event)"
                     showFirstLastButtons>
      </mat-paginator>
    </div>

  </div>
  <div id="t7"  class="table-container" *ngIf="!noRowPattern && !rowResize && !input">
    <div *ngIf="!showFooter" class="table-scroll"  style="max-height:{{scrollRows(rows)}};">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
          <th mat-header-cell *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%'}">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>
          </th>


          <td mat-cell *matCellDef="let element"
              [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso'
            }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [rowData]="element"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [onlyView]="onlyView"
                          [noclick]="noclick"
                          (callBack)="callBackRowData($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <div *ngIf="showFooter" class="table-scroll">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

          <th mat-header-cell  *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%' } ">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>

          </th>

          <td mat-cell *matCellDef="let element"
              [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso'
            }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [noclick]="noclick"
                          (callBack)="callBackRowData($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <ng-container *ngIf="!tableData?.length">
      <div class="msg-nessuno">
        {{nessunElemento}}
      </div>
    </ng-container>

    <div *ngIf="showFooter" class="table-footer">
      <mat-paginator #paginatorDefault
                     *ngIf="!totalPageslazy"
                     [pageSize]="rows"
                     [hidePageSize]="true"
                     showFirstLastButtons>
      </mat-paginator>

      <mat-paginator #paginatorLazy
                     *ngIf="totalPageslazy"
                     [pageSize]="rows"
                     [pageIndex]="pageIndex"
                     [length]="totalPageslazy"
                     [hidePageSize]="true"
                     (page)="cambioPage($event)"
                     showFirstLastButtons>
      </mat-paginator>
    </div>

  </div>

  <div *ngIf="noRowPattern && scroll && !input">
    <div *ngIf="!showFooter" class="table-scroll"  style="max-height:{{scrollRows(rows)}};min-height:{{scrollRows(effectiveRow)}};">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
          <th mat-header-cell *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%'}">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>
          </th>


          <td mat-cell *matCellDef="let element; let i = index"
              [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso'
            }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [noclick]="noclick"
                          [onlyView]="onlyView"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          (callBack)="callBackRowDataColor($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <div *ngIf="showFooter" class="table-scroll">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

          <th mat-header-cell  *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%' } ">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>

          </th>

          <td mat-cell *matCellDef="let element; let i = index"
              [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso'
            }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [onlyView]="onlyView"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [noclick]="noclick"
                          (callBack)="callBackRowDataColor($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>
      </table>
    </div>
    <div *ngIf="noRowPattern && scroll">
      <div *ngIf="!showFooter && !pulizie" class="table-scroll"  style="max-height:{{scrollRows(rows)}};min-height:{{scrollRows(effectiveRow)}};">
        <table mat-table [dataSource]="dataSource">
          <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
            <th mat-header-cell *matHeaderCellDef
                [ngStyle]="{'width': column.size + '%'}">
              <app-row-header [rowProp]="column.prop"
                              [name]="column.name"
                              [order]="column.order"
                              [orderIcon]="orderColList[i]"
                              [filter]="true"
                              (callBackOrder)="changeOrder($event, i)">
              </app-row-header>
            </th>


            <td mat-cell *matCellDef="let element; let i = index"
                [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso'
            }"
                [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

              <app-row-data [rowProp]="column.prop"
                            [reload]="reload"
                            [rowData]="element"
                            [text0]="column.text0"
                            [text1]="column.text1"
                            [refValue]="column.refValue"
                            [noclick]="noclick"
                            [onlyView]="onlyView"
                            [colTooltip]="column.tooltip || false"
                            [size]="column.size"
                            (callBack)="callBackRowDataColor($event)">
              </app-row-data>
            </td>

          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

        </table>
      </div>
      <div *ngIf="showFooter && !pulizie" class="table-scroll">
        <table mat-table [dataSource]="dataSource">
          <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

            <th mat-header-cell  *matHeaderCellDef
                [ngStyle]="{'width': column.size + '%' } ">
              <app-row-header [rowProp]="column.prop"
                              [name]="column.name"
                              [order]="column.order"
                              [orderIcon]="orderColList[i]"
                              [filter]="true"
                              (callBackOrder)="changeOrder($event, i)">
              </app-row-header>

            </th>

            <td mat-cell *matCellDef="let element; let i = index"
                [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso'
            }"
                [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

              <app-row-data [rowProp]="column.prop"
                            [reload]="reload"
                            [rowData]="element"
                            [text0]="column.text0"
                            [text1]="column.text1"
                            [size]="column.size"
                            [onlyView]="onlyView"
                            [colTooltip]="column.tooltip || false"
                            [size]="column.size"
                            (callBack)="callBackRowDataColor($event)">
              </app-row-data>
            </td>

          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>
        </table>
      </div>
      <ng-container *ngIf="!tableData?.length">
        <div class="msg-nessuno">
          {{nessunElemento}}
        </div>
      </ng-container>

      <div *ngIf="showFooter && !input" class="table-footer">
        <mat-paginator #paginatorDefault
                       *ngIf="!totalPageslazy"
                       [pageSize]="rows"
                       [hidePageSize]="true"
                       showFirstLastButtons>
        </mat-paginator>

        <mat-paginator #paginatorLazy
                       *ngIf="totalPageslazy"
                       [pageSize]="rows"
                       [pageIndex]="pageIndex"
                       [length]="totalPageslazy"
                       [hidePageSize]="true"
                       (page)="cambioPage($event)"
                       showFirstLastButtons>
        </mat-paginator>
      </div>

    </div>
  </div>
</div>
<!-- color rowSelected -->
<div *ngIf="noScrollV && colorSelected != ''" class="noScroll">
  <div id="t8" class="table-container" *ngIf="input == true">
    <div *ngIf="!showFooter" class="table-scroll"  style="max-height:{{height}}vh;overflow-y:{{overflow}};">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
          <th mat-header-cell *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%'}">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>
          </th>


          <td mat-cell *matCellDef="let element"
              [ngClass]="{
              'rigaVerde': element.coloreRiga == 'verde',
              'rigaGialla': element.coloreRiga == 'giallo',
              'rigaArancione': element.coloreRiga == 'arancione',
              'rigaRossa': element.coloreRiga == 'rosso'
              }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [noclick]="noclick"
                          (callBack)="callBackRowDataColor($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <div *ngIf="showFooter" class="table-scroll">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

          <th mat-header-cell  *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%' } ">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>

          </th>

          <td mat-cell *matCellDef="let element"
              [ngClass]="{
              'rigaVerde': element.coloreRiga == 'verde',
              'rigaGialla': element.coloreRiga == 'giallo',
              'rigaArancione': element.coloreRiga == 'arancione',
              'rigaRossa': element.coloreRiga == 'rosso'
              }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [onlyView]="onlyView"
                          [noclick]="noclick"
                          [pattern]="column.pattern"
                          (callBack)="callBackRowDataColor($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <ng-container *ngIf="!tableData?.length">
      <div class="msg-nessuno">
        {{nessunElemento}}
      </div>
    </ng-container>

    <div *ngIf="showFooter" class="table-footer">
      <mat-paginator #paginatorDefault
                     *ngIf="!totalPageslazy"
                     [pageSize]="rows"
                     [hidePageSize]="true"
                     showFirstLastButtons>
      </mat-paginator>

      <mat-paginator #paginatorLazy
                     *ngIf="totalPageslazy"
                     [pageSize]="rows"
                     [pageIndex]="pageIndex"
                     [length]="totalPageslazy"
                     [hidePageSize]="true"
                     (page)="cambioPage($event)"
                     showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>

  <div id="t9" class="table-container" *ngIf="!noRowPattern && rowResize && !input">
    <div id="s1" *ngIf="!showFooter && !height" class="table-scroll"  style="max-height:{{height}}vh;overflow-y:{{overflow}};">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
          <th mat-header-cell *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%'}">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>
          </th>


          <td mat-cell *matCellDef="let element"
              [ngClass]="{
              'rigaVerde': element.coloreRiga == 'verde',
              'rigaGialla': element.coloreRiga == 'giallo',
              'rigaArancione': element.coloreRiga == 'arancione',
              'rigaRossa': element.coloreRiga == 'rosso'
              }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          (callBack)="callBackRowDataColor($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <div id="s2" *ngIf="!showFooter && height" class="table-scroll"  style="max-height:{{height}}vh;overflow-y:{{overflow}};">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
          <th mat-header-cell *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%'}">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>
          </th>


          <td mat-cell *matCellDef="let element"
              [ngClass]="{
              'rigaVerde': element.coloreRiga == 'verde',
              'rigaGialla': element.coloreRiga == 'giallo',
              'rigaArancione': element.coloreRiga == 'arancione',
              'rigaRossa': element.coloreRiga == 'rosso'
              }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [noclick]="noclick"
                          (callBack)="callBackRowDataColor($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <div id="s3" *ngIf="showFooter" class="table-scroll">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

          <th mat-header-cell  *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%' } ">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>

          </th>

          <td mat-cell *matCellDef="let element"
              [ngClass]="{
              'rigaVerde': element.coloreRiga == 'verde',
              'rigaGialla': element.coloreRiga == 'giallo',
              'rigaArancione': element.coloreRiga == 'arancione',
              'rigaRossa': element.coloreRiga == 'rosso'
              }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [onlyView]="onlyView"
                          [noclick]="noclick"
                          [pattern]="column.pattern"
                          (callBack)="callBackRowDataColor($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <ng-container *ngIf="!tableData?.length">
      <div class="msg-nessuno">
        {{nessunElemento}}
      </div>
    </ng-container>

    <div *ngIf="showFooter" class="table-footer">
      <mat-paginator #paginatorDefault
                     *ngIf="!totalPageslazy"
                     [pageSize]="rows"
                     [hidePageSize]="true"
                     showFirstLastButtons>
      </mat-paginator>

      <mat-paginator #paginatorLazy
                     *ngIf="totalPageslazy"
                     [pageSize]="rows"
                     [pageIndex]="pageIndex"
                     [length]="totalPageslazy"
                     [hidePageSize]="true"
                     (page)="cambioPage($event)"
                     showFirstLastButtons>
      </mat-paginator>
    </div>

  </div>
  <div id="t10" class="table-container" *ngIf="!noRowPattern && !rowResize && !input">
    <div *ngIf="!showFooter" class="table-scroll"  style="max-height:{{scrollRows(rows)}};">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
          <th mat-header-cell *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%'}">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>
          </th>


          <td mat-cell *matCellDef="let element"
              [ngClass]="{
              'rigaVerde': element.coloreRiga == 'verde',
              'rigaGialla': element.coloreRiga == 'giallo',
              'rigaArancione': element.coloreRiga == 'arancione',
              'rigaRossa': element.coloreRiga == 'rosso'
              }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [rowData]="element"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [onlyView]="onlyView"
                          [noclick]="noclick"
                          (callBack)="callBackRowDataColor($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <div *ngIf="showFooter" class="table-scroll">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

          <th mat-header-cell  *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%' } ">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>

          </th>

          <td mat-cell *matCellDef="let element"
              [ngClass]="{
              'rigaVerde': element.coloreRiga == 'verde',
              'rigaGialla': element.coloreRiga == 'giallo',
              'rigaArancione': element.coloreRiga == 'arancione',
              'rigaRossa': element.coloreRiga == 'rosso'
              }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [noclick]="noclick"
                          (callBack)="callBackRowDataColor($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <ng-container *ngIf="!tableData?.length">
      <div class="msg-nessuno">
        {{nessunElemento}}
      </div>
    </ng-container>

    <div *ngIf="showFooter" class="table-footer">
      <mat-paginator #paginatorDefault
                     *ngIf="!totalPageslazy"
                     [pageSize]="rows"
                     [hidePageSize]="true"
                     showFirstLastButtons>
      </mat-paginator>

      <mat-paginator #paginatorLazy
                     *ngIf="totalPageslazy"
                     [pageSize]="rows"
                     [pageIndex]="pageIndex"
                     [length]="totalPageslazy"
                     [hidePageSize]="true"
                     (page)="cambioPage($event)"
                     showFirstLastButtons>
      </mat-paginator>
    </div>

  </div>

  <div *ngIf="noRowPattern && scroll && !input">
    <div *ngIf="!showFooter" class="table-scroll"  style="max-height:{{height}}vh;overflow-y:{{overflow}};">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
          <th mat-header-cell *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%'}">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>
          </th>


          <td mat-cell *matCellDef="let element; let i = index"
              [ngClass]="{
              'rigaVerde': element.coloreRiga == 'verde',
              'rigaGialla': element.coloreRiga == 'giallo',
              'rigaArancione': element.coloreRiga == 'arancione',
              'rigaRossa': element.coloreRiga == 'rosso'
              }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [noclick]="noclick"
                          [onlyView]="onlyView"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          (callBack)="callBackRowDataColor($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <div *ngIf="showFooter" class="table-scroll">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

          <th mat-header-cell  *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%' } ">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>

          </th>

          <td mat-cell *matCellDef="let element; let i = index"
              [ngClass]="{
              'rigaVerde': element.coloreRiga == 'verde',
              'rigaGialla': element.coloreRiga == 'giallo',
              'rigaArancione': element.coloreRiga == 'arancione',
              'rigaRossa': element.coloreRiga == 'rosso'
              }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [onlyView]="onlyView"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [noclick]="noclick"
                          (callBack)="callBackRowDataColor($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>
      </table>
    </div>
    <div *ngIf="noRowPattern && scroll">
      <div *ngIf="!showFooter && !pulizie" class="table-scroll"  style="max-height:{{height}}vh;overflow-y:{{overflow}};">
        <table mat-table [dataSource]="dataSource">
          <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
            <th mat-header-cell *matHeaderCellDef
                [ngStyle]="{'width': column.size + '%'}">
              <app-row-header [rowProp]="column.prop"
                              [name]="column.name"
                              [order]="column.order"
                              [orderIcon]="orderColList[i]"
                              [filter]="true"
                              (callBackOrder)="changeOrder($event, i)">
              </app-row-header>
            </th>


            <td mat-cell *matCellDef="let element; let i = index"
                [ngClass]="{
              'rigaVerde': element.coloreRiga == 'verde',
              'rigaGialla': element.coloreRiga == 'giallo',
              'rigaArancione': element.coloreRiga == 'arancione',
              'rigaRossa': element.coloreRiga == 'rosso'
              }"
                [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

              <app-row-data [rowProp]="column.prop"
                            [reload]="reload"
                            [rowData]="element"
                            [text0]="column.text0"
                            [text1]="column.text1"
                            [refValue]="column.refValue"
                            [noclick]="noclick"
                            [onlyView]="onlyView"
                            [colTooltip]="column.tooltip || false"
                            [size]="column.size"
                            (callBack)="callBackRowDataColor($event)">
              </app-row-data>
            </td>

          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

        </table>
      </div>
      <div *ngIf="showFooter && !pulizie" class="table-scroll">
        <table mat-table [dataSource]="dataSource">
          <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

            <th mat-header-cell  *matHeaderCellDef
                [ngStyle]="{'width': column.size + '%' } ">
              <app-row-header [rowProp]="column.prop"
                              [name]="column.name"
                              [order]="column.order"
                              [orderIcon]="orderColList[i]"
                              [filter]="true"
                              (callBackOrder)="changeOrder($event, i)">
              </app-row-header>

            </th>

            <td mat-cell *matCellDef="let element; let i = index"
                [ngClass]="{
              'rigaVerde': element.coloreRiga == 'verde',
              'rigaGialla': element.coloreRiga == 'giallo',
              'rigaArancione': element.coloreRiga == 'arancione',
              'rigaRossa': element.coloreRiga == 'rosso'
              }"
                [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

              <app-row-data [rowProp]="column.prop"
                            [reload]="reload"
                            [rowData]="element"
                            [text0]="column.text0"
                            [text1]="column.text1"
                            [size]="column.size"
                            [onlyView]="onlyView"
                            [colTooltip]="column.tooltip || false"
                            [size]="column.size"
                            (callBack)="callBackRowDataColor($event)">
              </app-row-data>
            </td>

          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>
        </table>
      </div>
      <ng-container *ngIf="!tableData?.length">
        <div class="msg-nessuno">
          {{nessunElemento}}
        </div>
      </ng-container>

      <div *ngIf="showFooter && !input" class="table-footer">
        <mat-paginator #paginatorDefault
                       *ngIf="!totalPageslazy"
                       [pageSize]="rows"
                       [hidePageSize]="true"
                       showFirstLastButtons>
        </mat-paginator>

        <mat-paginator #paginatorLazy
                       *ngIf="totalPageslazy"
                       [pageSize]="rows"
                       [pageIndex]="pageIndex"
                       [length]="totalPageslazy"
                       [hidePageSize]="true"
                       (page)="cambioPage($event)"
                       showFirstLastButtons>
        </mat-paginator>
      </div>

    </div>
  </div>
</div>
<div *ngIf="!noScrollV && colorSelected != ''">
  <div id="t11" class="table-container" *ngIf="input == true">
    <div *ngIf="!showFooter" class="table-scroll"  style="max-height:{{height}}vh;overflow-y:{{overflow}};">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
          <th mat-header-cell *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%'}">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>
          </th>


          <td mat-cell *matCellDef="let element"
              [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso'
            }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [noclick]="noclick"
                          (callBack)="callBackRowData($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <div *ngIf="showFooter" class="table-scroll">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

          <th mat-header-cell  *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%' } ">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>

          </th>

          <td mat-cell *matCellDef="let element"
              [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso'
            }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [onlyView]="onlyView"
                          [noclick]="noclick"
                          [pattern]="column.pattern"
                          (callBack)="callBackRowDataColor($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <ng-container *ngIf="!tableData?.length">
      <div class="msg-nessuno">
        {{nessunElemento}}
      </div>
    </ng-container>

    <div *ngIf="showFooter" class="table-footer">
      <mat-paginator #paginatorDefault
                     *ngIf="!totalPageslazy"
                     [pageSize]="rows"
                     [hidePageSize]="true"
                     showFirstLastButtons>
      </mat-paginator>

      <mat-paginator #paginatorLazy
                     *ngIf="totalPageslazy"
                     [pageSize]="rows"
                     [pageIndex]="pageIndex"
                     [length]="totalPageslazy"
                     [hidePageSize]="true"
                     (page)="cambioPage($event)"
                     showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>

  <div id="t12" class="table-container" *ngIf="!noRowPattern && rowResize && !input">
    <div *ngIf="!showFooter && !height" class="table-scroll"  style="max-height:{{height}}vh;overflow-y:{{overflow}};">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
          <th mat-header-cell *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%'}">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>
          </th>


          <td mat-cell *matCellDef="let element"
              [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso'
            }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          (callBack)="callBackRowDataColor($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <div *ngIf="!showFooter && height" class="table-scroll"  style="max-height:{{height}}vh;overflow-y:{{overflow}};">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
          <th mat-header-cell *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%'}">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>
          </th>


          <td mat-cell *matCellDef="let element"
              [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso'
            }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [noclick]="noclick"
                          (callBack)="callBackRowDataColor($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <div *ngIf="showFooter" class="table-scroll">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

          <th mat-header-cell  *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%' } ">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>

          </th>

          <td mat-cell *matCellDef="let element"
              [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso'
            }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [onlyView]="onlyView"
                          [noclick]="noclick"
                          [pattern]="column.pattern"
                          (callBack)="callBackRowDataColor($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <ng-container *ngIf="!tableData?.length">
      <div class="msg-nessuno">
        {{nessunElemento}}
      </div>
    </ng-container>

    <div *ngIf="showFooter" class="table-footer">
      <mat-paginator #paginatorDefault
                     *ngIf="!totalPageslazy"
                     [pageSize]="rows"
                     [hidePageSize]="true"
                     showFirstLastButtons>
      </mat-paginator>

      <mat-paginator #paginatorLazy
                     *ngIf="totalPageslazy"
                     [pageSize]="rows"
                     [pageIndex]="pageIndex"
                     [length]="totalPageslazy"
                     [hidePageSize]="true"
                     (page)="cambioPage($event)"
                     showFirstLastButtons>
      </mat-paginator>
    </div>

  </div>
  <div id="t13" class="table-container" *ngIf="!noRowPattern && !rowResize && !input">
    <div *ngIf="!showFooter" class="table-scroll"  style="max-height:{{scrollRows(rows)}};">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
          <th mat-header-cell *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%'}">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>
          </th>


          <td mat-cell *matCellDef="let element"
              [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso'
            }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [rowData]="element"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [onlyView]="onlyView"
                          [noclick]="noclick"
                          (callBack)="callBackRowDataColor($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <div *ngIf="showFooter" class="table-scroll">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

          <th mat-header-cell  *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%' } ">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>

          </th>

          <td mat-cell *matCellDef="let element"
              [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso'
            }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [noclick]="noclick"
                          (callBack)="callBackRowDataColor($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <ng-container *ngIf="!tableData?.length">
      <div class="msg-nessuno">
        {{nessunElemento}}
      </div>
    </ng-container>

    <div *ngIf="showFooter" class="table-footer">
      <mat-paginator #paginatorDefault
                     *ngIf="!totalPageslazy"
                     [pageSize]="rows"
                     [hidePageSize]="true"
                     showFirstLastButtons>
      </mat-paginator>

      <mat-paginator #paginatorLazy
                     *ngIf="totalPageslazy"
                     [pageSize]="rows"
                     [pageIndex]="pageIndex"
                     [length]="totalPageslazy"
                     [hidePageSize]="true"
                     (page)="cambioPage($event)"
                     showFirstLastButtons>
      </mat-paginator>
    </div>

  </div>

  <div *ngIf="noRowPattern && scroll && !input">
    <div *ngIf="!showFooter" class="table-scroll"  style="max-height:{{height}}vh;overflow-y:{{overflow}};">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
          <th mat-header-cell *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%'}">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>
          </th>


          <td mat-cell *matCellDef="let element; let i = index"
              [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso'
            }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [noclick]="noclick"
                          [onlyView]="onlyView"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          (callBack)="callBackRowDataColor($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

      </table>
    </div>
    <div *ngIf="showFooter" class="table-scroll">
      <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

          <th mat-header-cell  *matHeaderCellDef
              [ngStyle]="{'width': column.size + '%' } ">
            <app-row-header [rowProp]="column.prop"
                            [name]="column.name"
                            [order]="column.order"
                            [orderIcon]="orderColList[i]"
                            [filter]="true"
                            (callBackOrder)="changeOrder($event, i)">
            </app-row-header>

          </th>

          <td mat-cell *matCellDef="let element; let i = index"
              [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso'
            }"
              [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

            <app-row-data [rowProp]="column.prop"
                          [reload]="reload"
                          [rowData]="element"
                          [text0]="column.text0"
                          [text1]="column.text1"
                          [refValue]="column.refValue"
                          [onlyView]="onlyView"
                          [colTooltip]="column.tooltip || false"
                          [size]="column.size"
                          [noclick]="noclick"
                          (callBack)="callBackRowDataColor($event)">
            </app-row-data>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>
      </table>
    </div>
    <div *ngIf="noRowPattern && scroll">
      <div *ngIf="!showFooter && !pulizie" class="table-scroll"  style="max-height:{{height}}vh;overflow-y:{{overflow}};">
        <table mat-table [dataSource]="dataSource">
          <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index;">
            <th mat-header-cell *matHeaderCellDef
                [ngStyle]="{'width': column.size + '%'}">
              <app-row-header [rowProp]="column.prop"
                              [name]="column.name"
                              [order]="column.order"
                              [orderIcon]="orderColList[i]"
                              [filter]="true"
                              (callBackOrder)="changeOrder($event, i)">
              </app-row-header>
            </th>


            <td mat-cell *matCellDef="let element; let i = index"
                [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso'
            }"
                [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

              <app-row-data [rowProp]="column.prop"
                            [reload]="reload"
                            [rowData]="element"
                            [text0]="column.text0"
                            [text1]="column.text1"
                            [refValue]="column.refValue"
                            [noclick]="noclick"
                            [onlyView]="onlyView"
                            [colTooltip]="column.tooltip || false"
                            [size]="column.size"
                            (callBack)="callBackRowDataColor($event)">
              </app-row-data>
            </td>

          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>

        </table>
      </div>
      <div *ngIf="showFooter && !pulizie" class="table-scroll">
        <table mat-table [dataSource]="dataSource">
          <ng-container [matColumnDef]="column.prop" *ngFor="let column of columnsInfo; let i = index">

            <th mat-header-cell  *matHeaderCellDef
                [ngStyle]="{'width': column.size + '%' } ">
              <app-row-header [rowProp]="column.prop"
                              [name]="column.name"
                              [order]="column.order"
                              [orderIcon]="orderColList[i]"
                              [filter]="true"
                              (callBackOrder)="changeOrder($event, i)">
              </app-row-header>

            </th>

            <td mat-cell *matCellDef="let element; let i = index"
                [ngClass]="{
            'rigaVerde': element.coloreRiga == 'verde',
            'rigaGialla': element.coloreRiga == 'giallo',
            'rigaArancione': element.coloreRiga == 'arancione',
            'rigaRossa': element.coloreRiga == 'rosso'
            }"
                [ngStyle]="{'width': column.size ? column.size.toString() + '%' : '' }">

              <app-row-data [rowProp]="column.prop"
                            [reload]="reload"
                            [rowData]="element"
                            [text0]="column.text0"
                            [text1]="column.text1"
                            [size]="column.size"
                            [onlyView]="onlyView"
                            [colTooltip]="column.tooltip || false"
                            [size]="column.size"
                            (callBack)="callBackRowDataColor($event)">
              </app-row-data>
            </td>

          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsName"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsName;" [style]="selectColor(row)"></tr>
        </table>
      </div>
      <ng-container *ngIf="!tableData?.length">
        <div class="msg-nessuno">
          {{nessunElemento}}
        </div>
      </ng-container>

      <div *ngIf="showFooter && !input" class="table-footer">
        <mat-paginator #paginatorDefault
                       *ngIf="!totalPageslazy"
                       [pageSize]="rows"
                       [hidePageSize]="true"
                       showFirstLastButtons>
        </mat-paginator>

        <mat-paginator #paginatorLazy
                       *ngIf="totalPageslazy"
                       [pageSize]="rows"
                       [pageIndex]="pageIndex"
                       [length]="totalPageslazy"
                       [hidePageSize]="true"
                       (page)="cambioPage($event)"
                       showFirstLastButtons>
        </mat-paginator>
      </div>

    </div>
  </div>
</div>
