export class Paged {
  last?: boolean;
  totalPages?: number;
  totalElements?: number;
  size?: number;
  number?: number;
  first?: boolean;
  numberOfElements?: number;
  empty?: boolean;

  constructor(data: any) {
    Object.assign(this, data);
  }

}
