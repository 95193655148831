import {MyDate} from "./MyDate";

export class PulizieHeader {
  idStanza: number;
  idUnitaServizio: string;
  saCod: string;
  saDestinazione: string;
  desStanza: string;
  desSetting: string;
  desUnitaServizio: string;
  colore: string;
  desAreaControllo: string;
  categoriaLocali: string;
  intervento1: string;
  intervento2: string;
  intervento3: string;
  ripasso: string;
  frequenza: string;
  dataInizio?: MyDate;
  scheda?: boolean;


  constructor(data: any) {
    Object.assign(this, data);
    this.dataInizio = this.dataInizio ? new MyDate(this.dataInizio) : undefined;
  }
}
