import {Richiesta} from "./Richiesta";

export class FormResponse {
  type: string;
  richiesta: Richiesta;

  constructor(type: string, data: Richiesta) {
    this.type = type;
    this.richiesta = data;
  }
  getOggUpdate() {
    return this.getOggCreate();
  }

  getOggCreate(): FormResponseCreate {
    return {
      type: this.type,
      richiesta: this.richiesta
    };
  }
}

export interface FormResponseCreate {
  type: string;
  richiesta: Richiesta;
}

export interface FormResponseUpdate extends FormResponseCreate {

}
