import {Paged} from "./Paged";
import {MyDate} from "./MyDate";
import {CentroCosto} from "./CentroCosto";

export class PuntoPrelievo {

	id?: string;
    ppId?: string;
    ppDesc?: string;
    centroCosto?: CentroCosto;
    ccDesc?: string;
    lgDesc?: string;
    dataInizioVal?: MyDate;
    dataFineVal?: MyDate;


    constructor(data: any) {
        Object.assign(this, data);
        this.centroCosto = data.centroCosto ? new CentroCosto(data.centroCosto) : undefined;
        this.dataInizioVal = data.dataInizioVal ? new MyDate(data.dataInizioVal) : undefined;
        this.dataFineVal = data.dataFineVal ? new MyDate(data.dataFineVal) : undefined;
    }

    /* servono per essere visualizzati nei dropdown */
    get viewValue(): string {
        return this.ppDesc ? this.ppDesc : '';
    }
    get value(): PuntoPrelievo {
        return this;
    }

    get desCentroCosto() {
        //return this.centroCosto ? this.centroCosto.viewValue : '';
        return this.centroCosto ? this.centroCosto.ccId + ' - '+this.centroCosto.viewValue : '';
    }

    get dataInizioValGGMMAA() {
        return this.dataInizioVal ? this.dataInizioVal.giornoMeseAnno : null;
    }

    get datadataFineValGGMMAA() {
        return this.dataFineVal ? this.dataFineVal.giornoMeseAnno : null;
    }



    getOggCreate() {
        return {
            ppId: this.ppId,
            ppDesc: this.ppDesc,
            centroCosto: this.centroCosto ? { ccId: this.centroCosto!.ccId!} : undefined,
            ccDesc: this.ccDesc,
            lgDesc: this.lgDesc,
            dataInizioVal: this.dataInizioVal ? this.dataInizioVal.dateSave : null,
            dataFineVal: this.dataFineVal  ? this.dataFineVal.dateSave : null,


        };
    }

    getOggUpdate() {
        return this.getOggCreate();
    }



}

export class PuntoPrelievoPaged extends Paged {
    content: PuntoPrelievo[];

    constructor(data: any) {
        super(data);

        this.content = data.content ? data.content.map((ts: any) => new PuntoPrelievo(ts)) : [];
    }
}

