import {forkJoin, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {HttpHeaders} from "@angular/common/http";
import {FormControl} from "@angular/forms";
import {SdgLibService} from "sdg-lib";
import {Utente} from "../entities/Utente";
import {Role} from "../entities/Role";
import {TestiService} from "./config/testi.service";
import {MyDate} from "../entities/MyDate";
import {SimpleUtente} from "../entities/SimpleUtente";
import {MsgService} from "./utils/msg.service";
import {Credentials} from "../entities/Credentials";
import {ApiRestKeycloakService} from "./portal/api-rest-keycloak.service";
import {MailService} from "./portal/mail.service";
import {ApiRestServiceAnagrafiche} from "./api-rest.service-anagrafiche";

function check_if_valid(value: string, utenti: Utente[], type: string, update?:number){
    let flag = true;
    if(value){
        let exist = false;
        if (type == "username") {
            for (let utente of utenti){ if (value == utente.username) {exist = true;}}
            if (exist) {flag = false;}
        }
        if (type == "email") {
            for (let utente of utenti){ if (value == utente.email) { exist = true;}
            }
            if (exist) { flag = false }
            if (update! < 2){ flag = true }
        }
    }
    return flag ? null : {
        valid: {
            valid: false
        }
    };
}


@Injectable({
    providedIn: 'root'
})
export class UtentiService {

    utenti: Utente[] | undefined;

    testi: any;

    utente: string;

    preUtente:Utente;

    riattiva = false;

    unblock = false;

    update = 2;

    userId: string;

    constructor(private apiKeycloakRestService: ApiRestKeycloakService,
                private mailService: MailService,
                private apiRestService: ApiRestServiceAnagrafiche,
                private msgService: MsgService,
                private readonly shared: SdgLibService,
                private testiService: TestiService){
        this.testi = this.testiService.componentAnagrafiche;
    }

    public userExists = (control:FormControl) => {
        return check_if_valid(control.value, this.utenti!, "username");
    }

    public mailExists = (control:FormControl) => {
        const update = this.update;
        this.update = this.update + 1;
        return check_if_valid(control.value, this.utenti!, "email", update);
    }



    getAllUtenti(): Observable<Utente[]> {
        const url = '/users';
        return this.apiKeycloakRestService.getWithHeader(url)
            .pipe(
                map(res => res.filter((utente: any) => utente.username != "adminalfresco").map((utente: any) => new Utente(utente)))
            );
    }

    getAllUtentiCount(): Observable<number> {
        const url = '/users/count';
        return this.apiKeycloakRestService.getWithHeader(url)
            .pipe();
    }

    getAllUtentiPaged(first: string, max: string, filters: string[][]): Observable<Utente[]> {
        let param = "";
        for (let filter of filters){
            param += "&"+filter[0]+"="+filter[1]
        }

        const url = '/users?first=' + first +  '&max=' + max + param;
        return this.apiKeycloakRestService.getWithHeader(url)
            .pipe(
                map(res => res.filter((utente: any) => utente.username != "adminalfresco").map((utente: any) => new Utente(utente)))
            );
    }


    setUpdate() {
        this.update = 0;
    }


    getUtente(): string {
        return this.utente;
    }

    setUtenti(utenti: Utente[]) {
        this.utenti = utenti;
    }

    getPreUtente(): Utente {
        return this.preUtente;
    }

    setPreUtente(utente: Utente) {
        this.preUtente = utente;
    }

    getRiattiva(): boolean {
        return this.riattiva;
    }

    setRiattiva(data: boolean) {
        this.riattiva = data;
    }

    getUnblock(): boolean {
        return this.unblock;
    }

    setUnblock(data: boolean) {
        this.unblock = data;
    }


    private userMail(user: Utente, data: Utente): string {
        if (user.id == data.id){
            return user.email;
        } else {
            return '';
        }
    }

    validateBeforeSave(data: Utente): number {
        let chkUser = true;
        let chkMail = true;
        if (data.id == undefined) {
            this.utenti!.forEach((user) => {
                if (user.username == data.username) {
                    chkUser = false;
                }
                if (user.email == data.email) {
                    chkMail = false;
                }
            });
        } else {
            this.utenti!.forEach((user) => {
                if (data.email != this.userMail(user,data)) {
                    if (user.email == data.email) {
                        chkMail = false;
                    }
                }
            });
        }


        if (chkUser && chkMail) {
            return 0;
        }
        if (!chkUser && !chkMail) {
            this.msgService.msgStringError(this.testi.errUserMailExist,this.testi);
            return 3;
        }
        if (chkUser && !chkMail) {
            this.msgService.msgStringError(this.testi.errMailExist,this.testi);
            return 1;
        }
        if (!chkUser && chkMail) {
            this.msgService.msgStringError(this.testi.errUserExist,this.testi);
            return 2;
        }
        return 0;
    }


    createUserWithRoles(utente: Utente): Observable<any> {
        return this.createUtente(utente).pipe(
            switchMap(userCreate => {
                return this.getUtenteByUsername(utente).pipe(
                    switchMap(data => {
                        this.storicizza(data);
                        return forkJoin(
                            this.setUserRoles(data[0].id, utente),
                            this.setRealmRoles(data[0].id, utente),
                            this.setUserPassword(data[0].id, utente)
                        );
                    })
                )
            }))
    }

    private storicizza(data: any){
        const now = new Date();
        const simpleUser = new SimpleUtente(data[0]);
        simpleUser.editDate = new MyDate(now);
        simpleUser.editUser = this.shared.utente.username;
        const restUser$ = this.storicizzaUtente(simpleUser);
        restUser$.subscribe(res => {},
            error => this.msgService.msgError(this.testi,"TestERROR") //TODO this.testi.errore
        );
    }

    private storicizzaUpdate(data: Utente){
        const now = new Date();
        const simpleUser = new SimpleUtente(data);
        simpleUser.editDate = new MyDate(now);
        simpleUser.editUser = this.shared.utente.username;
        const restUser$ = this.storicizzaUtente(simpleUser);
        restUser$.subscribe(res => {},
            error => this.msgService.msgError(this.testi,"TestERROR") //TODO this.testi.errore
        );
    }



    getUserByMail(mail: string){
        let url = '/users?email='+ mail;
        return this.apiKeycloakRestService.getWithHeader(url);
    }

    createUtente(utente: Utente): Observable<any> {
        const url = `/users`;
        const ut = utente.getOggCreate();
        return this.apiKeycloakRestService.postWithHeaderAndBody(url, utente.getOggCreate());
    }

    setUserRoles(idUtente: string, utente: Utente): Observable<any> {
        let url = '/users/' + idUtente + '/role-mappings/realm';
        return this.apiKeycloakRestService.postWithHeaderAndBody(url, utente.roles);
    }

    setRealmRoles(idUtente: string, utente: Utente): Observable<any> {
        const qu = new Role({"id": "36901bd7-1330-4a3b-9cde-8c7972e7b89f",
            "name": "query-users",
            "description": "${role_query-users}",
            "composite": false,
            "clientRole": true,
            "containerId": "acd6a1a6-88ce-4628-b55d-4ea207a2001a"});
        const vu = new Role({
                "id": "f4775055-c481-413c-bb8b-49396c1adb52",
                "name": "view-users",
                "description": "${role_view-users}",
                "composite": true,
                "clientRole": true,
                "containerId": "acd6a1a6-88ce-4628-b55d-4ea207a2001a"
        });
        const mu = new Role({
            "id": "744d5291-a6bc-444d-b285-9725687adfc2",
            "name": "manage-users",
            "description": "${role_manage-users}",
            "composite": false,
            "clientRole": true,
            "containerId": "acd6a1a6-88ce-4628-b55d-4ea207a2001a"
        });
        const qr = new Role({
            "id": "13538d9c-e97a-4a3f-b9fc-f4fdb3ec2bb0",
            "name": "query-realms",
            "description": "${role_query-realms}",
            "composite": false,
            "clientRole": true,
            "containerId": "acd6a1a6-88ce-4628-b55d-4ea207a2001a"
        });
        const qg = new Role({
            "id": "3e70303d-cb40-49df-b429-0e2b7026a8ab",
                "name": "query-groups",
                "description": "${role_query-groups}",
                "composite": false,
                "clientRole": true,
                "containerId": "acd6a1a6-88ce-4628-b55d-4ea207a2001a"
        });
        const arr= [qu,vu,mu,qr,qg];
        const rm = 'acd6a1a6-88ce-4628-b55d-4ea207a2001a';
        let url = '/users/' + idUtente + '/role-mappings/clients/'+rm;
        return this.apiKeycloakRestService.postWithHeaderAndBody(url, arr);
    }

    generatePassword(): string {
        const minLength = 8;
        const capitalLetter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const smallLetter = 'abcdefghijklmnopqrstuvwxyz';
        const numbers = '0123456789';
        const specialCharacters = '!@#$%^&*()_+[]{}|;:,.<>?';

        let password = '';

        // Ensure at least one character from each category
        password += capitalLetter[Math.floor(Math.random() * capitalLetter.length)];
        password += smallLetter[Math.floor(Math.random() * smallLetter.length)];
        password += numbers[Math.floor(Math.random() * numbers.length)];
        password += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];

        // Fill the rest of the password
        const remainingLength = minLength - password.length;
        const allCharacters = capitalLetter + smallLetter + numbers + specialCharacters;
        for (let i = 0; i < remainingLength; i++) {
            password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
        }

        // Shuffle the password characters
        password = this.shuffle(password);

        return password;
    }

    shuffle(input: string): string {
        const array = input.split('');
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array.join('');
    }

    setUserPassword(idUtente: string, utente: Utente): Observable<any> {
        let url = '/users/' + idUtente + '/reset-password';
        const password = this.generatePassword();
        ////console.log("Password:", password)
        return forkJoin([
            this.mailService.sendWelcomeMail(utente, password),
            this.apiKeycloakRestService.putWithHeaderAndBody(url, new Credentials({"value":password}))
        ]);
    }

    getAllRuoli(header: HttpHeaders): Observable<Role[]> {
        const url = `/roles`;
        return this.apiKeycloakRestService.getWithHeader(url, header)
            .pipe(
                map(res => res.map((ruoli: any) => new Role(ruoli))))
    }


    getUserRoles(header: HttpHeaders, id: string): Observable<Role[]> {
        const url = '/users/' + id + '/role-mappings/realm';
        return this.apiKeycloakRestService.getWithHeader(url, header)
            .pipe(
                map(res => res.map((ruoli: any) => new Role(ruoli)))
            )
    }

    getUserRealmRoles(header: HttpHeaders, id: string): Observable<Role[]> {
        const url = '/users/' + id + '/role-mappings';
        return this.apiKeycloakRestService.getWithHeader(url, header);

    }

    delUserRoles(idUtente: string,  roles?: Role[]): Observable<any> {
        let url = '/users/' + idUtente + '/role-mappings/realm';
        return this.apiKeycloakRestService.deleteWithHeaderAndBody(url, roles);
    }



    getUtenteByUsername(utente: Utente): Observable<Utente[]> {
        const url = `/users?username=` + utente.username + "&exact=true";
        return this.apiKeycloakRestService.getWithHeader(url)
            .pipe(
                map(res => res.map((utente: Utente) => new Utente(utente)
                ))
            );
    }


    updateUtente(utente: Utente, remRuoli: Role[] | undefined): Observable<any> {
        const url = `/users/${utente.id}`;
        this.storicizzaUpdate(utente);
        return forkJoin([
            this.apiKeycloakRestService.putWithHeaderAndBody(url, utente.getOggCreate()),
            this.delUserRoles(utente.id, remRuoli),
            this.setUserRoles(utente.id, utente),
        ]);
    }

    storicizzaUtente(utente: SimpleUtente): Observable<any> {
        const url = `userhistory/add`;
        return this.apiRestService.postWithBody(url, utente.getOggCreate());
    }

}
