import {Injectable} from '@angular/core';

import {ApiRestServiceAnagrafiche} from './api-rest.service-anagrafiche';
import {Observable} from 'rxjs';
import {Dieta, DietaPaged} from '../entities/Dieta';
import {map} from 'rxjs/operators';
import {HttpHeaders} from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class DieteService {

  constructor( private apiRestService: ApiRestServiceAnagrafiche) {}


  getAllDiete(): Observable<Dieta[]> {
    const url =  `diete/all`;
    return this.apiRestService.getWithHeader(url)
      .pipe(
        map( res => res ? res.map((ts: any) => new Dieta(ts)) : [] )
      );
  }

  getAllDieteFiltrataPaged(page: number, size: number, params: any[][]): Observable<DietaPaged> {
    const url =  `diete/filtratapaged?page=${page}&size=${size}`;
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Paged': 'true'
    });

    return this.apiRestService.getWithParams(url, params, header)
        .pipe(
            map( res => new DietaPaged(res) )
           //map( res => res ? res.map((ts: any) => new DietePaged(ts)) : [] )
        );
  }





  getDiete(id: number): Observable<Dieta> {
    const url =  `diete/id=${id}`;

    return this.apiRestService.getWithHeader(url)
      .pipe(
        map( res => new Dieta(res) )
      );
  }



  deleteDiete(id: number, headers: HttpHeaders): Observable<Dieta> {
    const url =  `diete/id=${id}`;

    return this.apiRestService.delete(url, headers);
  }


  updateDieta(dieta: Dieta): Observable<Dieta> {
    const url = `diete/update/id=${dieta.id}`;

    return this.apiRestService.patchWithBody(url, dieta.getOggUpdate());
  }

  createDieta(dieta: Dieta): Observable<Dieta> {
    const url = `diete/save`;

    return this.apiRestService.postWithBody(url, dieta.getOggCreate());
  }



}
