
<div *ngIf="richiesta.idTicket">
    <app-header-popup cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container"
                      [testo]="title"
                  [loading]="loading"
                  (close)="cancel($event)">
</app-header-popup>
</div>
<div *ngIf="!richiesta.idTicket">
    <app-header-popup cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container"
                      [testo]="title"
                      [loading]="loading"
                      (close)="cancel($event)">
    </app-header-popup>
</div>
<!-- OrderByDesc - title -->
<div *ngIf="accessibilita != ''" class="accessibilita">{{accessibilita}}</div>
<div *ngIf="arrayFormInfo">
    <app-form-column-richieste [arrayFormInfo]="arrayFormInfo"
                     [formButton]="formButton"
                     [nullDropDown]="false"
                     [objValue]="richiesta"
                     [dmsUpload]="dmsUpload"
                     [testi]="testi"
                     [type]="'richieste'"
                     [selectsGroup]="dropdownList"
                     [groupValidator]="'ruolo'"
                     [update]="update"
                     [error]="error"
                     [msgForbidden]="msgForbidden"
                     [newmode]="false"
                     (updateForm)="updateForm($event)"
                     (saveForm)="saveOgg($event)"
                     (cancel)="cancel($event)">
    </app-form-column-richieste>
</div>



