import {Paged} from "./Paged";
import {MyDate} from "./MyDate";
import {UnitaServizio} from "./UnitaServizio";
import {CategoriaLocali} from "./CategoriaLocali";
import {PuntoPrelievo} from "./PuntoPrelievo";
import {CentroCosto} from "./CentroCosto";
import {ListinoCosti} from "./ListinoCosti";


export class StanzaLight {

    categoriaLocali?: CategoriaLocali;
    centroCosto?: CentroCosto;
    colore?: ListinoCosti;
    dataInizioVal?: MyDate;
    id?: number;
    puntoPrelievo?: PuntoPrelievo;
    saDesc?: string;
    unitaServizio?: UnitaServizio;

    constructor(data: any) {
        this.categoriaLocali = undefined;
        this.centroCosto =  undefined;
        this.colore =  undefined;
        this.dataInizioVal =  undefined;
        this.id = data.id ? data.id : undefined;
        this.saDesc = data.saDesc ? data.saDesc : data.sa_desc ? data.sa_desc : undefined;
        this.unitaServizio = undefined;
        this.puntoPrelievo =  undefined;


    }

    /* servono per essere visualizzati nei dropdown */
    get viewValue(): string {
        return this.saDesc ? this.saDesc : '';
    }
    get value(): StanzaLight {
        return this;
    }

    get desUnitaServizio() {
        return this.unitaServizio ? this.unitaServizio.viewValue : '';
    }

    get desPuntoPrelievo() {
        return this.puntoPrelievo ? this.puntoPrelievo.ppId + ' - ' + this.puntoPrelievo.viewValue : '';
    }

    get desCentroCosto() {
        return this.centroCosto ? this.centroCosto.ccId + ' - ' + this.centroCosto.viewValue : '';
    }

    get desColore() {
        return this.colore ? this.colore.viewValue : '';
    }


    get desCategoriaLocale() {
        return this.categoriaLocali ? this.categoriaLocali.viewValue : '';
    }


    get dataInizioValGGMMAA() {
        return this.dataInizioVal ? this.dataInizioVal.giornoMeseAnno : null;
    }


    getOggCreate()  {
        return {
            id: 		          this.id,
            unitaServizio: this.unitaServizio ? { id: this.unitaServizio!.id!} : undefined,
            colore: 		       this.colore ? { colore: this.colore!.colore!} : undefined,
            dataInizioVal: this.dataInizioVal ? this.dataInizioVal.dateSave : null,
            saDesc: 		       this.saDesc,
            puntoPrelievo:         this.puntoPrelievo ? { ppId: this.puntoPrelievo!.ppId!} : undefined,
            centroCosto:           this.centroCosto ? { ccId: this.centroCosto!.ccId!} : undefined,
            categoriaLocali: this.categoriaLocali ? { id: this.categoriaLocali!.id!} : undefined,

        };
    }

    getOggUpdate() {
        return this.getOggCreate();
    }



 }

export class StanzaLightPaged extends Paged {
    content: StanzaLight[];

    constructor(data: any) {
        super(data);

        this.content = data.content ? data.content.map((ts: any) => new StanzaLight(ts)) : [];
    }
}

