import {Component, OnInit} from '@angular/core';
import {TableRichiesteCols} from './table-richieste-cols';
import {MatDialog} from '@angular/material/dialog';
import {PageEvent} from "@angular/material/paginator";
import {PopUpDetailsGroups} from "../../entities/PopUpDetails";
import {Richiesta, richiestaPaged, storico} from "../../entities/Richiesta";
import {HttpHeaders} from "@angular/common/http";
import {KeycloakService} from "keycloak-angular";
import {TestiService} from "../../service/config/testi.service";
import {DmsService} from "../../service/dms.service";
import {RuoloService} from "../../service/ruolo.service";
import {RichiesteService} from "../../service/richieste.service";
import {tipoStanza} from "../../entities/richiesteDetails";
import {RichiesteDetailsComponent} from "../richieste-details/richieste-details.component";
import {CallbackTableState} from "../../entities/enums/CallbackTableState";
import {TableName} from "../../entities/table/TableName";
import {ConfigService} from "../../service/config/config.service";
import {DetailsCallback} from "../../entities/table/DetailsCallback";
import {SdgLibService} from "sdg-lib";
import {SharedFunction} from "../../service/utils/SharedFunction";
import {CallbackDetailsState} from "../../entities/enums/CallbackDetailsState";
import {RichiesteDetailsComponentPop} from "./richieste-details-pop/richieste-details-pop.component";
import {SnackbarService} from "../../service/utils/snackbar.service";
import {MsgService} from "../../service/utils/msg.service";
import {TableCallback} from "../../entities/table/TableCallback";
import {Gestore} from "../../entities/Gestore";
import {ApiRestServicePortal} from "../../service/portal/api-rest.service-portal";


@Component({
  selector: 'app-richieste',
  templateUrl: './richieste.component.html',
  styleUrls: ['./richieste.component.scss']
})
export class RichiesteComponent implements OnInit {

  richieste?: Richiesta[];

  dmsNew: boolean = false;

  filterSelected: string[][] = [];

  token: string ;

  stanze?: tipoStanza[];

  msgForbidden: string;

  richiestePaged: richiestaPaged;
  richiesteStorico: storico[];
  richiestaStoricoNum: string;

  nuovo: boolean;

  instance:string;

  tableVoci: TableName[];
  tableDetails: TableName[];

  pageIndex = 0;

  formButton: any;
  loading = false;
  rows = 15;
  isMobile: boolean;
  testi: any;
  form: any;
  _token: string;
  role: string;

  dropdownList: Map<string, any[]> = new Map<string, any[]>();

  _headers: HttpHeaders;

  richiesteUsers= ["caposala", "operatore", "responsabile", "gestore", "ammnistratore"];

  constructor(private testiService: TestiService,
              private richiesteService: RichiesteService,
              private ruoloService: RuoloService,
              private msgService: MsgService,
              private dialog: MatDialog,
              private configService: ConfigService,
              private portalService: ApiRestServicePortal,
              private TRCols: TableRichiesteCols,
              private snackbar: SnackbarService,
              private dmsService: DmsService,
              private readonly keycloak: KeycloakService,
              private readonly shared: SdgLibService
              ) {
    this.testi = this.testiService.componentRichieste;
    this.instance = this.portalService.portalName;

    this.msgForbidden = this.testi.msgForbidden;

    //console.log(shared.utente.username);

    this.isMobile = this.configService.isMobile;
    this.tableVoci = shared.utente.username != 'a.micheli' ? TableRichiesteCols.getRichiesteList(this.testi) : TableRichiesteCols.getRichiesteListMobile(this.testi);
    this._token = shared.utente.token ??  "";

    this.role = shared.utente!.roles!.filter(value => this.richiesteUsers.includes(value))[0];

    this.getRichieste();

    this.msgService.msgSnackbar$
        .subscribe( res => this.snackbar.openSnackBar(res) );
  }

  ngOnInit(): void {
  }

  private getHeaders(token: string) {
    this._headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Access-Control-Allow-Origin': '*',  // sabina: tolto per il CORS
          'Authorization': 'Bearer ' + token
      });
  }

  private getRichieste() {
    this.loading = true;
    this.richiesteService.getRichiesteFiltrataPaged(this.pageIndex, this.rows , this.filterSelected)
        .subscribe( res => {
              this.richiestePaged = res;
              this.loading = false;
              this.shared.setGlobalLoading(true);
            },
            () => this.loading = false
        );
    this.loading= false
  }

  private uniqueId(): string {
      const dateString = Date.now().toString(36);
      const randomness = Math.random().toString(36).substr(2);
      return dateString + randomness;
  }


  private openDetails(richiesta: Richiesta, pattern: string) {
    if (pattern === "operatorePRESA IN CARICO OPERATORE"){
        richiesta.dataScadenza=undefined;
        //richiesta.tipoRifiuto=undefined;
    }
    this.shared.setGlobalLoading(false);
    if (!richiesta.dms) {
        this.dmsNew = true;
        this.dmsService.createTicketFolder(this.uniqueId()).subscribe(res => {
            richiesta.dms = res.entry.id;
            this.openDetailsPopup(richiesta,pattern,this.dmsNew);
            this.shared.setGlobalLoading(true);
        },
            err => {
                this.shared.setGlobalLoading(true);
                this.openDetailsPopup(richiesta,pattern,this.dmsNew);
            });
    } else {
       this.openDetailsPopup(richiesta,pattern, this.dmsNew);
       this.shared.setGlobalLoading(true);
    }
  }

  private openDetailsPopup(richiesta: Richiesta, pattern: string, dmsNew: boolean){
      const groupList = pattern.includes("Nuova") ? this.TRCols.groupsNew(this.instance,this.testi, pattern) : this.TRCols.groups(this.instance,this.testi, pattern);
      const data: PopUpDetailsGroups = {oggetto: richiesta, formGroups: groupList, dropdownList: this.dropdownList, dmsNew: dmsNew};
      const dialogConfig = SharedFunction.getConfigWidePopUp(this.isMobile);
      SharedFunction.openPopUp(this.dialog, data, RichiesteDetailsComponent, dialogConfig)
          .subscribe( (res: DetailsCallback) => this.callbackDetails(res) );
  }


  private openStorico() {
      this.tableDetails = !this.isMobile ? TableRichiesteCols.getStoricoRichiesteList(this.testi) : TableRichiesteCols.getStoricoRichiesteListMobile(this.testi);
      const dialogConfig = SharedFunction.getConfigMaxPopUp(this.isMobile);
      const tablePopup = [this.richiesteStorico,this.tableDetails,this.richiestaStoricoNum];
      SharedFunction.openPopUpTable(this.dialog, tablePopup, RichiesteDetailsComponentPop, dialogConfig)
          .subscribe( (res: DetailsCallback) => this.callbackDetails(res) );
  }

  private save(richiesta: Richiesta) {
      if (richiesta.stanza === "emptyItem"){
          richiesta.stanza = "";
      }
    const rest$ = this.richiesteService.createRichiesta(richiesta);
        rest$.subscribe(res => {
                this.dmsService.renameDMS(res.dms!, res.idTicket.toString()).subscribe();
                this.msgService.msgSuccess(this.testi,this.testi.richiesta);
                this.pageIndex=0;
                this.getRichieste();
            },
            error => {
            console.log(error)
            this.getRichieste()
            }
        );
  }

  private purgeRichiesta(richiesta: Richiesta): Richiesta{
      if (richiesta.tipoRifiuto && richiesta.tipoRifiuto.idTipoRifiuto) {
          richiesta.utenteGestore = undefined;
      }
      return richiesta;
  }

  private avanza(stato: number, richiesta: Richiesta) {
    switch (stato){
      case 0:
          this.richiesteService.avanzaRichiesta("ticket/rivendica",richiesta).subscribe(res => {
                if (this.dmsNew == true){
                    this.dmsService.renameDMS(res.dms!, res.idTicket.toString()).subscribe();
                }
                this.msgService.msgSuccess(this.testi,this.testi.richiesta, true);
                this.getRichieste();
              },
              error => {
              this.msgService.msgStringError("Operazione non riuscita, verificare lo stato della richiesta", this.testi);
              this.getRichieste()
              }
          );
          break;
      case 1:
        this.richiesteService.avanzaRichiesta("ticket/approva",richiesta).subscribe(res => {
              this.msgService.msgSuccess(this.testi,this.testi.richiesta, true);
              this.getRichieste();
            },
            error => this.getRichieste()
        );
        break;
      case 2:
        this.richiesteService.avanzaRichiesta("ticket/rifiuta",this.purgeRichiesta(richiesta)).subscribe(res => {
              this.msgService.msgSuccess(this.testi, this.testi.richiesta, true);
              this.getRichieste();
            },
            error => this.getRichieste()
        );
        break;
      case 3:
            this.richiesteService.avanzaRichiesta("ticket/riprendiassegnata",richiesta).subscribe(res => {
                    this.msgService.msgSuccess(this.testi,this.testi.richiesta, true);
                    this.getRichieste();
                },
                error => this.getRichieste()
            );
            break;
        case 4:
            this.richiesteService.avanzaRichiesta("ticket/confermarifiuto",richiesta).subscribe(res => {
                    this.msgService.msgSuccess(this.testi,this.testi.richiesta, true);
                    this.getRichieste();
                },
                error => this.getRichieste()
            );
            break;
        case 5:
            this.richiesteService.avanzaRichiesta("ticket/nonconfermarifiuto",richiesta).subscribe(res => {
                    this.msgService.msgSuccess(this.testi,this.testi.richiesta, true);
                    this.getRichieste();
                },
                error => this.getRichieste()
            );
            break;
        case 6:
            this.richiesteService.avanzaRichiesta("ticket/accetta",richiesta).subscribe(res => {
                    this.msgService.msgSuccess(this.testi,this.testi.richiesta, true);
                    this.getRichieste();
                },
                error => this.getRichieste()
            );
            break;
        case 7:
            this.richiesteService.avanzaRichiesta("ticket/chiudi",richiesta).subscribe(res => {
                    this.msgService.msgSuccess(this.testi,this.testi.richiesta, true);
                    this.getRichieste();
                },
                error => this.getRichieste()
            );
            break;
        case 8:
            this.richiesteService.updateRichiesta("ticket/restituiscirichiesta",richiesta).subscribe(res => {
                    this.msgService.msgSuccess(this.testi,this.testi.richiesta, true);
                    this.getRichieste();
                },
                error => this.getRichieste()
            );
            break;
        case 9:
            this.richiesteService.contestaRichiesta("ticket/contestachiuso",richiesta).subscribe(res => {
                    this.msgService.msgSuccess(this.testi,this.testi.richiesta, true);
                    this.getRichieste();
                },
                error => this.getRichieste()
            );
            break;

    }

  }

  private callbackDetails(event: DetailsCallback) {
    switch (event.type) {
        case CallbackDetailsState.CANCEL:
            this.getRichieste();
            break;
      case CallbackDetailsState.SAVE:
            this.save(event.data);
            break;
      case CallbackDetailsState.CONTESTA:
          this.avanza(9,event.data);
        break;
      case CallbackDetailsState.APPROVA:
        this.avanza(1,event.data);
        break;
      case CallbackDetailsState.RIFIUTA:
        this.avanza(2,event.data);
        break;
      case CallbackDetailsState.RIVENDICA:
        this.avanza(0,event.data);
        break;
      case CallbackDetailsState.RIPRENDI:
        this.avanza(3,event.data);
        break;
      case CallbackDetailsState.CRIFIUTO:
          this.avanza(4,event.data);
        break;
      case CallbackDetailsState.NCRIFIUTO:
          this.avanza(5,event.data);
        break;
      case CallbackDetailsState.ACCETTA:
          this.avanza(6,event.data);
        break;
      case CallbackDetailsState.RRICHIESTA:
          this.avanza(8,event.data);
        break;
      case CallbackDetailsState.CRICHIESTA:
          this.avanza(7,event.data);
        break;
    }
  }

  public cambioPage(event: PageEvent) {
        this.pageIndex = event.pageIndex;
        this.getRichieste();
    }

  isCaposala(): boolean{
    return this.role === "caposala" ? true : false;
  }

  isOperatore(): boolean{
      return this.role === "operatore" ? true : false;
  }

  isResponsabile(): boolean{
      return this.role === "responsabile" ? true : false;
  }



  callBackTable(event: TableCallback) {
    switch (event.type) {
      case CallbackTableState.AGGIORNA:
        this.getRichieste();
        break;
      case CallbackTableState.NUOVO:
       this.openDetails(new Richiesta({active: true}), this.role+"Nuova");
        break;
      case CallbackTableState.MODIFICA:
      case CallbackTableState.SINGLE:
      case CallbackTableState.DOUBLE:
        this.openDetails(event.data, this.role+event.data.statoTicket.desStatoTicket);
        break;
      case CallbackTableState.DETTAGLIO:
        this.richiestaStoricoNum = event.data.idTicket;
          this.richiesteService.getStorico(event.data.idTicket)
              .subscribe( res => {
                      this.richiesteStorico= res;
                      this.openStorico();
                  }
              );
        break;
      case CallbackTableState.FILTER:
          this.filterSelected = event.data;
          this.pageIndex=0;
          this.cleanFilter();
          this.getRichieste();
        break;
    }
  }

  private cleanFilter(){
      this.filterSelected.forEach((element,index) => {
          if (typeof element[1] != "number" && typeof element[1] != "string" ) {
            delete this.filterSelected[index];
          }
      });
  }
}
