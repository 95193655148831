<form class="form-base" *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="actionSave()">

  <div class="formContainer" *ngFor="let formLine of arrayFormInfo">

    <div class="formFieldGroup">

      <label [for]="formLine.prop" class="form-label"><div *ngIf="formLine.text != ''">{{formLine.text}}:</div></label>

      <div class="form-input">
        <app-form-inputs [formType]="formLine"
                         [formControlName]="formLine.prop"
                         [objValue]="objValue"
                         [testi]="testi"
                         [dropdownList]="selectsGroup!.get(formLine!.list!)"
                         [dropdownList2]="selectsGroup!.get(formLine!.list2!)">
        </app-form-inputs>
      </div>

      <div class="form-error"> <span *ngIf="formGroup!.controls[formLine.prop].invalid"> * </span> </div>

    </div>

  </div>

  <div class="formContainer">
    <div class="formFieldGroup">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="form-legenda">
    <div *ngIf="formGroup!.invalid" class="legenda-form-error"> * {{testi.obbligatorio}} </div>

    <div *ngFor="let msg of msgs" class="legenda-form-error">
      <span *ngIf="formGroup!.controls[msg.prop].invalid && formGroup!.controls[msg.prop].touched" class="error-validator">{{msg.msg}}</span>
    </div>
  </div>


  <div class="footerForm" *ngIf="enableFooter">
    <app-form-field-footer [disabled]="formGroup!.invalid"
                           [showSave]="showSave"
                           [testi]="testi"
                           [showDelete]="showDelete"
                           [showCancel]="showCancel"
                           [blockSave]="blockSave"
                           [blockDelete]="blockDelete"
                           [textDelete]="textDelete"
                           [textSave]="textSave"
                           [color]="color"
                           (saveForm)="actionSave()"
                           (delete)="actionDelete()"
                           (cancel)="actionCancel()">
    </app-form-field-footer>
  </div>

</form>
