<div mat-dialog-title class="header-container-material">
  <div class="header-dialog">

    <div>{{testo}}</div>

    <div class="header-close" (click)="close.emit()">
      <i class="fas fa-times"></i>
    </div>

  </div>

</div>

<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

