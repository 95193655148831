import {Paged} from "./Paged";
import {MyDate} from "./MyDate";
import {SchedaControllo} from "./SchedaControllo";
import {AreaControllo} from "./AreaControllo";


export class CategoriaLocali {

    id?: number;
    descrizione?: string;
    categoriaCodice?: string;
    areaControllo?: AreaControllo;
    schedaControllo?: SchedaControllo;
    dataInizioVal?: MyDate;
    dataFineVal?: MyDate;

    constructor(data: any) {
        Object.assign(this, data);
        this.areaControllo = data.areaControllo ? new AreaControllo(data.areaControllo) : undefined;
        this.schedaControllo = data.schedaControllo ? new SchedaControllo(data.schedaControllo) : undefined;
        this.dataInizioVal = data.schedaControllo ? new MyDate(data.dataInizioVal) : undefined;
        this.dataFineVal = data.dataFineVal ? new MyDate(data.dataFineVal) : undefined;
    }

    getOggCreate() {
        return {

            descrizione: this.descrizione,
            categoriaCodice: this.categoriaCodice,
            areaControllo: this.areaControllo ? { areaControlloId: this.areaControllo!.areaControlloId!} : undefined,
            schedaControllo: this.schedaControllo ? { id: this.schedaControllo!.id!} : undefined,
            dataInizioVal: this.dataInizioVal ? this.dataInizioVal.dateSave : null,

        };
    }

    getOggUpdate() {
        return this.getOggCreate();
    }

    get viewValue(): string {
        return this.descrizione? this.descrizione : '';
    }

    get value(): CategoriaLocali {
        return this;
    }


    get dataInzioValGGMMAA() {
        return this.dataInizioVal ? this.dataInizioVal.giornoMeseAnno : null;
    }

    get dataFineValGGMMAA() {
        return this.dataFineVal ? this.dataFineVal.giornoMeseAnno : null;
    }

    get desAreaControllo() {
        return this.areaControllo ? this.areaControllo.viewValue : '';
    }

    get desSchedaControllo() {
        return this.schedaControllo ? this.schedaControllo.viewValue : '';
    }

}

export class CategoriaLocaliPaged extends Paged {
    content: CategoriaLocali[];

    constructor(data: any) {
        super(data);

        this.content = data.content ? data.content.map((ts: any) => new CategoriaLocali(ts)) : [];
    }
}

