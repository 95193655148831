import {Paged} from "./Paged";
import {MyDate} from "./MyDate";

export class AreaControllo {

    areaControlloId?: number;
    areaControlloDesc?: string;

    dataInizioVal?: MyDate;
    dataFineVal?: MyDate;



    constructor(data: any) {
        Object.assign(this, data);
        this.dataInizioVal = data.dataInizioVal ? new MyDate(data.dataInizioVal) : undefined;
        this.dataFineVal = data.dataFineVal ? new MyDate(data.dataFineVal) : undefined;
    }

    getOggCreate() {
        return {
            areaControlloId: this.areaControlloId,
            areaControlloDesc: this.areaControlloDesc,
            dataInizioVal: this.dataInizioVal ? this.dataInizioVal.dateSave : null,
        };
    }

    getOggUpdate() {
        return this.getOggCreate();
    }

    get viewValue(): string {
        return this.areaControlloDesc ? this.areaControlloDesc : '';
    }
	get value(): AreaControllo {
        return this;
    }


    get dataInizioString(): string {
        return this.dataInizioVal ? this.dataInizioVal?.giornoMeseAnno : 'Senza Data';
    }

    get dataInizioValGGMMAA() {
        return this.dataInizioVal ? this.dataInizioVal.giornoMeseAnno : null;
    }

}


export class AreaControlloPaged extends Paged {
    content: AreaControllo[];

    constructor(data: any) {
        super(data);

        this.content = data.content ? data.content.map((ts: any) => new AreaControllo(ts)) : [];
    }
}

