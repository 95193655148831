<div class="filter-container" *ngIf="filterHeader">

   <app-table-header-filter *ngIf="filterHeader == 'richieste'"
                           [filterTypeList] = filterTypeList
                           [allList]="allList"
                            [type]="type"
                            [testi]="testi"
                           (callBack)="callBack.emit($event)">
   </app-table-header-filter>

    <app-table-header-filter *ngIf="filterHeader == 'report'"
                             [filterTypeList] = filterTypeList
                             [allList]="allList"
                             [type]="type"
                             [testi]="testi"
                             (callBack)="callBack.emit($event)">
    </app-table-header-filter>

    <app-table-header-filter *ngIf="filterHeader == 'utenti'"
                             [filterTypeList] = filterTypeList
                             [allList]="allList"
                             [type]="type"
                             [testi]="testi"
                             (callBack)="callBack.emit($event)">
    </app-table-header-filter>

    <app-table-header-filter *ngIf="filterHeader == 'standard'"
                             [filterTypeList] = filterTypeList
                             [allList]="allList"
                             [type]="type"
                             [testi]="testi"
                             (callBack)="callBack.emit($event)">
    </app-table-header-filter>

    <app-table-header-filter *ngIf="filterHeader == 'us'"
                             [filterTypeList] = filterTypeList
                             [allList]="allList"
                             [type]="type"
                             [testi]="testi"
                             (callBack)="callBack.emit($event)">
    </app-table-header-filter>

    <app-table-header-filter *ngIf="filterHeader == 'tipoMenuFilter'"
                             [filterTypeList] = filterTypeList
                             [allList]="allList"
                             [type]="type"
                             [testi]="testi"
                             (callBack)="callBack.emit($event)">
    </app-table-header-filter>

    <app-table-header-filter *ngIf="filterHeader == 'dieteFilter'"
                             [filterTypeList] = filterTypeList
                             [allList]="allList"
                             [type]="type"
                             [testi]="testi"
                             (callBack)="callBack.emit($event)">
    </app-table-header-filter>


    <app-table-header-filter *ngIf="filterHeader == 'puntiPrelievoFilter'"
                             [filterTypeList] = filterTypeList
                             [allList]="allList"
                             [type]="type"
                             [testi]="testi"
                             (callBack)="callBack.emit($event)">
    </app-table-header-filter>

    <app-table-header-filter *ngIf="filterHeader == 'pazienti'"
                             [filterTypeList] = filterTypeList
                             [allList]="allList"
                             [type]="type"
                             [testi]="testi"
                             (callBack)="callBack.emit($event)"
                             (callBackUpdate)="callBackUpdate.emit($event)">
    </app-table-header-filter>

</div>



<div class="table-header" *ngIf="!buttonAdd">
  <div *ngIf="back">
      <div class="file-upload">
          <button mat-flat-button
                  class="button-style"
                  type="button"
                  color="accent"
                  (click)="indietro()">
              <i class="fas fa-arrow-left"></i> Back
          </button>
      </div>
  </div>
  <div *ngIf="!cleanName" class="table-header-title">
    <!--{{testi.lista + ' ' + tableType}}-->
    <div class="space" *ngFor="let item of [].constructor(centerTitle);">&nbsp;</div>{{testi.lista + ' ' + tableType}}
  </div>
  <div *ngIf="cleanName" class="table-header-title">
    <!--{{tableType}}-->
    <div class="space" *ngFor="let item of [].constructor(centerTitle);">&nbsp;</div>{{tableType}}
  </div>
  <div class="input-aggiorna" *ngIf="buttonAggiorna">
    <button mat-flat-button
            type="button"
            color="accent"
            (click)="eventHeaders(callbackTableState.AGGIORNA)">
      <i class="fas fa-redo-alt"></i> {{testi.aggiorna}}
    </button>
  </div>
    <div *ngIf="dmsAddFolder">
        <div class="file-upload">
            <button mat-flat-button
                    class="button-style"
                    type="button"
                    color="accent"
                    class="upload"
                    (click)="newFolder($event)">
                <i class="fas fa-folder"></i> {{testi.nuovaCartella}}
            </button>
        </div>
    </div>
    <div>&nbsp;</div>
  <div *ngIf="buttonBrowse">
      <div class="file-upload">
        <input #fileInput
               id="importFile"
               type="file"
               style="display: none"
               (change)="fileHandler($event)" />

        <button type="button"
                mat-raised-button
                color="accent"
                class="upload"
                (click)="fileInput.click()">
            <i class="fas fa-upload"></i> {{testi.nuovo}}
        </button>
      </div>
  </div>

  <div *ngIf="searchField" class="search-input">
      <input matInput #searchTerm class="search-input-box" placeholder="&#xF002; Cerca" (keypress)="keypress($event, searchTerm.value)" (click)="searchText(searchTerm.value)">
      <!--<span matSuffi class="icon-pos" (click)="searchText(searchTerm.value)"><i class="fas fa-search" style="transform: translate(12em, -1.5em);"></i></span>-->
  </div>
</div>


<div class="table-header" *ngIf="buttonAdd">

  <div *ngIf="!cleanName" class="table-header-title-add">
    <!--{{testi.lista + ' ' + tableType}}-->
    <div class="space" *ngFor="let item of [].constructor(centerTitle);">&nbsp;</div>{{testi.lista + ' ' + tableType}}
  </div>
  <div *ngIf="cleanName" class="table-header-title-add">
    <!--{{tableType}}-->
    <div class="space" *ngFor="let item of [].constructor(centerTitle);">&nbsp;</div>{{tableType}}
  </div>

  <div class="input-aggiorna" *ngIf="buttonAggiorna">
    <button mat-flat-button
            type="button"
            color="accent"
            (click)="eventHeaders(callbackTableState.AGGIORNA)">
      <i class="fas fa-redo-alt"></i> {{testi.aggiorna}}
    </button>

  </div>
  <div class="input-function" *ngIf="buttonFunc">
    <button mat-flat-button
            type="button"
            color="accent"
            (click)="eventHeaders(callbackTableState.FUNCTION)">
       {{buttonFuncName}}
    </button>
  </div>

  <div class="input-add" *ngIf="!buttonAddName">
    <button *ngIf="!disableAdd"
            mat-flat-button
            type="button"
            color="accent"
            (click)="eventHeaders(callbackTableState.NUOVO)">
      <i class="fas fa-plus"></i> {{testi.nuovo}}
    </button>
  </div>
  <div class="input-add" *ngIf="buttonAddName && buttonAddName != 'Esporta'">
    <button *ngIf="!disableAdd"
            mat-flat-button
            type="button"
            color="accent"
            (click)="eventHeaders(callbackTableState.NUOVO)">
       {{buttonAddName}}
    </button>
  </div>
  <div class="input-add" *ngIf="buttonAddName && buttonAddName == 'Esporta'">
      <button *ngIf="!disableAdd"
              mat-flat-button
              type="button"
              color="accent"
              (click)="eventHeaders(callbackTableState.NUOVO)">
          <i class='fa fa-file-excel' aria-hidden='true'></i>&nbsp;&nbsp;{{buttonAddName}}
      </button>
  </div>

  <div class="input-add" *ngIf="!buttonAddName">
    <button *ngIf="disableAdd"
            mat-flat-button
            type="button"
            color="black"
            style="background-color: darkgrey;"
            [disabled]="true"
            [hidden]="false"
            (click)="eventHeaders(callbackTableState.NUOVO)">
      <i class="fas fa-plus"></i> {{testi.nuovo}}
    </button>
  </div>
  <div class="input-add" *ngIf="buttonAddName">
    <button *ngIf="disableAdd"
            mat-flat-button
            type="button"
            color="black"
            style="background-color: darkgrey;"
            [disabled]="true"
            [hidden]="false"
            (click)="eventHeaders(callbackTableState.NUOVO)">
      {{buttonAddName}}
    </button>
  </div>
  <!--
  <div class="input-add" *ngIf="buttonBrowse">
    <input class="ng-hide" id="input-file-id" multiple type="file" />
    <button for="input-file-id"
            mat-flat-button
            type="button"
            color="accent"
            (click)="eventHeaders(callbackTableState.NUOVO)">
      <i class="fas fa-plus"></i> {{testi.nuovo}}
    </button>
  </div>
  -->
</div>

