<form class="form-base" *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="actionSave()">
  <div class="formContainer" *ngFor="let group of arrayFormInfo">
    <div class="formFieldGroup" *ngFor="let item of group.groupItems; let i = index">
        <div *ngIf="odd(i) && group.groupItems.length != 1" style="width:7%"></div>
            <label [for]="item.prop" class="form-label-center" *ngIf="group.groupItems.length == 1 && group.groupItems[0].input != 'table'">
              <div *ngIf="item.input != 'blank' && item.input != 'table' && !item.hidden">
                {{item.text}}:
              </div>
            </label>
            <label [for]="item.prop" class="form-label" *ngIf="group.groupItems.length != 1">
              <div *ngIf="item.input != 'blank' && item.input != 'table' && !item.hidden">
                {{item.text}}:
              </div>
            </label>
        <div class="form-input" *ngIf="item.input != 'blank'">
          <div *ngIf="group.groupItems.length == 1 && item.input != 'table'" id="div-center">
            <app-form-inputs [formType]="item"
                             [formControlName]="item.prop"
                             [nullDropDown]="nullDropDown"
                             [dmsUpload]="dmsUpload"
                             [objValue]="objValue"
                             [testi] = "testi"
                             [msgForbidden]="msgForbidden"
                             [DragDropMaxHeight]="DragDropMaxHeight"
                             [dropdownList]="selectsGroup!.get(item!.list!)"
                             [dropdownList2]="selectsGroup!.get(item!.list2!)">
            </app-form-inputs>
          </div>
            <div *ngIf="group.groupItems.length == 1 && item.input == 'table'" id="div-table">
                <app-form-inputs [formType]="item"
                                 [formControlName]="item.prop"
                                 [nullDropDown]="nullDropDown"
                                 [objValue]="objValue"
                                 [testi]="testi"
                                 [msgForbidden]="msgForbidden"
                                 [DragDropMaxHeight]="DragDropMaxHeight"
                                 [dropdownList]="selectsGroup!.get(item!.list!)"
                                 [dropdownList2]="selectsGroup!.get(item!.list2!)">
                </app-form-inputs>
            </div>
          <div *ngIf="group.groupItems.length != 1" style="">
                  <app-form-inputs [formType]="item"
                             [formControlName]="item.prop"
                             [nullDropDown]="nullDropDown"
                             [dmsUpload]="dmsUpload"
                             [objValue]="objValue"
                             [testi]="testi"
                             [msgForbidden]="msgForbidden"
                             [DragDropMaxHeight]="DragDropMaxHeight"
                             [DragDropMaxHeight]="DragDropMaxHeight"
                             [dropdownList]="selectsGroup!.get(item!.list!)"
                             [dropdownList2]="selectsGroup!.get(item!.list2!)">
            </app-form-inputs>
          </div>
        </div>
      <div class="form-error"> <span *ngIf="formGroup!.controls[item.prop].invalid"> * </span> </div>
        <div *ngIf="!odd(i) && group.groupItems.length != 1 " style="width:7%"></div>
    </div>

  </div>

  <div class="formContainer">
    <div class="formFieldGroup">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="form-legenda">
    <div *ngIf="formGroup!.invalid" class="legenda-form-error"> * {{testi.obbligatorio}} </div>

    <div *ngFor="let msg of msgs" class="legenda-form-error">
      <span *ngIf="formGroup!.controls[msg.prop].invalid && formGroup!.controls[msg.prop].touched" class="error-validator">
        <div *ngIf="validateRegexEmail(formGroup!.controls[msg.prop].value) && msg.prop == 'email'">
             <div *ngIf="!msg.order">
               {{msg.msg}}
             </div>
        </div>
        <div *ngIf="!validateRegexEmail(formGroup!.controls[msg.prop].value) && msg.prop == 'email'">
             <div *ngIf="msg.order">
               {{msg.msg}}
             </div>
        </div>
          <div *ngIf="formGroup!.controls[msg.prop].value.length <= 2 && msg.prop == 'username'">
             <div *ngIf="!msg.order">
               {{msg.msg}}
             </div>
        </div>
        <div *ngIf="formGroup!.controls[msg.prop].value.length > 2  && msg.prop == 'username'">
             <div *ngIf="msg.order">
               {{msg.msg}}
             </div>
        </div>
        <div *ngIf="msg.prop != 'email' && msg.prop != 'username'">
               {{msg.msg}}
        </div>
      </span>
    </div>
  </div>


  <div class="footerForm" *ngIf="enableFooter">
      <div class="error-message">{{error}}</div>
      <app-form-field-footer [showCancel]="formButton.showCancel || undefined"
                             [showDelete]="formButton.showDelete || undefined"
                             [showSave]="formButton.showSave || undefined"
                             [blockCancel]="formButton.blockCancel || undefined"
                             [blockDelete]="formButton.blockDelete || undefined"
                             [blockSave]="formButton.blockSave || undefined"
                             [textCancel]="formButton.textCancel || undefined"
                             [textDelete]="formButton.textDelete || undefined"
                             [textSave]="formButton.textSave || undefined"
                             [tooltipSave]="formButton.tooltipSave || undefined"
                             [tooltipDelete]="formButton.tooltipDelete || undefined"
                             [newmode]="newmode"
                             [testi]="testi"
                             [disabled]="formGroup!.invalid"
                             (saveForm)="actionSave(formButton.saveForm || '')"
                             (delete)="actionSave(formButton.deleteForm || '')"
                             (cancel)="actionCancel()">
      </app-form-field-footer>
  </div>
</form>
