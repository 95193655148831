import {Paged} from "./Paged";
import {MyDate} from "./MyDate";

export class ListinoCosti {

    new?: boolean;
    colore?: string;
    costoOsp: number;
    costoInso: number;
    dataInizioVal?: MyDate;



    constructor(data: any) {
        Object.assign(this, data);
        this.dataInizioVal = data.dataInizioVal ? new MyDate(data.dataInizioVal) : undefined;

    }

    getOggCreate() {
        return {

            colore: this.colore,
            costoOsp: this.costoOsp,
            costoInso: this.costoInso,
            dataInizioVal: this.dataInizioVal ? this.dataInizioVal.dateSave : null,

        };
    }

    getOggUpdate() {
        return this.getOggCreate();
    }

    get viewValue(): string {
        return this.colore? this.colore : '';
    }
    get value(): ListinoCosti {
        return this;
    }


    get dataInzioValGGMMAA() {
        return this.dataInizioVal ? this.dataInizioVal.giornoMeseAnno : null;
    }


}

export class ListinoCostiPaged extends Paged {
    content: ListinoCosti[];

    constructor(data: any) {
        super(data);

        this.content = data.content ? data.content.map((ts: any) => new ListinoCosti(ts)) : [];
    }
}

