<div class="footerForm">
    <!-- Originali -->
    <button *ngIf="showCancel  && !blockCancel  && !newmode"
            mat-flat-button
            type="button"
            color="accent"
            (click)="cancel.emit()">
        <div *ngIf="textCancel">{{textCancel}}</div> <div *ngIf="!textCancel"><i class="fas fa-times"></i>&nbsp;&nbsp;{{testi.annulla}}</div>
    </button>
    <button *ngIf="showCancel  && blockCancel  && !newmode"
            mat-flat-button
            type="button"
            color="accent"
            [disabled]="blockCancel"
            (click)="cancel.emit()">
        <div *ngIf="textCancel">{{textCancel}}</div> <div *ngIf="!textCancel"><i class="fas fa-times"></i>&nbsp;&nbsp;{{testi.annulla}}</div>
    </button>

    <button *ngIf="showDelete && !blockDelete  && !newmode"
            mat-flat-button
            type="button"
            color="warn"
            [disabled]="disabled"
            (click)="delete.emit()">
        <div *ngIf="textDelete">{{textDelete}}</div> <div *ngIf="!textDelete"><i class="fas fa-trash-alt"></i>&nbsp;&nbsp;{{testi.delete}}</div>
    </button>
    <button *ngIf="showDelete && blockDelete && !newmode"
            mat-flat-button
            type="button"
            color="warn"
            [disabled]="blockDelete"
            (click)="delete.emit()">
        <div *ngIf="textDelete" matTooltip="{{tooltipDelete}}">{{textDelete}}</div> <div *ngIf="!textDelete" matTooltip="{{tooltipDelete}}"><i class="fas fa-trash-alt"></i>&nbsp;&nbsp;{{testi.delete}}</div>
    </button>

    <button *ngIf="showSave && !blockSave && !newmode"
            mat-flat-button
            type="button"
            color="primary"
            [disabled]="disabled"
            (click)="saveForm.emit()">
        <div *ngIf="textSave">{{textSave}}</div> <div *ngIf="!textSave"><i class="fas fa-save"></i>&nbsp;&nbsp;&nbsp;{{testi.salva}}</div>
    </button>
    <button *ngIf="showSave && blockSave && !newmode"
            mat-flat-button
            type="button"
            color="primary"
            [disabled]="blockSave"
            (click)="saveForm.emit()">
        <div *ngIf="textSave" matTooltip="{{tooltipSave}}">{{textSave}}</div> <div *ngIf="!textSave" matTooltip="{{tooltipSave}}"><i class="fas fa-save"></i>&nbsp;&nbsp;{{testi.salva}}</div>
    </button>
    <button *ngIf="showRiattiva && newmode"
            mat-flat-button
            type="button"
            color="primary"
            [disabled]="disabled"
            (click)="delete.emit()">
        {{testi.riattiva}}
    </button>

    <button *ngIf="!hideSave && newmode && !blockSave"
            mat-flat-button
            type="button"
            color="primary"
            [disabled]="disabled"
            (click)="saveForm.emit()">
        <i class="fas fa-save"></i> {{testi.salva}}
    </button>

    <button *ngIf="!hideSave && newmode && blockSave"
            mat-flat-button
            type="button"
            color="primary"
            [disabled]="blockSave"
            (click)="saveForm.emit()">
        <i class="fas fa-save"></i> {{testi.salva}}
    </button>

</div>
