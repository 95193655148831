import {Component, Inject, OnInit} from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TestiService} from "../../../service/config/testi.service";
import {DetailsCallback} from "../../../entities/table/DetailsCallback";
import {CallbackDetailsState} from "../../../entities/enums/CallbackDetailsState";


@Component({
  selector: 'app-richieste-details',
  templateUrl: './richieste-details-pop.component.html',
  styleUrls: ['./richieste-details-pop.component.scss']
})
export class RichiesteDetailsComponentPop implements OnInit {

  title?: string;
  loading = false;

  btn: number;

  form: any;

  role: string;

  formButton: any;


  dropdownList: Map<string,any[]> = new Map<string,any[]>();

  update: boolean;

  msgForbidden: string;

  testi;

  richiesteUsers= ["caposala", "operatore", "responsabile", "gestore", "ammnistratore"]

  constructor(public dialogRef: MatDialogRef<RichiesteDetailsComponentPop>,
              private testiService: TestiService,
              @Inject(MAT_DIALOG_DATA) public data: any)
  {
    this.testi = this.testiService.componentRichieste;
    //console.log(data);
    this.msgForbidden = this.testi.msgForbidden;

  }

  ngOnInit(): void {
  }

  updateForm(event: any) {

  }

  cancel() {
    this.dialogRef.close({type: CallbackDetailsState.CANCEL} as DetailsCallback );
  }

}
