import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {forkJoin, Observable} from "rxjs";


@Injectable({
    providedIn: 'root'
})
// i testi sono caricati sul InitAppService (APP_INITIALIZER)

export class MenuAnagraficheService {

    menu: Map<string, any> = new Map<string, any>();

    get anagraficheMenu() { return this.checkMenuEsite('sdg'); }
    get pulizieMenu() { return this.checkMenuEsite('pulizie'); }
    get lavanoloMenu() { return this.checkMenuEsite('lavanolo'); }
    get ristorazioneMenu() { return this.checkMenuEsite('ristorazione'); }


    constructor( private http: HttpClient,
                 private handler: HttpBackend) {
        this.http = new HttpClient(handler);
    }

    private checkMenuEsite(type: string): any {
        return this.menu.has(type) ? this.menu.get(type) : {};
    }


    private getArrayMenuObservable(): Observable<any>[] {
        return [
            this.jsonRestMenu('sdg', 'sdg'),
            this.jsonRestMenu('pulizie', 'pulizie'),
            this.jsonRestMenu('lavanolo', 'lavanolo'),
            this.jsonRestMenu('ristorazione', 'ristorazione'),
        ];
    }

    setMenu(): Observable<any> {
        const array$ = this.getArrayMenuObservable();
        ////console.log(array$);
        return forkJoin(array$);

    }



    private jsonRestMenu(link: string, type: string) {
        const url = `../../assets/json/menu/${link}.json`;
        const urlConVersion = url;
        return this.http.get(urlConVersion)
             .pipe(
                tap( res => {
                    this.menu.set(type, res);
                } )
            );
    }
}
