import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CallbackTableState} from "../../../entities/enums/CallbackTableState";
import {StatoIntervento} from "../../../entities/enums/StatoIntervento";
import {TableCallback} from "../../../entities/table/TableCallback";
import {MsgService} from "../../../service/utils/msg.service";


@Component({
  selector: 'app-row-data',
  templateUrl: './row-data.component.html',
  styleUrls: ['./row-data.component.scss']
})
export class RowDataComponent implements OnInit {

  constructor(private msgService: MsgService) {
  }

  @Input() rowProp!: any;
  @Input() rowData?: any;
  @Input() pattern?: any;
  @Input() text0?: string;
  @Input() text1?: string;
  @Input() admin = true;
  @Input() colTooltip? = false;
  @Input() size?: number;
  @Input() onlyView = false;
  @Input() refValue?: string = '';
  @Input() noclick?: boolean;
  @Input() testi: any;
  @Input() reload?: boolean = false;

  _rowData: any;

  @Output() callBack = new EventEmitter<TableCallback>();

  @ViewChild('cell') textDiv: ElementRef;


  cellWidth: number;
  maxValue: number = 0;

  selected: number = 0;
  state = CallbackTableState;
  statoIntervento = StatoIntervento;

  // count the clicks
  private clicks = 0;
  private clickTimeout: any | undefined = undefined;

  ngOnInit() {
    if (this.refValue){
      //this.maxValue=Number(this.rowData[this.refValue]);
    }
  }

  ngOnChanges(){
      if (this.rowProp == 'drop01' && this.reload == true) {
        this.selected = this.rowData[this.refValue!];
      }
  }

  ngAfterViewInit() {
    if (this.textDiv){
      this.cellWidth=this.textDiv.nativeElement.clientWidth;
    }
  }

  clickCell(event: TableCallback) {
    this.callBack.emit({type: CallbackTableState.CLICKCELL, data: event });
  }

  callbackEmit(event: TableCallback, type: CallbackTableState) {
    this.callBack.emit({type: type, data: event });
  }

  callbackDropEmit(event: TableCallback, type: CallbackTableState, selected: any) {
    this.callBack.emit({type: type, data: {event: event, selected: selected}});
  }

  callbackInputChange(event: TableCallback, type: CallbackTableState, selected: any) {
    if (this.refValue) {
      if (this.selected >= 0) {
        this.callBack.emit({type: type, data: {event: event, selected: selected}});
      } else {
        if (this.selected < 0){
          this.msgService.msgStringError(this.testi,"Valori negativi non Ammessi ")
        }
        this.selected = 0;
      }
    }
  }

  public singleDoubleClick(event: TableCallback, type: CallbackTableState): void {
    this.clicks++;
    if (this.clickTimeout) {
      if (this.clicks <= 2) {
        this.setClickTimeout(this.handleDoubleClick(event));
      } else {
        this.setClickTimeout(() => {});
      }
    } else {
      this.setClickTimeout(this.handleSingleClick(event) );
    }
  }

  stringSize(text: string, size: number | undefined){
    if (text) {
      if (size && text.length > size && text.length > 0) {
        //text = text.substring(0, size);
        //text = text + "...";
      }
    }
    return text;
  }
  
  tesaurus(value: number, text0: string, text1: string | undefined){
    if (value==0) {
     return text0;
    } else {
      return text1;
    }
  }

  private setClickTimeout(cb: any) {
    if (this.clickTimeout) {
      clearTimeout(this.clickTimeout);
    }
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = undefined;
      this.clicks = 0;
    }, 400);
  }

  private handleSingleClick(event: any) {
    this.callBack.emit({type: CallbackTableState.SINGLE, data: event });
  }

  private handleDoubleClick(event: any) {
    this.callBack.emit({type: CallbackTableState.DOUBLE, data: event });
  }
}
