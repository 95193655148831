export enum CallbackDetailsState {
  CANCEL = 'CANCEL',
  SAVE = 'SAVE',
  SUCESSO = 'SUCESSO',
  DELETE = 'DELETE',
  NEW = 'NEW',
  REFRESH = 'REFRESH',
  CONTESTA = 'CONTESTA',
  APPROVA = 'APPROVA',
  RIFIUTA = 'RIFIUTA',
  RIVENDICA = 'RIVENDICA',
  RIPRENDI = 'RIPRENDI',
  CRIFIUTO = "CRIFIUTO",
  NCRIFIUTO = "NCRIFIUTO",
  ACCETTA = "ACCETTA",
  RRICHIESTA = "RRICHIESTA",
  CRICHIESTA = "CRICHIESTA",
  Rpulizie = "Rpulizie",
  Cpulizie = "Cpulizie",
  CHIUDI = "CHIUDI",
  CONFERMA = "CONFERMA"
}
