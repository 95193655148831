import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {CallbackTableState} from "../../../../entities/enums/CallbackTableState";
import {TestiService} from "../../../../service/config/testi.service";
import {StatoService} from "../../../../service/stato.service";
import {MyDate} from "../../../../entities/MyDate";
import {TableCallback} from "../../../../entities/table/TableCallback";

@Component({
  selector: 'app-table-header-tipostruttura',
  templateUrl: './table-header-tipostruttura.component.html',
  styleUrls: ['./table-header-tipostruttura.component.scss']
})
export class TableHeaderTipoStrutturaComponent implements OnInit {
  @Input() testi?: any;

  @Output() callBack = new EventEmitter<TableCallback>();

  allList: Map<string, any[]> = new Map<string, any[]>();

  filterPrimaRiga: any[] = [];
  filterSecondaRiga: any[] = [];

  filterSelezionato: any[][] = [];
  filtroCambiato = false;

  constructor(
              private statoService: StatoService,
              private testiService: TestiService) {
  }

  ngOnInit(): void {
  // this.setAllList();
    this.setFilter();
    const qtFilter = this.filterPrimaRiga.length + this.filterSecondaRiga.length;
    for (let i = 0; i < qtFilter; i++ ) {
      this.filterSelezionato.push([]);
    }
  }

  private setFilter() {
    this.filterPrimaRiga = [
      {label: this.testi.descrizione + ':',  type: 'text', max: '', param: 'descrizione', index: 0 },
    ];

    this.filterSecondaRiga = [
   /*  {label: this.testi.vivaio + ':',  type: 'dropdown', param: 'idVivaio', lista: 'vivaioList', prop: 'id', index: 3  },
      {label: this.testi.coltura + ':',  type: 'dropdown', param: 'idColtura', lista: 'colturaList', prop: 'id', index: 4  },
      {label: this.testi.senzaCaposquadra + ':',  type: 'checkbox', param: 'senzaCaposquadra', index: 5  }, */
    ];
  }

  filterText(event: any, param: string, index: number) {
    this.filterSelezionato[index] = event ? [param, event] : [];
    this.filtroCambiato = true;
  }


  filterDropdown(event: any, prop: string, param: string, multiple: boolean, index: number) {
    if (multiple) {
      const selectedList = event.map((item: any) => item[prop] ).join(',');
      this.filterSelezionato[index] = event.length ? [param, selectedList] : [];
    } else {
      this.filterSelezionato[index] = event ? [param, event[prop]] : [];
    }
    this.filtroCambiato = true;
  }


  filterDate(event: Date, param: string, index: number) {
    let myDate = event ? new MyDate(event) : null;
    if (param == 'dataA' && myDate) {
      myDate = myDate.addDays(1);
    }
    this.filterSelezionato[index] = myDate ? [param, myDate.dateSave] : [];
    this.filtroCambiato = true;
  }

  filterCheckBox(event: Object, param: string, index: number) {
    this.filterSelezionato[index] = event ? [param, '1'] : [];
    this.filtroCambiato = true;
  }


  applicaFilter() {
    const filter = this.filterSelezionato.filter( item => item.length );
    this.callBack.emit({type: CallbackTableState.FILTER, data: filter});
    this.filtroCambiato = false;
  }

  exportExcel() {
    this.callBack.emit({type: CallbackTableState.EXCEL});
  }

  private setAllList() {

    const arrayName: Map<string, Observable<any[]>>  = new Map<string, Observable<any[]>>();
    //arrayName.set('vivaioList', this.vivaioService.getAllVivaio());
   // arrayName.set('colturaList', this.colturaService.getAllColture());
    arrayName.set('statoList', this.statoService.getAllStato());
    this.getallList(arrayName);
  }

  private getallList(arrayName: Map<string, Observable<any[]>>) {
    arrayName.forEach( (value: Observable<any[]>, key: string) => this.allList.set(key, []));

    arrayName.forEach( (value: Observable<any[]>, key: string) => {
      value.subscribe( res => this.allList.set(key, res));
    });
  }

}
