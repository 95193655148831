import {StatoIntervento} from './enums/StatoIntervento';

export class Stato {
    id?: number;
    stato?: StatoIntervento;

    constructor(data: any) {
        Object.assign(this, data);
    }

    getOggCreate(): StatoCreate {
        return {
            stato: this.stato!,
        };
    }

    getOggUpdate(): StatoUpdate {
        const oggCreate: Partial<StatoUpdate> = this.getOggCreate();
        oggCreate.id = this.id!;
        return oggCreate as StatoUpdate;
    }

    get viewStato(): string {
        return this.stato ? this.stato : '';
    }

    get viewValue(): string {
        return this.stato ?? '';
    }

    get value(): Stato {
        return this;
    }
}

export interface StatoCreate {
    stato: StatoIntervento;
}

export interface StatoUpdate extends StatoCreate {
    id: number;
}
