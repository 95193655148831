import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {TableName} from "../../../entities/table/TableName";
import {TestiService} from "../../../service/config/testi.service";
import {TableCallback} from "../../../entities/table/TableCallback";


@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss']
})
export class TableViewComponent implements OnInit, OnChanges {

  @Input() disableAdd?: boolean = false;
  @Input() tableDataHeader!: TableName[];
  @Input() centerTitle?: number = 0;
  @Input() tableData: any[] = [];
  @Input() allList: Map<string, any[]> = new Map<string, any[]>();
  @Input() tableType?: string;
  @Input() admin?: boolean;
  @Input() testi?: any;
  @Input() type?: string = '';
  @Input() expand = false;
  @Input() buttonAdd = true;
  @Input() buttonBrowse = false;
  @Input() buttonAddName?: string;
  @Input() buttonFunc? = false;
  @Input() buttonFuncName?: string;
  @Input() cleanName = false;
  @Input() emptyMessage?: string;
  @Input() buttonAggiorna = false;
  @Input() overflow: string = 'auto';
  @Input() showHeader = true;
  @Input() hideTitle? = false;
  @Input() showFooter = true;
  @Input() noRowPattern? = false;
  @Input() scroll? = true;
  @Input() input?: boolean = false;
  @Input() rowResize? = true;
  @Input() colorSelected?: string;
  @Input() rows = 1;
  @Input() pulizie: boolean = false;
  @Input() height?: number;
  @Input() dataKey = 'id';
  @Input() filterHeader?: string;
  @Input() filterHeaderList?: any[];
  @Input() filterDataList?: Map<string, any[]>;
  @Input() pageIndex?: number;
  @Input() totalPageslazy: number|undefined;
  @Input() loading = false;
  @Input() onlyView = false;
  @Input() noScrollV = false;
  @Input() noclick?:boolean;
  @Input() searchField? = false;
  @Input() back?=false;
  @Input() dmsAddFolder? = false;
  @Input() dms?: boolean = false;
  @Input() extendRows?: boolean = false;
  @Input() titleStyle?: string = '';
  @Input() reload?: boolean = false;

  @Output() callBackPaginator: EventEmitter<PageEvent> = new EventEmitter();
  @Output() callBackUpdate = new EventEmitter<TableCallback>();
  @Output() callBack = new EventEmitter<TableCallback>();

  @ViewChild('table', { static: true }) table!: ElementRef;

  _tableData: any[] = [];

  filterSelected: any[] = [];

  testiIntervento: string;

  constructor(private testiService: TestiService) {
    this.testiIntervento = 'Interventi';
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    changes['tableData'] && (this._tableData = this.tableData);
  }

  setFilter(event: any) {
    switch (event.type) {
      case 'print':
        break;
      case 'filterData':
        this.callBack.emit(event);
        // this.filterSelected = [...event.filter];
        break;
    }
  }

}
