import {MyDate} from './MyDate';
import {Role} from './Role';
import * as moment from 'moment';
import {Credentials} from "./Credentials";

export class Utente {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  validityEnd?: MyDate | undefined;
  validityStart?: MyDate;
  editDate?: MyDate | undefined;
  editUser?: string;
  enabled: boolean;
  roles: Role[];
  note?: string;
  credentials?: Credentials;
  dms?: string;

  constructor(data: any) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.username = data.username;
    this.email = data.email;
    this.enabled = data.enabled;
	this.editUser = data.attributes ? data.attributes.EditUser : data.editUser ? data.editUser : '';
    this.note = data.attributes ? data.attributes.Note : data.note ? data.note : '';
    this.dms = data.attributes ? data.attributes.dms : data.dms ? data.dms : '';

    let dI = data.attributes ? moment(data.attributes.ValidityStart[0], "DD/MM/YYYY") : undefined;
    this.validityStart = dI ? new MyDate(dI) : data.validityStart ? data.validityStart : undefined ;

    let dF = data.attributes ? moment(data.attributes.ValidityEnd[0], "DD/MM/YYYY") : undefined;
    if (dF) {
      this.validityEnd = dF.isValid() ? new MyDate(dF): data.validityEnd ? data.validityEnd : undefined ;
    } else if (data.editDate) {
      this.validityEnd =  data.validityEnd ? data.validityEnd : undefined;
    }else{
      this.validityEnd = undefined;
    }

    let eD = data.attributes ? moment(data.attributes.EditDate[0], "DD/MM/YYYY - HH:MM") : undefined;
    this.editDate = eD ? new MyDate(eD) : data.editDate ? data.editDate : undefined ;

    this.roles = data.roles ? data.roles.map((role: any) => new Role(role)) : [];
  }


  get dataFineValidita(): string | undefined {
    return this.validityEnd ? new MyDate(this.validityEnd).giornoMeseAnno : undefined;
  }

  get dataInizioValidita(): string {
    return new MyDate(this.validityStart).giornoMeseAnno;
  }

  get dataModificaUtente(): string {
    return new MyDate(this.editDate).giornoMeseAnnoOre;
  }

  getOggUpdate() {
    return this.getOggCreate();
  }

  getOggCreate(): UtenteCreate {
    return {
      username: this.username!,
      enabled: this.enabled,
      attributes: Object.fromEntries(this.getAttributeUser()),
      realmRoles: this.roles.map(rl => new String(rl.name)),
      firstName: this.firstName!,
      lastName: this.lastName!,
      email: this.email!
    };
  }


  getAttributeUser() : Map<string, string> {
    const m =  new Map<string, string>();
    m.set("ValidityStart", this.dataInizioValidita);
    m.set( "ValidityEnd", this.dataFineValidita || '');
    m.set( "Note", this.note ? this.note : "");
    m.set("EditDate", this.dataModificaUtente);
    m.set("EditUser", this.editUser ? this.editUser : "");
    m.set("dms", this.dms ? this.dms: "");
    return m;

  }
}

export interface UtenteCreate {
  username: string;
  attributes: any ;
  firstName: string;
  lastName: string;
  email: string;
  realmRoles: String[];
  enabled: boolean;
  dms?: string;
}

export interface UtenteUpdate extends UtenteCreate {
  id: number;
}
