export class Role {
  id?: string;
  name?: string;
  description?: string;

  constructor(data: any) {
    Object.assign(this, data);
  }

  get viewValue(): string {
    return this.description ? this.description : '';
  }

  getOggCreate(): RoleCreate {
    return {
      id: this.id!,
      name: this.name!,
      description: this.description!,
    };
  }


  getOggUpdate() {
    return {
      id: this.id,
      desc: this.description
    };
  }
}


export interface RoleUpdate {
  id: string;
  desc: string;
}

export interface RoleCreate {
  id: string;
  name: string;
  description: string;

}
