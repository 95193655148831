import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {
    impianto,
    statoTicket,
    tipoInterventi,
    tipoRichiesta,
    tipoRifiuto,
    tipoStanza,
    tipoUnitaServizio,
    unitaServizio, unitaServizioExtended,
    urgenza,
    utenteGestore
} from "../entities/richiesteDetails";
import {Richiesta, richiestaPaged, storico} from "../entities/Richiesta";
import {HttpHeaders} from "@angular/common/http";
import {TestiService} from "./config/testi.service";
import {ApiRestServiceRichieste} from "./api-rest.service-richieste";
import {ApiRestKeycloakService} from "./portal/api-rest-keycloak.service";
import {MsgService} from "./utils/msg.service";
import {MailService} from "./portal/mail.service";
import {Report, ReportPaged} from "../entities/Report";


@Injectable({
    providedIn: 'root'
})
export class RichiesteService {

    richieste: Richiesta[] | undefined;

    testi: any;

    utente: string;

    update = 2;

    constructor(private apiKeycloakRestService: ApiRestKeycloakService,
                private mailService: MailService,
                private apiRestService: ApiRestServiceRichieste,
                private msgService: MsgService,
                private testiService: TestiService){
        this.testi = this.testiService.componentRichieste;
    }

    getStorico(idTicket: string): Observable<storico[]> {
        const url = 'storicoticket/idticket=' + idTicket;
        return this.apiRestService.getWithHeader(url)
            .pipe(
                map(res => res.map((st: any) => new storico(st)))
            );
    }

    getRichiesteFiltrataPaged(page: number, size: number, params: any[][]): Observable<richiestaPaged> {
        const url = `ticket/dto/filtratapaged?page=${page}&size=${size}`;
        const headers = new HttpHeaders({'Content-Type': 'application/json', 'Paged': 'true'});
        return this.apiRestService.getWithParams(url,params, headers)
            .pipe(
                map(res => new richiestaPaged(res))
            );
    }

    getReportFiltrataPaged(page: number, size: number, params: any[][]): Observable<ReportPaged> {
        const url = `ticket/report/filtratapaged?page=${page}&size=${size}&idTipologiaServizio=1`;
        const headers = new HttpHeaders({'Content-Type': 'application/json', 'Paged': 'true'});
        return this.apiRestService.getWithParams(url,params, headers)
            .pipe(
                map(res => new ReportPaged(res))
            );
    }

    getReportFiltrata(params: any[][]): Observable<Report[]> {
        const url = `ticket/report/filtrata?`;
        const headers = new HttpHeaders({'Content-Type': 'application/json', 'Paged': 'true'});
        return this.apiRestService.getWithParams(url,params, headers)
            .pipe(
                map(res => res ? res.map((report: any) => new Report(report)) : [])
            );
    }

    getRichiesteType(): Observable<tipoRichiesta[]> {
        const url = 'tipirichieste/all';
        return this.apiRestService.getWithHeader(url)
            .pipe(
                map(res => res ? res.map((tipoRich: any) => new tipoRichiesta(tipoRich)) : [])
            );
    }

    getRichiesteFiltered4InsertType(): Observable<tipoRichiesta[]> {
        const url = 'tipirichieste/filteredinsert';
        return this.apiRestService.getWithHeader(url)
            .pipe(
                map(res => res ? res.map((tipoRich: any) => new tipoRichiesta(tipoRich)) : [])
            );
    }

    getInterventiType(flag:boolean): Observable<tipoInterventi[]> {
        const url = 'tipointervento/allbyuser?flaginterne='+ flag;
        return this.apiRestService.getWithHeader(url)
            .pipe(
                map(res => res.map((tipoInt: any) => new tipoInterventi(tipoInt)))
            );
    }

    getUnitaServizioType(): Observable<unitaServizio[]> {
        const url = 'utentiunitaservizio/allstruttureusbyutente ';
        //const url = 'unitaservizio/all';
        return this.apiRestService.getWithHeaderAlt(url)
            .pipe(
                map(res => res.map((tipoUS: any) => new unitaServizio(tipoUS)))
            );
    }

    getUnitaServizioFullType(): Observable<unitaServizioExtended[]> {
        const url = 'utentiunitaservizio/allstruttureusbyutentevisualizza ';
        //const url = 'unitaservizio/all';
        return this.apiRestService.getWithHeaderAlt(url)
            .pipe(
                map(res => res.map((tipoUS: any) => new unitaServizioExtended(tipoUS)))
            );
    }

    getRifiutoType(): Observable<tipoRifiuto[]> {
        const url = 'tiporifiuto/all';
        return this.apiRestService.getWithHeader(url)
            .pipe(
                map(res => res.map((tipoR: any) => new tipoRifiuto(tipoR)))
            );
    }

    getUSType(): Observable<tipoUnitaServizio[]> {
        const url = 'utentiunitaservizio/allstruttureusbyutente ';
        //const url = 'unitaservizio/all';
        return this.apiRestService.getWithHeaderAlt(url)
            .pipe(
                map(res => res.map((tipoUS: any) => new tipoUnitaServizio(tipoUS)))
            );
    }

    getUrgenzaType(): Observable<urgenza[]> {
        const url = 'urgenza/all';
        return this.apiRestService.getWithHeader(url)
            .pipe(
                map(res => res.map((tipoUr: any) => new urgenza(tipoUr)))
            );
    }

    getImpiantoType(): Observable<impianto[]> {
        const url = 'impianto/all';
        return this.apiRestService.getWithHeader(url)
            .pipe(
                map(res => res.map((tipoImp: any) => new impianto(tipoImp)))
            );
    }

    getUtentiGestore(): Observable<utenteGestore[]> {
        const url = 'gestori/all';
        return this.apiRestService.getWithHeaderAlt(url)
            .pipe(
                map(res => res.map((ug: any) => new utenteGestore(ug)))
            );
    }

    getStatoTicketType(): Observable<statoTicket[]> {
        const url = 'stati/all';
        return this.apiRestService.getWithHeader(url)
            .pipe(
                map(res => res.map((tipoRich: any) => new statoTicket(tipoRich)))
            );
    }

    getStanzeType(id: number): Observable<tipoStanza[]> {
        const url = 'stanze/allbyunitaservizio/id='+id;
        return this.apiRestService.getWithHeaderAlt(url)
            .pipe(
                map(res => res.map((tipoRich: any) => new tipoStanza(tipoRich)))
            );
    }


    getAllRichiesteByUtente(): Observable<Richiesta[]> {
        const url = 'ticket/dto/byutente';
        return this.apiRestService.getWithHeader(url)
            .pipe(
                map(res => res.map((richiesta: any) => new Richiesta(richiesta)))
            );
    }

    setUpdate() {
        this.update = 0;
    }


    getUtente(): string {
        return this.utente;
    }

    avanzaRichiesta(url: string,richiesta: Richiesta): Observable<Richiesta> {
        //console.log(richiesta.getOggStep())
        return this.apiRestService.patchWithBody(url, richiesta.impianto ? richiesta.getOggStep2(): richiesta.getOggStep());
    }

    updateRichiesta(url: string,richiesta: Richiesta): Observable<Richiesta> {
        return this.apiRestService.patchWithBody(url, richiesta.getOggUpdate());
    }

    contestaRichiesta(url: string,richiesta: Richiesta): Observable<Richiesta> {
        richiesta.tipoRichiesta = new tipoRichiesta({idTipoTicket: 2, desTipoTicket: "Contestazione per intervento eseguito"});
        return this.apiRestService.patchWithBody(url, richiesta.getOggContesta());
    }


    createRichiesta(richiesta: Richiesta): Observable<Richiesta> {
        const url = `ticket/dto`;
        return this.apiRestService.postWithBody(url, richiesta.getOggCreate());
    }
}
