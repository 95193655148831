import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {debounceTime, distinctUntilChanged, Subject} from "rxjs";
import {MsgService} from "../../../service/utils/msg.service";

class FormControl {
}

@Component({
  selector: 'app-ble-input-textarea',
  templateUrl: './ble-input-textarea.component.html',
  styleUrls: ['./ble-input-textarea.component.scss']
})
export class BleInputTextareaComponent implements OnInit {

  initialValue = "";
  revertValue = "";

  @Input() valore: string | '';
  @Input() testo ?: string;
  @Input() block?: boolean;
  @Input() invalid ? = false;
  @Input() hidden ? = false;
  @Input() testi?: any;
  @Input() rows ? = 2;
  @Input() cols ? = 30;
  @Input() append? = true;
  @Input() max = 3500;
  @Input() msgForbidden?: string | '';

  @Output() cambioValore = new EventEmitter();

  valoreUpdate = new Subject<string>();

  constructor(private msgService: MsgService) { }

  ngOnInit(): void {
    this.msgForbidden = this.msgForbidden != '' ? this.msgForbidden : "Non è possibile elimare testo già presente";
    if (this.valore != undefined) {
      let text = "";
      for (let valori of this.valore) {
        text += valori;
      }
      if (this.append) {
        this.initialValue = this.valore ? text : '';
        this.revertValue = this.valore ? text : ''
      }
    }
    this.valoreUpdate.pipe(
            debounceTime(250),
            distinctUntilChanged()
        )
        .subscribe((value: any) => {
          this.inputChange(value)});
  }


  inputChange(valore: string) {
    //TODO controlla se va
    if (!valore.includes(this.initialValue)){
      this.msgService.msgAlert(this.msgForbidden!, this.testi);
      valore = this.revertValue;
      this.valore = this.revertValue;
      this.valoreUpdate.next(valore);
    } else {
      this.revertValue = valore;
    }
    if (valore.length >= this.max) {
      this.msgService.msgStringErrorMaxChar(this.testi);
    }
    this.cambioValore.emit(valore);
  }
}
