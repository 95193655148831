import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {HttpHeaders} from "@angular/common/http";
import {MailService} from "./mail.service";
import {MsgService} from "./utils/msg.service";
import {TestiService} from "./config/testi.service";
import {Pulizie} from "../entities/Pulizie";
import {PulizieHeader} from "../entities/PulizieHeader";
import {TreeNodeMaterial} from "../entities/TreeNodeMaterial";
import {Richiesta} from "../entities/Richiesta";
import {UnitaServizio} from "../entities/UnitaServizio";
import {Stanza} from "../entities/Stanza";
import {ApiRestServicePulizie} from "./api-rest.service-pulizie";
import {ApiRestKeycloakService} from "./portal/api-rest-keycloak.service";


@Injectable({
    providedIn: 'root'
})
export class PulizieService {

    pulizie: Pulizie[] | undefined;

    testi: any;

    utente: string;

    selectedTree: TreeNodeMaterial[];

    update = 2;

    constructor(private apiKeycloakRestService: ApiRestKeycloakService,
                private mailService: MailService,
                private apiRestService: ApiRestServicePulizie,
                private msgService: MsgService,
                private testiService: TestiService){
        this.testi = this.testiService.componentPulizie;
    }

    setSelectedTree(tree: any){
        this.selectedTree = tree;
    }

    getSelectedTree(){
        return this.selectedTree;
    }

    getTree(): Observable<TreeNodeMaterial[]> {
        const url = 'blocchi/tree';
        return this.apiRestService.getWithHeaderAlt(url)
            .pipe(
                map(res => res.map((tree: any) => new TreeNodeMaterial(tree)))
            );
    }

    getUnitaServizio(): Observable<UnitaServizio[]> {
        const url = 'unitaservizio/all';
        return this.apiRestService.getWithHeaderAlt(url)
            .pipe(
                map(res => res.map((us: any) => new UnitaServizio(us)))
            );
    }

    getStanze(): Observable<Stanza[]> {
        const url = 'stanze/all';
        return this.apiRestService.getWithHeaderAlt(url)
            .pipe(
                map(res => res.map((us: any) => new Stanza(us)))
            );
    }

    getStanzeByUS(us: string): Observable<Stanza[]> {
        const url = 'stanze/allbyunitaservizio/id='+us;
        return this.apiRestService.getWithHeaderAlt(url)
            .pipe(
                map(res => res.map((us: any) => new Stanza(us)))
            );
    }


    getStanzaDettaglio(param: any[][], id:string): Observable<PulizieHeader> {
        const url = 'ordinipulizieordinarie/stanzadettaglio/id='+id;
        const headers = new HttpHeaders({'Content-Type': 'application/json', 'Paged': 'true'});
        return this.apiRestService.getWithParams(url,param, headers)
            .pipe(
                map(res => new PulizieHeader(res))
            );
    }


    createRichiesta(richiesta: any): Observable<any> {
        const url = `ticket/dto`;
        return this.apiRestService.postWithBodyAlt(url, richiesta);
    }

    patchRichiesta(id: string, type: string): Observable<any> {
        const url = `ticket/savebyall/idordine=` + id + '/modulo=pulizie/tipo='+type;
        return this.apiRestService.patchSenzaBodyRic(url);
    }

    getRichiesta(id: string): Observable<Richiesta> {
        const url = `ticket/dto/id=` + id;
        return this.apiRestService.getWithHeaderAlt2(url)
            .pipe(
                map(res => new Richiesta(res))
            );
    }
}
