/*import('./bootstrap')
    .catch(err => console.error(err));
*/
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import { KeycloakService } from 'keycloak-angular';
import {KeycloakInstance} from "keycloak-js";


if (environment.production) {
    enableProdMode();
}


declare const window: any;
const keycloak = window.keycloak;

if (keycloak) {
    //keycloak.logout();
    const keycloakService = new KeycloakService();
    console.log(document.cookie);
    if (document.cookie == "logout=true"){
        keycloak.logout();
        document.cookie = "logout=false;";
    }
    if (!keycloak.authenticated){
        console.error("Forbidden");
    }
    // @ts-ignore
    keycloakService['_instance'] = keycloak as KeycloakInstance;
    platformBrowserDynamic([
        { provide: 'keycloak-service', useValue: keycloakService},
    ]).bootstrapModule(AppModule)
        .catch((error) => console.error('Error bootstrapping the application', error));
} else {
    console.error('Keycloak object not found. Reloading.');
    setTimeout(() => window.location.reload(true), 2000);
}




